<template>
  <div class="resource">
    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd" v-if="judgeButton('add')">新增</el-button>
    <el-button type="success" plain size="small" icon="el-icon-edit" :disabled="!edit" @click="handleEdit" v-if="judgeButton('edit')">编辑</el-button>
    <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel" v-if="judgeButton('delete')">删除</el-button>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%;margin-top:16px"
      @selection-change="handleSelectionChange"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      v-loading="loading">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        label="内容"
        prop="thingDimeType" v-if="resData.kindDealType == '1'">
        <template slot-scope="scope">
          <DictTag :options="dropDown.thingDimeType" :value="scope.row.thingDimeType"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="别名">
      </el-table-column>
      <el-table-column 
        label="操作"
        fixed="right"
        width="120">
        <template slot-scope="scope">
          <el-button
            size="mini" icon="el-icon-edit"
            @click="handleEdit(scope.row)" v-if="judgeButton('edit')">编辑</el-button>
          <el-button
            size="mini" icon="el-icon-delete"
            @click="handleDel(scope.row)" v-if="judgeButton('delete')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="60px"
        class="demo-ruleForm"
      >
        <el-form-item label="内容" prop="thingDimeType" v-if="resData.kindDealType == '1'">
          <el-select v-model="ruleForm.thingDimeType" placeholder="请选择内容" style="width:100%">
            <el-option v-for="(item, index) in dropDown.thingDimeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="别名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入别名" style="width:100%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isDisabled">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
import {mapState} from 'vuex'
import DictTag from '@/components/DictTag.vue'
  export default {
    components: {
      DictTag,
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    props: {
      resData: {
        type: Object,
      },
    },
    data() {
      return {
        tableData: [],
        dialogFormVisible: false,
        ruleForm: {
          name: "",
          thingDimeType: ''
        },
        // 维度
        options: [],
        // 弹出层标题
        title: '',
        edit: false,
        del: false,
        multipleSelection: [],
        ids: [],
        loading: true,
        isDisabled: false,
        rules: {
          name: [{ required: true, message: "请输入名称", trigger: "submit" }],
        },
      }
    },
    created() {
      // 获取内容维度类型下拉框
      if(!this.dropDown.thingDimeType) {
        this.$http.get(url.getDataList, {params: {type: 'buss_thing_dimeType'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setThingDimeType', res.data.data)
          }
        })
      }
      this.getList()
    },
    methods: {
      judgeButton(type) {
        return this.currentMenu.perms.some(item => item.menuType == 'F' && item.perms == `${this.currentMenu.kind}:${type}`)
      },
      // 拿资源维度列表
      getList() {
        this.$http.get(url.getThingaliasList, {params: {cbRestypeId: this.resData.cbRestypeId}}).then(res => {
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
            this.loading = false
          }
        })
      },
      // 表格选中框事件
      handleSelectionChange(rows) {
        // this.multipleSelection = rows
        this.ids = rows.map(item => item.ofrThingAliasId)
        this.edit = rows.length === 1
        this.del = rows.length
      },
      // 表格的修改按钮
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.ofrThingAliasId || this.ids[0]
        this.title = '编辑别名'
        this.dialogFormVisible = true
        // this.selected = row
        this.$http.get(url.getThingalias + row.ofrThingAliasId).then(res => {
          if(res.data.code === 200) {
            let data = res.data.data
            for(let key in data) {
              this.ruleForm[key] = data[key]
            }
          }
        })
      },
      // 表格的删除按钮
      handleDel(row) {
        // this.selected = row
        const ids = row.ofrThingAliasId || this.ids.toString()
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeThingalias + ids).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isDisabled = true
            if(!this.ruleForm.ofrThingAliasId) {
              this.$http.post(url.addThingalias, this.ruleForm).then(res => {
                // console.log(res);
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }else {
              this.$http.post(url.editThingalias, this.ruleForm).then(res => { 
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleAdd() {
        this.title = '新增别名'
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        this.dialogFormVisible = true
        // 初始化表单内容
        for(let key in this.ruleForm) {
          this.ruleForm[key] = ""
        }
        if(this.resData.kindDealType == '1') {
          this.ruleForm.thingDimeType = '1'
        }else {
          this.ruleForm.thingDimeType = '0'
        }
        this.ruleForm.cbRestypeId = this.resData.cbRestypeId
        this.ruleForm.ofrResourceId = 0
      }
    }
  }
</script>

<style lang="scss" scoped>
.resource {
  .el-table {
    .el-button {
      color: #0c75e5;
      border: 0;
      padding: 0;
    }
  }
}
</style>