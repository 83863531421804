<template>
  <div class="contain">
    <div class="header-title">总分类账</div>
    <div class="inquire">
      <el-date-picker
        v-model="date"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        format="yyyy-MM"
        value-format="yyyy-MM"
        size="mini"
        style="margin-bottom: 16px;margin-right:16px"
        :clearable="false"
        @change="handleChange">
      </el-date-picker>
      <el-select v-model="accountType" placeholder="请选择账套" filterable @change="handleChange" size="mini">
        <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
      </el-select>
    </div>
    <el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 100%" v-loading="loading" :header-cell-style="{ background: '#F9F9FB', color: '#606266' }" :height="tableHeight">
      <el-table-column prop="subjectNumber" label="科目编码" header-align="center"></el-table-column>
      <el-table-column prop="subjectName" label="科目名称" header-align="center"></el-table-column>
      <el-table-column prop="abstractStr" label="摘要" width="100" align="center"></el-table-column>
      <el-table-column prop="period" label="期间" width="100" align="center"></el-table-column>
      <el-table-column prop="debit" label="借方" align="right" header-align="center">
        <template slot-scope="scope">
          {{scope.row.debit | comdify('no')}}
        </template>
      </el-table-column>
      <el-table-column prop="credit" label="贷方" align="right" header-align="center">
        <template slot-scope="scope">
          {{scope.row.credit | comdify('no')}}
        </template>
      </el-table-column>
      <el-table-column prop="direction" label="方向" width="50" align="center"></el-table-column>
      <el-table-column prop="amount" label="余额" align="right" header-align="center">
        <template slot-scope="scope">
          {{scope.row.amount | comdify('no')}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import url from '@/config/url'
import handleTree from '@/utils/handleTree'
import { mapState } from "vuex"
  export default {
    computed: {
      ...mapState({
        tagsList: (state) => state.tags.tagsList,
        dropDown: state => state.dropDown.dropDown
      }),
    },
    data() {
      return {
        date: [
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-01'),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))
        ],
        accountType: '1',
        loading: true,
        tableData: [],
        tableHeight: window.innerHeight - 230,
      }
    },
    created () {
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      this.getList();
      window.addEventListener('setItem', ()=> {
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        this.getList()
      })
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 230;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 230;
        })()
      }
    },
    methods: {
      // 默认接受四个值 { 当前行的值, 当前列的值, 行的下标, 列的下标 }
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0 || columnIndex === 1 ) {
          if (rowIndex % 3 === 0) {
            return {
              rowspan: 3,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      handleChange() {
        this.getList()
      },
      getList() {
        this.loading = true
        this.$http.get(url.totalAccountSubject, {
          params: {
            startDate: this.date[0],
            endDate: this.date[1],
            accountType: this.accountType
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
          }
        })
      },
      handleDetail(item) {
        // this.dialogFormVisible = true
        // console.log(item);
        // this.$router.push('/accounting/detail')
        this.$router.push({path: '/accounting/detail', query: {
          accountType: this.activeName,
          subjectNumber: this.totalAccount.subjectNumber,
          subjectName: this.totalAccount.subjectName,
          date: item.period
        }})
        let obj = {
          path: '/accounting/detail',
          name: 'lendgerDetail',
          label: '财务',
          children: [],
          menuId: 43
        }
        let path = '/accounting/detail'
        this.tagsList.forEach((val, index) => {
          // console.log(val.path.split('/'));
          
          if(obj.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path)) {
            // console.log(val);
            for(let key in val) {
              if(key != 'children' && key != 'label') {
                val[key] = obj[key]
              }
            }
            this.$store.commit('selectMenu', val)
          }
        })
        this.$store.commit("changeMenu", obj);
      },
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  min-height: calc(100vh - 146px);
  .header-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
</style>