
 
// 金额添加千分位
export function comdify(n) {
    if(!n) return n;
    let str = n.split('.');
    let re = /\d{1,3}(?=(\d{3})+$)/g;
    let n1 = str[0].replace(re, "$&,");
    return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
};
//去除千分位中的‘，’
export function delcommafy(num){
    if(!num) return num;
    num = num.toString();
    num = num.replace(/,/gi, '');
    return num;
};