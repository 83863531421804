<template>
  <div class="approval">
    <div class="list">
      <div class="title">{{currentMenu.label}}</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        ref="table" 
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        @expand-change="expandChange"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="cfappName" label="审批模板" width="120"></el-table-column>
        <el-table-column prop="ofoworkName" label="活动" align="center" width="160">
          <template slot-scope="scope">
            <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="nowStep" label="当前步骤" align="center" width="80">
          <template slot-scope="scope">
            <a @click="handleExpand(scope.row)" class="step">{{scope.row.nowStep}}</a>
          </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
          <template slot-scope="scope">
            <el-table border :data="scope.row.stepData" style="width: calc(100vw - 290px);margin-left:150px" max-height="250">
              <el-table-column prop="order" label="步骤" align="center" width="50"/>
              <el-table-column prop="ofrthingName" label="审批人" align="center" width="150"/>
              <el-table-column prop="appstatus" label="状态" align="center" width="100">
                <template slot-scope="scope">
                  <DictTag :options="dropDown.appStatus" :value="scope.row.appstatus" :isStyle="true" v-if="scope.row.steptype == '1' || scope.row.steptype == '9' "/>
                  <span v-if="scope.row.steptype == '2'" style="color: #909399;background-color: #f4f4f5;padding: 0 10px; font-size: 12px;height: 32px;display:inline-block;line-height:32px;border-radius:4px; border: 1px solid #e9e9eb">抄送</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="center" width="180">
                <template slot-scope="scope">
                  {{scope.row.createTime | filter_handleDateTime}}
                </template>
              </el-table-column>
              <el-table-column label="审批时间" align="center" width="180">
                <template slot-scope="scope">
                  {{scope.row.updateTime | filter_handleDateTime}}
                </template>
              </el-table-column>
              <el-table-column
                label="签名"
                align="center">
                <template slot-scope="scope">
                  <img :src="$base_url + scope.row.signUrl" alt="" style="width:30px;transform: rotate(-90deg)" />
                </template>
              </el-table-column>
              <el-table-column
                label="审批途径"
                align="center">
                <template slot-scope="scope">
                  {{scope.row.way == '0' ? '电脑端' : (scope.row.way == '1' ? '小程序' : '')}}
                </template>
              </el-table-column>
              <el-table-column prop="remarks" label="审批意见" align="center" show-overflow-tooltip>
                
                <template slot-scope="scope">
                  <div id="editor-content-view" class="editor-content-view" v-html="scope.row.remarks"></div>
                </template>
                
              </el-table-column>
              <el-table-column
                label="操作"
                width="100"
                align="center"
                fixed="right"
                v-if="currentMenu.appstatus == '3'">
                <template slot-scope="scope" v-if="(scope.row.isApp == '1' || scope.row.isApp == '3') && scope.row.appstatus == '3'">
                  <el-form size="mini">
                    <el-button type="primary" size="mini" plain @click="handleAudit(scope.row)">审批</el-button>
                  </el-form>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="appstatus" label="状态" align="center" width="100">
          <template slot-scope="scope">
            <DictTag :options="dropDown.appStatus" :value="scope.row.appstatus" :isStyle="true"/>
          </template>
        </el-table-column>
        <el-table-column prop="ofrresourceName" label="发起岗位" align="center" width="150"></el-table-column>
        <el-table-column prop="ofrthingName" label="发起人" align="center" width="150"></el-table-column>
        <el-table-column prop="createTime" label="发起时间" width="180" align="center">
          <template slot-scope="scope">
            {{scope.row.createTime | filter_handleDateTime}}
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="意见" show-overflow-tooltip align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      title="审批"
      :visible.sync="dialogFromVisible"
      width="1000"
      append-to-body
      top="59px" >
        <el-form :model="form" label-width="70px">
          <el-form-item label="审批结果">
            <el-select v-model="form.appstatus" style="width: 100%">
              <el-option label="同意" value="1"></el-option>
              <el-option label="不同意" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审批人" v-if="isShowSprs">
            <el-select v-model="form.sprs" placeholder="请选择下一步审批人" style="width:100%" filterable multiple >
              <el-option-group
                v-for="group in chooseList"
                :key="group.ofrResourceId"
                :label="group.resourceName">
                <el-option
                  v-for="item in group.ofrThingList"
                  :key="item.ofrThingId"
                  :label="item.name"
                  :value="item.ofrThingId">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="审批意见">
            <!-- <el-input type="textarea" :rows="3" placeholder="审批意见" v-model="form.remarks"></el-input> -->
             <div style="border: 1px solid #ccc;">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 500px; overflow-y: hidden;"
                  v-model="form.remarks"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
                />
              </div>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-form>
        <el-button type="primary" @click="submitAudit" :disabled="false">提 交</el-button>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import qs from 'qs'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import Vue from 'vue'
// import { DomEditor } from '@wangeditor/editor'
// const toolbar = DomEditor.getToolbar(editor)

// const curToolbarConfig = toolbar.getConfig()
// console.log( curToolbarConfig.toolbarKeys ) // 当前菜单排序和分组
let v = new Vue();
const editorConfig = {                       // JS 语法
    MENU_CONF: {}

    // 其他属性...
}
// console.log(v.$base_url);
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF['uploadImage'] = {
  server: v.$base_url + '/api/utils/uploadFile',
  fieldName: 'file',
  headers: {
    token: window.sessionStorage.getItem('token')
  },
  customInsert(res, insertFn) {
    insertFn(v.$base_url + res.data)
  },
}
// const toolbar = Toolbar.getToolbar(Editor)

// const curToolbarConfig = toolbar.getConfig()
// console.log( curToolbarConfig.toolbarKeys )
const toolbarConfig = {                        // JS 语法
  /* 工具栏配置 */
  excludeKeys: [
    "group-video"
  ]

}
  export default {
    components: {
      DictTag,
      Editor,
      Toolbar
    },
    name: 'approval',
    data() {
      return {
        tableData: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        loading: true,
        form: {
          appstatus: '1',
          remarks: '',
          osAppStepId: '',
          sprs: []
        },
        dialogFromVisible: false,
        data: {},
        menuList: JSON.parse(sessionStorage.getItem('menuList')),
        chooseList: [],
        isShowSprs: false,
        editor: null,
        html: '<p>hello</p>',
        toolbarConfig: toolbarConfig,
        editorConfig: editorConfig,
        mode: 'default', // or 'simple'
      }
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      }),
    },
    created() {
      // console.log(this.$route);
      this.getList()
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
      handleAudit(row) {
        this.isShowSprs = row.isApp == '3'
        this.dialogFromVisible = true
        // console.log(this.$refs);
        this.form.appstatus = '1'
        this.form.remarks = ''
        this.form.sprs = []
        this.form.osAppStepId = row.osAppStepId
        this.chooseList = row.chooseList ? row.chooseList : []
      },
      submitAudit() {
        let obj = Object.assign({}, this.form)
        obj.sprs = this.form.sprs.toString()
        obj.way = '0'
        this.$http.post(url.submitOsApp, qs.stringify(obj)).then(res => {
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.dialogFromVisible = false
            this.getList()
          }  
        })
      },
      getList() {
        this.loading = true
        this.$http.get(url.getMyOsApp, {params: {
          appstatus: this.currentMenu.appstatus,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }}).then(res => {
          if(res.data.code == 200) {
            this.total = res.data.data.total
            if(res.data.data.list) {
              res.data.data.list.map(item => {
                let stepData = []
                item['stepData'] = stepData
              })
              this.tableData = res.data.data.list
            }else {
              this.tableData = []
            }
            this.loading = false
            // this.$eventBus.$emit('getApprovalCount', res.data.data.total)
          }
        })
        if(!this.dropDown.appStatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_app_status' }}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setAppStatus', res.data.data)
            }
          })
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getList()
      },
      goProject(row) {
        // console.log(row);
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: row.ofoworkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            this.data = res.data.data
            this.$store.commit("setJumpData", {...res.data.data, isApp: true})
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            this.menuList.forEach(el => {
              if(obj.path.indexOf(el.path) != -1) {
                this.$store.commit('setSecondMenu', el.children)
              }
            });
            this.$store.commit("selectMenu", obj);
          }
          
        })
      },
      handleExpand(row) {
        this.$refs.table.toggleRowExpansion(row)
      },
      expandChange(row, expandedRows) {
        if(expandedRows.length) {
          this.tableData.map(async (item, index) => {
            if(item.osAppId == row.osAppId) {
              let stepData = []
              await this.$http.get(url.getMyOsAppStep, {params: {osappId: row.osAppId}}).then(res => {
                if(res.data.code == 200) {
                  stepData = res.data.data
                }
                
              })
              this.tableData[index].stepData = stepData
            }
          })
        }
      },
    },
    watch: {
      currentMenu: {
        handler(newValue, oldValue) {
        // console.log(newValue);
          this.getList()
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.approval {
  padding: 16px;
  .list {
    width: calc(100% - 32px);
    padding: 16px;
    background-color: #fff;
    min-height: calc(100% - 32px);
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,1);
    border-radius: 11px;
    box-sizing: content-box;
    overflow: auto;
    .title {
      height: 14px;
      font-size: 14px;
      font-family: STHeitiSC-Medium, STHeitiSC;
      font-weight: 500;
      color: #282828;
      line-height: 15px;
      padding-left: 8px;
      border-left: #0C75E5 2px solid;
      margin-bottom: 16px;
      font-weight: bolder;
    }
    .el-table {
      margin-bottom: 39px;
      .step {
        border: 1px solid #337ab7;
        padding: 0 5px;
        border-radius: 4px;
        color: #337ab7;
        cursor: pointer;
      }
      .step:hover {
        color: #ffffff;
        background-color: #337ab7;
      }
      /* .el-button {
        color: #0c75e5;
        border: 0;
        padding: 0;
      } */
      ::v-deep .el-table__expand-icon {
        display: none;
      }
    }
    .el-card {
      margin-bottom: 16px;
      margin-left: 8px;
      box-shadow: 0px 0px 5px 0px rgba(197,197,197,0.5);
      border-radius: 7px;
      border: 0;
      position: relative;
      ::v-deep .el-card__body {
        padding: 13px 11px;
      }
      ::v-deep .el-descriptions-item__label {
        width: 60px;
        margin-right: 8px;
      }
      ::v-deep .el-descriptions__table tbody:last-child td {
        padding-bottom: 0;
      }
      .el-button {
        width: 49px;
        height: 19px;
        background: #D5E9FF;
        border-radius: 4px;
        border: 1px solid #0C75E5;
        font-size: 11px;
        font-family: STHeitiSC-Light, STHeitiSC;
        font-weight: 300;
        color: #0C75E5;
        line-height: 19px;
        padding: 0;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -9px;
      }
    }
  }
}
::v-deep .el-dialog {
  margin: 0 auto;
  max-height: calc(100% - 72px);
  // height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  /* padding: 10px 0 10px 20px; */
  /* margin-right: 20px; */
  max-height: calc(100vh - 246px);
  // height: calc(100vh - 206px);
  overflow: auto;
}
</style>