import Ucenter from '@/views/ucenter/Index.vue'

import Message from '@/views/ucenter/message/Index.vue'
import Userinfo from '@/views/ucenter/userinfo/Index.vue'

import Develop from '@/views/develop/Index.vue'

const routes = [
  {
    path: '/ucenter',
    name: 'ucenter',
    // redirect: '/ucenter/userinfo',
    component: Ucenter,
    // children: [
    //   {
    //     path: 'message',
    //     name: 'message',
    //     component: Message
    //   },
    //   {
    //     path: 'userinfo',
    //     name: 'userinfo',
    //     component: Userinfo
    //   },
    //   {
    //     path: '*',
    //     name: 'develop',
    //     component: Develop
    //   },
    // ]
  }
]

export default routes