<template>
  <div class="contain">
    <!-- <div class="main"> -->
      <!-- <div class="title">分享给我</div> -->
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <el-tab-pane label="活动" name="first">
          <Work />
        </el-tab-pane>
        <el-tab-pane label="项目" name="second">
          <Project />
        </el-tab-pane>
      </el-tabs>
    <!-- </div> -->
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Project from '@/views/share/Project.vue'
import Work from '@/views/share/Work.vue'
export default {
  components: {
    Project,
    Work
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  created () {
    // this.init()
  },
 
  methods: {
    handleClick(tab, event) {
    }
  }
}
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  .main {
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
    border-radius: 11px;
    padding: 16px;
    box-sizing: border-box;
    .title {
      height: 16px;
      font-size: 16px;
      font-family: STHeitiSC-Medium, STHeitiSC;
      font-weight: 500;
      color: #282828;
      line-height: 16px;
      padding-left: 8px;
      border-left: #0c75e5 2px solid;
      margin-bottom: 16px;
      font-weight: bolder;
    }
  }
}
</style>