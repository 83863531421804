import Finance from '@/views/finance/Index.vue'

const routes = [
  {
    path: '/finance',
    name: 'finance',
    component: Finance
  }
]

export default routes