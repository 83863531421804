<template>
  <div class="main">
    <div class="contain-left" v-if="isShowLeft">
      <el-select v-model="date" placeholder="请选择期间" filterable @change="handleChange" size="mini" style="margin:8px">
        <el-option v-for="(item, index) in timeList" :key="index" :label="item.name" :value="item.code"></el-option>
      </el-select>
      <!-- <div v-for="(item, index) in menuList" :key="index" :class="['deal', isActive == item.menuId ? 'isActive' : '']" @click="changeMenu(item)">
        <i :class="item.icon"></i>
        {{item.label}}
      </div> -->


      <div v-for="(item, index) in menuList" :key="index">
        <el-divider content-position="left" v-if="item.children.length">
          <!-- {{item.label}} -->
        </el-divider>
        <div v-for="(val, id) in item.children" :key="id" :class="['deal', isActive == val.menuId ? 'isActive' : '']" @click="changeMenu(val)">
          <i :class="val.icon"></i>
          {{val.label}}
        </div>
      </div>

      <!-- <el-collapse accordion v-model="activeNames">
        <el-collapse-item v-for="(item, index) in menuList" :key="index" :title="item.label">
          <div v-for="(val, id) in item.children" :key="id" :class="['deal', isActive == val.menuId ? 'isActive' : '']" @click="changeMenu(val)">
            <i :class="val.icon"></i>
            {{val.label}}
          </div>
        </el-collapse-item>
      </el-collapse> -->
      
    </div>
    <div :class="isShowLeft ? 'middle iconfont icon-zhedie-l' : 'middle iconfont icon-zhedie1'" @click="isShowLeft = !isShowLeft">
    </div>
    <div class="contain-right">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
    </div>
    
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from "vuex"
  export default {
    computed: {
      ...mapState({
        currentMenu: (state) => state.tags.currentMenu,
        secondMenu: (state) => state.secondAside.secondMenu,
        tagsList: (state) => state.tags.tagsList,
        // path: (state) => state.secondAside.path 
      }),
    },
    data() {
      return {
        menuList: JSON.parse(sessionStorage.getItem('secondMenu')),
        isShowLeft: true,
        isActive: JSON.parse(sessionStorage.getItem('currentMenu')).menuId,
        timeList: [],
        date: JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).code : this.parseTime(new Date().getTime(), '{y}-{m}'),
        activeNames: ['1']
      }
    },
    created () {
      this.getTimeList();
    },
    mounted () {
      // console.log(11);
    },
    methods: {
      getTimeList() {
        this.$http.get(url.getCbTimeList).then(res => {
          if(res.data.code == 200) {
            this.timeList = res.data.data
            if(!sessionStorage.getItem('date')) {
              let currentTime = this.parseTime(new Date().getTime(), '{y}-{m}')
              this.timeList.forEach(item => {
                if(item.code == currentTime) {
                  this.resetSetItem('date', JSON.stringify(item));
                }
              })
            }
          }
        })
      },
      changeMenu(item) {
        this.isActive = item.menuId
        this.$router.push({
          path: item.path
        })
        this.tagsList.forEach((val, index) => {
          // console.log(val.path.split('/'));
          
          if(item.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path.split('/')[3])) {
            // console.log(val);
            for(let key in val) {
              if(key != 'children' && key != 'label' && key != 'icon') {
                val[key] = item[key]
              }
            }
            // this.$store.commit('selectMenu', val)
          }else {
            // this.$store.commit('selectMenu', item)
          }
        })
        this.$store.commit('changeMenu', item)
      },
      handleChange(e) {
        this.timeList.forEach(item => {
          if(item.name == e) {
            this.resetSetItem('date', JSON.stringify(item));
            // sessionStorage.setItem('date', )
            // this.$eventBus.$emit('updateList', true)
          }
        })
        // console.log(this.date);
        // console.log(e);
        
      }
    },
    watch: {
      currentMenu(newValue, oldValue) {
        // console.log(newValue);
        this.isActive = newValue.menuId
        // this.$router.push({
        //   path: newValue.path
        // })
      }
    },
  }
</script>

<style scoped lang="scss">
.main {
  display: flex;
  height: 100%;
  overflow: hidden;
  .contain-left {
    width: 160px;
    background-color: #fff;
    /* padding: 0 8px; */
    box-shadow: 2px 3px 8px 0px rgb(208, 208, 208);
    overflow-x: hidden;
    overflow-y: auto;
    ::v-deep .el-collapse {
      margin: 0 8px;
    }
    ::v-deep .el-divider--horizontal {
      margin: 8px;
      width: auto;
    }
    ::v-deep .el-divider__text {
      padding: 0 8px;
      font-size: 13px;
    }
    ::v-deep .el-divider__text.is-left {
      left: 10px;
    }
    .deal {
      padding: 0 15px 0 6px;
      font-size: 13px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      color: #282828;
      margin: 8px;
      padding: 7px;
      cursor: pointer;
      display: flex;
      align-items: center;
      >i {
        margin-right: 8px;
      }
    }
    .deal:hover {
      background-color: #E5F3FF;
    }
    .isActive {
      color: #177BE6;
      background-color: #E5F3FF;
    }
    
  }
  .middle {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #79bbff;
  }
  .middle:hover {
    color: #0c75e5;
  }
  .contain-right {
    padding: 16px;
    flex: 1;
    overflow: auto;
    padding-left: 0;
  }
}
</style>