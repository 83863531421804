import axios from "axios";
import {Message} from 'element-ui'
import router from '../router'

// axios.defaults.baseURL = 'http://124.222.4.69:8090'


// 请求拦截器
axios.interceptors.request.use(cfg => {
  // console.log(cfg);
  if(sessionStorage.getItem('token')) {
    cfg.headers.token = sessionStorage.getItem('token')
  }
  return cfg
})

// 响应拦截器
axios.interceptors.response.use(res => {
  // const that = this
  // console.log(res);
  // console.log(this);
  // debugger
  if(res.data.code == 200 || res.request.responseType === 'blob') {

  }else if(res.data.code == 401 || res.data.code == 1) {
    // console.log(11111);
    router.push('/login');
    return
  }else {
    if(res.data.msg) {
      Message.error(res.data.msg)
    }else {
      Message.error('操作失败')
    }
  }
  // // 二进制数据处理
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    if (res.data.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = () => {
        // TODO 错误处理
        Message.error(
          `Error: ${JSON.parse(reader.result).message}！`
        );
      };
      reader.readAsText(res.data);
      return true;
    } else {
      return res.data;
    }
  }

  return res
})

export default axios