import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import WxBind from '../views/wxBind.vue'
import Index from '../views/Index.vue'
import Develop from '../views/develop/Index.vue'

import homeRouter from './modules/index'
import projectRouter from './modules/project'
import processRouter from './modules/process'
import resourceRouter from './modules/resource'
import financeRouter from './modules/finance'
import setRouter from './modules/set'
import ucenterRouter from './modules/ucenter'
import accountingRouter from './modules/accounting'
import reportRouter from './modules/report'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  // console.log(location);
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    redirect: '/login',
    component: Login
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   redirect: {
  //     name: 'login',
  //     params: {
  //       orgName: 'albb'
  //     }
  //   },
  //   component: Login
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  // {
  //   path: '/login/:orgName',
  //   name: 'login',
  //   component: Login
  // },
  {
    path: '/wxbind',
    name: 'wxbind',
    component: WxBind
  },
  {
    path: '/',
    // redirect: '/login',
    component: Index,
    children: [
      ...projectRouter,
      ...processRouter,
      ...resourceRouter,
      ...financeRouter,
      ...setRouter,
      ...ucenterRouter,
      ...accountingRouter,
      ...reportRouter,
      ...homeRouter,
    ]
  },
  // {
  //   path: '*',
  //   name: 'develop',
  //   component: Develop
  // },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


//beforeEach每次进行路由跳转时都会执行
// router.beforeEach((to,from,next) => {
//   console.log(to);
//   console.log(from);
//   console.log(next);
//   // if(to.matched.length === 0){
//   //     from.path ? next({path: from.path}) : next('/develop')
//   // }else{
//   //     next()
//   // }
// })
export default router
