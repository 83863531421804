<template>
  <div class="ucenter">
    <!-- <router-view class="second-contain"/> -->
    <div class="left" v-if="isShowLeft">
      <div v-for="(item, index) in ofoFlowDealList" :key="index" :class="['item', isDeal == index ? 'isDeal' : '']" @click="changeDeal(index)">
        <i class="el-icon-tickets" style="margin-right:5px"></i>
        {{item.name}}
      </div>
      <div v-if="ofoFlowDealList.length" style="width:84%;height:1px;background:#DCDFE6;margin:0 14px"></div>
      <div :class="['item', isDeal == (ofoFlowDealList.length + 1) ? 'isDeal' : '']" @click="getChange('0')">
        <i class="el-icon-postcard" style="margin-right:5px"></i>
        基本信息
      </div>
      <div :class="['item', isDeal == (ofoFlowDealList.length + 2) ? 'isDeal' : '']" @click="getChange('1')">
        <i class="el-icon-chat-dot-square" style="margin-right:5px"></i>
        消息
      </div>
    </div>
    <div class="show-mask" @click="isShowLeft = !isShowLeft">
      <i :class="isShowLeft ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"></i>
    </div>
    <div class="right">
      <template v-if="isDeal == ofoFlowDealList.length + 1">
        <userinfo />
      </template>
      <keep-alive>
        <template v-if="isDeal == ofoFlowDealList.length + 2">
          <message />
        </template>
      </keep-alive>
      <template v-if="(isDeal != ofoFlowDealList.length + 1) && (isDeal != ofoFlowDealList.length + 2)">
        <template v-if="ofoFlowWorkList.length">
          <el-tabs type="border-card" v-model="ofoFlowWorkId" @tab-click="changeWork">
            <el-tab-pane v-for="(item, index) in ofoFlowWorkList" :key="index" :label="item.name" :name="item.ofoFlowWorkId + ''">
              <template  v-if="ofoFlowWorkId == item.ofoFlowWorkId">
                <div style="text-align:right" v-if="!isShowHistory">
                  <el-button icon="el-icon-refresh" type="text" @click="handleRefresh" v-if="handleDutys(ofoFlowDeal.dutys, 'updType')">配置更新</el-button>
                  <el-button icon="el-icon-time" type="text" @click="() => {isShowHistory = true; productsList = []; thingsList = []}"></el-button>
                </div>
                <div style="text-align:left" v-if="isShowHistory">
                  <el-button icon="el-icon-back" type="text" @click="isShowHistory = false"></el-button>
                </div>
                <el-form :model="ruleForm" label-width="120px" :rules="!disabled? rules : {}" ref="ruleForm" :disabled="disabled" v-if="!isShowHistory">
                  <template v-if="!disabled">
                    <!-- 名称 编号 -->
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                          <el-input v-model="ruleForm.name" style="width: 100%" clearable></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="编号" prop="code">
                          <el-autocomplete
                            class="inline-input"
                            v-model="ruleForm.code"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入编号"
                            @select="handleSelect"
                            clearable
                            style="width: 100%"
                          ></el-autocomplete>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
                    <el-row v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">
                      <el-col :span="12">
                        <el-form-item :label="ofoFlowDeal.restype1Name" prop="dealId">
                          <template slot="label">
                            {{ofoFlowDeal.restype1Name}}
                            <!-- <el-button type="text" @click="addResource" v-if="resourceType.isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button> -->
                          </template>
                          
                          <el-select 
                            v-model="ruleForm.dealId"
                            :placeholder="!disabled ? `请选择${ofoFlowDeal.restype1Name}`: ''"
                            style="width: 100%"
                            @change="getResChange"
                            filterable
                            clearable
                          >
                            <el-option v-for="(item, index) in resourceList" :key="index" :label="item.resourceName" :value="item.ofrResourceId" ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item >
                          <div slot="label" style="display:flex;justify-content:flex-end">
                            <template v-if="ofoFlowWork.thingDimeType">
                              <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
                            </template>
                            <template v-if="!ofoFlowWork.thingDimeType">
                              内容
                            </template>
                            <!-- <el-button type="text" size="mini" @click="addThing" v-if="resourceType.isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button> -->
                          </div>
                          
                          <el-select
                            v-model="ruleForm.dealownId"
                            :placeholder="!disabled? `请选择内容` : ''"
                            style="width: 100%"
                            filterable
                            clearable
                            @change="getDealownChange"
                          >
                            <el-option v-for="(item, index) in thingList" :key="index" :label="item.name" :value="item.ofrThingId"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
                    <el-row v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">
                      <el-col :span="12">
                        <el-form-item :label="ofoFlowDeal.restype1Name" prop="dealowhId">
                          <el-select
                            v-model="ruleForm.dealowhId"
                            :placeholder="!disabled? `请选择${ofoFlowDeal.restype1Name}` : ''"
                            style="width: 100%"
                            @change="getDealowhChange"
                            filterable
                          >
                            <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="联系人">
                          <el-select
                            v-model="ruleForm.dealownId"
                            :placeholder="!disabled ? '请选择联系人': ''"
                            style="width: 100%"
                            filterable
                            clearable
                          >
                            <el-option v-for="(item, index) in contactList" :key="index" :label="item.ofrthingName" :value="item.ofrthingId"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 项目时间  是否预警 -->
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="活动时间" prop="workDate">
                          <el-date-picker
                            v-model="ruleForm.workDate"
                            type="date"
                            placeholder="选择活动时间"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            :clearable='false'
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="4" v-if="ofoFlowWork.isNotice == '0'">
                        <el-form-item label="是否协同">
                          <el-checkbox v-model="ruleForm.isNotice" :true-label="'0'" :false-label="'1'" :disabled="documentstatus == '1'"></el-checkbox>
                        </el-form-item>
                      </el-col>
                      <el-col :span="4">
                        <el-form-item label="是否预警">
                          <el-checkbox v-model="ruleForm.ifalert" :true-label="'0'" :false-label="'1'"></el-checkbox>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 是否协同 -->
                    <el-row v-if="ruleForm.isNotice == '0'">
                      <el-col :span="12">
                        <el-form-item label="协同人">
                          <el-select
                            v-model="ruleForm.noticeUsers"
                            placeholder="请选择协同人"
                            style="width: 100%"
                            multiple
                            filterable
                            clearable
                            :disabled="documentstatus == '1'"
                          >
                            <el-option-group
                              v-for="group in postAndUserList"
                              :key="group.ofrResourceId"
                              :label="group.resourceName">
                              <el-option
                                v-for="item in group.ofrThingList"
                                :key="item.ofrThingId"
                                :label="item.name"
                                :value="item.ofrThingId + ''">
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="协同活动">
                          <el-select
                            v-model="ruleForm.noticeFlowWork"
                            placeholder="请选择协同活动"
                            style="width: 100%"
                            filterable
                            clearable
                            :disabled="documentstatus == '1'"
                          >
                            <el-option v-for="(item, index) in workCopyToList1" :key="index" :label="item.name" :value="item.ofoFlowWorkId  + ''"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 是否预警 -->
                    <el-row v-if="ruleForm.ifalert == '0'">
                      <el-col :span="12">
                        <el-form-item label="预警时间" prop="alertDate">
                          <el-date-picker
                            v-model="ruleForm.alertDate"
                            type="date"
                            placeholder="选择预警时间"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            :picker-options="pickerBeginOption"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <template v-if="disabled">
                    <el-descriptions :column="3" :contentStyle="{'padding-right': '16px'}">
                      <el-descriptions-item label="名称">{{ruleForm.name}}</el-descriptions-item>
                      <el-descriptions-item label="编号">{{ruleForm.code}}</el-descriptions-item>
                      <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
                      <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealName}}</el-descriptions-item>
                      <el-descriptions-item v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">
                        <div slot="label">
                          <template v-if="ofoFlowWork.thingDimeType">
                            <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
                          </template>
                          <template v-if="!ofoFlowWork.thingDimeType">
                            内容
                          </template>
                        </div>
                        {{ruleForm.dealownName}}
                      </el-descriptions-item>
                      <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
                      <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealowhName}}</el-descriptions-item>
                      <el-descriptions-item label="联系人" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">
                        {{ruleForm.dealownName}}
                      </el-descriptions-item>
                      <el-descriptions-item label="活动时间">{{ruleForm.workDate}}</el-descriptions-item>
                      <el-descriptions-item label="是否预警">{{ruleForm.ifalert == '1' ? '否' : '是'}}</el-descriptions-item>
                    </el-descriptions>
                  </template>
                  <!-- 导航栏 -->
                  <div class="nav-tabs">
                    <div v-for="(item, index) in navList" :key="index" @click="checked(index)" :class="['nav-title', isActive == index ? 'isActive' : '']" v-show="item.isShow">
                      <div class="nav-icon">
                        <span :class="item.icon"></span>
                      </div>
                      <div class="nav-text">{{item.text}}</div>
                    </div>
                  </div>
                  <!-- 明细 -->
                  <div class="nav-content" v-show="isActive == 0">
                    <keep-alive>
                      <Subsidiary 
                        :disabled="disabled" 
                        :ofoFlowWork="ofoFlowWork" 
                        :thingsList="thingsList"
                        :documentstatus="documentstatus"
                        @copyFromConfirm="copyFromConfirm"
                        @sendThingsList="receiveThingsList"
                        :workConfig="workConfig"
                        :key="timer"/>
                    </keep-alive>
                  </div>
                  <!-- 产品及服务 -->
                  <div class="nav-content" v-show="isActive == 1">
                    <keep-alive>
                      <Product 
                        :disabled="disabled"
                        :ofoFlowWork="ofoFlowWork" 
                        :productsList="productsList"
                        @copyFromConfirm="copyFromConfirm"
                        :documentstatus="documentstatus"
                        @sendProductsList="receiveProductsList"
                        :workConfig="workConfig"
                        :key="timer"/>
                    </keep-alive>
                  </div>
                  <!-- 活动内容 -->
                  <div class="nav-content" v-show="isActive == 2">
                    <keep-alive>
                      <Content 
                        :disabled="disabled" 
                        ref="children" 
                        :resource="resource" 
                        :resourceDimes="resourceDimes" 
                        :ofoWorkId="ruleForm.ofoWorkId" 
                        :ofoFlowWork="ofoFlowWork"
                        :resTypesList="resTypesList"
                        @getCbussDimeList="getCbussDimeList"/>
                    </keep-alive>
                  </div>
                  <!-- 文档 -->
                  <div class="nav-content" v-show="isActive == 3">
                    <keep-alive>
                      <Document :disabled="disabled" :documentsList="documentsList" :ofoFlowWork="ofoFlowWork" :ruleForm="ruleForm" />
                    </keep-alive>
                    
                  </div>
                  <!-- 附件 -->
                  <div class="nav-content" v-show="isActive == 4">
                    <keep-alive>
                      <Annex :fileList="fileList" :disabled="disabled" />
                    </keep-alive>
                  </div>
                  <!-- 凭证 -->
                  <div class="nav-content" v-show="isActive == 5">
                    <keep-alive>
                      <Voucher :ojdtacctList="ojdtacctList"/>
                    </keep-alive>
                  </div>
                  <!-- 审批 -->
                  <div class="nav-content" v-if="isActive == 6">
                    <keep-alive>
                      <Approval :approvalList="approvalList" @refreshGetwork="refreshGetwork"/>
                    </keep-alive>
                  </div>
                  <template v-if="!disabled">
                    <!-- 单位 部门 -->
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="单位" prop="selforgId">
                          <el-select
                            v-model="ruleForm.selforgId"
                            placeholder="请选择单位"
                            style="width: 100%"
                          >
                            <el-option v-for="(item, index) in orgList" :key="index" :label="item.resourceName" :value="item.ofrResourceId"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="部门" prop="selfowhId">
                          <el-select
                            v-model="ruleForm.selfowhId"
                            placeholder="请选择部门"
                            style="width: 100%"
                            @change="getDeptChange"
                            filterable
                          >
                            <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- 单据负责人 注释/操作人 -->
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="单据负责人">
                          <el-select
                            v-model="ruleForm.selfownId"
                            placeholder="请选择单据负责人"
                            style="width: 100%"
                            clearable
                            filterable
                          >
                            <el-option v-for="(item, index) in leaderList" :key="index" :label="item.ofrthingName" :value="item.ofrthingId"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="注释">
                          <el-input type="textarea" v-model="ruleForm.remarks" style="width: 100%" clearable></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <template v-if="disabled">
                    <el-descriptions :column="3" :contentStyle="{'padding-right': '16px'}">
                      <el-descriptions-item label="单位">{{ruleForm.selforgName}}</el-descriptions-item>
                      <el-descriptions-item label="部门">{{ruleForm.selfowhName}}</el-descriptions-item>
                      <el-descriptions-item label="单据负责人">{{ruleForm.selfownName}}</el-descriptions-item>
                      <el-descriptions-item label="操作人">{{ruleForm.userName}}</el-descriptions-item>
                      <el-descriptions-item label="注释">{{ruleForm.remarks}}</el-descriptions-item>
                    </el-descriptions>
                  </template>
                  <div style="text-align:center">
                    <el-button type="primary" @click="submitForm('ruleForm')" v-show="!disabled" :disabled="isDisabled">提 交</el-button>
                  </div>
                  
                </el-form>
                <!-- 历史记录 -->
                <template v-if="isShowHistory">
                  <Activity :ofoFlowDeal="ofoFlowDealList[isDeal]" :ofoFlowWork="item" :ofoFlowDealName="ofoFlowDealList[isDeal].name" :ofoItemName="allData.name" :siftForm="siftForm" ref="activity" :isShare="isShare" v-if="item.ofoFlowWorkId + '' == ofoFlowWorkId" :restaurants="restaurants" :resourceList1="resourceList1"/>
                  <!-- <div style="overflow:hidden;margin-top:16px">
                    <el-card class="box-card" v-for="(item,index) in workList" :key="index" @click.native="handleView (item)" :class="(index + 1) % 5 == 0 ? 'noright' : ''">
                      <span :style="item.documentstatus == '0' ? 'color: #0063FB;background-color: #E5F3FF' : (item.documentstatus == '1' ? 'color: #13ce66;background-color: #e7faf0' : (item.documentstatus == '2' ? 'color: #ffba00;background-color: #fff8e6' : 'color: #ff4949;background-color: #ffeded'))" class="status">
                        <DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/> 
                      </span>
                      <el-tooltip popper-class="atooltip" 
                        effect="dark" 
                        :content="item.code" 
                        placement="top-start">
                        <span class="code">{{item.code}}</span>
                      </el-tooltip>
                      
                      <div class="text header">
                        <span class="text-left">名称:</span>
                        <el-tooltip popper-class="atooltip" 
                          effect="dark" 
                          :content="item.name" 
                          placement="top-start">
                          <span class="text-right">{{item.name}}</span>
                        </el-tooltip>
                      </div> 
                      <div class="text">
                        <span class="text-left">日期:</span>
                        <span class="text-right">{{item.workDate | filter_handleDate}}</span>
                      </div>
                      <div class="text" v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '4'">
                        <span class="text-left">数量:</span>
                        <span class="text-right">{{item.quantity}}</span>
                      </div>
                      <div class="text" v-if="ofoFlowWork.costmanagetype == '7'">
                        <span class="text-left">时间:</span>
                        <span class="text-right">{{item.quantity | filter_handleTimeRange}}</span>
                      </div>
                      <div class="text" v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'">
                        <span class="text-left">金额:</span>
                        <span class="text-right">{{item.ptotal | comdify}}</span>
                      </div>
                      <div class="text" v-if="ofoFlowWork.costmanagetype == '7' || ofoFlowWork.costmanagetype == '8' || ofoFlowWork.costmanagetype == '4'">
                        <span class="text-left">&nbsp;</span>
                      </div>
                    </el-card>
                  </div>
                  <el-pagination
                    @size-change="handleSizeChange"
                    background
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    layout="->, prev, pager, next, sizes, jumper, total"
                    :total="total"
                  >
                  </el-pagination> -->
                </template>
              </template>
            </el-tab-pane>
          </el-tabs>
        </template>
        <div v-if="!ofoFlowWorkList.length" style="color:#999">暂无数据~</div>
      </template>
    </div>
  </div>
</template>

<script>
import Userinfo from '@/views/ucenter/userinfo/Index.vue'
import Message from '@/views/ucenter/message/Index.vue'
import {mapState} from 'vuex'
import Activity from '@/components/project/activity.vue'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
// 产品及服务
import Product from '@/components/project/activity/product.vue'
// 明细
import Subsidiary from '@/components/project/activity/subsidiary.vue'
// 文档
import Document from '@/components/project/activity/document.vue'
// 活动内容
import Content from '@/components/project/activity/content.vue'
// 审批
import Approval from '@/components/project/activity/approval.vue'
// 凭证
import Voucher from '@/components/project/activity/voucher.vue'
// 附件
import Annex from '@/components/project/activity/annex.vue'
import url from '@/config/url'
  export default {
    components: {
      Userinfo,
      Message,
      DictTag,
      Product,
      Approval,
      Content,
      Subsidiary,
      Voucher,
      Annex,
      Document,
      Activity
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        toMine: (state) => state.toMine.toMine
      }),
    },
    data() {
      return {
        allData: {},
        isDeal: 0,
        ofoFlowDealList: [],
        ofoFlowDeal: {},
        ofoFlowWorkList: [],
        siftForm: {
          code: '',
          name: '',
          dealId: '',
          dealowhId: ''
        },
        isShare: this.$route.query.isShare ? this.$route.query.isShare : '1',
        ofoFlowWorkId: '',
        ofoFlowWork: {},
        ruleForm: {
          code: '',
          name: '',
          workDate: '',
          ifalert: '1',
          alertDate: '',
          dealId: '',
          dealowhId: '',
          selforgId: '',
          selfowhId: '',
          dealownId: '',
          selfownId: '',
          dealownName: '',
          isNotice: '1',
          noticeUsers: '',
          noticeFlowWork: '',
          dataSourceConfig: []
        }, // 表单对象
        // 表单规则
        rules: {
          workDate: [{ required: true, message: "请选择日期", trigger: "change" }],
          dealId: [{ required: true, message: "请选择资源", trigger: "change" }],
          selforgId: [{ required: true, message: "请选择单位", trigger: "change" }],
          selfowhId: [{ required: true, message: "请选择部门", trigger: "change" }],
          dealowhId: [{ required: true, message: "请选择部门", trigger: "change" }],
          resourceName: [{ required: true, message: "请输入资源名称", trigger: "change" }]
        },
        disabled: false, //是否禁用表单
        restaurants: [],
        resourceType: {},
        resourceList: [],
        thingList: [],
        departmentList: [],
        contactList: [],
        navList: [],
        isActive: 0,
        thingsList: [],
        productsList: [],
        documentstatus: '',
        ojdtacctList: [], // 凭证列表
        resource: {
          cbRestypeId: '',
          resourceName: '',
          remarks: '',
          dataSourceConfig: []
        }, // 资源对象
        resTypesList: [],
        resourceDimes: [], // 资源维度
        documentsList: [], // 文档列表
        fileList: [], // 附件列表
        approvalList: [], // 审批列表
        leaderList: [], // 负责人列表
        orgList: [], // 单位列表
        list: [],
        list2: [],
        ptotalObj: {},
        ptotalObj2: {},
        ctotalObj: {},
        ctotalObj2: {},
        isDisabled: false,
        isShowHistory: false,
        workList: [],
        pageNum: 1,
        pageSize: 10,
        loading: false,
        total: 0,
        postAndUserList: [],
        workCopyToList1: [],
        resourceList1: [],
        isShowLeft: true,
        pickerBeginOption: {
          disabledDate: (time) => {
            return time.getTime() < Date.now()-1 * 24 * 60 * 60 * 1000
          }
        },
        workIndex: 0,
        workConfig: {},
        timer: ''
      }
    },
   async created () {
      await this.getDeal();
      // if(this.isMsg == '0') {
      //   this.isDeal = this.ofoFlowDealList.length + 2
      // }
      // this.$eventBus.$on('goMine', data => {
      //   console.log(data);
      // })
      // console.log(this.toMine);
      if(this.toMine.isUcenter) {
        this.isDeal = this.ofoFlowDealList.length + 1
      }
      if(this.toMine.isMsg) {
        this.isDeal = this.ofoFlowDealList.length + 2
      }
    },
    methods: {
      // 配置更新
      async handleRefresh() {
        this.$confirm('此操作将更新配置, 是否更新?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.updateItemConfig, qs.stringify({ofoItemId: this.ofoFlowDeal.ofoitemId})).then(res => {
          // console.log(res);
          if(res.data.code == 200){
            this.$message.success(res.data.msg)
            this.getDeal()
          }
        })
        }).catch(() => {     
        });
        // this.isDeal = this.ofoFlowWorkList.length + 1
        // await this.getDeal()
        // this.$message.success('更新成功');
      },
      getChange(type) {
        this.ofoFlowWorkList = []
        if(type == '0') {
          this.isDeal = this.ofoFlowDealList.length + 1
        }else {
          this.isDeal = this.ofoFlowDealList.length + 2
        }
      },
      async getDeal() {
        await this.$http.get(url.getFlowMenuList, {params: {
          itemType: '2'
        }}).then(res => {
          if(res.data.code == 200) {
            this.ofoFlowDealList = res.data.data
            if(res.data.data.length) {
              this.ofoFlowDeal = res.data.data[this.isDeal]
              this.ofoFlowWorkList = res.data.data[this.isDeal].ofoFlowWorkList
            }else {
              this.isDeal = 1
            }
            if(this.ofoFlowWorkList.length) {
              this.ofoFlowWork = this.ofoFlowWorkList[this.workIndex]
              this.ofoFlowWorkId = this.ofoFlowWorkList[this.workIndex].ofoFlowWorkId + ''
              this.getNav()
              this.getData()
            }
          }
        })
      },
      changeDeal(index) {
        this.isShowHistory = false
        // this.isShowHistory = true
        this.isDeal = index
        this.workIndex = 0
        this.ofoFlowDeal = this.ofoFlowDealList[index]
        this.ofoFlowWorkList = this.ofoFlowDealList[index].ofoFlowWorkList
        if(this.ofoFlowWorkList.length) {
          this.ofoFlowWork = this.ofoFlowDealList[index].ofoFlowWorkList[0]
          this.ofoFlowWorkId = this.ofoFlowDealList[index].ofoFlowWorkList[0].ofoFlowWorkId + ''
          this.getNav()
          this.getData()
        }
      },
      changeWork(tab, event) {
        // console.log(tab);
        this.workIndex = tab.index
        this.isShowHistory = false
        this.ofoFlowWork = this.ofoFlowDealList[this.isDeal].ofoFlowWorkList[tab.index]
        this.ofoFlowWorkId = this.ofoFlowDealList[this.isDeal].ofoFlowWorkList[tab.index].ofoFlowWorkId + ''
        this.getNav()
        this.getData()
      },
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().includes(queryString.toLowerCase()));
        };
      },
      handleSelect(e) {
        // console.log(item.value.split(' - '));
        this.ruleForm.code = e.value.split(' - ')[1]
      },
      // 资源选择框发生改变时内容也改变
      getResChange(value, string) {
        this.resourceList.forEach(item => {
          if(item.ofrResourceId == value) {
            if(item.ofrThingList.length) {
              this.thingList = item.ofrThingList
              if(!string) {
                this.ruleForm.dealownId = item.ofrThingList[0].ofrThingId
                this.ruleForm.dealownName = item.ofrThingList[0].name
              }
            }else {
              this.thingList = []
              this.ruleForm.dealownId = ''
              this.ruleForm.dealownName = ''
            }
          }
        })
      },
      // 切换供应商内容
      getDealownChange(e) {
        let index = this.thingList.findIndex(item => item.ofrThingId == e)
        this.ruleForm.dealownName = this.thingList[index].name
      },
      // 交易组件有甲方资源类型并且资源种类为‘管理’ 选择框发生改变时，联系人也发生改变
      getDealowhChange(value, string) {
        this.departmentList.forEach(item => {
          if(item.cbOwhId == value) {
            if(item.owhThingList) {
              this.contactList = item.owhThingList
              if(!string) {
                this.ruleForm.dealownId = item.owhThingList[0].ofrthingId
              }
              // this.ruleForm.dealownId = this.ruleForm.dealownId ? this.ruleForm.dealownId : item.owhThingList[0].ofrthingId
            }else {
              this.contactList = []
              this.ruleForm.dealownId = ''
            }
          }
        })
      },
      // 确认复制从列表
      copyFromConfirm(val) {
        this.ruleForm.code = this.ruleForm.code ? this.ruleForm.code : ((val.obj.code.indexOf('OFW') == 0 ) ? '' : val.obj.code)
        this.ruleForm.dealId = val.obj.dealId
        this.ruleForm.dealowhId = val.obj.dealowhId
        this.ruleForm.dealownId = val.obj.dealownId
        this.ruleForm.selforgId = val.obj.selforgId
        // this.ruleForm.code = 
        val.productsList.forEach(item => {
          this.productsList.push(item)
          // 
        })
        val.thingsList.forEach(item => {
          this.thingsList.push(item)
        })
      },
      // 审批完成
      // refreshGetwork(val) {
      //   if(val) {
      //     let item = {ofoWorkId: this.ruleForm.ofoWorkId}
      //     this.getDetail(item)
      //   }
      // },
      // 部门选择框发生改变时，负责人也发生改变
      getDeptChange(value, string) {
        this.departmentList.forEach(item => {
          if(item.cbOwhId == value) {
            if(item.owhThingList) {
              this.leaderList = item.owhThingList
              if(!string) {
                this.ruleForm.selfownId = item.owhThingList[0].ofrthingId
              }
            }else {
              this.leaderList = []
              this.ruleForm.selfownId = ''
            }
          }
        })
      },
      // 获取导航
      getNav() {
        this.navList = [
          {
            icon: 'iconfont icon-lvzhou_mingxi',
            text: this.ofoFlowWork.thingtypeName + '（明细）',
            isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
          },
          {
            icon: 'iconfont icon-chanpin',
            text: this.ofoFlowWork.kindtypeName,
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
          },
          {
            icon: 'iconfont icon-demand',
            text: '活动内容',
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0'
          },
          {
            icon: 'iconfont icon-wendang',
            text: '文档',
            isShow: this.ofoFlowWork.kinddocutype
          },
          {
            icon: 'iconfont icon-fujian',
            text: '附件',
            isShow: false
          },
          // {
          //   icon: 'iconfont icon-ziyuan-xianxing',
          //   text: '凭证',
          //   isShow: this.ofoFlowWork.ojdttype == '0',
          // },
          // {
          //   icon: 'iconfont icon-tianshenpi',
          //   text: '审批',
          //   isShow: true
          // },
        ]
        let navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            navArr.push(index)
          }
        })
        this.isActive = navArr[0]
      },
      // 切换导航
      checked(index) {
        this.isActive = index
      },
      // 接收产品列表
      receiveProductsList(val) {
        this.productsList.forEach((item, index) => {
          item.index = index
        })
        this.list = val[0]
        this.ptotalObj = val[1]
        this.ctotalObj = val[2]
      },
      // 接收明细列表
      receiveThingsList(val) {
        this.thingsList.forEach((item, index) => {
          item.index = index
        })
        this.list2 = val[0]
        this.ptotalObj2 = val[1]
        this.ctotalObj2 = val[2]
      },
      // 提交表单
      submitForm(formName) {
        let location1 = true
        let location2 = true
        let res = true
        let fileRequired = true
        let resRequired = true
        // 活动为对象管理
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0') {
          this.$refs.children.$refs.resource.validate((resValid, resErr) => {
            if(resValid) {
              resRequired = true
            }else {
              resRequired = false
            }
          })
        }
        // 产品位置
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.chooseOwh == '0' && this.list.length) { 
          this.list.some((item, index) => {
            if(!item.cbowhId) {
              this.$message.error(`请选择第 ${index + 1} 行仓库`)
              location1 = false
              return true
            }else {
              location1 = true
            }
          })
        }else {
          location1 = true
        }
        // 明细位置
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.chooseOwh == '0' && this.list2.length) { 
          this.list2.some((item, index) => {
            if(!item.cbowhId) {
              this.$message.error(`请选择第 ${index + 1} 行仓库`)
              location2 = false
              return true
            }else {
              location2 = true
            }
          })
        }else {
          location2 = true
        }
        // 判断资源行是否选中多个资源
        this.list.some((item, index) => {
          item.ofrresourceId = item.ofrresourceId + ''
          if(item.ofrresourceId.includes(',')) {
            this.$message.error(`第 ${index + 1} 行资源选中多条，请选择一条`)
            res = false
            return true
          }else {
            res = true
          }
        })
        // 判断附件必填
        if(this.fileList.length) {
          this.fileList.some((item, index) => {
            if(item.isRequired == '1' && !item.fileList.length) {
              this.$message.error(`请上传${item.name}`)
              fileRequired = false
              return true
            }else {
              fileRequired = true
            }
          })
        }
        this.$refs[formName][0].validate((valid) => {
          if (valid) {
            if (valid && location1 && location2 && res && fileRequired && resRequired) {
              // 格式化文档列表
              let documentsList = []
              if(this.documentsList) {
                documentsList = this.handleDimes(this.documentsList)
              }
              let resourceDimes = []
              if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0' && this.resourceDimes) {
                resourceDimes = this.handleDimes(this.resourceDimes)
              }
              // 表格价税合计
              let ptotalObj = {}
              if(this.thingsList.length) {
                ptotalObj = this.ptotalObj2
              }else {
                ptotalObj = this.ptotalObj
              }
              
              // 表格成本价税合计
              let ctotalObj = {}
              if(this.thingsList.length) {
                ctotalObj = this.ctotalObj2
              }else {
                ctotalObj = this.ctotalObj
              }
              // 禁用提交按钮
              this.isDisabled = true
              let query = {
                ...this.ruleForm,
                documents: JSON.stringify(documentsList),
                list: JSON.stringify(this.list),
                list2: JSON.stringify(this.list2),
                ...ptotalObj,
                ...ctotalObj,
                resourceName: this.$refs.children[0].resource.resourceName,
                resourceDimes: JSON.stringify(resourceDimes),
                resourceRemarks: this.$refs.children[0].resource.remarks,
                cbRestypeId: this.$refs.children[0].resource.cbRestypeId,
                files: JSON.stringify(this.fileList)
              }
              this.$http.post(url.addWork, qs.stringify({...query, ofoflowworkId: this.ofoFlowWorkId})).then(res => {
                // console.log(res);
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.isShowHistory = true
                  this.getData()
                }
              })
            }
          } else {
            for(let key in err) {
              if(key.includes('dataSourceConfig')) {
                 this.$message.error(`${this.documentsList[key.split('.')[1]].dimeName}不能为空`)
                 return false;
              }
            }
            return false;
          }
        });
      },
      async getData() {
        this.timer = new Date().getTime()
        this.productsList = []
        this.thingsList = []
        this.ruleForm = {
          code: '',
          name: '',
          workDate: '',
          ifalert: '1',
          alertDate: '',
          dealId: '',
          dealowhId: '',
          selforgId: '',
          selfowhId: '',
          dealownId: '',
          selfownId: '',
          dealownName: '',
          isNotice: '1',
          noticeUsers: '',
          noticeFlowWork: ''
        },
        this.ruleForm.name = this.ofoFlowWork.name
        this.ruleForm.workDate = this.parseTime(Date.now(), '{y}-{m}-{d}')
        await this.$http.get(url.getOfoFlowWorkfileList, {params: {ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.fileList = res.data.data
            
          }
        })
        if(this.fileList.length) {
          this.navList[4].isShow = true
        }else {
          this.navList[4].isShow = false
        }
        let navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            navArr.push(index)
          }
        })
        this.isActive = navArr[0]
        if(this.ofoFlowWork.isNotice == '0') {
          // 获取关联人员列表
          this.$http.get(url.getPostAndUserList).then(res => {
            if(res.data.code == 200) {
              this.postAndUserList = res.data.data
            }
          })
          // 获取活动复制到列表（协同用到）
          this.$http.get(url.getWorkCopyToList, {params: {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              this.workCopyToList1 = res.data.data
            }
          })
        }
        // 获取资源以及内容
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name) {
          // console.log(111);
          // console.log(this.ofoFlowWork.thingDimeType);
          this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: this.ofoFlowDeal.restype1, thingDimeType: this.ofoFlowWork.thingDimeType}}).then(res => {
            if(res.data.code == 200) {
              this.resourceType = res.data.data.cbResourceType
              this.resourceList = res.data.data.list
              this.resourceList.forEach(item => {
                if(item.ofrThingList.length) {
                  // item.ofrThingList =  this.handleDimes(item.ofrThingList, 'name', 'name')
                  item.ofrThingList = item.ofrThingList
                }
              })
              if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
                this.ruleForm.dealId = this.resourceList.length ? this.resourceList[0].ofrResourceId : ''
                this.getResChange(this.ruleForm.dealId)
              }
            }
          })
        }
        // 获取资源维度，活动核算对象为对象管理
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0') {
          this.$http.get(url.getResTypes, {params: {cbRestypeIds: this.ofoFlowWork.kindtype}}).then(res => {
            if(res.data.code == 200) {
              this.resTypesList = res.data.data
              this.resource.cbRestypeId = this.resource.cbRestypeId ? this.resource.cbRestypeId : this.resTypesList[0].cbRestypeId
              this.getCbussDimeList()
            }
          })
        }
        // 获取文档列表
        if(this.ofoFlowWork.kinddocutype && this.documentstatus != '0') {
          // console.log(111);
          this.$http.get(url.getCbussDimeList, {params: {cbussId: this.ofoFlowWork.kinddocutype, dimetype: '2'}}).then(res => {
            if(res.data.code == 200) {
              this.documentsList = this.handleFormList(res.data.data)
              this.ruleForm.dataSourceConfig = this.documentsList
            }
          })
        }
        // 获取单位列表
        this.$http.get(url.getOrgList, {params: {restype2: this.ofoFlowDeal.restype2}}).then(res => {
          if(res.data.code == 200) {
            this.orgList = res.data.data
            this.orgList.forEach(item => {
              if(item.resourceName == JSON.parse(window.sessionStorage.getItem('loginUser')).orgName) {
                this.ruleForm.selforgId = item.ofrResourceId
              }
            })
            if(!this.ruleForm.selforgId) {
              this.ruleForm.selforgId = this.orgList.length ? this.orgList[0].ofrResourceId : ''
            }
          }
        })
        // 获取部门及人员列表
        this.$http.get(url.getDeptAndUser).then(res => {
          if(res.data.code == 200) {
            this.departmentList = res.data.data
            this.ruleForm.selfowhId = JSON.parse(window.sessionStorage.getItem('loginUser')).deptId ? JSON.parse(window.sessionStorage.getItem('loginUser')).deptId : this.departmentList[0].cbOwhId
            this.getDeptChange(this.ruleForm.selfowhId)
            this.ruleForm.selfownId = JSON.parse(window.sessionStorage.getItem('loginUser')).bussId
            if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind == '6' && this.ofoFlowDeal.restype1Name) {
              this.ruleForm.dealowhId = this.departmentList.length ? this.departmentList[0].cbOwhId : ''
              this.getDealowhChange(this.ruleForm.dealowhId)
            }
          }
        })
        // 获取文档维度列表
        // console.log(this.documentstatus!= '0');
        // 获取上级列表
        if(this.ofoFlowWork.beforework && this.ofoFlowWork.beforeworkName) {
          this.$http.get(url.getWorkCodeList, {params: {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              // this.restaurants = res.data.data
              this.restaurants = res.data.data.map(item => {
                return {
                  value: `${item.name} - ${item.code}`,
                  ofoWorkId: item.ofoWorkId,
                  code: item.code
                }
              })
            }
          })
        }else {
          this.restaurants = []
        }
        // 获取交易下拉列表
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name && this.ofoFlowDeal.restype1Kind == '6') {
          this.$http.get(url.getDeptAndUser).then(res => {
            this.departmentList = res.data.data
          })
        }else if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name && this.ofoFlowDeal.restype1Kind != '6') {
          // this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: item.restype1}}).then(res => {
          //   this.resourceList = res.data.data.list
          // })
          this.$http.get(url.getDealList, {params: {ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId}}).then(res => {
            this.resourceList1 = res.data.data
          })
        }
        // 获取资源行配置
        if(this.ofoFlowWork.checkConfig) {
          this.$http.get(url.getItemWorkConfig, {params: {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              this.workConfig = res.data.data
            }
          })
        }else {
          this.workConfig = {}
        }
        // 获取审批状态下拉框
        if(!this.dropDown.appStatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_app_status' }}).then(res => {
            this.$store.commit('setAppStatus', res.data.data)
          })
        }
        // 获取内容维度类型下拉框
        if(!this.dropDown.thingDimeType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_thing_dimeType' }}).then(res => {
            this.$store.commit('setThingDimeType', res.data.data)
          })
        }
      },
      getCbussDimeList(val) {
        // this.resource.cbRestypeId = val.cbRestypeId
        // console.log(this.resource.cbRestypeId);
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resource.cbRestypeId, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.resourceDimes = this.handleFormList(res.data.data)
            this.resource.dataSourceConfig = this.resourceDimes
          }
        })
      },
    },
    watch: {
      toMine(newValue, oldValue) {
        // console.log(newValue);
        if(this.toMine.isUcenter) {
          this.isDeal = this.ofoFlowDealList.length + 1
        }
        if(this.toMine.isMsg) {
          this.isDeal = this.ofoFlowDealList.length + 2
        }
      }
    },
  }
</script>

<style scoped lang="scss">
.ucenter {
  display: flex;
  height: 100%;
  overflow: hidden;
  align-items: center;
  .left {
    width: 160px;
    background-color: #fff;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,1);
    /* padding: 0 8px; */
    .item {
      /* padding: 0 15px 0 6px; */
      font-size: 13px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      color: #282828;
      margin: 8px;
      padding: 7px;
      cursor: pointer;
    }
    .item:hover {
      background-color: #E5F3FF;
    }
    .isDeal {
      color: #177BE6;
      background-color: #E5F3FF;
    }
  }
  .middle {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #79bbff;
  }
  .middle:hover {
    color: #0c75e5;
  }
  .right {
    /* margin-left: 176px;
    padding: 16px; */
    /* display: flex;
    flex-direction: column; */
    padding: 16px;
    /* margin: 16px; */
    flex: 1;
    overflow: auto;
    padding-left: 0;
    height: calc(100% - 32px);
    ::v-deep .el-tabs__nav {
        z-index: 1;
      }
    .nav-tabs {
      display: flex;
      /* overflow: hidden; */
      /* height: 50px; */
      /* background-color: yellow; */
      margin-bottom: 16px;
      margin-right: 20px;
      border-bottom: 1px solid #eeeeee;
      .nav-title {
        display: flex;
        padding-bottom: 16px;
        /* border-bottom: #409eff 2px solid; */
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        .nav-icon {
          width: 40px;
          height: 40px;
          background-color: #FFFFFF;
          border-radius: 50%;
          margin-right: 8px;
          text-align: center;
          line-height: 40px;
          border: #409eff 1px solid;
          color: #409eff;
          .iconfont {
            font-size: 18px;
          }
        }
      }
      .isActive {
        border-bottom: #409eff 3px solid;
        color: #409eff;
        .nav-icon {
          background-color: #409eff;
          color: #FFFFFF;
        }
      }
    }
    .nav-content {
      margin-bottom: 16px;
      margin-right: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
    }
  }
}
</style>