<template>
  <div class="contain">
    <div class="contain-left">
      <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText"
        size="mini"
        style="margin-bottom:10px">
      </el-input>
      <el-tree
        :data="ofoFlowDealList"
        :props="defaultProps"
        default-expand-all
        highlight-current
        ref="tree"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
        node-key="code">
      </el-tree>
    </div>
    <div class="contain-right">
      <el-form :inline="true" :model="queryParams" class="demo-form-inline" v-show="isShowFormInline" size="small">
        <el-form-item>
          <strong slot="label">活动名称</strong>
          <el-input
            v-model="queryParams.ofoworkName"
            placeholder="请输入活动名称"
            clearable
            @keyup.enter.native="inquire"
            @clear="inquire"
            style="width:240px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <strong slot="label">附件类型</strong>
          <el-input
            v-model="queryParams.ofoflowworkfileName"
            placeholder="请输入附件类型"
            clearable
            @keyup.enter.native="inquire"
            @clear="inquire"
            style="width:240px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <strong slot="label">附件名称</strong>
          <el-input
            v-model="queryParams.fileName"
            placeholder="请输入附件名称"
            clearable
            @keyup.enter.native="inquire"
            @clear="inquire"
            style="width:240px"
          ></el-input>
        </el-form-item>
        <el-form-item class="date">
          <strong slot="label">上传时间</strong>
          <el-date-picker
            v-model="date"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="my-el-date-picker"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:240px">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
          <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          <el-button plain type="warning" icon="el-icon-download" @click="handleDown">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="fileList"
        style="width: 100%;margin-bottom:39px"
        v-loading="loading"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}">
        <el-table-column
          prop="ofoworkName"
          label="活动名称"
          width="300">
          <template slot-scope="scope">
            <div class="link" @click="toWork(scope.row)">{{ scope.row.ofoworkName }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="ofoWorkFileId"
          label="活动序号"
          width="100">
        </el-table-column> -->
        <el-table-column
          prop="ofoflowworkfileName"
          label="附件类型"
          width="200">
        </el-table-column>
        <el-table-column
          prop="fileName"
          label="附件名称">
          <template slot-scope="scope">
            <el-link :href="`${baseUrl}${scope.row.fileUrl}`" :underline="false" target="_blank">
              <span class="el-icon-document"> {{ scope.row.fileName }} </span>
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="上传时间"
          width="200">
          <template slot-scope="scope">
            {{scope.row.createTime | filter_handleDateTime}}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        layout="->, prev, pager, next, sizes, jumper, total"
        :total="total"
      >
      </el-pagination>
    </div>
    
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
  export default {
    props: {
      ofoFlowDealList: {
        type: [Array],
        default: () => ([])
      },
      name: {
        type: String
      },
      ofoItemId: {
        type: [String, Number]
      }
    },
    data() {
      return {
        fileList: [],
        loading: true,
        total: 0,
        // ofoItemId: this.$route.params.ofoItemId,
        baseUrl: this.$base_url,
        isShowFormInline: true,
        
        defaultProps: {
          children: 'ofoFlowWorkList',
          label: 'name'
        },
        filterText: '',
        queryParams: {
          // ofoItemId: this.$route.params.ofoItemId,
          beginTime: null,
          endTime: null,
          fileName: '',
          ofoflowworkId: null,
          ofoflowworkfileName: '',
          ofoworkName: '',
          pageNum: 1,
          pageSize: 10,
        },
        date: []
      }
    },
    created () {
      this.ofoItemId = this.ofoItemId ? this.ofoItemId : this.$route.params.ofoItemId
      this.queryParams.ofoItemId = this.ofoItemId
      this.getList()
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods: {
      
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      handleNodeClick(data, node) {
        if(node.level == 2) {
          this.queryParams.ofoflowworkId = data.ofoFlowWorkId
        }else {
          this.queryParams.ofoflowworkId = null
        }
        this.getList()
      },
      // 跳到活动详情
      toWork(row) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: row.ofoworkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$store.commit("setJumpData", {...res.data.data, isFile: true})
          }
          
        })
      },
      getList() {
        this.loading = true
        if(this.date.length) {
          this.queryParams.beginTime = this.date[0]
          this.queryParams.endTime = this.date[1]
        }
        this.$http.get(url.getItemFileList, {params: this.queryParams}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.fileList =res.data.data.list
            this.total = res.data.data.total
          }
        })
      },
      inquire() {
        this.queryParams.pageNum = 1
        this.getList()
      },
      reset() {
        this.$refs.tree.setCurrentKey(null);
        this.queryParams = {
          ofoItemId: this.$route.params.ofoItemId,
          beginTime: null,
          endTime: null,
          fileName: '',
          ofoflowworkId: null,
          ofoflowworkfileName: '',
          ofoworkName: '',
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize,
        },
        this.getList()
      },
      handleDown() {
        const loading = this.$loading({
          lock: true,
          text: '下载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        this.$http({
          url: url.exportItemFile,
          method: 'post',
          params: {
            ...this.queryParams,
            beginTime: this.date.length ? this.date[0] : '',
            endTime: this.date.length ? this.date[1] : '',
            ofoItemId: this.$route.params.ofoItemId
          },
          responseType: 'blob',
          headers: {            //注意
            'Content-Type': 'application/json; application/octet-stream'
          }
        }).then(res => {
          let name = this.name + '.zip'
          this.fileHandleCompressed(res, name);
          loading.close()
        }).catch(err => {
         
        })
      },
      // 文件流转为zip
      fileHandleCompressed(data, fileName) {
        // let blob = new Blob([data], { type: 'application/zip,charset=utf-8'})//此处必须添加
        let blob = data
        let url = window.URL.createObjectURL(blob);
        const link = window.document.createElement('a'); // 创建a标签
        link.href = url;
        link.download = fileName; // 重命名文件
        link.click();
        URL.revokeObjectURL(url); // 释放内存
      },
      handleSizeChange(val) {
        this.queryParams.pageSize = val;
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.queryParams.pageNum = val;
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  display: flex;
  max-height: calc(100vh - 242px);
  .contain-left {
    margin-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }
  .contain-right {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>