<template>
  <div class="contain">
    <img src="../../assets/images/develop.png" alt="">
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.contain {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  >img {
    width: 75%;
    height: 90%;
  }
}
</style>