// 处理表单数据
function getDimeValue(dimeObj) {
  let dimeValue = null
  if(dimeObj.editType == '3' || dimeObj.editType == '6') { // 下拉框 || 单选框
    if(dimeObj.options) {
      // let dimeArr = []
      // console.log(111);
      // console.log(dimeObj);
      // console.log(JSON.parse(dimeObj.options).find(arr => arr.optionValue == dimeObj.dimeValue));
      // dimeArr.push(JSON.parse(dimeObj.options).find(arr => arr.optionValue == dimeObj.dimeValue).optionLabel)
      JSON.parse(dimeObj.options).forEach(el => {
        if(dimeObj.dimeValue == el.optionValue) {
          dimeValue = el.optionLabel
        }
      })
      // dimeValue = dimeArr.toString()
    }
    if(!dimeValue) {
      dimeValue = dimeObj.dimeValue
    }
  }else if(dimeObj.editType == '7' && dimeObj.dimeValue) { // 多选框
    if(typeof(dimeObj.dimeValue) != 'object') {
      dimeObj.dimeValue = JSON.parse(dimeObj.dimeValue)
    }
    let dimeArr = []
    if(dimeObj.options) {
      // dimeObj.dimeValue.forEach(item => {
      //   // console.log(222);
      //   // console.log(JSON.parse(dimeObj.options).find(arr => arr.optionValue == item));
      //   dimeArr.push(JSON.parse(dimeObj.options).find(arr => arr.optionValue == item).optionLabel)
      // })
      
      JSON.parse(dimeObj.options).forEach(val => {
        dimeObj.dimeValue.forEach(el => {
          if(val.optionValue == el) {
            // str += val.optionLabel + ', '
            dimeArr.push(val.optionLabel)
          }
        })
      })
    }else {
      dimeArr = dimeObj.dimeValue
      // dimeObj.dimeValue.forEach(val => {
      //   str += val + ', '
      // })
    }
    dimeValue = dimeArr.toString()
  }else if(dimeObj.editType == '8' && dimeObj.dimeValue) { // 附件
    if(typeof(dimeObj.dimeValue) != 'object') {
      dimeValue = JSON.parse(dimeObj.dimeValue)
    }else {
      dimeValue = dimeObj.dimeValue
    }
  }else if(dimeObj.editType == '11' && dimeObj.dimeValue) { // 百分比
    dimeValue = dimeObj.dimeValue + '%'
  }else if(dimeObj.editType == '12') { // 资源
    let dimeArr = []
    if(dimeObj.options) {
      dimeObj.dimeValue.forEach(item => {
        dimeObj.options.forEach(val => {
          if(val.ofrResourceId == item) {
            dimeArr.push(val.resourceName)
          }
        })
        // dimeArr.push(dimeObj.options.find(arr => arr.ofrResourceId == item).resourceName)
      })
    }else {
      dimeArr = dimeObj.dimeValue
    }
    dimeValue = dimeArr.toString()
  }else if(dimeObj.editType == '13') { // 内容
    let dimeArr = []
    if(dimeObj.options) {
      dimeObj.options.forEach(val => {
        let a = val.ofrThingList.filter(list => dimeObj.dimeValue.map(Number).includes(Number(list.ofrThingId)))
        if(a.length > 0){
          dimeArr.push(...a.map(item => item.name))
        }
      })
    }else {
      dimeArr = dimeObj.dimeValue
    }
    dimeValue = dimeArr.toString()
  }else if(dimeObj.editType == '14') { // 项目
    // console.log(dimeObj);
    let dimeArr = []
    if(dimeObj.options) {
      dimeObj.options.forEach(val => {
        let a = val.ofoFlowItemList.filter(list => dimeObj.dimeValue.map(Number).includes(Number(list.ofoItemId)))
        if(a.length > 0){
          dimeArr.push(...a.map(item => item.name))
        }
      })
    }else {
      dimeArr = dimeObj.dimeValue
    }
    dimeValue = dimeArr.toString()
  }else {
    dimeValue = dimeObj.dimeValue
  }
  return dimeValue
}

export default function handleDimes(list) {
  let arr = []
  list.forEach(item => {
    arr.push({
      cbdId: item.cbdId,
      dimeValue: item.dimeValue,
      dimeText: item.dimeValue ? getDimeValue(item) : ''
    })
  })
  return arr
}