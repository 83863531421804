export default {
  state: {
    tab: JSON.parse(sessionStorage.getItem('tab'))? JSON.parse(sessionStorage.getItem('tab')): {
      showCard: false,
      showTable: true
    }
  },
  mutations: {
    setStates(state, val) {
      state.tab.showCard = !state.tab.showCard
      state.tab.showTable = !state.tab.showTable
      window.sessionStorage.setItem('tab', JSON.stringify(state.tab))
    }
  }
}