import Resource from '@/views/resource/Index.vue'

import Kind from '@/views/resource/kind/Index.vue'
// import Pro from '@/views/resource/pro/Index.vue'
import Position from '@/views/resource/position/Index.vue'
import User from '@/views/resource/user/Index.vue'
// import Subject from '@/views/resource/subject.vue'
import Dime from '@/views/resource/dime/Index.vue'
import Import from '@/views/resource/import/Index.vue'
import ProImport from '@/views/resource/proImport/Index.vue'
import Inventory from '@/views/resource/inventory/Index.vue'

import ResourceDetail from '@/views/resource/kind/detail.vue'

import Develop from '@/views/develop/Index.vue'

const routes = [
  {
    path: '/resource',
    name: 'resource',
    // redirect: '/resource/kind',
    meta: {
      keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    },
    component: Resource,
    children: [
      {
        path: 'kind',
        name: 'kind',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Kind
      },
      // {
      //   path: 'pro',
      //   name: 'pro',
      //   component: Pro
      // },
      {
        path: 'position',
        name: 'position',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Position
      },
      {
        path: 'user',
        name: 'user',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: User
      },
      // {
      //   path: 'subject',
      //   name: 'subject',
      //   component: Subject
      // },
      {
        path: 'dime',
        name: 'dime',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Dime
      },
      {
        path: 'import',
        name: 'import',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Import
      },
      {
        path: 'proImport',
        name: 'proImport',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: ProImport
      },
      {
        path: 'inventory',
        name: 'inventory',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Inventory
      },
      {
        path: 'kind/:cbRestypeId',
        name: 'kindDetail',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: ResourceDetail
      },
      {
        path: 'kind/:cbRestypeId/content/:ofrResourceId',
        name: 'content',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: () => import('@/views/resource/kind/content.vue')
      },
      {
        path: '*',
        name: 'develop',
        component: Develop
      },
    ]
  }
]

export default routes