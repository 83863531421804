<template>
  <div class="container">
    <div class="title">企业配置</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="right"
    >
      <el-button type="primary" size="small" @click="handleSave('ruleForm')" :loading="isLoading" style="margin: 0 0 16px 66px">保存</el-button>
      <el-tabs tab-position="left">
        <el-tab-pane label="资产出租情况">
          <h4>公共配套面积：</h4>
          <el-form-item label="公共区域登记-活动名称" prop="vis_public_area_reg">
            <el-input v-model="ruleForm.vis_public_area_reg" placeholder="请输入公共区域登记-活动名称"/>
          </el-form-item>
          <h4>可租资产面积（载体发布-载体回收）：</h4>
          <el-form-item label="载体发布-活动名称" prop="vis_asset_announcement">
            <el-input v-model="ruleForm.vis_asset_announcement" placeholder="请输入载体发布-活动名称"/>
          </el-form-item>
          <el-form-item label="载体回收-活动名称" prop="vis_asset_recycle">
            <el-input v-model="ruleForm.vis_asset_recycle" placeholder="请输入载体回收-活动名称"/>
          </el-form-item>
          <h4>即将到期资产面积：</h4>
          <el-form-item label="租赁合同-活动名称" prop="vis_lease_contract">
            <el-input v-model="ruleForm.vis_lease_contract" placeholder="请输入租赁合同-活动名称"/>
          </el-form-item>
          <el-form-item label="预警天数" prop="vis_warning_count">
            <el-input-number v-model="ruleForm.vis_warning_count" placeholder="请输入预警天数" controls-position="right"></el-input-number>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="租金收益情况">
          <el-form-item label="主营业务-财务科目" prop="vis_business_subject_code">
            <treeselect :multiple="true" v-model="ruleForm.vis_business_subject_code" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择主营业务-财务科目" :appendToBody="true" />
          </el-form-item>
          <el-form-item label="应收账款-财务科目" prop="vis_receivable_subject_code">
            <treeselect :multiple="true" v-model="ruleForm.vis_receivable_subject_code" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择应收账款-财务科目" :appendToBody="true" />
          </el-form-item>
          <el-form-item label="企业租赁-活动名称" prop="vis_lease_income">
            <el-input v-model="ruleForm.vis_lease_income" placeholder="请输入企业租赁-活动名称"/>
          </el-form-item>
          <el-form-item label="物业费用-活动名称" prop="vis_property_income">
            <el-input v-model="ruleForm.vis_property_income" placeholder="请输入物业费用-活动名称"/>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="企业考核情况">
          <el-form-item label="考核评估-活动名称" prop="vis_rank_doc">
            <el-input v-model="ruleForm.vis_rank_doc" placeholder="请输入考核评估-活动名称"/>
          </el-form-item>
          <el-form-item label="资源类型" prop="vis_resource_type_rank">
            <el-select v-model="ruleForm.vis_resource_type_rank" placeholder="请选择资源类型" filterable @change="changeRes($event, '2', 'vis_rank_result_dime_num')" >
              <el-option-group
                v-for="group in resTypeTree"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id + ''">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="企业考核情况-内容维度" prop="vis_rank_result_dime_num">
            <el-select v-model="ruleForm.vis_rank_result_dime_num" placeholder="请选择企业考核情况-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="企业培育情况">
          <el-form-item label="考核评估-活动名称" prop="vis_rank_doc">
            <el-input v-model="ruleForm.vis_rank_doc" placeholder="请输入考核评估-活动名称"/>
          </el-form-item>
          <el-form-item label="资源类型" prop="vis_resource_type_rank">
            <el-select v-model="ruleForm.vis_resource_type_rank" placeholder="请选择资源类型" filterable @change="changeRes($event, '2', 'vis_rank_result_dime_num')" >
              <el-option-group
                v-for="group in resTypeTree"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id + ''">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="企业产值-内容维度" prop="vis_com_dime_num1">
            <el-select v-model="ruleForm.vis_com_dime_num1" placeholder="请选择企业产值-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业税收-内容维度" prop="vis_com_dime_num1">
            <el-select v-model="ruleForm.vis_com_dime_num2" placeholder="请选择企业税收-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="就业人数-内容维度" prop="vis_com_dime_num1">
            <el-select v-model="ruleForm.vis_com_dime_num3" placeholder="请选择就业人数-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="知识产权-内容维度" prop="vis_com_dime_num1">
            <el-select v-model="ruleForm.vis_com_dime_num4" placeholder="请选择知识产权-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="国科小数量-内容维度" prop="vis_com_dime_num1">
            <el-select v-model="ruleForm.vis_com_dime_num5" placeholder="请选择国科小数量-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="高企数量-内容维度" prop="vis_com_dime_num1">
            <el-select v-model="ruleForm.vis_com_dime_num6" placeholder="请选择高企数量-内容维度" clearable filterable >
              <el-option v-for="(item, index) in vis_rank_result_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="产业分类">
          <el-form-item label="资源类型" prop="vis_resource_type_indu">
            <el-select v-model="ruleForm.vis_resource_type_indu" placeholder="请选择资源类型" filterable @change="changeRes($event, '1', 'vis_indu_type_dime_num')" >
              <el-option-group
                v-for="group in resTypeTree"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id + ''">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="产业分类-资源维度" prop="vis_indu_type_dime_num">
            <el-select v-model="ruleForm.vis_indu_type_dime_num" placeholder="请选择产业分类-资源维度" clearable filterable >
              <el-option v-for="(item, index) in vis_indu_type_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="新增租赁提醒">
          <el-form-item label="租赁合同-活动名称" prop="vis_lease_contract">
            <el-input v-model="ruleForm.vis_lease_contract" placeholder="请输入租赁合同-活动名称"/>
          </el-form-item>
          <el-form-item label="资源类型" prop="vis_resource_type">
            <el-select v-model="ruleForm.vis_resource_type" placeholder="请选择资源类型" filterable @change="changeRes($event, '1', 'vis_com_type_dime_num')" >
              <el-option-group
                v-for="group in resTypeTree"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id + ''">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="企业种类-维度" prop="vis_com_type_dime_num">
            <el-select v-model="ruleForm.vis_com_type_dime_num" placeholder="请选择企业种类-维度" clearable filterable >
              <el-option v-for="(item, index) in vis_com_type_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业法人-维度" prop="vis_legal_person_dime_num">
            <el-select v-model="ruleForm.vis_legal_person_dime_num" placeholder="请选择企业法人-维度" clearable filterable >
              <el-option v-for="(item, index) in vis_com_type_dime_num" :key="index" :label="item.dimeName" :value="item.cbDimeId + ''"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="楼层企业">
          <el-form-item label="交付登记-活动名称" prop="vis_delivery_registration">
            <el-input v-model="ruleForm.vis_delivery_registration" placeholder="请输入交付登记-活动名称"/>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import url from '@/config/url'
import handleTree from '@/utils/handleTree'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      Treeselect
    },
    data() {
      return {
        ruleForm: {
          vis_asset_announcement: null,
          vis_lease_contract: null,
          vis_warning_count: '',
          vis_public_area_reg: null,
          vis_asset_recycle: null,
          vis_business_subject_code: null,
          vis_receivable_subject_code: null,
          vis_lease_income: null,
          vis_property_income: null,
          vis_rank_doc: null,
          vis_resource_type_rank: null,
          vis_rank_result_dime_num: null,
          vis_resource_type_com: null,
          vis_com_dime_num1: null,
          vis_com_dime_num2: null,
          vis_com_dime_num3: null,
          vis_com_dime_num4: null,
          vis_com_dime_num5: null,
          vis_com_dime_num6: null,
          vis_resource_type_indu: null,
          vis_indu_type_dime_num: null,
          vis_resource_type: null,
          vis_com_type_dime_num: null,
          vis_legal_person_dime_num: null,
          vis_delivery_registration: null,
        },
        rules: {
          // account_user: [{ required: true, message: "请选择记账人", trigger: "submit" }],
          // check_user: [{ required: true, message: "请选择审核人", trigger: "submit" }],
        },
        ofrthingList: [],
        cbSubjectList: [],
        isLoading: false,
        resTypeTree: [],
        cbussDimeList: [],
        vis_rank_result_dime_num: [],
        vis_indu_type_dime_num: [],
        vis_com_type_dime_num: []
      }
    },
    created () {
      this.getData();
    },
    methods: {
      handleSave(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // console.log(this.ruleForm);
            this.isLoading = true
            let obj = {}
            for(let key in this.ruleForm) {
              if(key == 'vis_receivable_subject_code' || key == 'vis_business_subject_code') {
                if(this.ruleForm[key]) {
                  obj[key] = this.ruleForm[key].join(',')
                }else {
                  obj[key] = ''
                }
              }else {
                obj[key] = this.ruleForm[key]
              }
            }
            this.$http.post(url.updateConfigs, qs.stringify(obj)).then(res => {
              this.isLoading = false
              if(res.data.code == 200) {
                this.$message.success(res.data.msg);

              }
            })
          } else {
            return false;
          }
        });
        
      },
      
      changeRes(e, dimetype, key) {
        this.getCbussDimeList(e, dimetype, key)
      },
      getCbussDimeList(cbussId, dimetype, key) {
        this.$http.get(url.getCbussDimeList, {params: {
          cbussId,
          dimetype
        }}).then(res => {
          if(res.data.code == 200) {
            this[key] = res.data.data
          }
        })
      },
      getData() {
        // 获取科目列表
        this.$http.get(url.getCbSubjectList).then(res => {
          if(res.data.code == 200) {
            this.cbSubjectList = handleTree(res.data.data, 'cbSubjectId', 'pid')
          }
        })
        // 获取资源类型列表
        this.$http.get(url.getResTypeTree).then(res => {
          if(res.data.code == 200) {
            this.resTypeTree = res.data.data
          }
        })
        this.$http.get(url.getConfigs).then(res => {
          if(res.data.code == 200) {
            for(let key in res.data.data) {
              if(key == 'vis_receivable_subject_code' || key == 'vis_business_subject_code') {
                if(res.data.data[key]) {
                  this.ruleForm[key] = res.data.data[key].split(',')
                }else {
                  this.ruleForm[key] = null
                }
              }else {
                this.ruleForm[key] = res.data.data[key]
              }
            }
            if(this.ruleForm.vis_resource_type_rank) {
              this.getCbussDimeList(this.ruleForm.vis_resource_type_rank, '2', 'vis_rank_result_dime_num')
            }
            if(this.ruleForm.vis_resource_type_indu) {
              this.getCbussDimeList(this.ruleForm.vis_resource_type_indu, '1', 'vis_indu_type_dime_num')
            }
            if(this.ruleForm.vis_resource_type) {
              this.getCbussDimeList(this.ruleForm.vis_resource_type, '1', 'vis_com_type_dime_num')
            }
          }
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
        //   if (node.children && !node.children.length) {
        //   delete node.children;
        // }
        return {
          // id: node.cbSubjectId,
          id: node.subjectNumber,
          label: node.subjectName + ': ' + node.subjectNumber,
          children: node.children
        };
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .title {
    text-align: center;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 16px;
  }
}
.el-form {
  height: calc(100% - 44px);
}
.el-tabs {
  height: calc(100% - 44px);
  ::v-deep .el-tabs__content {
    height: 100%;
    overflow: auto;
  }
}
.vue-treeselect, .el-select {
  width: 98%;
}
</style>