export default {
  state: {
    tagsList: JSON.parse(sessionStorage.getItem('tagsList')) ? JSON.parse(sessionStorage.getItem('tagsList')) : [
      {
        children: [],
        icon: "iconfont icon-gongzuotai",
        label: "桌面",
        name: "index",
        path: "/index"
      }
    ],
    currentMenu: JSON.parse(sessionStorage.getItem('currentMenu')) ? JSON.parse(sessionStorage.getItem('currentMenu')) : {
      children: [],
      icon: "iconfont icon-gongzuotai",
      label: "桌面",
      name: "index",
      path: "/index"
    },

    currentIndex: sessionStorage.getItem('currentIndex') ? sessionStorage.getItem('currentIndex') : 0,
  },
  mutations: {
    // 用于增加tags
    selectMenu(state, val) {
      var obj = Object.assign({}, val)
      if(val.children.length && val.label != '资源') {
        if(val.label == '财务') {
          obj = Object.assign({}, val.children[0].children[0])
          obj.label = '财务'
          state.currentMenu = obj
        }else {
          // console.log(1234);
          obj.path = obj.children[0].path
          state.currentMenu = obj.children[0]
        }
      }else {
        state.currentMenu = obj
      }
      if((/\d/).test(state.currentMenu.path)) {
        if(state.currentMenu.path.split('/')[1] == 'project') {
          state.currentIndex = state.tagsList.findIndex(item => item.path.includes('/project/'))
          if(state.currentIndex === -1) {
            state.currentIndex = state.tagsList.length
            state.tagsList.push(val)
          }else {
            state.tagsList[state.currentIndex] = val
          }
        }else if(state.currentMenu.label == '凭证详情') {
          state.currentIndex = state.tagsList.findIndex(item => item.label == '凭证详情')
          if(state.currentIndex === -1) {
            state.currentIndex = state.tagsList.length
            state.tagsList.push(val)
          }else {
            state.tagsList[state.currentIndex] = val
          }
        // }else if(state.currentMenu.name == 'kind') {
        //   state.currentIndex = state.tagsList.findIndex(item => item.label == '资源')
        //   if(state.currentIndex === -1) {
        //     state.currentIndex = state.tagsList.length
        //     state.tagsList.push(val)
        //   }else {
        //     state.tagsList[state.currentIndex] = val
        //   }
        }else if(state.currentMenu.label == '资源详情') {
          state.currentIndex = state.tagsList.findIndex(item => item.label == '资源详情')
          if(state.currentIndex === -1) {
            state.currentIndex = state.tagsList.length
            state.tagsList.push(val)
          }else {
            state.tagsList[state.currentIndex] = val
          }
        }else if(state.currentMenu.label == '内容详情') {
          state.currentIndex = state.tagsList.findIndex(item => item.label == '内容详情')
          if(state.currentIndex === -1) {
            state.currentIndex = state.tagsList.length
            state.tagsList.push(val)
          }else {
            state.tagsList[state.currentIndex] = val
          }
        }else if(state.currentMenu.label == '流程详情') {
          state.currentIndex = state.tagsList.findIndex(item => item.label == '流程详情')
          if(state.currentIndex === -1) {
            state.currentIndex = state.tagsList.length
            state.tagsList.push(val)
          }else {
            state.tagsList[state.currentIndex] = val
          }
        }else if(state.currentMenu.label == '报表详情') {
          state.currentIndex = state.tagsList.findIndex(item => item.label == '报表详情')
          if(state.currentIndex === -1) {
            state.currentIndex = state.tagsList.length
            state.tagsList.push(val)
          }else {
            state.tagsList[state.currentIndex] = val
          }
        }
      }
      else {
        state.currentIndex = state.tagsList.findIndex(item => item.label === obj.label)
        // 添加前需判断tagsList中是否已经存在该val，若有则无需添加
        // let result = state.tagsList.findIndex(item => item.path === val.path)
        if(state.currentIndex === -1) {
          state.currentIndex = state.tagsList.length
          state.tagsList.push(obj)
        }
      }
     
      
      // 用于刷新时保证标签导航栏不变
      window.sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
      window.sessionStorage.setItem('currentMenu', JSON.stringify(state.currentMenu))
      window.sessionStorage.setItem('currentIndex', state.currentIndex)
    },
    selectMenu1(state, val) {
      if(val.children.length) {
        val.children.forEach((item, index) => {
          if(val.name == item.name) {
            state.currentMenu = val.children[index]
          }
        });
      }else {
        state.currentMenu = val
      }
      
      state.currentIndex = state.tagsList.findIndex(item => item.path === val.path)
      // 添加前需判断tagsList中是否已经存在该val，若有则无需添加
      let result = state.tagsList.findIndex(item => item.path === val.path)
      if(result === -1) {
        state.currentIndex = state.tagsList.length
        state.tagsList.push(val)
      }else {
        state.tagsList[result] = val
      }
      // 用于刷新时保证标签导航栏不变
      window.sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
      window.sessionStorage.setItem('currentMenu', JSON.stringify(state.currentMenu))
      window.sessionStorage.setItem('currentIndex', state.currentIndex)
    },
    // 用于关闭tags
    closeTag(state, val) {
      // 删除前先查找index
      let result = state.tagsList.findIndex(item => item.path === val.path)
      result === -1 ? '' : state.tagsList.splice(result, 1)
      // console.log( state.tagsList.length);
      if (state.tagsList.length === 0) state.currentMenu = null
      // 用于刷新时保证标签导航栏不变
      window.sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
      window.sessionStorage.setItem('currentMenu', JSON.stringify(state.currentMenu))
    },
    // 用于改变当前菜单
    changeMenu(state, val) {
      state.currentMenu = val
      window.sessionStorage.setItem('currentMenu', JSON.stringify(state.currentMenu))
    },
    // 用于清空tags
    clearMenu(state, val) {
      state.tagsList = val
      // 用于刷新时保证标签导航栏不变
      window.sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
    },
    // 用于关闭多个tag
    closeMoreTag(state, val) {
      state.tagsList = val
      window.sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
    },
  }
}
