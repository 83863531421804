<template>
  <div class="entry">
    <div class="sift">
      <div class="title">结账</div>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">月份</strong>
            <el-date-picker
              v-model="formInline.date"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              format="yyyy-MM"
              value-format="yyyy-MM">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <strong slot="label">账套</strong>
            <el-select v-model="formInline.accountType" placeholder="请选择账套" clearable filterable>
              <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleCheckout">结账</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="main">
      <div class="title">凭证列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="accountCode" label="代码" width="160">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.accountCode}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofoworkName" label="活动" align="center" min-width="200">
          <template slot-scope="scope">
            <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofrthingName" label="负责人" align="center" width="120"></el-table-column>
        <el-table-column prop="ojdtDate" label="日期" align="center" width="120">
          <template slot-scope="scope">
            {{scope.row.ojdtDate | filter_handleDate}}
          </template>
        </el-table-column>
        <el-table-column prop="tbperd" label="期间" align="center" width="120"></el-table-column>
        <el-table-column prop="debitTotal" label="借方" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.debitTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="creditTotal" label="贷方" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.creditTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="documentstatus" label="状态" align="center" width="100">
          <template slot-scope="scope">
            <DictTag :options="dropDown.ojdtStatus" :value="scope.row.documentstatus" :isStyle="true"/>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="注释" align="center" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column label="操作" fixed="right" width="80" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click.native.stop="handleDocumentStatus(scope.row)"
              type="text"
            >退回
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="90%"
      top="59px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
        class="demo-ruleForm"
        label-position="right"
      >
         <el-row>
          <el-col :span="24">
            <el-form-item label="日期" prop="period">
              <el-date-picker
                v-model="ruleForm.period"
                type="date"
                placeholder="选择凭证日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                :clearable='false'
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button @click="addList" plain type="primary" style="margin-bottom: 16px" size="small">增行</el-button>
        <el-table
          class="table"
          :data="list"
          :header-cell-style="{background:'#F9F9FB',color:'#606266'}" ref="multipleTable" style="margin-bottom: 20px">
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <!-- 科目 -->
          <el-table-column
            prop="cboactId"
            label="科目">
            <template slot-scope="scope">
              <treeselect v-model="scope.row.cboactId" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择科目" />
            </template>
          </el-table-column>
          <!-- 借方 -->
          <el-table-column
            prop="debit"
            label="借方"
            width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.debit" clearable/>
            </template>
          </el-table-column>
          <!-- 贷方 -->
          <el-table-column
            prop="credit"
            label="贷方"
            width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.credit"/>
            </template>
          </el-table-column>
          <!-- 项目	-->
          <el-table-column
            prop="ofoitemId"
            label="项目"
            width="200">
            <template slot-scope="scope">
              <el-select v-model="scope.row.ofoitemId" clearable filterable>
                <el-option-group
                  v-for="group in itemList"
                  :key="group.cfFlowItemId"
                  :label="group.showName">
                  <el-option
                    v-for="item in group.ofoFlowItemList"
                    :key="item.ofoItemId"
                    :label="item.name"
                    :value="item.ofoItemId">
                  </el-option>
                </el-option-group>
              </el-select>
            </template>
          </el-table-column>
          <!-- 现金流 -->
          <el-table-column
            prop="vatgroup"
            label="现金流">
            <template slot-scope="scope">
              <el-select v-model="scope.row.cashId" clearable filterable>
                <el-option 
                  v-for="item in cashList"
                  :label="item.name"
                  :value="item.cashFlowId"
                  :key="item.cashFlowId"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 注释 -->
          <el-table-column
            prop="remarks"
            label="注释">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remarks" clearable/>
            </template>
          </el-table-column>
          
          <el-table-column
            label="操作"
            width="80"
            >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="12">
            <el-form-item label="类型" prop="financeType">
              <el-select v-model="ruleForm.financeType" placeholder="请选择凭证类型" style="width:100%">
                <el-option v-for="(item, index) in dropDown.financeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人" prop="ofrthingId">
              <el-select v-model="ruleForm.ofrthingId" placeholder="请选择负责人" style="width:100%">
                <el-option-group
                  v-for="group in ofrthingList"
                  :key="group.ofrResourceId"
                  :label="group.resourceName">
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId">
                  </el-option>
                </el-option-group>
              </el-select>
              <!-- <el-select v-model="ruleForm.ofrthingId" placeholder="请选择负责人"> -->
                <!-- <el-option v-for="(item, index) in dropDown.thingDimeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option> -->
              <!-- </el-select> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="24"> -->
            <el-form-item label="注释" prop="remarks">
            <el-input type="textarea" v-model="ruleForm.remarks" placeholder="请输入描述"></el-input>
          </el-form-item>
          <!-- </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
import getCurrentDate from '@/utils/getCurrentDate'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      DictTag,
      Treeselect,
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        formInline: {
          date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-01'),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
          accountType: ''
        },
        ruleForm: {
          ojdtDate: '',
          documentstatus: '',
          financeType: '',
          ofrthingId: '',  
          remarks: ''
        },
        rules: {
          ojdtDate: [{ required: true, message: "请选择日期", trigger: "submit" }],
          // documentstatus: [{ required: true, message: "请选择状态", trigger: "submit" }],
          financeType: [{ required: true, message: "请选择凭证类型", trigger: "submit" }],
          ofrthingId: [{ required: true, message: "请选择负责人", trigger: "submit" }],
        },
        // options: [],
        // 弹出层标题
        title: '新增凭证',
        isLoading: false,
        pageNum: 1, 
        pageSize: 10,
        total: 0,
        // 编辑按钮不可点
        edit: false,
        del: false,
        list: [],
        itemList: [], // 项目列表
        cbSubjectList: [], // 科目列表
        options: [], // 树选项
        ofrthingList: [], // 负责人列表
        ojdtIdList: [],
        cashList: []
      }
    },
    created () {
      this.getList()
      this.getData()
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.formInline.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        this.pageNum = 1
        this.getList()
      })
      // this.$http.get(url.getPostAndUserList).then(res => {
      //   this.ofrthingList = res.data.data
      // })
    },
    methods: {
      goProject(item) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: item.ofoworkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            this.$store.commit("setJumpData", {...res.data.data, turn: true})
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            this.$store.commit('setSecondMenu', [])
            this.$store.commit("selectMenu", obj);
          }
        })
      },
      // 结账按钮
      handleCheckout() {
        this.$http.get(url.genCloseAccountsOffOjdt, {params: {
          startMonth: this.formInline.date ? this.formInline.date[0] : '',
          endMonth: this.formInline.date ? this.formInline.date[1] : '',
          accountType: this.formInline.accountType
        }}).then(res => {
          if(res.data.code == 200) {
            this.ruleForm = res.data.data.ojdt
            this.ruleForm.period = res.data.data.period
            this.list = res.data.data.ojdtacctList
            this.ojdtIdList = res.data.data.ojdtIdList
            this.dialogFormVisible = true
          }
        })
      },
      getList() {
        // 获取凭证状态下拉
        if(!this.dropDown.ojdtStatus){
          this.$http.get(url.getDataList, {params: {type: 'buss_ojdt_status'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setOjdtStatus', res.data.data)
            }
          })
        }
        // 获取凭证类型下拉
        if(!this.dropDown.financeType){
          this.$http.get(url.getDataList, {params: {type: 'buss_finance_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setFinanceType', res.data.data)
            }
          })
        }
        this.loading = true
        this.$http.get(url.getOffOjdtList, {params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          documentstatusList: '2',
          financeType: '3'
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
            this.total = res.data.data.total
          }
        })
      },
      // 增行
      addList() {
        this.list.push({
          cboactId: null,
          debit: '',
          credit: '',
          ofoitemId: '',
          remarks: '',
          cashId: ''
        })
      },
      // 减行
      handleDelete(index) {
        this.list.splice(index, 1)
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            this.ruleForm.list = JSON.stringify(this.list)
            this.ruleForm.ojdtIdList = this.ojdtIdList
            // console.log(this.ruleForm);
            // if(!this.ruleForm.offOjdtId) { // 新增凭证
              this.$http.post(url.addOffOjdt1, qs.stringify(this.ruleForm)).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.pageNum = 1
                  this.getList()
                }
              })
            // }else { // 修改凭证
            //   this.$http.post(url.editOffOjdt, qs.stringify(this.ruleForm)).then(res => {
            //     this.isLoading = false
            //     if(res.data.code === 200) {
            //       this.$message.success(res.data.msg);
            //       this.dialogFormVisible = false;
            //       this.pageNum = 1
            //       this.loading = true
            //       this.getList()
            //     }
            //   })
            // }
          } else {
            return false;
          }
        });
      },
      handleDetail(row) {
        this.$router.push({
          path: '/accounting/vDetail/' + row.offOjdtId, query: {
            account: true
        }})
        let obj = {
          path: '/accounting/vDetail/' + row.offOjdtId,
          name: 'voucherDetail',
          label: '凭证详情',
          offOjdtId: row.offOjdtId,
          children: []
        }
        this.$store.commit("selectMenu", obj);
      },
      handleDocumentStatus(row) {
        const id = row.offOjdtId || this.ids.toString()
        this.$confirm(`此操作将此凭证修改为草稿, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.get(url.batchUpdateDocumentStatus, {params:{
            offOjdtIds: id,
            documentstatus: '0'
          }}).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
              this.pageNum = 1
              this.getList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.offOjdtId)
        this.edit = rows.length === 1
        this.del = rows.length >= 1
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getList()
      },
      // 小于10补0
      add0(n) {
        return n < 10 ? '0' + n : n
      },
      getData() {
        // 获取账套类型下拉
        if(!this.dropDown.subjectAccountType2){
          this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSubjectAccountType2', res.data.data)
            }
          })
        }
        this.$http.get(url.getAllItemList).then(res => {
          if(res.data.code == 200) {
            this.itemList = res.data.data
          }
        })
        this.$http.get(url.getCbSubjectList).then(res => {
          if(res.data.code == 200) {
            this.cbSubjectList = handleTree(res.data.data, 'cbSubjectId', 'pid')
          }
        })
        this.$http.get(url.getPostAndUserList).then(res => {
          if(res.data.code == 200) {
            this.ofrthingList = res.data.data
          }
        })
        this.$http.get(url.getCashList).then(res => {
          if(res.data.code == 200) {
            this.cashList = res.data.data
          }
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
        //   if (node.children && !node.children.length) {
        //   delete node.children;
        // }
        return {
          id: node.cbSubjectId,
          label: node.subjectName + ': ' + node.subjectNumber,
          children: node.children
        };
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
   margin: 0 auto;
   height: calc(100% - 70px);
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 10px 20px;
    height: calc(100% - 134px);
    overflow: auto;
  }
  ::v-deep .table{
  overflow: visible;
}
::v-deep .table .cell{
  overflow: visible;
}
::v-deep .table .el-table__body-wrapper{
  overflow: visible;
}
.entry {
  /* padding: 16px; */
  .sift, .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add{
      margin-bottom: 16px;
    }
    .el-table {
      margin-top: 16px;
      margin-bottom: 39px;
    }
  }
  .sift {
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>