<template>
  <div class="entry">
    <div class="sift">
      <div class="title">科目查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">科目名称</strong>
            <el-input
              v-model="formInline.subjectName"
              placeholder="请输入科目名称"
              clearable 
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <strong slot="label">科目编号</strong>
            <el-input
              v-model="formInline.subjectNumber"
              placeholder="请输入科目编号"
              clearable 
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <strong slot="label">科目类型</strong>
            <el-select v-model="formInline.subjectType" placeholder="请选择科目类型" clearable filterable @change="inquire">
              <el-option v-for="(item, index) in dropDown.subjectType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">账套</strong>
            <el-select v-model="formInline.accountType" placeholder="请选择账套" clearable filterable @change="inquire">
              <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="main">
      <div class="title">科目列表</div>
      <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新增</el-button>
      <el-table
        :data="cbSubjectList"
        tooltip-effect="dark"
        row-key="cbSubjectId"
        style="width: 100%"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop="subjectName" label="科目名称" width="300"></el-table-column>
        <el-table-column prop="subjectNumber" label="科目编号" align="center" width="200"></el-table-column>
        <el-table-column prop="subjectType" label="科目类型" align="center">
          <template slot-scope="scope">
            <DictTag :options="dropDown.subjectType" :value="scope.row.subjectType" />
          </template>
        </el-table-column>
        <el-table-column prop="pid" label="层级" align="center">
          <template slot-scope="scope">
            {{scope.row.pid + 1}}
          </template>
        </el-table-column>
        <el-table-column prop="accountType" label="账套" align="center">
          <template slot-scope="scope">
            <DictTag :options="dropDown.subjectAccountType2" :value="scope.row.accountType" />
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click.native.stop="handleEdit(scope.row)"
              type="text"
            >编辑
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              @click.native.stop="handleDel(scope.row)"
              type="text"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="50%"
      top="59px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="账套" prop="accountType">
              <el-select v-model="ruleForm.accountType" placeholder="请选择账套" style="width:100%">
                <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="父级" prop="pid">
              <treeselect v-model="ruleForm.pid" :options="options" :normalizer="normalizer" placeholder="请选择父级" @select="handleSelect" />
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col :span="12">
            <el-form-item label="科目名称" prop="subjectName">
              <el-input
                v-model="ruleForm.subjectName"
                placeholder="请输入科目名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="科目编号" prop="subjectNumber">
              <el-input
                v-model="ruleForm.subjectNumber"
                placeholder="请输入科目编号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="科目类型" prop="subjectType">
              <el-select v-model="ruleForm.subjectType" placeholder="请选择科目类型" style="width:100%">
                <el-option v-for="(item, index) in dropDown.subjectType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="remark">
              <el-input type="textarea" v-model="ruleForm.remark" placeholder="请输入描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      DictTag,
      Treeselect,
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      var validateCode = async (rule, value, callback) => {
        if (!value) {
          callback(new Error("请输入科目编号"));
        } else{
          await this.$http.get(url.validateCbSubjectNumber, {params: {
            subjectNumber: this.ruleForm.subjectNumber,
            cbSubjectId: this.ruleForm.cbSubjectId
          }}).then(res => {
            if(res.data.code == 200) {
              callback()
            }else if(res.data.code == 500) {
              callback(new Error("科目编号已存在，请重新输入！"));
            }
          })
        }
      };
      return {
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        formInline: {
          subjectName: '',
          subjectNumber: '',
          subjectType: '',
          accountType: ''
        },
        ruleForm: {
          subjectName: '',
          subjectNumber: '',
          subjectType: '',
          accountType: '',
          pid: null,
          remark: '',
        },
        rules: {
          subjectName: [{ required: true, message: "请输入科目名称", trigger: "submit" }],
          subjectNumber: [{ required: true, validator: validateCode, trigger: "blur" }],
          subjectType: [{ required: true, message: "请选择科目类型", trigger: "submit" }],
          pid: [{ required: true, message: "请选择父级", trigger: "submit" }],
          accountType: [{ required: true, message: "请选择账套", trigger: "submit" }],
        },
        // options: [],
        // 弹出层标题
        title: '',
        isLoading: false,
        list: [],
        cbSubjectList: [], // 科目列表
        options: [], // 树选项
      }
    },
    created () {
      this.getList()
    },
    methods: {
      handleSelect(node) {
        // console.log(node);
        // console.log(id);
        this.ruleForm.subjectNumber = node.label.split(': ')[1]
        this.$refs.ruleForm.validateField('subjectNumber');
      },
      // 查询按钮
      inquire() {
        this.getList()
      },
      // 重置按钮
      reset() {
        for(let key in this.formInline) {
          this.formInline[key] = ""
        }
        this.getList()
      },
      getList() {
        // 获取科目类型下拉
        if(!this.dropDown.subjectType){
          this.$http.get(url.getDataList, {params: {type: 'buss_subject_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSubjectType', res.data.data)
            }
          })
        }
        // 获取账套类型下拉
        if(!this.dropDown.subjectAccountType2){
          this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSubjectAccountType2', res.data.data)
            }
          })
        }
        this.loading = true
        this.options = []
        this.$http.get(url.getCbSubjectList, {params: this.formInline}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.cbSubjectList = handleTree(res.data.data, 'cbSubjectId', 'pid')
            // const data = {id: 0, label: '顶级节点', children: [] };
            // data.children = this.getTree(this.cbSubjectList);
            this.options = this.getTree(this.cbSubjectList);
            // this.options.push(data)
            // console.log(this.options);
          }
        })
      },
      handleAdd() {
        this.dialogFormVisible = true
        this.title = '新增科目'
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        for(let key in this.ruleForm) {
          if(key == 'pid') {
            this.ruleForm[key] = null
          }else {
            this.ruleForm[key] = ''
          }
        }
      },
      // 提交表单
      submitForm(formName) {
        // console.log(formName);
        // console.log(this.$refs);
        // console.log(this.$refs[formName]);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // console.log(this.ruleForm);
            if(!this.ruleForm.cbSubjectId) { // 新增凭证
              this.$http.post(url.addCbSubject, qs.stringify(this.ruleForm)).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.getList()
                }
              })
            }else { // 修改凭证
              this.$http.post(url.editCbSubject, qs.stringify(this.ruleForm)).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.cbSubjectId || this.ids[0]
        this.dialogFormVisible = true
        this.title = '修改科目'
        this.$http.get(url.getCbSubject, {params: {cbSubjectId: id}}).then(res => {
          if(res.data.code === 200) {
            this.ruleForm = res.data.data
          }
        })
      },
      handleDel(row) {
        const id = row.cbSubjectId
        this.$confirm('此操作将永久删除该科目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeCbSubject, qs.stringify({cbSubjectId: id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })
          
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.cbSubjectId)
        this.edit = rows.length === 1
        this.del = rows.length >= 1
      },
      /*转化tree标准格式*/
      getTree(tree) {
        let arr = [];
        // console.log(tree);
        // if (tree) {
          if(tree.length !== 0) {
            tree.forEach(item => {
              let obj = {};
              obj.label = item.subjectName + ': ' + item.subjectNumber;
              obj.id = item.cbSubjectId;
              // if(item.children)
              if (item.children && item.children.length > 0) {
                obj.children = this.getTree(item.children);
              }
              arr.push(obj);
            });
          }
        // }
        return arr
      },
      /** 转换数据结构 */
      normalizer(node) {
        this.$nextTick(() => {
          if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.cbSubjectId,
          label: node.subjectName + ': ' + node.subjectNumber,
          children: node.children
        };
        })
      },
      /** 转换数据结构 */
      // normalizer(node) {
      //   // this.$nextTick(() => {
      //   //   if (node.children && !node.children.length) {
      //   //   delete node.children;
      //   // }
      //   return {
      //     id: node.cbSubjectId,
      //     label: node.subjectName + ': ' + node.subjectNumber,
      //     children: node.children
      //   };
      //   // })
      // },
    },
  }
</script>

<style lang="scss" scoped>
.entry {
  /* padding: 16px; */
  .sift, .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add{
      margin-bottom: 16px;
    }
    .el-table {
      margin-top: 16px;
      margin-bottom: 39px;
    }
  }
  .sift {
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>