// 获取当前日期
export default function getCurrentDate() {
  var date = new Date();
  // 获取当前月份
  var nowMonth = date.getMonth() + 1;
  // 获取当前是几号
  var strDate = date.getDate();
  function add0(n) {
    return n < 10 ? '0' + n : n
  }
  // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
  return date.getFullYear() + '-' + add0(nowMonth) + '-' + add0(strDate);
}