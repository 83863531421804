<template>
  <div class="contain">
    <div class="title">科目余额表</div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini">
      <!-- <el-col :span="6"> -->
        <el-form-item>
          <strong slot="label">月份</strong>
          <el-date-picker
            v-model="formInline.date"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            format="yyyy-MM"
            value-format="yyyy-MM"
            @change="inquire"
            style="width:240px">
          </el-date-picker>
        </el-form-item>
      <!-- </el-col> -->
      <!-- <el-col :span="6">
        <el-form-item>
          <strong slot="label">月份</strong>
          <el-date-picker
            v-model="formInline.date"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            format="yyyy-MM"
            value-format="yyyy-MM"
            @change="inquire">
          </el-date-picker>
        </el-form-item>
      </el-col> -->
      
      <el-form-item>
        <strong slot="label">账套</strong>
        <el-select v-model="formInline.accountType" placeholder="请选择账套" filterable @change="inquire">
          <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <strong slot="label">科目类型</strong>
        <el-select v-model="formInline.subjectType" placeholder="请选择科目类型" filterable @change="inquire" clearable>
          <el-option v-for="(item, index) in dropDown.subjectType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <strong slot="label">是否全部科目</strong>
        <el-select v-model="formInline.isAllSub" filterable @change="inquire">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
      v-loading="loading"
      border
      :height="tableHeight"
      row-key="subjectId"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="subjectNumber" label="科目编码" header-align="center"></el-table-column>
      <el-table-column prop="subjectName" label="科目名称" header-align="center">
        <!-- <temp slot-scope="scope">
          <a @click="handleDetail(scope.row)" class="link">{{scope.row.accountCode}}</a>
        </temp late> -->
      </el-table-column>
      <el-table-column label="期初余额" header-align="center">
        <el-table-column prop="startDebit" label="借方" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.startDebit | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="startCredit" label="贷方" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.startCredit | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="本期发生" header-align="center">
        <el-table-column prop="betweenDebit" label="借方" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.betweenDebit | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="betweenCredit" label="贷方" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.betweenCredit | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="期末余额" header-align="center">
        <el-table-column prop="endDebit" label="借方" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.endDebit | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="endCredit" label="贷方" align="right" header-align="center">
          <template slot-scope="scope">
            {{scope.row.endCredit | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
  export default {
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        tableData: [],
        loading: true,
        formInline: {
          date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
          accountType: '1',
          subjectType: '',
          isAllSub: false,
        },
        tableHeight: window.innerHeight - 235
      }
    },
    created () {
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      // 获取科目类型下拉
      if(!this.dropDown.subjectType){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_type'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectType', res.data.data)
          }
        })
      }
      this.getList();
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.formInline.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        this.getList()
      })
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 235;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 235;
        })()
      }
    },
    methods: {
      getList() {
        this.tableData = []
        this.loading = true
        this.$http.get(url.accountBalanceTable, {params: {
          accountType: this.formInline.accountType,
          startDate: this.formInline.date ? this.formInline.date[0] : '',
          endDate: this.formInline.date ? this.formInline.date[0] : '',
          isAllSub: this.formInline.isAllSub,
          subjectType: this.formInline.subjectType
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            let length = res.data.data.detail.length
            // console.log(handleTree(res.data.data.detail[0], 'subjectId', 'pid'));
            // for(let i = 0;i < length; i ++) {
            //   res.data.data.detail[i] = handleTree(res.data.data.detail[i], 'subjectId', 'pid')
            //   res.data.data.detail[i].forEach(item => {
            //     this.tableData.push(item)
            //   });
            //   this.tableData.push(res.data.data.total[i])
            // }
            for(let i = 0;i < length; i ++) {
              this.tableData.push(res.data.data.total[i])
              res.data.data.detail[i] = handleTree(res.data.data.detail[i], 'subjectId', 'pid')
              res.data.data.detail[i].forEach(item => {
                this.tableData.push(item)
              });
            }
            this.tableData.push(res.data.data.count)
          }
        })
      },
      inquire() {
        this.getList()
      }
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  height: 100%;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 16px;
  }
}
</style>