import Vue from 'vue'
import Vuex from 'vuex'
import tags from './tags'
import tab from './tab'
import dropDown from './dropDown'
import toWork from './toWork'
import secondAside from './secondAside'
import toMine from './toMine'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tags,
    tab,
    dropDown,
    toWork,
    secondAside,
    toMine
  }
})
