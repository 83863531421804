<template>
  <div class="entry">
    <div class="main">
      <div class="title">报表列表</div>
      
      <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
        <el-form-item>
          <strong slot="label">项目</strong>
          <el-select v-model="formInline.ofoItemId" placeholder="请选择项目" clearable filterable @change="inquire">
            <el-option-group
              v-for="group in itemList"
              :key="group.cfFlowItemId"
              :label="group.showName">
              <el-option
                v-for="item in group.ofoFlowItemList"
                :key="item.ofoItemId"
                :label="item.name"
                :value="item.ofoItemId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item>
          <strong slot="label">日期</strong>
          <el-date-picker
            v-model="formInline.date"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            format="yyyy-MM"
            value-format="yyyy-MM"
            @change="inquire"
          >
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="inquire" size="small" plain>查询</el-button>
        <el-button type="primary" @click="handleSave" size="small">保存</el-button>
      </el-form>
      <template v-if="repttype == '1'">
        <el-row>
          <el-col :span="12">
            <el-table
              :data="leftTable"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
              v-loading="loading"
              border
            >
              <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.spaceNum" :key="index" v-if="scope.row.spaceNum">&nbsp;</span>
                  {{scope.row.name}}
                </template>
              </el-table-column>
              <el-table-column prop="lineid" label="行号"  width="50" align="center"></el-table-column>
              <el-table-column prop="scope" label="计划" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.jihua | comdify('no')}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="heyue" label="合约" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.heyue | comdify('no')}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="hesuan" label="核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.hesuan | comdify('no')}}</span>
                </template>
              </el-table-column>
              
              <el-table-column prop="scope" label="本年核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.hesuanYear | comdify('no')}}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="12">
            <el-table
              :data="rightTable"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
              v-loading="loading"
              border
            >
              <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.spaceNum" :key="index" v-if="scope.row.spaceNum">&nbsp;</span>
                  {{scope.row.name}}
                </template>
              </el-table-column>
              <el-table-column prop="lineid" label="行号"  width="50" align="center"></el-table-column>
              <el-table-column prop="scope" label="计划" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.jihua | comdify('no')}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="heyue" label="合约" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.heyue | comdify('no')}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="hesuan" label="核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.hesuan | comdify('no')}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="scope" label="本年核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.name">{{scope.row.hesuanYear | comdify('no')}}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          :height="'61vh'"
        >
          <el-table-column prop="name" label="名称">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.spaceNum" :key="index" v-if="scope.row.spaceNum">&nbsp;</span>
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column prop="lineid" label="行号"  width="50" align="center"></el-table-column>
          <el-table-column prop="scope" label="计划" width="200" align="right" v-if="repttype != '5'">
            <template slot-scope="scope">
              {{scope.row.jihua | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="heyue" label="合约" width="200" align="right" v-if="repttype != '5'">
            <template slot-scope="scope">
              {{scope.row.heyue | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="hesuan" label="核算" width="200" align="right">
            <template slot-scope="scope">
              {{scope.row.hesuan | comdify('no')}}
            </template>
          </el-table-column>
          
          <el-table-column prop="hesuanYoY" label="同比" width="200" align="right" v-if="repttype == '5'">
            <template slot-scope="scope">
              {{scope.row.hesuanYoY | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="hesuanMoM" label="环比" width="200" align="right" v-if="repttype == '5'">
            <template slot-scope="scope">
              {{scope.row.hesuanMoM | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="scope" label="本年核算" width="180" align="right" v-if="repttype != '5'">
            <template slot-scope="scope">
              {{scope.row.hesuanYear | comdify('no')}}
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination> -->
    </div>
    <el-dialog
      title="保存统计"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-form-item label="名称" prop="countName">
          <el-input
            v-model="ruleForm.countName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
import getCurrentDate from '@/utils/getCurrentDate'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      DictTag,
      Treeselect,
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown,
        tagsList: (state) => state.tags.tagsList,
      })
    },
    data() {
      return {
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        formInline: {
          date: [this.$route.query.startDate ? this.$route.query.startDate : '', this.$route.query.endDate ? this.$route.query.endDate : ''],
          ofoItemId: ''
        },
        ruleForm: {
          countName: ''
        },
        rules: {
          countName: [{ required: true, message: "请输入名称", trigger: "submit" }]
        },
        isLoading: false,
        itemList: [], // 项目列表
        cfReptId: this.$route.query.cfReptId,
        repttype: this.$route.query.repttype,
        leftTable: [],
        rightTable: []
      }
    },
    created () {
      this.$http.get(url.getAllItemList).then(res => {
        // console.log(res);
        this.itemList = res.data.data
      })
      // this.tableData = handleTree(this.tableData, 'cfReptContId', 'pid')
      this.getList()
      // this.getData()
    },
    methods: {
      // 查询按钮
      inquire() {
        this.loading = true
        this.getList()
      },
      getList() {
        this.$http.get(url.cffreptCount, {params: {
          cfreptId: this.cfReptId,
          startDate: this.formInline.date ? this.formInline.date[0] : '',
          endDate:  this.formInline.date ? this.formInline.date[1] : '',
          ofoitemId: this.formInline.ofoItemId
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            if(this.repttype == '1') {
              this.leftTable = res.data.data.lList
              this.rightTable = res.data.data.rList
            }else {
              this.tableData = res.data.data
            }
          }
        })
      },
      handleSave() {
        this.dialogFormVisible = true
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        // console.log(itemList.length == 0 || cbSubjectList.length == 0);
        this.ruleForm.countName = this.$route.query.name
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            this.ruleForm.list = JSON.stringify(this.list)
            this.$http.post(url.insertCffreptCount, qs.stringify({
              ...this.ruleForm,
              reportType: this.cfReptId,
              countResult: this.repttype == '1' ? JSON.stringify({lList: this.leftTable, rList: this.rightTable}) : (JSON.stringify(this.tableData))
            })).then(res => {
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.$router.push('/accounting/reports')
                let obj = {
                  children: [],
                  icon: "iconfont icon-baobiao",
                  label: "报表",
                  name: "accountReport",
                  path: "/accounting/reports"
                }
                this.$store.commit("changeMenu", obj);
                // this.loading = true
                // this.getList()
              }
            })
          } else {
            return false;
          }
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  /* ::v-deep .el-table{
 overflow: visible;
}
 
 ::v-deep .el-table__body-wrapper{
 overflow: visible;
} */
::v-deep .el-table__body tr:hover>td.el-table__cell {
  background-color: #fff;
}
::v-deep .el-table__cell{
 height: 48px;
}
.entry {
  /* padding: 16px; */
  .sift, .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add{
      margin-bottom: 16px;
    }
    .el-table {
      margin-top: 16px;
      margin-bottom: 39px;
    }
  }
  .sift {
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>