<template>
  <div class="contain">
    <el-date-picker
      v-model="date"
      type="monthrange"
      range-separator="至"
      start-placeholder="开始月份"
      end-placeholder="结束月份"
      format="yyyy-MM"
      value-format="yyyy-MM"
      size="mini"
      style="margin-bottom: 16px;margin-right:16px"
      :clearable="false"
      @change="inquire">
    </el-date-picker>
    <el-select v-model="accountType" placeholder="请选择账套" filterable @change="inquire" size="mini">
      <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
    </el-select>
    <el-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
      v-loading="loading"
      border
      :height="tableHeight"
    >
      <el-table-column prop="period" label="日期" width="100" align="center"></el-table-column>
      <el-table-column prop="accountCode" label="凭证字号" align="center" width="180">
        <!-- <template slot-scope="scope">
          <a @click="handleDetail(scope.row)" class="link">{{scope.row.accountCode}}</a>
        </template> -->
      </el-table-column>
      <el-table-column prop="abstractStr" label="摘要" header-align="center"></el-table-column>
      <el-table-column prop="debit" label="借方" align="right" width="150" header-align="center">
        <template slot-scope="scope">
          {{scope.row.debit | comdify('no')}}
        </template>
      </el-table-column>
      <el-table-column prop="credit" label="贷方" align="right" width="150" header-align="center">
        <template slot-scope="scope">
          {{scope.row.credit | comdify('no')}}
        </template>
      </el-table-column>
      <el-table-column prop="direction" label="方向" align="center" width="50">
      </el-table-column>
      
      <el-table-column prop="amount" label="余额" align="right" width="150" header-align="center">
        <template slot-scope="scope">
          {{scope.row.amount | comdify('no')}}
        </template>
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
        accountType: '',
        tableData: [],
        loading: false,
        tableHeight: window.innerHeight - 193
      }
    },
    created () {
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 193;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 193;
        })()
      }
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      this.getList()
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        this.getList()
      })
    },
    methods: {
      getList(){
        this.loading = true
        this.$http.get(url.costAccount, {params: {
          accountType: this.accountType,
          subjectType: '',
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
          }
        })
      },
      inquire() {
        this.getList()
      },
      handleDetail(row) {
        console.log(row);
      }
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
}
</style>