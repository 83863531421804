<template>
  <div class="resource">
    <div class="sift">
      <div class="title">库存查询</div>
      <div class="inquire">
        <el-form :model="formInline" class="demo-form-inline" label-width="60px">
          <el-row>
            <el-col :span="8">
              <el-form-item>
                <strong slot="label">位置</strong>
                <treeselect v-model="formInline.cbowhId" :options="cbOwhTree" :normalizer="normalizer" placeholder="请选择父级" />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
                <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button> -->
      </div>
    </div>
    <div class="detail">
      <div class="title">库存列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="ofOwhResourceId" label="序号"></el-table-column>
        <el-table-column prop="cbowhName" label="位置">
          <!-- <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.restypeName}}</a>
          </template> -->
        </el-table-column>
        <el-table-column prop="ofrresourceName" label="产品名称">
          <!-- <template slot-scope="scope">
            <DictTag :options="dropDown.kind" :value="scope.row.kind"/>
          </template> -->
        </el-table-column>
        <el-table-column prop="quantity" label="数量" align="right">
          <!-- <template slot-scope="scope"> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column prop="price" label="成本单价" align="right">
          <template slot-scope="scope">
            {{scope.row.price | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="linetotal" label="成本合计" align="right">
          <template slot-scope="scope">
            {{scope.row.linetotal | comdify}}
          </template>
        </el-table-column>
        <!-- <el-table-column 
          label="操作"
          fixed="right"
          width="180">
          <template slot-scope="scope">
            <el-button
              size="mini" icon="el-icon-view" type="text"
              @click="handleDetail(scope.row)">详情</el-button>
            <el-button
              size="mini" icon="el-icon-edit" type="text"
              @click="handleEdit(scope.row)">编辑</el-button>
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="资源名称" prop="restypeName">
          <el-input v-model="ruleForm.restypeName" placeholder="请输入资源名称"></el-input>
        </el-form-item>
        <el-form-item label="种类" prop="kind">
          <el-select v-model="ruleForm.kind" placeholder="请选择资源种类" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型" prop="kindDealType">
          <el-select v-model="ruleForm.kindDealType" placeholder="请选择资源交易类型" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kindDealType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    DictTag,
    Treeselect
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        name: "",
        cbowhId: "0",
      },
      tableData: [],
      dialogFormVisible: false,
      // 弹出层标题
      title: "",
      ruleForm: {
        restypeName: "",
        kind: "",
        kindDealType: "",
      },
      rules: {
        restypeName: [{ required: true, message: "请输入资源名称", trigger: "submit" }],
        kind: [{ required: true, message: "请选择资源种类", trigger: "submit" }],
        kindDealType: [{ required: true, message: "请选择资源交易类型", trigger: "submit" }]
      },
      // 编辑按钮不可点
      edit: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      ids: [],
      isLoading: false,
      cbOwhTree: [],
      defaultProps: {
        /** 唯一标识 **/
        value: 'Id',
        /** 标签显示 **/
        label: 'label',
        /** 子级 **/
        children: 'children' }
    };
  },
  created() {
    this.getList();
    this.init()
  },
  methods: {
    init() {
      this.$http.get(url.getCbOwhTree, {params: {
        // cbRestypeId: this.formInline.cbRestypeId,
        // name: this.formInline.name
      }}).then(res => {
        if(res.data.code == 200) {
          const data = {id: 0, label: '顶级节点', children: [] };
          data.children = this.getTree(res.data.data);
          this.cbOwhTree.push(data)
          // this.cbOwhTree = res.data.data.map(item => {
          //   return {
          //     Id: item.cbOwhId,
          //     label: item.name,
          //     children: item.children.map(val => {
          //       return {
          //         Id: val.cbOwhId,
          //         label: val.name,
          //       }
          //     })
          //   }
          // })
        }
      })
    },
    /*转化tree标准格式*/
    getTree(tree) {
      let arr = [];
      if (tree.length !== 0) {
        tree.forEach(item => {
          let obj = {};
          obj.label = item.name;
          obj.id = item.cbOwhId;
          if (item.children.length > 0) {
            obj.children = this.getTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr
    },
    
    /** 转换数据结构 */
    normalizer(node) {
      this.$nextTick(() => {
        if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.cbOwhId,
        label: node.name,
        children: node.children
      };
      })
    },
    handleNodeClick(data) {
      // console.log(data);
      // this.formInline.type = data.Id
	  //  this.form.Id = data.Id
	  //  this.form.label = data.label
	  //  // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
	  //  this.$refs.selectTree.blur()
    },
    // 查询按钮
    inquire() {
      this.loading = true
      this.pageNum = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.loading = true
      this.pageNum = 1
      this.getList()
    },
    // 表格的选中框事件
    handleSelectionChange(rows) {
      this.ids = rows.map(item => item.cbRestypeId)
      this.edit = rows.length === 1
    },
    // 获取字典数据
    getList() {
      // // 获取种类下拉
      // if(!this.dropDown.kind){
      //   this.$http.get(url.getDataList, {params: {type: 'buss_kind'}}).then(res => {
      //     if(res.data.code == 200) {
      //       this.$store.commit('setKind', res.data.data)
      //     }
      //   })
      // }
      // // 获取交易类型下拉
      // if(!this.dropDown.kindDealType){
      //   this.$http.get(url.getDataList, {params: {type: 'buss_kind_dealType'}}).then(res => {
      //     if(res.data.code == 200) {
      //       this.$store.commit('setKindDealType', res.data.data)
      //     }
      //   })
      // }
      // 获取列表数据
      this.$http.get(url.getOwhResourceList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          cbowhId: this.formInline.cbowhId == '0' ? '' : this.formInline.cbowhId
        }
      }).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          // 新增项目
          if(this.title === "新增资源") {
            this.$http.post(url.addCbResType, qs.stringify(this.ruleForm)).then(res => {
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.pageNum = 1
                this.loading = true
                this.getList()
              }
            })
          }else { // 编辑资源
            this.$http.post(url.editCbResType, qs.stringify(this.ruleForm)).then(res => {
              // console.log(res);
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.pageNum = 1
                this.loading = true
                this.getList()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 新增项目按钮
    handleAdd() {
      this.title = "新增资源"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        // 清除表单校验效果
        this.$refs.ruleForm.clearValidate();
      })
      // 初始化表单内容
      for(let key in this.ruleForm) {
        this.ruleForm[key] = ""
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
    // 编辑按钮
    handleEdit(row) {
      if(this.$refs.ruleForm) {
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
      }
      
      const id = row.cbRestypeId || this.ids[0]
      this.dialogFormVisible = true
      this.title = '编辑资源'
      this.$http.get(url.getResType, {params: {cbRestypeId: id}}).then(res => {
        if(res.data.code === 200) {
          this.ruleForm = res.data.data
        }
      })
    },
    // 删除按钮
    handleDel(row) {
      this.$confirm('此操作将永久删除该资源类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeCbResType, qs.stringify({cbRestypeId: row.cbRestypeId})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageNum = 1
            this.loading = true
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 查看详情按钮
    handleDetail(row) {
      // let cbRestypeId = row.cbRestypeId
      // this.$router.push("/resource/kind/" + cbRestypeId)
      // let obj = {
      //   path: "/resource/kind/" + cbRestypeId,
      //   name: "kindDetail",
      //   label: "资源详情",
      //   cbRestypeId: cbRestypeId,
      //   children: []
      // };
      // this.$store.commit("selectMenu", obj);
    },
  },
  watch: {
    formInline: {
      handler(newValue, oldValue) { 
        // console.log(newValue);
        if(newValue.cbowhId != '0') {
          // console.log(11111);
          this.pageNum = 1
          this.loading = true
          this.getList()
        }
      },
      deep: true
    }
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">
</style>