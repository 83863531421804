export default {
  state: {
    secondMenu: JSON.parse(sessionStorage.getItem('secondMenu'))? JSON.parse(sessionStorage.getItem('secondMenu')): [],
    path: sessionStorage.getItem('path')? sessionStorage.getItem('path'): '',
  },
  mutations: {
    setSecondMenu(state, val) {
      state.secondMenu = val
      window.sessionStorage.setItem('secondMenu', JSON.stringify(val))
    },
    setPath(state, val) {
      state.path = val
      window.sessionStorage.setItem('path', val)
    }
  }
}