<template>
  <div class="contain">
    <SecondAside class="second-aside" v-if="secondAside"/>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
import SecondAside from '@/components/SecondAside.vue'

  export default {
    computed: {
      ...mapState({
        dropDown: (state) => state.dropDown.dropDown
      }),
    },
    components: {
      SecondAside,
    },
    data() {
      return {
        secondAside: false
      }
    },
    created () {
      this.$eventBus.$on('secondAside', (data) => {
        console.log(data);
        this.secondAside = data
      })
      // console.log(this.$route.meta.keepAlive);;
    },
    methods: {
      
    },
  }
</script>

<style scoped lang="scss">
.contain {
  /* position: fixed; */
  /* top: 82px; */
  /* left: 82px; */
  overflow: auto;
  background-color: #F2F4F5;
  height: calc(100% - 82px);
  width: calc(100% - 82px);
  box-sizing: border-box;
  /* z-index: -1; */
  /* pointer-events: none; */
  .second-aside {
    position: fixed;
    top: 82px;
    left: 82px;
    z-index: 1;
  }
}
</style>