<template>
  <div class="resource">
    <div class="sift">
      <div class="title">资源查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small"@submit.native.prevent>
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入名称"
              clearable 
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <strong slot="label">种类</strong>
            <el-select v-model="formInline.type" placeholder="请选择种类" clearable filterable @change="inquire">
              <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small" v-if="judgeButton('add')">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small" v-if="judgeButton('edit')">编辑</el-button>
      </div>
    </div>
    <div class="detail">
      <div class="title">资源列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column prop="restypeNumber" label="代码" width="200"></el-table-column> -->
        <el-table-column prop="restypeName" label="名称">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.restypeName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="kind" label="资源种类" width="150" align="center">
          <template slot-scope="scope">
            <DictTag :options="dropDown.kind" :value="scope.row.kind"/>
          </template>
        </el-table-column>
        <el-table-column prop="kindDealType" label="资源交易类型" width="200" align="center">
          <template slot-scope="scope">
            <DictTag :options="dropDown.kindDealType" :value="scope.row.kindDealType"/>
          </template>
        </el-table-column>
        <el-table-column 
          label="操作"
          fixed="right"
          width="180"
          align="right">
          <template slot-scope="scope">
            <el-button
              size="mini" icon="el-icon-view" type="text"
              @click="handleDetail(scope.row)">详情</el-button>
            <el-button
              size="mini" icon="el-icon-edit" type="text"
              @click="handleEdit(scope.row)" v-if="judgeButton('edit')">编辑</el-button>
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)" v-if="judgeButton('delete')">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="资源名称" prop="restypeName">
          <el-input v-model="ruleForm.restypeName" placeholder="请输入资源名称"></el-input>
        </el-form-item>
        <el-form-item label="种类" prop="kind">
          <el-select v-model="ruleForm.kind" placeholder="请选择资源种类" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型" prop="kindDealType">
          <el-select v-model="ruleForm.kindDealType" placeholder="请选择资源交易类型" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kindDealType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否新增" prop="isAdd">
          <el-radio-group v-model="ruleForm.isAdd">
            <el-radio :label="'0'">是</el-radio>
            <el-radio :label="'1'">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否私有" prop="isPrivate">
          <el-radio-group v-model="ruleForm.isPrivate">
            <el-radio :label="'0'">是</el-radio>
            <el-radio :label="'1'">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否BOM" prop="isBom" v-if="ruleForm.kind == '2' || ruleForm.kind == '3'">
          <el-radio-group v-model="ruleForm.isBom">
            <el-radio :label="'0'">是</el-radio>
            <el-radio :label="'1'">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
export default {
  components: {
    DictTag
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown,
      currentMenu: (state) => state.tags.currentMenu,
    })
  },
  data() {
    return {
      formInline: {
        name: "",
        type: "",
      },
      tableData: [],
      dialogFormVisible: false,
      // 弹出层标题
      title: "",
      ruleForm: {
        restypeName: "",
        kind: "",
        kindDealType: "",
        isAdd: "",
        isBom: "",
        isPrivate: ""
      },
      rules: {
        restypeName: [{ required: true, message: "请输入资源名称", trigger: "submit" }],
        kind: [{ required: true, message: "请选择资源种类", trigger: "submit" }],
        kindDealType: [{ required: true, message: "请选择资源交易类型", trigger: "submit" }]
      },
      // 编辑按钮不可点
      edit: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      ids: [],
      isLoading: false
    };
  },
  created() {
    // console.log(this.$route);

    this.getList();
  },
  watch: {
    // kind
    // 'kind': {
    //   handler (newValue, oldValue) {
    //     console.log(newValue);
    //   },
    //   deep: true
    // }
    $route:{
      handler(val, oldval){
        // console.log(val);//新路由信息
        this.pageNum = 1
        this.getList()
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    // 判断按钮显示
    judgeButton(type) {
      return this.currentMenu.children.some(item => item.menuType == 'F' && item.perms == `${this.$route.query.kind}:${type}`)
    },
    // 查询按钮
    inquire() {
      this.pageNum = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.pageNum = 1
      this.getList()
    },
    // 表格的选中框事件
    handleSelectionChange(rows) {
      this.ids = rows.map(item => item.cbRestypeId)
      this.edit = rows.length === 1
    },
    // 获取字典数据
    getList() {
      // 获取种类下拉
      if(!this.dropDown.kind){
        this.$http.get(url.getDataList, {params: {type: 'buss_kind'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setKind', res.data.data)
          }
        })
      }
      // 获取交易类型下拉
      if(!this.dropDown.kindDealType){
        this.$http.get(url.getDataList, {params: {type: 'buss_kind_dealType'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setKindDealType', res.data.data)
          }
        })
      }
      switch(this.$route.query.kind) {
        case 'rl': 
          this.formInline.type = '1';
          break;
        case 'wl':
          this.formInline.type = '2';
          break;
        case 'cp': 
          this.formInline.type = '3';
          break;
        case 'wd':
          this.formInline.type = '4';
          break;
        case 'fw': 
          this.formInline.type = '5';
          break;
        case 'gl':
          this.formInline.type = '6';
          break;
        case 'hb': 
          this.formInline.type = '7';
          break;
      }
      this.loading = true
      // 获取列表数据
      this.$http.get(url.getResTypeList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          restypeName: this.formInline.name,
          kind: this.formInline.type
        }
      }).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          // 新增项目
          if(this.title === "新增资源") {
            this.$http.post(url.addCbResType, qs.stringify(this.ruleForm)).then(res => {
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.pageNum = 1
                this.loading = true
                this.getList()
              }
            })
          }else { // 编辑资源
            this.$http.post(url.editCbResType, qs.stringify(this.ruleForm)).then(res => {
              // console.log(res);
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.pageNum = 1
                this.loading = true
                this.getList()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 新增项目按钮
    handleAdd() {
      this.title = "新增资源"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        // 清除表单校验效果
        this.$refs.ruleForm.clearValidate();
      })
      // 初始化表单内容
      for(let key in this.ruleForm) {
        if(key == 'isAdd' || key == 'isBom' || key == 'isPrivate') {
          // this.ruleForm[key] = (this.formInline.type == '2' || this.formInline.type == '3') ? "1" : ''
        }else if(key == 'kind') {
          this.ruleForm[key] = this.formInline.type
        }else {
          this.ruleForm[key] = ""
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
    // 编辑按钮
    handleEdit(row) {
      if(this.$refs.ruleForm) {
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
      }
      
      const id = row.cbRestypeId || this.ids[0]
      this.dialogFormVisible = true
      this.title = '编辑资源'
      this.$http.get(url.getResType, {params: {cbRestypeId: id}}).then(res => {
        if(res.data.code === 200) {
          this.ruleForm = res.data.data
        }
      })
    },
    // 删除按钮
    handleDel(row) {
      this.$confirm('此操作将永久删除该资源类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeCbResType, qs.stringify({cbRestypeId: row.cbRestypeId})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageNum = 1
            this.loading = true
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 查看详情按钮
    handleDetail(row) {
      let cbRestypeId = row.cbRestypeId
      // console.log(this.currentMenu);
      // console.log(this.$route);
      let perms = this.currentMenu.children.every(item => item.menuType == 'F') ? this.currentMenu.children : []
      let kind = this.$route.query.kind
      this.$router.push("/resource/kind/" + cbRestypeId)
      let obj = {
        path: "/resource/kind/" + cbRestypeId,
        name: "kindDetail",
        label: "资源详情",
        cbRestypeId: cbRestypeId,
        children: [],
        perms: perms,
        kind: kind
      };
      this.$store.commit("selectMenu", obj);
    },
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">
</style>