<template>
  <div class="contain">
    <div class="left">
      <el-tabs v-model="accountType" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabPane" :key="index" :label="item.label" :name="item.name">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            size="mini"
            style="margin-bottom:16px"
            >
          </el-input>

          <el-tree
            class="filter-tree"
            :data="cbSubjectTreeList"
            :props="{label: 'subjectName', id: 'cbSubjectId'}"
            default-expand-all
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="nodeClick"
            highlight-current
            :current-node-key="nodeKey"
            node-key="subjectNumber"
            :expand-on-click-node="false"
            >
          </el-tree>
        </el-tab-pane>
      </el-tabs>
      

    </div>
    <div class="right">
      <div class="header-title">{{subjectName}}明细账</div>
      <div class="inquire" style="display:flex;justify-content:space-between">
        <el-date-picker
          v-model="date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          format="yyyy-MM"
          value-format="yyyy-MM"
          size="mini"
          style="margin-bottom: 16px"
          :clearable="false"
          @change="handleChange">
        </el-date-picker>
        <download-excel
          class="export-excel-wrapper"
          :data="detailAccount"
          :fields="json_fields"
          :header="`${subjectName}明细账`"
          :name="`${subjectName}明细账.xls`"
        >
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <el-button type="text" icon="el-icon-download" style="padding:0" >导出</el-button>
        </download-excel>
      </div>
      <el-table
        :data="detailAccount"
        tooltip-effect="dark"
        style="width: 99%"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
        border
        :height="tableHeight"
      >
        <el-table-column prop="period" label="日期" width="100" header-align="center"></el-table-column>
        <el-table-column prop="accountCode" label="凭证字号" align="center" width="180">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.accountCode}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="abstractStr" label="摘要" header-align="center"></el-table-column>
        <el-table-column prop="debit" label="借方" align="right" width="150" header-align="center">
          <template slot-scope="scope">
            {{scope.row.debit | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="credit" label="贷方" align="right" width="150" header-align="center">
          <template slot-scope="scope">
            {{scope.row.credit | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="direction" label="方向" align="center" width="50">
        </el-table-column>
        
        <el-table-column prop="amount" label="余额" align="right" width="150" header-align="center">
          <template slot-scope="scope">
            {{scope.row.amount | comdify('no')}}
          </template>
        </el-table-column>
        
      </el-table>
    </div>
    
  
    
  </div>
</template>

<script>
import url from '@/config/url'
import handleTree from '@/utils/handleTree'
  export default {
    data() {
      return {
        date: this.$route.query.date ? [this.$route.query.date, this.$route.query.date] : [
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-01'),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))
        ],
        loading: true,
        pageNum: 1,
        cbSubjectTreeList: [],
        subjectNumber: null,
        filterText: '',
        tabPane: [
          {
            label: '核算',
            name: '1'
          },
          {
            label: '合约',
            name: '2'
          },
          {
            label: '计划',
            name: '3'
          }
        ],
        accountType: this.$route.query.accountType ? this.$route.query.accountType : '1',
        nodeKey: '',
        index: 0,
        detailAccount: [],
        dialogFormVisible: false,
        tableHeight: window.innerHeight - 230,
        subjectName: '',
        json_fields: {
          "日期":'period',
          "凭证字号":'accountCode',
          "摘要":'abstractStr',
          "借方":'debit',
          "贷方":'credit',
          "方向":'direction',
          "余额":'amount',
        },
      }
    },
    created () {
      // console.log(111);
      this.getList();
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        // this.loading = true
        // this.pageNum = 1
        this.getList()
      })
      
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 230;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 230;
        })()
      }
    },
    methods: {
      handleChange() {
        this.getDetailAccount()
      },
      getDetailAccount() {
        this.loading = true
        this.$http.get(url.detailAccount, {
          params: {
            startDate: this.date[0],
            endDate: this.date[1],
            accountType: this.accountType,
            subjectNumber: this.subjectNumber
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.detailAccount = res.data.data
          }
        })
      },
      handleClick(tab, event) {
        this.index = tab.index
        this.$route.query.date = ''
        this.getList()
      },
      getList() {
        // 左侧科目树
        this.$http.get(url.cbSubjectTreeList, {params: {
          accountType: this.accountType
        }}).then(res => {
          if(res.data.code == 200) {
            this.cbSubjectTreeList = handleTree(res.data.data, 'cbSubjectId', 'pid')
            // this.cbSubjectTreeList = res.data.data;
            if(res.data.data.length) {
              this.nodeKey = this.cbSubjectTreeList[0].subjectId
              if(this.$route.query.date) {
                this.$nextTick(() => {
                  this.$refs.tree[this.$route.query.accountType - 1].setCurrentKey(this.$route.query.subjectNumber)
                })
                this.subjectNumber = this.$route.query.subjectNumber
                this.subjectName = this.$route.query.subjectName
                this.getDetailAccount()
              }else {
                this.$nextTick(() => {
                  this.$refs.tree[this.index].setCurrentKey(this.cbSubjectTreeList[0].subjectNumber)
                })
                this.subjectNumber = this.cbSubjectTreeList[0].subjectNumber
                this.subjectName = this.cbSubjectTreeList[0].subjectName
                this.getDetailAccount()
              }
            }else {
              this.loading = false
              this.detailAccount = []
            }
          }
        })
      },
      filterNode(value, data) {
        // console.log(value);
        // console.log(data);
        if (!value) return true;
        return data.subjectName.indexOf(value) !== -1;
      },
      nodeClick(data, node) {
        this.subjectNumber = data.subjectNumber
        this.subjectName = data.subjectName
        this.getDetailAccount()
      },
      handleDetail(row) {
        this.$router.push({
          path: '/accounting/vDetail/' + row.offOjdtId, query: {
            account: true
        }})
        let obj = {
          path: '/accounting/vDetail/' + row.offOjdtId,
          name: 'voucherDetail',
          label: '凭证详情',
          offOjdtId: row.offOjdtId,
          children: []
        }
        this.$store.commit("selectMenu", obj);
        // console.log(item);
      },
    },
    watch: {
      filterText(val) {
        this.$refs.tree[this.index].filter(val);
      }
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  display: flex;
  min-height: calc(100vh - 146px);
  /* width: 100%; */
  .header-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .left {
    .el-tree {
      max-height: calc(100vh - 244px);
      overflow: auto;
    }
  }
  .right{
    margin-left: 16px;
    flex: 1;
  }
}
</style>