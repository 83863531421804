<template>
  <div class="upload-file">
    <el-upload
      multiple
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="false"
      :headers="headers"
      class="upload-file-uploader"
      ref="upload"
    >
      <!-- 上传按钮 -->
      <el-button size="mini" type="primary">选取文件</el-button>
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip">
        请上传
        <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
        <!-- <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template> -->
        的文件
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <fileList :fileList="fileList" :isDelete="true" @input="handleInput" />
  </div>
</template>

<script>
import { Loading } from 'element-ui';
export default {
  name: "FileUpload",
  props: {
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 10,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 20,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => [
        // 图片
        "bmp", "gif", "jpg", "jpeg", "png",
        // word excel powerpoint
        "doc", "docx", "xls", "xlsx", "ppt", "pptx", "html", "htm", "txt",
        // 压缩文件
        "rar", "zip", "gz", "bz2",
        // 视频格式
        "mp4", "avi", "rmvb",
        // pdf
        "pdf"
      ],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      number: 0,
      uploadList: [],
      baseUrl: this.$base_url,
      uploadFileUrl: this.$base_url + "/api/utils/uploadFile", // 上传的图片服务器地址
      headers: {
        'token': sessionStorage.getItem('token')
      },
      fileList: [],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          let temp = 1;
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            if (typeof item === "string") {
              item = { fileName: item, url: item };
            }
            item.uid = item.uid || new Date().getTime() + temp++;
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      // return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      // if (this.fileType) {
      //   let fileExtension = "";
      //   if (file.name.lastIndexOf(".") > -1) {
      //     fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
      //   }
      //   const isTypeOk = this.fileType.some((type) => {
      //     if (file.type.indexOf(type) > -1) return true;
      //     if (fileExtension && fileExtension.indexOf(type) > -1) return true;
      //     return false;
      //   });
      //   if (!isTypeOk) {
      //     this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
      //     return false;
      //   }
      // }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      // this.$message.loading("正在上传文件，请稍候...");
      Loading.service({
        text: "正在上传文件，请稍候..."
      });
      this.number++;
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error("上传文件失败，请重试");
      // this.$message.closeLoading()
      Loading.service().close()
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      this.uploadList.push({ fileName: res.fileName, fileUrl: res.data });
      if (this.uploadList.length === this.number) {
        this.fileList = this.fileList.concat(this.uploadList);
        this.uploadList = [];
        this.number = 0;
        this.$emit("input", this.fileList);
        // this.$message.closeLoading();
        Loading.service().close()
      }
    },
    handleInput(fileList) {
      this.$emit("input", fileList);
    },
    // 获取文件名称
    getFileName(name) {
      // console.log(name);
      // // if (name.lastIndexOf("/") > -1) {
      // //   return name.slice(name.lastIndexOf("/") + 1);
      // // } else {
      // //   return "";
      // // }
      // return name
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};
</script>

<style scoped lang="scss">
</style>
