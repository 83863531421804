<template>
  <div class="contain">
    <div class="left">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabPane" :key="index" :label="item.label" :name="item.name">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            size="mini"
            style="margin-bottom:16px"
            >
          </el-input>

          <el-tree
            class="filter-tree"
            :data="cbSubjectTreeList"
            :props="{label: 'subjectName', id: 'cbSubjectId'}"
            default-expand-all
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="nodeClick"
            highlight-current
            :current-node-key="nodeKey"
            node-key="cbSubjectId"
            :expand-on-click-node="false"
            >
          </el-tree>
        </el-tab-pane>
      </el-tabs>
      

    </div>
    <div class="right">
      <div class="header-title">{{totalAccount.subjectName}}总账</div>
      <div class="inquire">
        <el-date-picker
          v-model="date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          format="yyyy-MM"
          value-format="yyyy-MM"
          size="mini"
          style="margin-bottom: 16px"
          :clearable="false"
          @change="handleChange">
        </el-date-picker>
      </div>
      <table style="width: 100%;border: 1px solid #f3f3f3;font-size:14px" cellSpacing="0" border="1" >
        <tr height="40px" style="background: rgb(249, 249, 251)">
          <th width="200">科目编码</th>
          <th width="250">科目名称</th>
          <th width="80">期间</th>
          <th>摘要</th>
          <th>借方</th>
          <th>贷方</th>
          <th width="35">方向</th>
          <th>余额</th>
        </tr>
        <tbody v-if="totalAccount.list.length">
          <tr>
            <td :rowspan="totalAccount.list.length + 1" style="padding: 0 10px">{{totalAccount.subjectNumber}}</td>
            <td :rowspan="totalAccount.list.length + 1" style="padding: 0 10px">{{totalAccount.subjectName}}</td>
          </tr>
          <tr v-for="(item, index) in totalAccount.list" :key="index" height="40px">
            <td align="center" v-if="index == 0">{{item.period}}</td>
            <td align="center" class="link" @click="handleDetail(item)" v-if="index != 0">{{item.period}}</td>
            <td style="padding: 0 10px">{{item.abstractStr}}</td>
            <td align="right" style="padding: 0 10px">{{item.debit | comdify('no')}}</td>
            <td align="right" style="padding: 0 10px">{{item.credit | comdify('no')}}</td>
            <td align="center">{{item.direction}}</td>
            <td align="right" style="padding: 0 10px">{{item.amount | comdify('no')}}</td>
          </tr>
        </tbody>
        <tbody v-if="!totalAccount.list.length">
          <tr height="40px">
            <td colspan="8" align="center">暂无数据</td>
          </tr>
        </tbody>

      </table>
    </div>
  
    
  </div>
</template>

<script>
import url from '@/config/url'
import handleTree from '@/utils/handleTree'
import { mapState } from "vuex"
  export default {
    computed: {
      ...mapState({
        tagsList: (state) => state.tags.tagsList,
      }),
    },
    data() {
      return {
        date: [
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-01'),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))
        ],
        cbSubjectTreeList: [],
        filterText: '',
        tabPane: [
          {
            label: '核算',
            name: '1'
          },
          {
            label: '合约',
            name: '2'
          },
          {
            label: '计划',
            name: '3'
          }
        ],
        activeName: '1',
        nodeKey: '',
        index: 0,
        totalAccount: {
          list: []
        },
        dialogFormVisible: false,
        subjectType: '',
        subjectNumber: '',
      }
    },
    created () {
      // console.log(111);
      this.getList();
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        // this.loading = true
        // this.pageNum = 1
        this.getList()
      })
    },
    methods: {
      handleChange() {
        this.getList()
      },
      getTotalAccount() {
        this.$http.get(url.totalAccount, {
          params: {
            startDate: this.date[0],
            endDate: this.date[1],
            accountType: this.activeName,
            subjectType: this.subjectType,
            subjectNumber: this.subjectNumber
          }
        }).then(res => {
          if(res.data.code == 200) {
            this.totalAccount = res.data.data
          }
        })
      },
      handleClick(tab, event) {
        this.index = tab.index
        this.getList()
      },
      getList() {
        // 左侧科目树
        this.$http.get(url.cbSubjectTreeList, {params: {
          accountType: this.activeName,
          startDate: this.date[0],
          endDate: this.date[1]
        }}).then(res => {
          if(res.data.code == 200) {
            // this.cbSubjectTreeList = this.getTree(res.data.data);
            this.cbSubjectTreeList = handleTree(res.data.data, 'cbSubjectId', 'pid')
            // this.cbSubjectTreeList = res.data.data;
            if(res.data.data.length) {
              this.nodeKey = this.cbSubjectTreeList[0].cbSubjectId
              this.$nextTick(() => {
                this.$refs.tree[this.index].setCurrentKey(this.cbSubjectTreeList[0].cbSubjectId)
              })
              this.subjectType = this.cbSubjectTreeList[0].subjectType
              this.subjectNumber = this.cbSubjectTreeList[0].subjectNumber
              this.getTotalAccount()
            }else {
              this.totalAccount = {list: []}
            }
          }
        })
      },
      handleSelect() {},
      filterNode(value, data) {
        if (!value) return true;
        return data.subjectName.indexOf(value) !== -1;
      },
      nodeClick(data, node) {
        this.subjectType = data.subjectType
        this.subjectNumber = data.subjectNumber
        this.getTotalAccount()
      },
      handleDetail(item) {
        // this.dialogFormVisible = true
        // console.log(item);
        // this.$router.push('/accounting/detail')
        this.$router.push({path: '/accounting/detail', query: {
          accountType: this.activeName,
          subjectNumber: this.totalAccount.subjectNumber,
          subjectName: this.totalAccount.subjectName,
          date: item.period
        }})
        let obj = {
          path: '/accounting/detail',
          name: 'lendgerDetail',
          label: '财务',
          children: [],
          menuId: 43
        }
        let path = '/accounting/detail'
        this.tagsList.forEach((val, index) => {
          // console.log(val.path.split('/'));
          
          if(obj.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path)) {
            // console.log(val);
            for(let key in val) {
              if(key != 'children' && key != 'label') {
                val[key] = obj[key]
              }
            }
            this.$store.commit('selectMenu', val)
          }
        })
        this.$store.commit("changeMenu", obj);
      },
    },
    watch: {
      filterText(val) {
        this.$refs.tree[this.index].filter(val);
      }
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  display: flex;
  min-height: calc(100vh - 146px);
  .header-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .left {
    .el-tree {
      max-height: calc(100vh - 244px);
      overflow: auto;
    }
  }
  .right{
    margin-left: 16px;
    flex: 1;
  }
}
</style>