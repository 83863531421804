<template>
  <div class="project">
    <div class="left" v-if="isShowLeft">
      <div v-for="(item, index) in cfflowitem" :key="index" :class="['item', isActive == index ? 'isActive' : '']" @click="getCflowChange(item, index)">
        <i class="el-icon-tickets" style="margin-right:5px"></i>
        {{item.showName}}
      </div>
      <template v-if="myTopItemList.length">
        <div style="margin: 0 14px;font-size:13px;display:flex;align-items:center;color:#666">
          <div>我的置顶</div>
          <div style="flex:1;height:1px;background:#DCDFE6;margin-left:5px"></div>
        </div>
        <div v-for="(item, id) in myTopItemList" :key="item.ofoItemId" class="item" @click="handleDetail(item)">
          <i class="el-icon-tickets" style="margin-right:5px"></i>
          {{item.name}}
        </div>
      </template>
      <div style="width:84%;height:1px;background:#DCDFE6;margin:0 14px"></div>

      <div :class="['item', isActive == (cfflowitem.length + 1) ? 'isActive' : '']" @click="getFunction('c')">
        <i class="el-icon-star-on" style="margin-right:5px"></i>
        我的收藏
      </div>
      <div :class="['item', isActive == (cfflowitem.length + 2) ? 'isActive' : '']" @click="getFunction('s')">
        <i class="el-icon-share" style="margin-right:5px"></i>
        分享给我
      </div>
      <div style="width:84%;height:1px;background:#DCDFE6;margin:0 14px 10px"></div>

    </div>
    <div :class="isShowLeft ? 'middle iconfont icon-zhedie-l' : 'middle iconfont icon-zhedie1'" @click="isShowLeft = !isShowLeft">
    </div>
    <!-- <div class="show-mask" @click="isShowLeft = !isShowLeft">
      <i :class="isShowLeft ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"></i>
    </div> -->
    <div class="right">
      <el-tabs>
        <el-tab-pane :label="titleHeader">
          <div class="sift" v-show="isShowFormInline">
            <div class="title">项目筛选查询</div>
            <el-form :inline="true" :model="siftForm" class="demo-form-inline" @submit.native.prevent size="small" label-width="96px">
              <el-form-item>
                <span slot="label">
                  <strong>名称</strong>
                </span>
                <el-input
                  v-model="siftForm.name"
                  placeholder="请输入名称"
                  clearable
                  @keyup.enter.native="inquire"
                  @clear="inquire"
                  style="width:240px"
                ></el-input>
              </el-form-item>
              <el-form-item v-if="!isMine && item.prop != 'cfflowitemName' && item.prop != 'process' && item.isSearch == '0' && item.showType != '4'" v-for="(item, index) in showList" :key="index">
                <span slot="label">
                  <strong>{{item.label}}</strong>
                </span>
                <!-- 基础数据 -->
                <template v-if="item.showType == '1'">
                  <!-- 人员 -->
                  <el-select
                    v-if="item.prop == 'userName'"
                    v-model="item.showValue"
                    :placeholder="'请选择' + item.label"
                    @change="inquire"
                    clearable
                    filterable
                    style="width:240px"
                  >
                    <el-option-group
                      v-for="group in postAndUserList"
                      :key="group.ofrResourceId"
                      :label="group.resourceName">
                      <el-option
                        v-for="item in group.ofrThingList"
                        :key="item.ofrThingId"
                        :label="item.name"
                        :value="item.ofrThingId">
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <!-- 项目时间 -->
                  <el-date-picker
                    v-if="item.prop == 'itemDate'"
                    v-model="item.showValue"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    class="my-el-date-picker"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width:240px"
                    @change="inquire">
                  </el-date-picker>
                  <!-- 数据字典 -->
                  <el-select
                    v-if="item.labelType == '2'"
                    v-model="item.showValue"
                    :placeholder="'请选择' + item.label"
                    @change="inquire"
                    clearable
                    filterable
                    style="width:240px"
                  >
                    <el-option v-for="(item, index) in dropDown[item.prop]" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
                  </el-select>
                  <!-- 描述 -->
                  <el-input
                    v-if="item.prop == 'remarks'"
                    v-model="item.showValue"
                    :placeholder="'请输入' + item.label"
                    clearable
                    @keyup.enter.native="inquire"
                    @clear="inquire"
                    style="width:240px"
                  ></el-input>
                </template>
                <template v-if="(item.showType == '2' || item.showType == '3') && item.cbussDime.editType != '8'">
                  <!-- 输入框 -->
                  <el-input
                    v-if="item.cbussDime.editType == '1' || item.cbussDime.editType == '2'"
                    v-model="item.showValue"
                    :placeholder="'请输入' + item.label"
                    clearable
                    @keyup.enter.native="inquire"
                    @clear="inquire"
                    style="width:240px"
                  ></el-input>
                  <!-- 下拉框 -->
                  <el-select
                    v-if="item.cbussDime.editType == '3' || item.cbussDime.editType == '6' || item.cbussDime.editType == '7'"
                    v-model="item.showValue"
                    :placeholder="'请选择' + item.label"
                    @change="inquire"
                    clearable
                    filterable
                    style="width:240px"
                  >
                    <el-option v-for="(val, index) in JSON.parse(item.cbussDime.options)" :key="index" :label="val.optionLabel" :value="val.optionValue"></el-option>
                  </el-select>
                  <!-- 日期范围 -->
                  <el-date-picker
                    v-if="item.cbussDime.editType == '5' || item.cbussDime.editType == '9'"
                    v-model="item.showValue"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    class="my-el-date-picker"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width:240px"
                    @change="inquire">
                  </el-date-picker>
                  <!-- 资源 -->
                  <el-select
                    v-if="item.cbussDime.editType == '12'"
                    v-model="item.showValue"
                    clearable
                    filterable
                    :placeholder="'请选择' + item.label"
                    @change="inquire"
                    style="width:240px"
                  >
                    <el-option
                      v-for="(drop, id) in item.cbussDime.options"
                      :key="id"
                      :label="drop.resourceName"
                      :value="drop.ofrResourceId + ''"
                    ></el-option>
                  </el-select>
                  <!-- 内容 -->
                  <el-select
                    v-if="item.cbussDime.editType == '13'"
                    style="width: 240px"
                    v-model="item.showValue"
                    clearable
                    filterable
                    :placeholder="'请选择' + item.label"
                    @change="inquire"
                  >
                    <el-option-group
                      v-for="(group, id1) in item.cbussDime.options"
                      :key="id1"
                      :label="group.resourceName">
                      <el-option
                        v-for="(drop, id) in group.ofrThingList"
                        :key="id"
                        :label="drop.name"
                        :value="drop.ofrThingId + ''">
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <InputNumberRange v-if="item.cbussDime.editType == '4' || item.cbussDime.editType == '10'" v-model="item.showValue" style="width:240px" ref="inputNumber"/>
                  <InputNumberRange v-if="item.cbussDime.editType == '11'" v-model="item.showValue" style="width:240px" percent ref="inputNumber"/>
                </template>
              </el-form-item>





              <el-form-item>
                <span slot="label">
                  <!-- <strong>{{item.label}}</strong> -->
                </span>
                <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
                <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
              </el-form-item>
            </el-form>


          </div>
          <div class="list">
            <div class="header" style="margin-bottom:16px">
              <div style="display: flex;flex-wrap: wrap;;align-items:center">
                <span class="title" style="margin-bottom:0">{{titleHeader}}</span>
                <div style="width:240px" v-if="innerWidth >= 1100">
                  <el-button
                    icon="el-icon-plus"
                    type="primary"
                    plain
                    size="mini"
                    @click="handleAdd"
                    v-show="handleDutys(dutys, 'addType') && titleHeader != '我的收藏' && !isMine"
                  >新建</el-button>
                  <el-button icon="el-icon-edit-outline" type="success" plain size="mini" :disabled="!selected" @click="handleEdit" v-show="handleDutys(dutys, 'updType') && tab.showTable && !isMine">编辑</el-button>
                  <el-button size="mini" type="danger" plain icon="el-icon-delete" @click.native.stop="handleDel" v-if="handleDutys(dutys, 'delType') && !isMine && tab.showTable" :disabled="!selected">删除</el-button>
                </div>

              </div>

              <!-- <div class="title" v-if="isMine">

              </div> -->
              <div style="display:flex;align-items:center">
                <el-pagination
                  @size-change="handleSizeChange"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="pageNum"
                  :page-sizes="tab.showCard ? [8, 16, 32, 64, 128] : [10, 20, 50, 100, 200]"
                  :page-size="pageSize"
                  layout="->, prev, pager, next, sizes, jumper, total"
                  :total="total"
                  :key="key"
                >
                <!-- <el-pagination
                  @size-change="handleSizeChange"
                  background
                  :current-page.sync="pageNum"
                  :page-sizes="[10, 20, 50, 100, 200]"
                  :page-size="pageSize"
                  :total="40"
                  :key="cflowItem.cfFlowItemId"
                > -->
                </el-pagination>
                <el-dropdown style="margin-right: 13px" :hide-on-click="false" trigger="click" ref="dropdown" v-show="tab.showCard && !isMine">
                  <span class="el-dropdown-link">
                    <i class="el-icon-sort el-icon--right" style="font-size:16px"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-checkbox v-model="nameCheck">名称</el-checkbox>
                      <el-button type="text" @click="handleSort(nameSort)" style="margin-left:10px">{{(nameSort == 'asc') ? '正序' : '倒序'}}</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-for="(item, index) in showList" :key="index" v-if="item.isSort == '0' && item.showType != '4'">
                      <el-checkbox v-model="item.value1" @change="handleCheck($event, index)">{{item.label}}</el-checkbox>
                      <el-button type="text" @click="handleSort(item.sortValue, index)" style="margin-left:10px">{{(item.sortValue == 'asc') ? '正序' : '倒序'}}</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                      <el-button size="mini" type="primary" @click="commitSort">确定</el-button>
                      <el-button size="mini" @click="resetSort">重置</el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-tooltip class="item" effect="dark" :content="isShowFormInline ? '隐藏搜索' : '显示搜索'" placement="top-end">
                  <el-button class="hidden" icon="el-icon-search" @click="isShowFormInline = !isShowFormInline"></el-button>
                </el-tooltip>
                <el-button class="tab" @click="change" :icon="tab.showCard? 'el-icon-tickets': 'el-icon-s-grid'"></el-button>
                <el-tooltip class="item" effect="dark" content="刷新列表" placement="top-end">
                  <el-button class="hidden" icon="el-icon-refresh" @click="getList"></el-button>
                </el-tooltip>
              </div>

            </div>
            <div style="width:240px;margin-bottom: 16px" v-if="innerWidth < 1100">
              <el-button
                icon="el-icon-plus"
                type="primary"
                plain
                size="mini"
                @click="handleAdd"
                v-show="handleDutys(dutys, 'addType') && titleHeader != '我的收藏' && !isMine"
              >新建</el-button>
              <el-button icon="el-icon-edit-outline" type="success" plain size="mini" :disabled="!selected" @click="handleEdit" v-show="handleDutys(dutys, 'updType') && tab.showTable && !isMine">编辑</el-button>
              <el-button size="mini" type="danger" plain icon="el-icon-delete" @click.native.stop="handleDel" v-if="handleDutys(dutys, 'delType') && !isMine && tab.showTable" :disabled="!selected">删除</el-button>
            </div>
            <div class="contain1">
              <div class="left1" ref="querytree">
                <el-collapse v-model="activeNames">
                  <el-collapse-item :title="item.label" :name="index + 1" v-for="(item, index) in cbussDimeTree" :key="index">
                    <el-tree
                      :data="item.children"
                      :props="defaultProps1"
                      @node-click="handleNodeClick1($event, index)"
                      default-expand-all
                      :ref="'tree' + (index + 1)"
                      :expand-on-click-node="false"
                      highlight-current
                      node-key="value">
                    </el-tree>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <div class="right1">
                <!-- 表格形式 -->
                <div class="main" v-if="tab.showTable">
                  <!-- 配置显示 -->
                  <el-table
                    v-if="showList.length"
                    :data="list"
                    ref="mainTable"
                    style="width: 100%"
                    :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
                    @selection-change="handleSelect"
                    v-loading="loading"
                    border
                    @sort-change="sortChange"
                    :height="tableHeight"
                    @header-dragend="changeColWidth"
                  >
                    <el-table-column
                      type="selection"
                      width="45"
                      align="center"
                    >
                    </el-table-column>

                    <el-table-column
                      prop="name"
                      label="名称"
                      min-width="200"
                      show-overflow-tooltip
                      sortable="custom"
                      fixed="left"
                      :key="Math.random()"
                      >
                      <template slot-scope="scope">
                        <a class="link" @click="handleDetail(scope.row)">{{scope.row.name}}</a>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-for="(item, index) in showList"
                      :key="index"
                      :prop="((item.showType == '2' && item.cbussDime.editType == '10') || (item.showType == '1' && item.labelType == '4')) ? 'sum' : ''"
                      :label="item.label"
                      :column-key="index + ''"
                      :sortable="(item.isSort == '0' && item.showType != '4') ? 'custom' : false"
                      min-width="130px"
                      :align="((item.showType == '2' || item.showType == '3') && (item.cbussDime.editType == '4' || item.cbussDime.editType == '10') || (item.showType == '1' || item.showType == '4') && (item.labelType == '4')) ? 'right' : 'center'"
                      header-align="center"
                      show-overflow-tooltip>
                      <template slot="header">
                        {{item.label}}
                        <el-button slot="reference" v-if="handleDutys(dutys, 'updType') && item.isEdit == '0'" type="text" icon="el-icon-edit-outline" @click.prevent.stop="handleItemEdit(item)"></el-button>
                      </template>
                      <template slot-scope="scope">
                        <div v-for="(val, id) in scope.row.showList" :key="id" v-if="item.cbsId == val.cbsId" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                          <!-- 基础数据 -->
                          <template v-if="item.showType == '1' && item.prop == val.prop">
                            <!-- 数据字典 -->
                            <template v-if="item.labelType == '2'"><DictTag :options="dropDown[item.prop]" :value="val.showValue" :isStyle="true"/></template>
                            <!-- 项目时间 -->
                            <template v-else-if="item.labelType == '3'">{{val.showValue | filter_handleDate('date')}}</template>
                            <!-- 金额 -->
                            <template v-else-if="item.labelType == '4'">{{val.showValue | comdify('no')}}</template>
                            <template v-else>{{val.showValue}}</template>
                          </template>
                          <!-- 项目维度 -->
                          <template v-if="item.showType == '2' && item.cbdId == val.cbdId">
                            <!-- <DimeTag :dimeObj="{...item.cbussDime, dimeText: val.showValue, dimeValue: val.showValue}"/> -->

                            <template v-if="item.cbussDime.editType != '8'">{{val.showValue}}</template>
                            <template v-else>
                              <fileList :fileList="JSON.parse(val.showValue)" />
                            </template>
                          </template>
                          <!-- 活动维度 -->
                          <template v-if="item.showType == '3' && item.cbdId == val.cbdId && item.cfFlowWorkId == val.cfFlowWorkId">
                            <template v-if="item.cbussDime.editType != '8'">{{val.showValue}}</template>
                            <template v-else>
                              <fileList :fileList="JSON.parse(val.showValue)" />
                            </template>
                          </template>
                          <!-- 活动基础数据 -->
                          <template v-if="item.showType == '4' && item.prop == val.prop && item.cfFlowWorkId == val.cfFlowWorkId">
                            <!-- 数据字典 -->
                            <template v-if="item.labelType == '2'"><DictTag :options="dropDown[item.prop]" :value="val.showValue" :isStyle="true"/></template>
                            <!-- 项目时间 -->
                            <template v-else-if="item.labelType == '3'">{{val.showValue | filter_handleDate('date')}}</template>
                            <!-- 金额 -->
                            <template v-else-if="item.labelType == '4'">{{val.showValue | comdify('no')}}</template>
                            <template v-else>{{val.showValue}}</template>
                          </template>

                          <!-- 计算 -->
                          <template v-if="item.showType == '9'">
                            <template v-if="val.calcShowType == '6'">
                              <el-progress v-if="!isNaN(handleCalc(val.childList, val.calcShowType))" :percentage="handleCalc(val.childList, val.calcShowType)"></el-progress>
                            </template>
                            <template v-else>{{handleCalc(val.childList, val.calcShowType)}}</template>

                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                      label="操作"
                      fixed="right"
                      :width="handleDutys(dutys, 'updType') ? (handleDutys(dutys, 'delType') ? '180' : '120') : '70'">
                      <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-view" @click="handleDetail(scope.row)">查看</el-button>
                        <el-button size="mini" type="text" icon="el-icon-edit" @click.native.stop="handleEdit(scope.row)" v-if="handleDutys(dutys, 'updType') && !isMine">编辑</el-button>
                        <el-button size="mini" type="text" icon="el-icon-delete" @click.native.stop="handleDel(scope.row)" v-if="handleDutys(dutys, 'delType') && !isMine">删除</el-button>
                      </template>
                    </el-table-column> -->
                  </el-table>
                  <el-table
                    v-if="!showList.length"
                    :data="list"
                    ref="multipleTable"
                    style="width: 100%"
                    :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
                    @selection-change="handleSelect"
                    v-loading="loading"
                    border
                  >
                    <!-- <template v-if="!isMine"> -->
                      <el-table-column
                        type="selection"
                        width="45"
                      >
                      </el-table-column>
                    <!-- </template> -->

                    <el-table-column
                      prop="name"
                      label="名称"
                      width="200"
                      show-overflow-tooltip
                      >
                      <template slot-scope="scope">
                        <a class="link" @click="handleDetail(scope.row)">{{scope.row.name}}</a>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="userName"
                      label="人员"
                      align="center"
                      width="100">
                    </el-table-column>


                    <el-table-column
                      label="发布"
                      prop="documentstatus"
                      align="center"
                      width="150"
                      >
                      <template slot-scope="scope">
                        <DictTag :options="dropDown.documentstatus" :value="scope.row.documentstatus" :isStyle="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="itemDate"
                      label="项目时间"
                      align="center"
                      width="150">
                      <template slot-scope="scope">
                        {{scope.row.itemDate | filter_handleDate('date')}}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="cfflowitemName"
                      label="类型"
                      show-overflow-tooltip
                      align="center">
                    </el-table-column>
                    <el-table-column
                      prop="remarks"
                      label="描述"
                      show-overflow-tooltip
                      align="center">
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      fixed="right"
                      v-if="titleHeader == '我的收藏'">
                      <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-view" @click="handleDetail(scope.row)">查看</el-button>
                        <el-button size="mini" type="text" icon="el-icon-edit" @click.native.stop="handleEdit(scope.row)" v-if="handleDutys(scope.row.dutys, 'updType')">编辑</el-button>
                        <el-button size="mini" type="text" icon="el-icon-delete" @click.native.stop="handleDel(scope.row)" v-if="handleDutys(scope.row.dutys, 'delType')">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 块形式 -->
                <div class="main" v-if="tab.showCard">
                  <div style="text-align:center;color:#999" v-if="!list.length">暂无数据</div>
                  <div class="item" v-loading="loading">
                    <el-card
                      shadow="always"
                      v-for="(item, index) in list"
                      :key="index"
                      :class="(index + 1) % 4 == 0 ? 'noright' : ''"
                      @click.native="handleDetail(item)"
                      :body-style="{padding: '10px 0 34px 10px'}"
                    >
                      <div slot="header" class="clearfix">
                        <div @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="text">{{item.name}}</div>
                        <el-tooltip effect="dark" :content="item.name" placement="top" v-if="isShowText">
                          <!-- <div class="text" @mouseout="hideTip">{{item.name}}</div> -->
                          <div class="text">{{item.name}}</div>
                        </el-tooltip>
                        <!-- <span>{{ item.name }}</span> -->
                        <!-- <div style="max-width: 45px"> -->
                        <div style="max-width: 45px" v-if="!isMine">
                          <el-button icon="el-icon-edit-outline" class="edit" @click.native.stop="handleEdit(item)" v-if="handleDutys(dutys, 'updType')" type="text"></el-button>
                          <el-button icon="el-icon-delete" class="edit" @click.native.stop="handleDel(item)" v-if="handleDutys(dutys, 'delType')" type="text"></el-button>
                        </div>
                        <div style="max-width: 45px" v-if="titleHeader == '我的收藏'">
                          <el-button icon="el-icon-edit-outline" class="edit" @click.native.stop="handleEdit(item)" v-if="handleDutys(item.dutys, 'updType')" type="text"></el-button>
                          <el-button icon="el-icon-delete" class="edit" @click.native.stop="handleDel(item)" v-if="handleDutys(item.dutys, 'delType')" type="text"></el-button>
                        </div>


                      </div>
                      <div v-if="showList.length" style="max-height:160px; overflow-y:auto">
                        <div class="line" v-for="(val, id) in showList" :key="id">
                          <div class="line-left">{{val.label}}:</div>
                          <div class="line-right">
                            <div v-for="(el, id1) in item.showList" :key="id1" v-if="el.cbsId == val.cbsId">
                              <template v-if="val.showType == '1' && val.prop == el.prop">
                                <!-- <div v-if="val.labelType == '1'">{{el.showValue}}</div> -->
                                <!-- 数据字典 -->
                                <div v-if="val.labelType == '2'"><DictTag :options="dropDown[val.prop]" :value="el.showValue"/></div>
                                <!-- 项目时间 -->
                                <div v-else-if="val.labelType == '3'">{{el.showValue | filter_handleDate('date')}}</div>
                                <!-- 金额 -->
                                <div v-else-if="val.labelType == '4'">{{el.showValue | comdify('no')}}</div>
                                <div v-else>{{el.showValue}}</div>
                              </template>
                              <template v-if="val.showType == '2' && val.cbdId == el.cbdId">
                                <!-- <DimeTag :dimeObj="{...val.cbussDime, dimeText: el.showValue, dimeValue: el.showValue}"/> -->
                                <template v-if="val.cbussDime.editType != '8'">{{el.showValue}}</template>
                                <template v-else>
                                  <fileList :fileList="JSON.parse(el.showValue)" />
                                </template>
                              </template>
                              <template v-if="val.showType == '3' && val.cbdId == el.cbdId && val.cfFlowWorkId == el.cfFlowWorkId">
                                <!-- <DimeTag :dimeObj="{...val.cbussDime, dimeText: el.showValue, dimeValue: el.showValue}"/> -->
                                <template v-if="val.cbussDime.editType != '8'">{{el.showValue}}</template>
                                <template v-else>
                                  <fileList :fileList="JSON.parse(el.showValue)" />
                                </template>
                              </template>
                              <template v-if="val.showType == '4' && val.prop == el.prop && val.cfFlowWorkId == el.cfFlowWorkId">
                                <!-- <div v-if="val.labelType == '1'">{{el.showValue}}</div> -->
                                <!-- 数据字典 -->
                                <div v-if="val.labelType == '2'"><DictTag :options="dropDown[val.prop]" :value="el.showValue"/></div>
                                <!-- 项目时间 -->
                                <div v-else-if="val.labelType == '3'">{{el.showValue | filter_handleDate('date')}}</div>
                                <!-- 金额 -->
                                <div v-else-if="val.labelType == '4'">{{el.showValue | comdify('no')}}</div>
                                <div v-else>{{el.showValue}}</div>
                              </template>
                              <template v-if="val.showType == '9'">
                                <template v-if="el.calcShowType == '6'">
                                  <el-progress v-if="!isNaN(handleCalc(el.childList, el.calcShowType))" :percentage="handleCalc(el.childList, el.calcShowType)"></el-progress>
                                </template>
                                <template v-else>{{handleCalc(el.childList, el.calcShowType)}}</template>

                              </template>
                            </div>
                            <!-- <template v-if="val.showType == '1'">
                              <div v-if="val.labelType == '1'">{{item[val.prop]}}</div>
                              数据字典
                              <div v-if="val.labelType == '2'"><DictTag :options="dropDown[val.prop]" :value="item[val.prop]"/></div>
                              项目时间
                              <div v-if="val.labelType == '3'">{{item[val.prop] | filter_handleDate('date')}}</div>
                            </template> -->
                            <!-- <template v-if="val.showType == '2'">
                              维度
                              <div v-for="(el, cbdId) in item.dimeList" :key="cbdId">
                                <div v-if="val.cbdId == el.cbdId">
                                  <DimeTag :dimeObj="{...val.cbussDime, dimeValue: el.dimeValue, dimeText: el.dimeText}"/>
                                </div>
                              </div>
                            </template> -->
                            <!-- <template v-if="val.showType == '3'">
                              活动维度
                              <div v-for="(el, cbdId) in item.dimeWorkList" :key="cbdId">
                                <template v-if="val.cbdId == el.cbdId && val.cfFlowWorkId == el.cfFlowWorkId">
                                  <div @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="text">{{el.dimeText}}</div>
                                  <el-tooltip effect="dark" :content="el.dimeText" placement="top" v-if="isShowText">
                                    <div class="text">{{el.dimeText}}</div>
                                  </el-tooltip>
                                </template>
                              </div>
                            </template> -->
                          </div>
                        </div>
                      </div>
                      <template v-if="!showList.length">
                        <div class="line">
                          <div class="line-left">状态:</div>
                          <div class="line-right"><DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/></div>
                        </div>
                        <div class="line">
                          <div class="line-left">人员:</div>
                          <div class="line-right">{{ item.userName }}</div>
                        </div>
                        <div class="line">
                          <div class="line-left">时间:</div>
                          <div class="line-right">{{ item.itemDate | filter_handleDate }}</div>
                        </div>
                        <div class="line">
                          <div class="line-left">类型:</div>
                          <div class="line-right">{{ item.cfflowitemName }}</div>
                        </div>
                        <div class="line">
                          <div class="line-left">描述:</div>
                          <div class="line-right">
                            <div @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="text">{{item.remarks}}</div>
                            <el-tooltip effect="dark" :content="item.remarks" placement="top" v-if="isShowText">
                              <!-- <div class="text" @mouseout="hideTip">{{item.remarks}}</div> -->
                              <div class="text">{{item.remarks}}</div>
                            </el-tooltip>
                          </div>
                        </div>
                      </template>

                      <div class="last-line">
                        <!-- <div style="margin-left:10px">
                          <el-button icon="el-icon-share" @click.native.stop="handleCollection(item, 's')" type="text" style="padding: 0"></el-button>
                          <el-button icon="el-icon-star-off" @click.native.stop="handleCollection(item, 'c')" type="text" style="padding: 0"></el-button>
                        </div> -->
                        <el-button class="view" @click="handleDetail(item)">查看详情</el-button>
                      </div>
                    </el-card>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </el-tab-pane>
        <el-tab-pane label="活动">
          <div class="list" style="display:flex">
            <div class="contain-left">
              <el-tree
                :data="cfFlowDealList"
                :props="defaultProps"
                default-expand-all
                highlight-current
                ref="tree"
                node-key="cfFlowWorkId"
                @node-click="handleNodeClick">
              </el-tree>
            </div>
            <!-- <div>1四大皆空发哈大家级大风和嘎聊的宽广豪放阿道夫高科技哈德看过回复23</div> -->
            <div class="contain-right">
              <el-form :model="queryParams" class="demo-form-inline" size="mini" inline @submit.native.prevent inline-message>
                <el-form-item label="项目">
                  <el-select v-model="queryParams.ofoitemId" placeholder="请选择项目" clearable filterable @change="getWorkListBycf" >
                    <el-option
                      v-for="item in allItemList"
                      :key="item.ofoItemId"
                      :label="item.name"
                      :value="item.ofoItemId">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="编号/名称">
                  <el-input
                    v-model="queryParams.codename"
                    placeholder="请输入编号/名称"
                    @keyup.enter.native="getWorkListBycf"
                    @clear="getWorkListBycf"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="getWorkListBycf">查询</el-button>
                  <el-button icon="el-icon-refresh" @click="resetWork">重置</el-button>
                </el-form-item>
              </el-form>
              <el-table
                :data="workList"
                border
                :cell-style="{
                  padding: '3px 0'
                }"
                :header-cell-style="{
                  padding: '3px 0',
                  background:'#F9F9FB',
                  color:'#606266'
                }"
                style="margin-bottom:10px"
                v-loading="workLoading">
                <el-table-column prop="ofoflowitemName" label="项目名称" align="left" min-width="130" show-overflow-tooltip />
                <el-table-column prop="name" label="名称" align="center">
                  <template slot-scope="scope">
                    <div class="link" @click="handleWorkView(scope.row)">{{scope.row.name}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="workDate" label="日期" align="center" width="110">
                  <template slot-scope="scope">
                    {{scope.row.workDate | filter_handleDate}}
                  </template>
                </el-table-column>
                <el-table-column v-for="(val, id) in workShowList" :key="id" :label="val.name" :align="(val.column == 'quantity' || val.column == 'ptotal') ? 'right' : 'center'" header-align="center">
                  <template slot-scope="scope">
                    <template v-if="val.type == 'b'">
                      <template v-if="val.column == 'ptotal'">
                        {{scope.row[val.column] | comdify}}
                      </template>
                      <template v-else>
                        {{scope.row[val.column]}}
                      </template>
                    </template>
                    <template v-else>{{scope.row.workShowMap[val.column]}}</template>
                  </template>
                </el-table-column>
                <template v-if="!workShowList.length">
                  <el-table-column prop="quantity" label="数量" align="right" header-align="center" v-if="cfoFlowWork.costmanagetype != '7' && cfoFlowWork.costmanagetype != '4'" />
                  <el-table-column prop="quantity" label="时间" align="center" v-if="cfoFlowWork.costmanagetype == '7'">
                    <template slot-scope="scope">
                      {{scope.row.quantity | filter_handleTimeRange}}
                    </template>
                  </el-table-column>
                  <el-table-column prop="ptotal" label="金额" align="right" header-align="center" v-if="cfoFlowWork.costmanagetype != '7' && cfoFlowWork.costmanagetype != '4'">
                    <template slot-scope="scope">
                      {{scope.row.ptotal | comdify}}
                    </template>
                  </el-table-column>
                </template>
                <el-table-column label="状态" align="center" width="80">
                  <template slot-scope="scope">
                    <DictTag v-if="scope.row.appstatus != '3'" :options="dropDown.documentstatus" :value="scope.row.documentstatus" isStyle/>
                    <el-tag v-else type="warning">审批中</el-tag>
                  </template>
                </el-table-column>
                <el-table-column v-if="(cfoFlowWork.checkType == '1' || cfoFlowWork.checkType == '2')" label="进度" align="center" width="180">
                  <template slot-scope="scope">
                    <el-progress :percentage="scope.row.percentage" style="margin: 0" v-if="scope.row.openTotal != null && scope.row.percentage != 100 && scope.row.checkStatus != '0'" :format="format"></el-progress>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange1"
                background
                @current-change="handleCurrentChange1"
                :current-page.sync="queryParams.pageNum"
                :page-sizes="[10, 20, 50, 100, 200]"
                :page-size="queryParams.pageSize"
                layout="->, prev, pager, next, sizes, jumper, total"
                :total="workTotal"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      :top="'10vh'"
      width="60%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        style="max-height: 55vh; overflow-y: auto;overflow-x: hidden"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入名称" style="width: 98%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="项目时间" prop="itemDate">
              <el-date-picker
                v-model="ruleForm.itemDate"
                type="date"
                placeholder="选择项目时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :clearable='false'
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布" prop="documentstatus">
              <el-select
                v-model="ruleForm.documentstatus"
                placeholder="请选择发布形式"
                style="width:97%"
              >
                <el-option v-for="(item, index) in dropDown.documentstatus" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="类型" prop="cfFlowItemId">
              <el-select
                v-model="ruleForm.cfFlowItemId"
                placeholder="请选择类型"
                style="width: 100%"
                disabled
              >
                <el-option v-for="(item, index) in cfflowitem" :key="index" :label="item.name" :value="item.cfFlowItemId" :disabled="item.dutys ? ((item.dutys.addType == '1') ? true : false) : false "></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="描述" prop="remarks">
              <el-input type="textarea" v-model="ruleForm.remarks" placeholder="添加描述" style="width: 97%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form :formList="formList" :gutter="20" v-if="formList" :ruleForm="ruleForm"/>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="分享人" :visible.sync="dialogShareVisible"
      :append-to-body="true" width="30%">
      <el-form :model="form" label-width="60px">
        <el-form-item label="分享人" >
          <el-select v-model="form.toUserIds" placeholder="请选择分享人" style="width:97%" filterable multiple>
            <el-option-group
              v-for="group in userList"
              :key="group.ofrResourceId"
              :label="group.resourceName">
              <el-option
                v-for="item in group.ofrThingList"
                :key="item.ofrThingId"
                :label="item.name"
                :value="item.ofrThingId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShareVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitShare">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="dialogEditVisible"
      :append-to-body="true" width="500px">
      <el-form :model="editForm">
        <template v-if="editObj.showType == '1'">
          <el-form-item :label="editObj.label" >
            <!-- 人员 -->
            <el-select
              v-if="editObj.prop == 'userName'"
              v-model="editForm.dimeValue"
              :placeholder="'请选择' + editObj.label"
              clearable
              filterable
              style="width:100%"
            >
              <el-option-group
                v-for="group in postAndUserList"
                :key="group.ofrResourceId"
                :label="group.resourceName">
                <el-option
                  v-for="item in group.ofrThingList"
                  :key="item.ofrThingId"
                  :label="item.name"
                  :value="item.ofrThingId">
                </el-option>
              </el-option-group>
            </el-select>
            <!-- 项目时间 -->
            <el-date-picker
              v-if="editObj.prop == 'itemDate'"
              v-model="editForm.dimeValue"
              type="date"
              unlink-panels
              :placeholder="'请选择' + editObj.label"
              class="my-el-date-picker"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width:100%">
            </el-date-picker>
            <!-- 数据字典 -->
            <el-select
              v-if="editObj.labelType == '2'"
              v-model="editForm.dimeValue"
              :placeholder="'请选择' + editObj.label"
              clearable
              filterable
              style="width:100%"
            >
              <el-option v-for="(item, index) in dropDown[editObj.prop]" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
            <!-- 描述 -->
            <el-input
              v-if="editObj.prop == 'remarks'"
              v-model="editForm.dimeValue"
              :placeholder="'请输入' + editObj.label"
              clearable
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="editObj.showType == '2'">
          <uniseriate-form :formList="[editObj.cbussDime]" />
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/config/url'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Form from '@/components/Form.vue'
import DimeTag from '@/components/DimeTag.vue'
import textRange from '@/utils/textRange.js'
import InputNumberRange from '@/components/form/InputNumberRange.vue'
  export default {
    components: {
      DictTag,
      Form,
      DimeTag,
      InputNumberRange
    },
    computed: {
      ...mapState({
        tab: (state) => state.tab.tab,
        dropDown: (state) => state.dropDown.dropDown
      })
    },
    data() {
      return {
        siftForm: {
          name: "",
          type: "",
          time: "",
        },
        isActive: 0,
        isShowFormInline: true,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        dialogFormVisible: false,
        ruleForm: {
          name: "",
          itemDate: "",
          // userId: "",
          cfFlowItemId: "",
          documentstatus: "",
          itemType: "",
          remarks: "",
          dataSourceConfig: []
        },
        rules: {
          name: [ { required: true, message: "请输入名称", trigger: "change" }],
          itemDate: [{ required: true, message: "请输入项目时间", trigger: "change" }],
          // userId: [{ required: true, message: "请选择人员", trigger: "change" }],
          cfFlowItemId: [{ required: true, message: "请选择类型", trigger: "change" }],
          documentstatus: [{ required: true, message: "请选择发布形式", trigger: "change" }],
          itemType: [{ required: true, message: "请选择菜单类型", trigger: "change" }],
        },
        // 弹出层标题
        title: '',
        // 下拉框不可选择
        selectDisabled: false,
        // 是否可以编辑
        selected: false,
        pageSize: 8,
        pageNum: 1,
        // 总条数，总共有多少条数据
        total: 0,
        total_money: 0,
        // 展示的数据
        list: [],
        loading: true,
        ids: [],
        cfflowitem: [],
        // user: [],
        isLoading: false,

        formList: [],
        cflowItem: {},
        dialogShareVisible: false,
        form: {
          toUserIds: [],
          obussId: ''
        },
        userList: [],
        showList: [],
        dutys: {},
        isMine: false,
        titleHeader: '',
        isShowText: false,
        isShowLeft: true,
        postAndUserList: [], // 岗位和人员列表
        key: '',
        nameCheck: false,
        nameSort: 'asc',
        values: [],
        dialogEditVisible: false,
        editObj: {},
        editForm: {
          dimeValue: ''
        },
        editVisible: false,
        cfFlowDealList : [],
        defaultProps: {
          children: 'cfFlowWorkList',
          label: 'name'
        },
        workList: [],
        queryParams: {
          cfFlowWorkId: null,
          baseId: null,
          codename: null,
          dealId: null,
          dealowhId: null,
          isShare: null,
          pageNum: 1,
          pageSize: 10,
          sortParams: null,
          ofoitemId: null
        },
        cfoFlowWork: {},
        workTotal: 0,
        workLoading: false,
        allItemList: [],
        baseUrl: this.$base_url,
        workShowList: [],
        tableHeight: 0,
        innerWidth: 0,
        myTopItemList: [],
        cbussDimeTree: [],
        defaultProps1: {
          children: 'children',
          label: 'label'
        },
        activeNames: [],
      }
    },
    created () {
      // console.log(window);
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 248;
        this.innerWidth = window.innerWidth;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 248;
          this.innerWidth = window.innerWidth;
        })()
      }
      this.pageSize = this.tab.showCard ? 8 : 10
      this.init()
    },

    methods: {
      // 表头拖拽事件
      changeColWidth() {
        this.$nextTick(() => {
          if(this.showList.length) {
            this.$refs.mainTable.doLayout()
          }
        });
      },
      // 处理计算符
      handleCalcu(calcu) {
        if(calcu == '1') {
          return '+'
        }else if(calcu == '2') {
          return '-'
        }else if(calcu == '3') {
          return '*'
        }else if(calcu == '4') {
          return '/'
        }else {
          return ''
        }
      },
      // 添加千分位
      comdify(n) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      handleCalc(listTemp, showType) {
        // list.every(item => item.showValue)
        if(listTemp.length) {
          // 计算showValue为空时设置为0
          const fillEmptyWithZero = obj => ({ ...obj, showValue: obj.showValue || 0 })
          const list = listTemp.map(fillEmptyWithZero)

          let arr = []
          let length = list.length
          for(let i = 0; i < (length - 1); i++) {
            arr.push(list[i].brackets && list[i].brackets.includes('(') ? list[i].brackets : '', list[i].showValue, list[i].brackets && list[i].brackets.includes(')') ? list[i].brackets : '', this.handleCalcu(list[i].calcu))
          }
          arr.push(list[length - 1].brackets && list[length - 1].brackets.includes('(') ? list[length - 1].brackets : '', list[length - 1].showValue, list[length - 1].brackets && list[length - 1].brackets.includes(')') ? list[length - 1].brackets : '')
          // console.log();
          let total = eval(arr.join(''))
          if(!Object.is(total, NaN) && total) {
            if(showType == '1') {
              return total.toFixed(4)
            }else if(showType == '2') { // 金额
              return this.comdify(total)
            }else if(showType == '3') { // 百分比
              return (total * 100).toFixed(0) + '%'
            }else if(showType == '6') { // 进度条
              return Number((total * 100).toFixed(0)) > 100 ? 100 : Number((total * 100).toFixed(0))
            }else {
              return total.toFixed(2)
            }
          }

        }else {
          return ''
        }
      },
      // 查看活动详情
      handleWorkView(row) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: row.ofoWorkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            this.data = res.data.data
            this.$store.commit("setJumpData", res.data.data)
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            // this.menuList.forEach(el => {
            //   if(obj.path.indexOf(el.path) != -1) {
            //     this.$store.commit('setSecondMenu', el.children)
            //   }
            // });
            this.$store.commit('setSecondMenu', [])
            this.$store.commit("selectMenu", obj);
          }

        })
      },
      format(percentage) {
        return percentage === 100 ? '' : `${percentage}%`;
      },
      // 重置活动查询
      resetWork() {
        this.queryParams.codename = null
        this.queryParams.ofoitemId = null
        this.queryParams.pageNum = 1
        this.getWorkListBycf()
      },
      // 点击树节点
      handleNodeClick(data, node) {
        if(node.level == 2) {
          this.cfoFlowWork = data
          this.queryParams.cfFlowWorkId = data.cfFlowWorkId
          this.queryParams.pageNum = 1
          this.getWorkListBycf()
        }
        // this.getList()
      },
      handleNodeClick1(e, index) {
        this.cbussDimeTree[index].value = e.value
        this.showList.forEach(item => {
          if(this.cbussDimeTree[index].cbdId == item.cbdId && this.cbussDimeTree[index].cbsId == item.cbsId) {
            item.showValue = e.value
          }
        })
        this.pageNum = 1
        this.getList()
      },
      // 根据流程获取交易活动
      getCfFlowDealList(cfFlowItemId) {
        this.queryParams.codename = null
        this.queryParams.ofoitemId = null
        this.queryParams.pageNum = 1
        this.$http.get(url.getCflowItemDetailByUser, {params: {cfFlowItemId}}).then(res => {
          if(res.data.code == 200) {
            this.cfFlowDealList = res.data.data.cfFlowDealList
            if(this.cfFlowDealList.length && this.cfFlowDealList[0].cfFlowWorkList.length) {
              this.cfoFlowWork = this.cfFlowDealList[0].cfFlowWorkList[0]
              this.$nextTick(()=>{// 等待挂载完成再进行下面这行代码
                this.$refs.tree.setCurrentKey(this.cfoFlowWork.cfFlowWorkId)
              })
              this.queryParams.cfFlowWorkId = this.cfoFlowWork.cfFlowWorkId
              this.queryParams.pageNum = 1
              this.getWorkListBycf()
            }else {
              this.workList = []
            }
          }
        })
        this.$http.get(url.getItemList, {params: {cfFlowItemId, pageSize: 1000}}).then(res => {
          if(res.data.code == 200) {
            this.allItemList = res.data.data.list
          }
        })
      },
      getWorkListBycf() {
        this.workLoading = true
        this.$http.get(url.getWorkListBycf, {params: this.queryParams}).then(res => {
          if(res.data.code == 200) {
            this.workList = res.data.data.list
            this.workTotal = res.data.data.total
            this.workShowList = res.data.data.show
            // this.cfFlowDealList = res.data.data.cfFlowDealList
          }
          this.workLoading = false
        })
      },
      // 编辑自定义显示列表
      handleItemEdit(item) {
        // this.
        if(this.ids.length) {
          this.dialogEditVisible = true
          this.editVisible = true
          this.editObj = item
        }else {
          this.$message.warning('请选择项目');
        }

      },
      // 提交修改
      submitEdit() {
        let obj = {}
        obj.cbsId = this.editObj.cbsId
        obj.ofoItemIds = this.ids.toString()
        if(this.editObj.showType == '1') {
          obj.dimeValue = this.editForm.dimeValue
        }else if(this.editObj.showType == '2') {
          if(this.editObj.cbussDime.dimeValue instanceof Array) {
            obj.dimeValue = JSON.stringify(this.editObj.cbussDime.dimeValue)
          }else {
            obj.dimeValue = this.editObj.cbussDime.dimeValue
          }
          obj.dimeText = this.handleDimes([this.editObj.cbussDime])[0].dimeText
        }
        this.$http.post(url.editItemByShow, qs.stringify(obj)).then(res => {
          if(res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.dialogEditVisible = false
            this.editObj = {}
            this.editForm.dimeValue = ''
            this.getList()
          }
        })
      },
      // 合计
      getSummaries(param) {
        // console.log(111);
        this.$nextTick(() => {
          // console.log(this.showList);
        })
        const {columns, data} = param
        const sums = []
        // this.showList.forEach((item, index) => {
        //   if()
        // })
        // if(columns.length > 2) {
        //   columns.forEach((item, index) => {
        //     if(index == 1) {
        //       sums[index] = '合计'
        //     }else if(index == 3) {
        //       sums[index] = '111'
        //     }
        //   })
        // }
        // const values = this.list.map(item => this.delcommafy(item.showList[10].showValue))

        // console.log(values);
        // console.log(1111);
        columns.forEach((item, index) => {
          // console.log(item.property);
          if(item.property == 'sum') {
            // console.log(index);
            // console.log(this.showList[index - 2]);
            // var values = []
            // this.list[0].showList.forEach((val, id) => {
            //   if(this.showList[index - 2].cbsId == val.cbsId) {
            //     this.values = this.list.map(el => this.delcommafy(el.showList[id].showValue))
            //   }
            // })
            // console.log(this.values);
            // sums[index] = 2222
          }
        })
        return sums
      },
      // 排序
      sortChange ({column, prop, order}) {
        // console.log(column);
        // console.log(prop);
        // console.log(order);
        if(prop == 'name') { // 名称
          this.nameCheck = true
          if(order == 'ascending') {
            this.nameSort = 'asc'
          }else if(order == 'descending') {
            this.nameSort = 'desc'
          }else {
            this.nameCheck = false
          }
        }else {
          this.nameCheck = false
          this.showList.forEach((item, index) => {
            if(column.columnKey == index) {
              this.showList[index].value1 = true
              if(order == 'ascending') {
                this.showList[index].sortValue = 'asc'
              }else if(order == 'descending') {
                this.showList[index].sortValue = 'desc'
              }else {
                this.showList[index].value1 = false
              }
            }else {
              this.showList[index].value1 = false
            }
          })
          // this.showList[Number(column.columnKey)].value1 = true
          // if(order == 'ascending') {
          //   this.showList[Number(column.columnKey)].sortValue = 'asc'
          // }else if(order == 'descending') {
          //   this.showList[Number(column.columnKey)].sortValue = 'desc'
          // }else {
          //   this.showList[Number(column.columnKey)].value1 = false
          // }
        }
        this.inquire()
      },
      handleSort(value, index) {
        if(index == undefined) {
          if(value == 'asc') {
            this.nameSort = 'desc'
          }else {
            this.nameSort = 'asc'
          }
        }else {
          if(value == 'asc') {
            this.showList[index].sortValue = 'desc'
          }else {
            this.showList[index].sortValue = 'asc'
          }
          let item = this.showList[index]
          this.$set(this.showList, index, item)
        }

      },
      // 排序复选框
      handleCheck(e, index) {
        this.showList[index].value1 = e
        let item = this.showList[index]
        this.$set(this.showList, index, item)
      },
      // 确定排序
      commitSort() {
        // this.$refs.dropdown.hide()
        this.inquire()
      },
      resetSort() {
        this.nameCheck = false
        this.nameSort = 'asc'
        this.showList.forEach(item => {
          item.value1 = false
          item.sortValue = 'asc'
        })
        this.inquire()
      },
      isShowToltip (e) {
        const bool = textRange(e.target)
        this.isShowText = bool
      },
      hideTip () {
        // console.log(11);
        this.isShowText = false
      },
      async getFormList(value) {
        await this.$http.get(url.getCbussDimeList, {params: {cbussId: value, dimetype: '3'}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.formList = this.handleFormList(res.data.data)
            this.ruleForm.dataSourceConfig = this.formList
          }
        })
      },
      async init() {
        // 获取我的置顶
        this.$http.get(url.getMyTopItem).then(res => {
          if(res.data.code == 200) {
            this.myTopItemList = res.data.data
          }
        })
        this.loading = true
        await this.$http.get(url.getCflowItemList).then(res => {
          if(res.data.code == 200) {
            this.cfflowitem = res.data.data
            if(this.cfflowitem.length) {
              this.cflowItem = res.data.data[0]
              this.siftForm.type = res.data.data[0].cfFlowItemId
              this.dutys = res.data.data[0].dutys
              this.titleHeader = res.data.data[0].showName
              this.getCfFlowDealList(this.siftForm.type)
              this.getList()
              this.getCbussShowList(this.siftForm.type)
            }else {
              this.getFunction('c')
              this.loading = false
            }
          }
        })
      },
      getCbussShowList(value) {
        this.cbussDimeTree = []
        this.$http.get(url.getCbussShowList, {params: {
          bussType: 'i',
          cbussId: value
        }}).then(res => {
          if(res.data.code == 200) {
            this.showList = res.data.data
            // console.log(this.showList.filter(item => {
            //   item.showType == '3'
            // }))
            let dimeList = res.data.data.filter(item => (item.isTree == '0' && (item.cbussDime.editType == '3' || item.cbussDime.editType == '6' || item.cbussDime.editType == '7')))
            // console.log(JSON.parse(JSON.stringify(dimeList)));

            dimeList.forEach(item => {
              item.children = JSON.parse(item.cbussDime.options).map(val => {
                return {
                  label: val.optionLabel,
                  value: val.optionValue
                }
              })
              this.cbussDimeTree.push(item)
            })
            this.cbussDimeTree.forEach((item, index) => {
              this.activeNames.push(index + 1)
            })
            if(this.cbussDimeTree.length === 0) {
              this.$refs.querytree.style.width = 0
            }else {
              this.$refs.querytree.style.width = '180px'
            }
            this.showList.forEach(item => {
              item.sortValue = 'asc'
              item.value1 = false
              // 基础数据
              if(item.showType == '1' && item.isSearch == '0') {
                if(item.prop == 'userName') { // 人员
                  this.$http.get(url.getPostAndUserList).then(res => {
                    if(res.data.code == 200) {
                      this.postAndUserList = res.data.data
                    }
                  })
                }
              }
              if(item.showType == '2' && (item.cbussDime.editType == '12' || item.cbussDime.editType == '13')) {
                this.$http.get(url.getResTypesAndList, {params: {
                  cbRestypeIds: item.cbussDime.resourceType
                }}).then(res => {
                  if(res.data.code == 200) {
                    item.cbussDime.options = res.data.data.list
                  }
                })
              }
            })
          }
        })
      },
      getChange(value) {
      },
      handleList(list) {
        // console.log(list.length);
        // console.log(list);
        // if(list.length) {
        //   list.forEach(item => {
        //     if(item.showType != '1' && (item.cbussDime.editType == '12' || item.cbussDime.editType == '13')) {
        //       delete item.cbussDime.options
        //     }
        //   })

        // // }else {
        // }
        return list
      },
      // 发送请求，获取项目列表
      getList() {
        // console.log(JSON.stringify(this.showList));
        this.loading = true
        this.list = []
        // this.total = 0
        let list1 = []
        let list2 = []
        if(this.showList.length) {
          this.showList.forEach(item => {
            if(item.showValue) {
              // list1.push(item)
              list1.unshift(Object.assign({}, item))
            }
            if(item.value1) {
              // list2.push(item)
              list2.unshift(Object.assign({}, item))
            }
          })
        }
        list1 = this.handleList(list1)
        list2 = this.handleList(list2)
        // list2 = list2.push(111)
        // console.log(list2);
        list2.some(item => item.prop == 'itemDate') ? true : list2.push({showType: '1', prop: 'itemDate', sortValue: 'desc'})
        // console.log(list1);
        // console.log(list2);
        // this.$http.post(url.getItemList4treequery, {
        //     pageNum: this.pageNum,
        //     pageSize: this.pageSize,
        //     name: this.siftForm.name,
        //     cfFlowItemId: this.siftForm.type,
        //     dimeParams: list1.length ? JSON.stringify(list1) : '',
        //     sortParams: list2.length ? JSON.stringify(list2) : '',
        //     nameSort: this.nameCheck ? this.nameSort : '',
        //     cbussDimeList
        //   }

        this.$http.post(url.getItemList, qs.stringify({
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            name: this.siftForm.name,
            cfFlowItemId: this.siftForm.type,
            dimeParams: list1.length ? JSON.stringify(list1) : '',
            sortParams: list2.length ? JSON.stringify(list2) : '',
            nameSort: this.nameCheck ? this.nameSort : '',
            // cbussDimeList
          })

        ).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.list = res.data.data.list
            // this.list.forEach(item => {
            //   item.showList = this.handleFormList(item.showList)
            // })
            this.$nextTick(() => {
              if(this.showList.length) {
                this.$refs.mainTable.doLayout()
              }
            });
            this.total = res.data.data.total;
          }
        })
        // 菜单类型下拉
        if(!this.dropDown.itemType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_item_type' }}).then(res => {
            this.$store.commit('setItemType', res.data.data)
          })
        }
        // 发布下拉
        if(!this.dropDown.documentstatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_document_status' }}).then(res => {
            this.$store.commit('setDocumentstatus', res.data.data)
          })
        }
      },
      getCflowChange(item, index) {
        // console.log(item);
        this.titleHeader = item.showName
        this.isMine = false
        this.isActive = index
        this.pageNum = 1
        if(this.tab.showCard) {
          this.pageSize = 8
        }else {
          this.pageSize = 10
        }
        this.siftForm.type = item.cfFlowItemId
        this.dutys = item.dutys
        // for(let key in this.dutys) {
        //   this.dutys[key] = item.dutys[key]
        // }
        // console.log(item);
        this.showList = []
        this.getCfFlowDealList(item.cfFlowItemId)
        this.getCbussShowList(item.cfFlowItemId)
        this.getList()
      },
      // 查询按钮
      inquire() {
        this.pageNum = 1
        if(this.isMine) {
          if(this.isActive  == (this.cfflowitem.length + 1)) {
            this.getCollect()
          }else {
            this.getShare()
          }
        }else {
          this.getList()
        }
      },
      // 重置按钮
      reset() {
        this.pageNum = 1
        // for(let key in this.siftForm) {
        //   this.siftForm[key] = ''
        // }
        this.siftForm.name = ''
        this.siftForm.time = ''
        // this.siftForm.type = this.cfflowitem[0].cfFlowItemId
        this.cfflowitem.forEach(item => {
          if(item.cfFlowItemId == this.siftForm.type) {
            this.cflowItem = item
          }
        })
        if(this.$refs.inputNumber) {
          this.$refs.inputNumber.forEach(item => {
            item.userInputForm = ''
            item.userInputTo = ''
          })
        }
        // this.$refs.inputNumber
        this.showList.forEach(item => {
          // if(item.showType == '2') {
            delete item.showValue
            // this.$emit('resetRange')
          // }
        })
        this.cbussDimeTree.forEach((item, index) => {
          item.value = ''
          this.$refs['tree' + (index + 1)][0].setCurrentKey(null);
        })
        if(this.isMine) {
          if(this.isActive  == (this.cfflowitem.length + 1)) {
            this.getCollect()
          }else {
            this.getShare()
          }
        }else {
          this.getList()
        }
      },
      // 新增按钮
      handleAdd() {
        this.title = '新增项目'
        this.dialogFormVisible = true;
        this.selectDisabled = false
        this.add = true
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        // 初始化表单内容
        for(let key in this.ruleForm) {
          // console.log(key);
          if(key == 'itemDate') {
            this.ruleForm[key] = this.parseTime(Date.now(), '{y}-{m}-{d}')
          }else if(key == 'documentstatus'){
            this.ruleForm[key] = '1'
          }else if(key == 'cfFlowItemId'){
            this.ruleForm[key] = this.siftForm.type
          }else if(key == 'itemType'){
            this.ruleForm[key] = '0'
          }else if(key == 'dataSourceConfig'){
            this.ruleForm[key] = []
          }else {
            this.ruleForm[key] = ''
          }
        }
        this.getFormList(this.siftForm.type)
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            let dimes = []
            if(this.formList) {
              dimes = this.handleDimes(this.formList)
            }
            for(let key in this.ruleForm) {
              if(key == 'dataSourceConfig') {
                delete this.ruleForm[key]
              }
            }
            // 新增项目
            if(!this.ruleForm.ofoItemId) {
              this.$http.post(url.addItem, qs.stringify({...this.ruleForm, ...{dimes: JSON.stringify(dimes)}})).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.getList()
                }
              })
            }else { // 编辑项目
              this.$http.post(url.editItem, qs.stringify({...this.ruleForm, ...{dimes: JSON.stringify(dimes)}})).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  if(this.titleHeader == '我的收藏') {
                    this.getCollect()
                  }else {
                    this.getList()
                  }

                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleSelect(rows) {
        this.ids = rows.map(item => item.ofoItemId)
        this.selected = rows.length === 1
      },
      handleEdit(val) {
        const id = val.ofoItemId || this.ids[0]
        this.dialogFormVisible = true
        this.title = '编辑项目'
        this.selectDisabled = true
        this.$http.get(url.getItem, {params: {ofoItemId: id}}).then(res => {
          if(res.data.code === 200) {
            let data = res.data.data
            for(let key in data) {
              if(key === 'cfflowitemId') {
                this.ruleForm.cfFlowItemId = data[key]
              }else if(key === 'itemDate') {
                this.ruleForm[key] = this.parseTime(data[key], '{y}-{m}-{d}')
              }else if(key === 'dimeList') {
                this.formList = this.handleFormList(data.dimeList)
                this.ruleForm.dataSourceConfig = this.formList
              }else {
                this.ruleForm[key] = data[key]
              }
            }
          }
        })
      },
      handleDel(val) {
        // const id = val.ofoItemId
        const id = val.ofoItemId || this.ids[0]
        this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeItem, qs.stringify({ofoItemId: id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleDetail(val) {
        // console.log(val);
        this.$store.commit('setJumpData', {})
        if(this.isActive  == (this.cfflowitem.length + 2)) {
          this.$router.push({path: '/project/' + val.ofoItemId, query: {isShare: '0'}});
          let obj = {
            path: "/project/" + val.ofoItemId,
            name: "proDetail",
            label: "项目详情",
            ofoItemId: val.ofoItemId,
            children: []
          };
          this.$store.commit("selectMenu", obj);
        }else {
          this.$router.push('/project/' + val.ofoItemId);
          let obj = {
            path: "/project/" + val.ofoItemId,
            name: "proDetail",
            label: "项目详情",
            ofoItemId: val.ofoItemId,
            children: []
          };
          this.$store.commit("selectMenu", obj);
        }

      },
      // 表格形式和卡片形式切换
      change() {
        this.$store.commit('setStates')
        this.pageNum = 1
        this.nameCheck = false
        this.nameSort = 'asc'
        this.showList.forEach(item => {
          item.value1 = false
          item.sortValue = 'asc'
        })
        if(this.tab.showCard) {
          this.pageSize = 8
        }else {
          this.pageSize = 10
        }
        if(this.isActive  == (this.cfflowitem.length + 1)) {
          this.getCollect()
        }else if(this.isActive  == (this.cfflowitem.length + 2)) {
          this.getShare()
        }else {
          this.getList()
        }

      },
      // 表格形式的序号
      indexMethod(index) {
        let id = index + 1 + (this.pageNum - 1) * this.pageSize
        return id < 10 ? ('0' + parseInt(id)) : id;
      },

      handleSizeChange(val) {
        this.pageSize = val;
        this.pageNum = 1
        if(this.isActive  == (this.cfflowitem.length + 1)) {
          this.getCollect()
        }else if(this.isActive  == (this.cfflowitem.length + 2)) {
          this.getShare()
        }else {
          this.getList()
        }
      },
      handleCurrentChange(val) {
        // console.log(val);
        this.key = new Date().getTime()
        this.pageNum = val;
        if(this.isActive  == (this.cfflowitem.length + 1)) {
          this.getCollect()
        }else if(this.isActive  == (this.cfflowitem.length + 2)) {
          this.getShare()
        }else {
          this.getList()
        }
      },

      handleSizeChange1(val) {
        this.queryParams.pageSize = val;
        this.queryParams.pageNum = 1
        this.getWorkListBycf()
      },
      handleCurrentChange1(val) {
        this.key = new Date().getTime()
        this.queryParams.pageNum = val;
        this.getWorkListBycf()
      },
      handleCollection(item, type) {
        if(type == 's') {
          this.dialogShareVisible = true
          this.form.obussId = item.ofoItemId
          if(!this.userList.length) {
            // 获取关联人员列表
            this.$http.get(url.getPostAndUserList).then(res => {
              if(res.data.code == 200) {
                this.userList = res.data.data
              }
            })
          }

        }else {
          this.$http.post(url.shareCollect, qs.stringify({
            bussType: 'c',
            flowType: 'i',
            obussId: item.ofoItemId
          })).then(res => {
            if(res.data.code == 200) {
              this.$message.success('收藏成功')
            }
          })
        }
      },
      submitShare() {
        this.$http.post(url.shareCollect, qs.stringify({
          bussType: 's',
          flowType: 'i',
          obussId: this.form.obussId,
          toUserIds: this.form.toUserIds.toString()
        })).then(res => {
          if(res.data.code == 200) {
            this.$message.success('分享成功')
            this.dialogShareVisible = false
          }
        })
      },
      getFunction(type) {
        this.pageNum = 1
        if(this.tab.showCard) {
          this.pageSize = 8
        }else {
          this.pageSize = 10
        }
        this.showList = []
        this.dutys = {}
        // for(let key in this.dutys) {
        //   this.dutys[key] = '1'
        // }
        this.isMine = true
        if(type == 'c') {
          this.isActive = this.cfflowitem.length + 1
          this.titleHeader = '我的收藏'
          // this.isMine = false
          this.getCollect()
        }else {
          this.isActive = this.cfflowitem.length + 2
          this.titleHeader = '分享给我'
          this.getShare()
        }
      },
      getCollect() {
        this.loading = true
        this.list = []
        this.total = 0
        this.$http.get(url.getCollectList, {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            name: this.siftForm.name,
            flowType: 'i'
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.list = res.data.data.list
            this.total = res.data.data.total;
          }
        })
      },
      getShare() {
        this.loading = true
        this.list = []
        this.total = 0
        this.$http.get(url.getShareList, {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            name: this.siftForm.name,
            flowType: 'i'
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.list = res.data.data.list
            this.total = res.data.data.total;
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.project {
  display: flex;
  overflow: hidden;
  height: 100%;
  align-items: center;
  .left {
    width: 160px;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0px rgb(208, 208, 208);
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    .item {
      padding: 0 15px 0 6px;
      font-size: 13px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      color: #282828;
      margin: 8px;
      /* color: #177BE6;
      background-color: #E5F3FF; */
      padding: 7px;
      cursor: pointer;
    }
    .item:hover {
      background-color: #E5F3FF;
    }
    .isActive {
      color: #177BE6;
      background-color: #E5F3FF;
    }
  }
  .middle {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #79bbff;
  }
  .middle:hover {
    color: #0c75e5;
  }
  .right {
    padding: 0 16px 16px 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    height: calc(100% - 32px);

    .sift {
      width: 100%;
      background-color: #fff;
      box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
      border-radius: 11px;
      padding: 16px;
      box-sizing: border-box;
      margin-bottom: 16px;
      position: relative;

    }
    .list {
      /* overflow: hidden; */
      width: 100%;
      background-color: #fff;
      box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
      border-radius: 11px;
      padding: 16px;
      box-sizing: border-box;
      flex: 1;
      .contain1 {
        display: flex;
        .left1 {
          //width: 180px;
          overflow: auto;
          margin-right: 10px;
          ::v-deep .el-collapse-item__content {
            padding-bottom: 8px;
          }
          ::v-deep .el-collapse-item__header {
            height: 30px;
            line-height: 30px;
          }
        }
        .right1 {
          flex: 1;
          overflow: auto;
        }
      }
      .contain-left {
        padding-right: 10px;
      }
      .contain-right {
        flex: 1;
        min-width: 200px;
        .el-card {
          background: #FFFFFF;
          box-shadow: 0px 0px 3px 0px rgba(208,208,208,1);
          border-radius: 9px;
          /* width: 100%; */
          width: calc(20% - 16px);
          /* height: 93px; */
          position: relative;
          padding: 0;
          margin-right: 16px;
          float: left;
          margin-bottom: 16px;
          box-sizing: border-box;
          .edit, .delete {
            position: absolute;
            right: 27px;
            top: 8px;
            border: 0;
            padding: 0;
            color: #8E8E8E;
          }
          .delete {
            right: 8px;
          }
          .iconfont {
            position: absolute;
            right: -5px;
            bottom: -5px;
            color: #0C75E5;
            font-size: 45px;
          }
          .status {
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
            height: 21px;
            font-size: 12px;
            line-height: 21px;
            border-radius: 0px 9px 0px 9px;
            display: flex;
            padding: 2px 5px;
            /* flex-wrap: nowrap; */
          }
          .code {
            position: absolute;
            left: 0;
            top: 0;
            border: 0;
            padding: 0;
            color: #282828;
            font-size: 12px;
            background-color: #F2F2F2;
            padding: 3px 5px;
            border-radius: 9px 0px 9px 0px;
            max-width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .text {
            font-size: 13px;
            line-height: 22px;
            padding: 0 10px;
            /* height: 22px; */
            display: flex;
            .text-left {
              /* display: inline-block;
              width: 45px; */
              color: #8E8E8E;
              letter-spacing: 4px;
            }
            .text-right {
              /* display: inline-block;
              width: calc(100% - 45px); */
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .header {
            margin-top: 24px;
          }
        }
      }
      .hidden {
        // float: right;
        padding: 0;
        // border: 0;
        /* position: absolute;
        bottom: 16px;
        right: 16px; */
        /* border-radius: 50%; */
        font-size: 16px;
        border: 0;
        margin-bottom: 0 !important;
      }
      .header {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        .tab {
          padding: 0;
          border: 0;
          font-size: 16px;
          /* margin-bottom: 16px; */
        }
      }
      ::v-deep .el-table--border td {
        border-right: 0;
      }
      .item {
        overflow: hidden;
        margin-bottom: 39px;
        .el-card {
          width: calc(25% - 16px);
          float: left;
          margin-bottom: 16px;
          margin-right: 16px;
          position: relative;
          cursor: pointer;
          ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: #fff;
          }

          // 定义滚动条轨道 内阴影
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            background-color: #fff;
          }

          // 定义滑块 内阴影
          ::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            background-color: #ddd;
            border-radius: 10px;
          }
          ::v-deep .el-card__header {
            padding: 10px 10px 10px 20px;
          }
          .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text {
              /* display: inline-block; */
              /* width: 80%; */
              height: 40px;
              line-height: 40px;
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .line {
            font-size: 13px;
            margin-bottom: 6px;
            width: 95%;
            display: flex;
            margin-left: 8px;
            box-sizing: border-box;
            .line-left {
              margin-right: 5px;
            }
            .line-right {
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

            }
          }
          .last-line {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
          .view {
            padding: 6px 10px;
            font-size: 12px;
            background-color: #0c75e5;
            color: #fff;
            border: 0;
          }
        }
        .noright {
          margin-right: 0;
        }
      }
    }
    .title {
      height: 16px;
      font-size: 14px;
      font-family: STHeitiSC-Medium, STHeitiSC;
      font-weight: 500;
      color: #282828;
      line-height: 16px;
      padding-left: 8px;
      border-left: #0c75e5 2px solid;
      margin-bottom: 16px;
      font-weight: bolder;
      margin-right: 16px;
    }
  }
}
::v-deep .el-dialog__body {
  /* padding: 30px 0 30px 20px; */
}
</style>
