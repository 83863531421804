<template>
  <div>
    <el-form :inline="true" :model="siftForm" class="demo-form-inline" size="small" @submit.native.prevent v-show="isShowFormInline">
      <el-form-item>
        <span slot="label">
          <strong>名称</strong>
        </span>
        <el-input
          v-model="siftForm.name"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="inquire"
          @clear="inquire"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
      <!-- <div style="float:right"> -->
        <el-tooltip class="item" effect="dark" :content="isShowFormInline ? '隐藏搜索' : '显示搜索'" placement="top-end">
          <el-button style="padding: 6px;border-radius: 50%;float:right" icon="el-icon-search" @click="isShowFormInline = !isShowFormInline"></el-button>
        </el-tooltip>
      <!-- </div> -->
    <div style="width:100%;overflow: hidden">
      <!-- <div style="display:flex;justify-content:flex-end"> -->
      <div style="text-align:center;color:#999" v-if="!workList.length">暂无数据</div>
      <el-card class="box-card" v-for="(item,index) in workList" :key="index" @click.native="handleView (item)" :class="(index + 1) % 5 == 0 ? 'noright' : ''">
        <span :style="item.documentstatus == '0' ? 'color: #0063FB;background-color: #E5F3FF' : (item.documentstatus == '1' ? 'color: #13ce66;background-color: #e7faf0' : (item.documentstatus == '2' ? 'color: #ffba00;background-color: #fff8e6' : 'color: #ff4949;background-color: #ffeded'))" class="status">
          <DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/> 
        </span>
        <span class="code">{{item.code}}</span>
        <div class="text header">
          <span class="text-left">名称:</span>
          <el-tooltip popper-class="atooltip" 
            effect="dark" 
            :content="item.name" 
            placement="top-start">
            <span class="text-right">{{item.name}}</span>
          </el-tooltip>
        </div>
        <div class="text">
          <span class="text-left">日期:</span>
          <span class="text-right">{{item.workDate | filter_handleDate}}</span>
        </div>
        <template v-if="item.ofoWorkShowList">
          <div class="text" v-for="(val, id) in item.ofoWorkShowList" :key="id">
            <span class="text-left">{{val.name + ':'}}</span>
            <span class="text-right">
              <template v-if="val.type == 'b'">
                <template v-if="val.column == 'ptotal'">
                  {{item[val.column] | comdify}}
                </template>
                <template v-else>
                  {{item[val.column]}}
                </template>
              </template>
              <template v-else>{{item.workShowMap[val.column]}}</template>
            </span>
          </div>
        </template>
        <template v-else>
          <div class="text">
            <span class="text-left">数量:</span>
            <span class="text-right">{{item.quantity}}</span>
          </div>
          <div class="text">
            <span class="text-left">金额:</span>
            <span class="text-right">{{item.ptotal | comdify}}</span>
          </div>
        </template>
      </el-card>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      background
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="->, prev, pager, next, sizes, jumper, total"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
  export default {
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown
      }),
    },
    components: {
      DictTag,
    },
    data() {
      return {
        workList: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        loading: true,
        siftForm: {
          name: ''
        },
        isShowFormInline: false
      }
    },
    created () {
      // 发布下拉
      if(!this.dropDown.documentstatus) {
        this.$http.get(url.getDataList, {params: {type: 'buss_document_status' }}).then(res => {
          this.$store.commit('setDocumentstatus', res.data.data)
        })
      }
      this.getList()
    },
    methods: {
      getList() {
        this.$http.get(url.getCollectList, {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            name: this.siftForm.name,
            flowType: 'w'
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.workList = res.data.data.list
            this.total = res.data.data.total;
          } 
        })
      },
      handleView(item) {
        // console.log(item);
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: item.ofoWorkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            // this.data = res.data.data
            
            // this.$eventBus.$emit('toWorkDetail', res.data.data)
            this.$store.commit("setJumpData", {...res.data.data, isApp: false})
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            this.$store.commit('setSecondMenu', [])
            // this.menuList.forEach(el => {
            //   if(obj.path.indexOf(el.path) != -1) {
            //     this.$store.commit('setSecondMenu', el.children)
            //   }
            // });
            this.$store.commit("selectMenu", obj);
          }
          
        })
      },
      inquire() {
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      reset() {
        this.siftForm.name = ''
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.pageNum = 1
        this.loading = true
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.loading = true
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
.el-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 3px 0px rgba(208,208,208,1);
  border-radius: 9px;
  width: calc(20% - 16px);
  /* height: 93px; */
  position: relative;
  padding: 0;
  margin-right: 16px;
  float: left;
  margin-bottom: 16px;
  box-sizing: border-box;
  .edit, .delete {
    position: absolute;
    right: 27px;
    top: 8px;
    border: 0;
    padding: 0;
    color: #8E8E8E;
  }
  .delete {
    right: 8px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px; 
    /* background-color: #fff; */
  }

  /* 定义滑块 内阴影 */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  ::v-deep .el-card__body {
    padding: 0;
    height: 133px;
    overflow: auto;
    .status {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      height: 21px;
      font-size: 12px;
      line-height: 21px;
      border-radius: 0px 9px 0px 9px;
      display: flex;
      padding: 2px 5px;
      /* flex-wrap: nowrap; */
    }
    .code {
      position: absolute;
      left: 0; 
      top: 0;
      border: 0;
      padding: 0;
      color: #282828;
      font-size: 12px;
      background-color: #F2F2F2;
      padding: 3px 5px;
      border-radius: 9px 0px 9px 0px;
    }
    .text {
      font-size: 13px;
      line-height: 22px;
      padding: 0 10px;
      /* height: 22px; */
      display: flex;
      .text-left {
        /* display: inline-block; */
        /* width: 45px; */
        color: #8E8E8E;
        letter-spacing: 4px;
      }
      .text-right {
        /* display: inline-block; */
        /* width: calc(100% - 45px); */
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .header {
      margin-top: 24px;
    }
    /* .footer {
      display: flex;
      .item-left {
        margin-right: 30px;
      }
    } */
    .view {
      position: absolute;
      bottom: -10px;
      right: 10px;
      padding: 0 6px;
      height: 21px;
      background: #E5F3FF;
      border-radius: 3px;
      border: 1px solid #0C75E5;
      font-size: 11px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      color: #0C75E5;
      line-height: 21px;
      margin-bottom: 16px;
      margin-left: calc(50% - 31px);
    }
  }
}
</style>