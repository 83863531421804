<template>
  <div class="resource">
    <div class="sift">
      <div class="title">人员查询</div>
      <div class="inquire">
        <el-form :model="formInline" class="demo-form-inline" size="small" :inline="true">
          <el-form-item>
            <strong slot="label">部门</strong>
            
            <el-select v-model="formInline.cbowhId" placeholder="请选择部门" clearable filterable @change="inquire">
              <el-option v-for="(item, index) in deptList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
        </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">人员</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入人员"
              clearable 
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="detail">
      <div class="title">人员列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :height="tableHeight"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <!-- <el-table-column prop="ofOwhResourceId" label="序号"></el-table-column> -->
        <el-table-column prop="cbowhName" label="部门">
          <!-- <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.restypeName}}</a>
          </template> -->
        </el-table-column>
        <el-table-column prop="ofrthingName" label="人员">
          <!-- <template slot-scope="scope">
            <DictTag :options="dropDown.kind" :value="scope.row.kind"/>
          </template> -->
        </el-table-column>
        <!-- <el-table-column prop="quantity" label="数量" align="right">
        </el-table-column> -->
        <!-- <el-table-column prop="price" label="成本单价" align="right">
          <template slot-scope="scope">
            {{scope.row.price | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="linetotal" label="成本合计" align="right">
          <template slot-scope="scope">
            {{scope.row.linetotal | comdify}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column 
          label="操作"
          fixed="right"
          width="180">
          <template slot-scope="scope">
            <el-button
              size="mini" icon="el-icon-view" type="text"
              @click="handleDetail(scope.row)">详情</el-button>
            <el-button
              size="mini" icon="el-icon-edit" type="text"
              @click="handleEdit(scope.row)">编辑</el-button>
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="资源名称" prop="restypeName">
          <el-input v-model="ruleForm.restypeName" placeholder="请输入资源名称"></el-input>
        </el-form-item>
        <el-form-item label="种类" prop="kind">
          <el-select v-model="ruleForm.kind" placeholder="请选择资源种类" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型" prop="kindDealType">
          <el-select v-model="ruleForm.kindDealType" placeholder="请选择资源交易类型" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kindDealType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    DictTag,
    Treeselect
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        name: "",
        cbowhId: null,
      },
      tableData: [],
      dialogFormVisible: false,
      // 弹出层标题
      title: "",
      ruleForm: {
        restypeName: "",
        kind: "",
        kindDealType: "",
      },
      rules: {
        restypeName: [{ required: true, message: "请输入资源名称", trigger: "submit" }],
        kind: [{ required: true, message: "请选择资源种类", trigger: "submit" }],
        kindDealType: [{ required: true, message: "请选择资源交易类型", trigger: "submit" }]
      },
      // 编辑按钮不可点
      edit: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      ids: [],
      isLoading: false,
      deptList: [],
      defaultProps: {
        /** 唯一标识 **/
        value: 'Id',
        /** 标签显示 **/
        label: 'label',
        /** 子级 **/
        children: 'children' },
      tableHeight: 0
    };
  },
  created() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 245;
    })
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.tableHeight = window.innerHeight - 245;
      })()
    }
    this.getList();
    this.init()
  },
  methods: {
    init() {
      this.$http.get(url.getDeptList, {params: {
        // cbRestypeId: this.formInline.cbRestypeId,
        // name: this.formInline.name
      }}).then(res => {
        if(res.data.code == 200) {
          // const data = {id: 0, label: '顶级节点', children: [] };
          // data.children = this.getTree(res.data.data);
          // this.cbOwhTree.push(data)
          this.deptList = res.data.data
        }
      })
    },
    // 查询按钮
    inquire() {
      this.loading = true
      this.pageNum = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.loading = true
      this.pageNum = 1
      this.getList()
    },
    // 表格的选中框事件
    handleSelectionChange(rows) {
      this.ids = rows.map(item => item.cbRestypeId)
      this.edit = rows.length === 1
    },
    // 获取字典数据
    getList() {
      // 获取列表数据
      this.$http.get(url.getOwhUserList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          cbowhId: this.formInline.cbowhId == '0' ? '' : this.formInline.cbowhId,
          ofrthingName: this.formInline.name
        }
      }).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          // 新增项目
          if(this.title === "新增资源") {
            this.$http.post(url.addCbResType, qs.stringify(this.ruleForm)).then(res => {
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.pageNum = 1
                this.loading = true
                this.getList()
              }
            })
          }else { // 编辑资源
            this.$http.post(url.editCbResType, qs.stringify(this.ruleForm)).then(res => {
              // console.log(res);
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.pageNum = 1
                this.loading = true
                this.getList()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 新增项目按钮
    handleAdd() {
      this.title = "新增资源"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        // 清除表单校验效果
        this.$refs.ruleForm.clearValidate();
      })
      // 初始化表单内容
      for(let key in this.ruleForm) {
        this.ruleForm[key] = ""
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
    // 编辑按钮
    handleEdit(row) {
      if(this.$refs.ruleForm) {
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
      }
      
      const id = row.cbRestypeId || this.ids[0]
      this.dialogFormVisible = true
      this.title = '编辑资源'
      this.$http.get(url.getResType, {params: {cbRestypeId: id}}).then(res => {
        if(res.data.code === 200) {
          this.ruleForm = res.data.data
        }
      })
    },
    // 删除按钮
    handleDel(row) {
      this.$confirm('此操作将永久删除该资源类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeCbResType, qs.stringify({cbRestypeId: row.cbRestypeId})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageNum = 1
            this.loading = true
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 查看详情按钮
    handleDetail(row) {
      // let cbRestypeId = row.cbRestypeId
      // this.$router.push("/resource/kind/" + cbRestypeId)
      // let obj = {
      //   path: "/resource/kind/" + cbRestypeId,
      //   name: "kindDetail",
      //   label: "资源详情",
      //   cbRestypeId: cbRestypeId,
      //   children: []
      // };
      // this.$store.commit("selectMenu", obj);
    },
  },
  watch: {
    // formInline: {
    //   handler(newValue, oldValue) { 
    //     // console.log(newValue);
    //     if(newValue.cbowhId != '0') {
    //       // console.log(11111);
    //       this.pageNum = 1
    //       this.loading = true
    //       this.getList()
    //     }
    //   },
    //   deep: true
    // }
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">
</style>