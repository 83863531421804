<template>
  <div>
    <!-- <el-button type="primary" style="margin-bottom:16px" plain @click="addList"  v-show="!disabled" size="small">增行</el-button> -->
    <el-button plain type="primary" size="small" v-if="!disabled && ofoFlowWork.isMultiple == '0'" @click="selectMore(false)" style="margin-bottom:16px">多选</el-button>
    <el-button size="small" type="info" plain @click="copyFromAdd" v-if="(ofoFlowWork.beforework || ofoFlowWork.cfbeforework) && !disabled" style="margin-bottom: 16px">复制从</el-button>
    <el-button plain type="danger" size="small" v-if="!disabled" @click="deleteMore" style="margin-bottom: 16px" :disabled="!selectedId.length">删除已选</el-button>
    <el-switch v-model="isTax" inactive-text="含税" style="float: right" v-show="!disabled && (ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5')" ></el-switch>
    <!-- 新增时可编辑的表格 -->
    <template v-if="!disabled">
      <!-- <el-table
        :data="thingsList"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}" @selection-change="handleRowChange"
        :key="Math.random()"  > -->
        <el-table
        :data="thingsList"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}" @selection-change="handleRowChange"
        key="table1"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          label="行号"
          width="50">
        </el-table-column>
        <!-- 位置 -->
        <el-table-column
          prop="cbowhId"
          label="位置"
          width="200"
          v-if="ofoFlowWork.chooseOwh == '0'">
          <template slot="header">
            <a class="vatgroup" @click="changeVatgroup('all', 'cbowhId')">位置
              <i class="el-icon-edit-outline"></i>
            </a>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="changeVatgroup('single', 'cbowhId', scope.row.cbowhId, scope.$index)">
              {{scope.row.cbowhName}}
            </a>
            
          </template>
        </el-table-column>
        <!-- 货物或应税劳务、服务名称 -->
        <el-table-column
          prop="ofrresourceId"
          :label="ofoFlowWork.thingtypeName + '（明细）'"
          min-width="220">
          <template slot="header">
            {{ofoFlowWork.thingtypeName + '（明细）'}}
            <el-button type="text" @click="addThing" v-if="!disabled && isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" v-if="ofoFlowWork.isMultiple == '0'" @click="selectMore(true, scope.row, scope.$index)">
              {{scope.row.ofrresourceName}} - {{scope.row.ofrthingName}}
            </a>
            <a class="vatgroup" v-else>
              {{scope.row.ofrresourceName}} - {{scope.row.ofrthingName}}
            </a>
          </template>
        </el-table-column>
        <!-- 起始数量 -->
        <el-table-column
          prop="beginValue"
          label="起始数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.beginValue" clearable @blur="handleCount1(scope.row)" class="right-input"/>
          </template>
        </el-table-column>
        
        <!-- 终止数量 -->
        <el-table-column
          prop="endValue"
          label="终止数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.endValue" clearable @blur="handleCount2(scope.row)" class="right-input"/>
          </template>
        </el-table-column>
        <!-- 数量期间 -->
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">{{scope.row.quantity}}
          </template>
        </el-table-column>
        <!-- 数量 -->
        <el-table-column
          prop="workConfigList1"
          min-width="150"
          v-for="(item, index) in workConfig.workConfigList1"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
          <template slot="header">
            <el-popover
              placement="right"
              width="150"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType != '2'">
                <template slot="append" v-if="item.showType == '1'">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList1')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.workConfigList1[index].configValue"
              clearable
              class="right-input"
              @input="computeSl(scope.row, index, 'workConfigList1')"
              v-if="scope.row.workConfigList1[index] && scope.row.workConfigList1[index].showType != '2'"
            >
              <template slot="append" v-if="scope.row.workConfigList1[index].showType == '1'">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList1[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList1[index] && scope.row.workConfigList1[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList1[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.quantity" @input="compute1(scope.row)" clearable class="right-input"/>
          </template>
        </el-table-column>
        <!-- 开始时间 -->
        <el-table-column
          prop="beginValue"
          label="开始时间"
          width="250"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.beginValue"
              type="datetime"
              placeholder="选择开始时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              @change="handleChangeSj1(scope.row)">
            </el-date-picker>
          </template>
        </el-table-column>
        <!-- 结束时间 -->
        <el-table-column
          prop="endValue"
          label="结束时间"
          width="250"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.endValue"
              type="datetime"
              placeholder="选择结束时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              @change="handleChangeSj2(scope.row)">
            </el-date-picker>
          </template>
        </el-table-column>
        <!-- 时间期间 -->
        <el-table-column
          prop="quantity"
          label="时间期间"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">{{scope.row.quantity | filter_handleTimeRange}}
          </template>
        </el-table-column>
        <!-- 单价 -->
        <el-table-column
          prop="workConfigList2"
          min-width="150"
          v-for="(item, index) in workConfig.workConfigList2"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot="header">
            <el-popover
              placement="right"
              width="150"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType != '2'">
                <template slot="append" v-if="item.showType == '1'">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList2')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.workConfigList2[index].configValue" clearable class="right-input" @input="computeSl(scope.row, index, 'workConfigList2')" v-if="scope.row.workConfigList2[index] && scope.row.workConfigList2[index].showType != '2'">
              <template slot="append" v-if="scope.row.workConfigList2[index].showType == '1'">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList2[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList2[index] && scope.row.workConfigList2[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList2[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          :label="isTax ? '单价（含税）' : '单价（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" @input="compute2(scope.row)" class="right-input" clearable @blur="compute22(scope.row)" @focus="compute222(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 金额 -->
        <el-table-column
          prop="workConfigList3"
          min-width="150"
          v-for="(item, index) in workConfig.workConfigList3"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '4' || ofoFlowWork.costmanagetype == '5'">
          <template slot="header">
            <el-popover
              placement="right"
              width="150"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType != '2'">
                <template slot="append" v-if="item.showType == '1'">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList3')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.workConfigList3[index].configValue" clearable class="right-input" @input="computeSl(scope.row, index, 'workConfigList3')" v-if="scope.row.workConfigList3[index] && scope.row.workConfigList3[index].showType != '2'">
              <template slot="append" v-if="scope.row.workConfigList3[index].showType == '1'">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList3[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList3[index] && scope.row.workConfigList3[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList3[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="total"
          :label="isTax ? '金额（含税）' : '金额（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.total" clearable @input="compute3(scope.row)" class="right-input" @blur="compute33(scope.row)" @focus="compute333(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 费用金额 -->
        <el-table-column
          prop="total"
          label="费用金额"
          min-width="200"
          v-if="ofoFlowWork.costmanagetype == '4'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.total" clearable class="right-input" @blur="compute33(scope.row)" @focus="compute333(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 税率 -->
        <el-table-column
          prop="vatgroup"
          label="税率"
          min-width="80"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot="header">
            <a class="vatgroup" @click="changeVatgroup('all', 'vatgroup')">税率
              <i class="el-icon-edit-outline"></i>
            </a>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="changeVatgroup('single', 'vatgroup', scope.row.vatgroup, scope.$index)">
              <DictTag :options="dropDown.invoiceVat" :value="scope.row.vatgroup"/>
            </a>
            
          </template>
          <!-- <template slot-scope="scope">
            <el-select v-model="scope.row.vatgroup" @change="compute4(scope.row)">
              <el-option 
              v-for="item in dropDown.invoiceVat"
              :label="item.dictLabel"
              :value="item.dictValue"
              :key="item.dictValue"
              ></el-option>
            </el-select>
          </template> -->
        </el-table-column>
        <!-- 税额 -->
        <el-table-column
          prop="totalVat"
          label="税额"
          min-width="100"
          align="right"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <!-- <template slot-scope="scope">
            <el-input v-model="scope.row.totalVat" disabled class="right-input"/>
          </template> -->
        </el-table-column>
        <!-- 票种 -->
        <el-table-column
          prop="invoicetype"
          label="票种"
          min-width="190"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot="header">
            <a class="vatgroup" @click="changeVatgroup('all', 'invoicetype')">票种
              <i class="el-icon-edit-outline"></i>
            </a>
          </template>
          <template slot-scope="scope">
            <a class="vatgroup" @click="changeVatgroup('single', 'invoicetype', scope.row.invoicetype, scope.$index)">
              <DictTag :options="dropDown.invoiceType" :value="scope.row.invoicetype"/>
            </a>
          </template>
          <!-- <template slot-scope="scope">
            <el-select v-model="scope.row.invoicetype" clearable>
              <el-option 
              v-for="item in dropDown.invoiceType"
              :label="item.dictLabel"
              :value="item.dictValue"
              :key="item.dictValue"
              ></el-option>
            </el-select>
          </template> -->
        </el-table-column>
        <!-- 成本单价 -->
        <el-table-column
          prop="cost"
          :label="isTax ? '成本单价（含税）' : '成本单价（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.cost" @input="compute5(scope.row)" class="right-input" clearable @blur="compute55(scope.row)" @focus="compute555(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 成本金额 -->
        <el-table-column
          prop="costTotal"
          :label="isTax ? '成本金额（含税）' : '成本金额（不含税）'"
          min-width="150"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.costTotal" @input="compute6(scope.row)" clearable class="right-input" @blur="compute66(scope.row)" @focus="compute666(scope.row)"/>
          </template>
        </el-table-column>
        <!-- 成本税额 -->
        <el-table-column
          prop="costTotalVat"
          label="成本税额"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">
            <el-input v-model="scope.row.costTotalVat" disabled class="right-input"/>
          </template>
        </el-table-column>
        <!-- 自定义 -->
        <el-table-column
          prop="workConfigList4"
          min-width="160"
          v-for="(item, index) in workConfig.workConfigList4"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name">
          <template slot="header">
            <el-popover
              placement="right"
              width="160"
              trigger="click"
              :ref="`popover${item.ofoFlowWorkConfigId}`">
              <el-input size="mini" v-model="configValue" clearable class="right-input" v-if="item.showType == '1'">
                <template slot="append">%</template>
              </el-input>
              <el-select
                v-model="configValue"
                filterable
                clearable
                style="width:100%"
                v-if="item.showType == '2'"
                size="mini"
              >
                <el-option 
                  v-for="(val, id) in JSON.parse(item.options)"
                  :key="id"
                  :label="val.dictLabel"
                  :value="val.dictValue + ''"
                ></el-option>
              </el-select>
              <el-input size="mini" v-model="configValue" clearable v-if="item.showType == '3'"></el-input>
              <el-date-picker
                v-if="item.showType == '4'" 
                v-model="configValue" 
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                size="mini">
              </el-date-picker>
              <!-- <el-input size="mini" v-model="configValue" clearable v-else></el-input> -->
              <el-input
                v-model="configValue"
                clearable
                v-if="item.showType == '5'"
                class="right-input"
                size="mini"
                @blur="configValue = comdify(configValue)"
                @focus="configValue = delComdify(configValue)"
              >
              </el-input>
              <div style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="mini" @click="submitConfigValue(item, index, 'workConfigList4')">确 定</el-button>
              </div>
              <a slot="reference" class="vatgroup" @click="configValue = ''">{{item.name}}</a>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!-- <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
            ></el-input> -->
            <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
              class="right-input"
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '1'"
            >
              <template slot="append">%</template>
            </el-input>
            <el-select
              v-model="scope.row.workConfigList4[index].configValue"
              filterable
              clearable
              style="width:100%"
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '2'"
            >
              <el-option 
                v-for="(val, id) in JSON.parse(scope.row.workConfigList4[index].options)"
                :key="id"
                :label="val.dictLabel"
                :value="val.dictValue + ''"
              ></el-option>
            </el-select>
            <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '3'"
            >
            </el-input>
            <el-date-picker
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '4'" 
              v-model="scope.row.workConfigList4[index].configValue" 
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width: 100%">
            </el-date-picker>
            <el-input
              v-model="scope.row.workConfigList4[index].configValue"
              clearable
              v-if="scope.row.workConfigList4[index] && scope.row.workConfigList4[index].showType == '5'"
              class="right-input"
              @input="scope.row.workConfigList4[index].configValue=scope.row.workConfigList4[index].configValue.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
              @blur="configComdify(scope.row.workConfigList4[index].configValue, index, scope.$index)"
              @focus="configDelComdify(scope.row.workConfigList4[index].configValue, index, scope.$index)"
            >
            </el-input>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="60"
          fixed="right"
          align="right"
          >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '4'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat | comdify}}</td>
          <td>税额：{{ptotalVat | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>价税合计: {{ptotal | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%; margin-top:10px;border-top: 1px solid #eeeeee" v-if="ofoFlowWork.costmanagetype == '2'">
        <tr style="height: 30px">
          <td style="width: 150px">成本合计</td>
          <td>成本金额：{{ctotalNovat | comdify}}</td>
          <td>成本税额：{{ctotalVat | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>成本价税合计: {{ctotal | comdify}}</td>
        </tr>
      </table>
    </template>
    
    <!-- 查看时不可编辑的表格 -->
    <template v-if="disabled">
      <el-table
        :data="thingsList"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        key="table2"
        ref="noEditTable"
        row-key="ofoWorkThingId"
        border >
        <el-table-column
          type="index"
          label="行号"
          width="50">
        </el-table-column>
        <!-- 位置 -->
        <el-table-column
          prop="cbowhName"
          label="位置"
          width="200"
          v-if="ofoFlowWork.chooseOwh == '0'">
        </el-table-column>
        <!-- 货物或应税劳务、服务名称 -->
        <el-table-column
          :label="ofoFlowWork.thingtypeName + '（明细）'"
          min-width="200"
          prop="ofrresourceName">
          <template slot-scope="scope">
            {{scope.row.ofrresourceName + ' - ' + scope.row.ofrthingName}}
          </template>
        </el-table-column>
        <!-- <el-table-column v-for="(item, index) in obussDimeList" :key="index" :label="item.dimeName" :prop="item.dimeValue">
          <template slot-scope="scope">
            <span>{{scope.row.obussDimeList[index].dimeValue}}</span>
          </template>
        </el-table-column> -->
        <!-- 起始数量 -->
        <el-table-column
          prop="beginValue"
          label="起始数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
        </el-table-column>
        <!-- 终止数量 -->
        <el-table-column
          prop="endValue"
          label="终止数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
        </el-table-column>
        <!-- 数量期间 -->
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '8'">
          <template slot-scope="scope">{{scope.row.quantity}}
          </template>
        </el-table-column>
        <!-- 数量 -->
        <el-table-column
          :prop="'quantity' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="right"
          v-for="(item, index) in workConfig.workConfigList1"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8' && ofoFlowWork.costmanagetype != '4'">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList1[index].options)" :value="scope.row.workConfigList1[index].configValue" v-if="scope.row.workConfigList1[index].showType == '2'"/>
            <div v-else>
              {{scope.row.workConfigList1[index].configValue}}
              <span v-if="scope.row.workConfigList1[index].showType == '1' && scope.row.workConfigList1[index].configValue">%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="数量"
          min-width="150"
          align="right"
          v-if="ofoFlowWork.costmanagetype != '7' && ofoFlowWork.costmanagetype != '8'">
          <!-- <template slot-scope="scope">{{comdifyPrams1(scope.row.quantity)}}</template> -->
        </el-table-column>
        <el-table-column
          prop="quantityKz"
          label="(控制)数量"
          min-width="150"
          align="right"
          v-if="limit.quantity && limit.quantity.limitRate">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.quantity}}
          </template>
        </el-table-column>
        <el-table-column
          prop="quantityQz"
          label="(前置)数量"
          min-width="150"
          align="right"
          v-if="limit.quantity">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.preQuantity}}
          </template>
        </el-table-column>
        <!-- 开始时间 -->
        <el-table-column
          prop="beginValue"
          label="开始时间"
          width="250"
          align="center"
          v-if="ofoFlowWork.costmanagetype == '7'">
        </el-table-column>
        <!-- 结束时间 -->
        <el-table-column
          prop="endValue"
          label="结束时间"
          width="250"
          align="center"
          v-if="ofoFlowWork.costmanagetype == '7'">
        </el-table-column>
        <!-- 时间期间 -->
        <el-table-column
          prop="quantity"
          label="时间期间"
          v-if="ofoFlowWork.costmanagetype == '7'">
          <template slot-scope="scope">{{scope.row.quantity | filter_handleTimeRange}}
          </template>
        </el-table-column>
        <!-- 单价 -->
        <el-table-column
          :prop="'price' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="right"
          v-for="(item, index) in workConfig.workConfigList2"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList2[index].options)" :value="scope.row.workConfigList2[index].configValue" v-if="scope.row.workConfigList2[index].showType == '2'"/>
            <div v-else>
              {{scope.row.workConfigList2[index].configValue | comdify('no')}}
              <span v-if="scope.row.workConfigList2[index].showType == '1' && scope.row.workConfigList2[index].configValue">%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          align="right"
          min-width="150"
          label="单价（含税）"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">{{comdifyPrams(scope.row.price)}}</template>
        </el-table-column>
        <el-table-column
          prop="priceKz"
          label="(控制)单价"
          min-width="150"
          align="right"
          v-if="limit.price && limit.price.limitRate">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.price | comdify}}
          </template>
        </el-table-column>
        <el-table-column
          prop="priceQz"
          label="(前置)单价"
          min-width="150"
          align="right"
          v-if="limit.price">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.prePrice | comdify}}
          </template>
        </el-table-column>
        <!-- 金额 -->
        <el-table-column
          :prop="'total' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="right"
          v-for="(item, index) in workConfig.workConfigList3"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '4' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList3[index].options)" :value="scope.row.workConfigList3[index].configValue" v-if="scope.row.workConfigList3[index].showType == '2'"/>
            <div v-else>
              {{scope.row.workConfigList3[index].configValue | comdify('no')}}
              <span v-if="scope.row.workConfigList3[index].showType == '1' && scope.row.workConfigList3[index].configValue">%</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="total"
          align="right"
          min-width="150"
          label="金额（含税）"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">{{scope.row.total | comdify}}</template>
        </el-table-column>
        <el-table-column
          prop="lineTotalKz"
          label="(控制)金额"
          min-width="150"
          align="right"
          v-if="limit.lineTotal && limit.price.limitRate">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.lineTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column
          prop="lineTotalQz"
          label="(前置)金额"
          min-width="150"
          align="right"
          v-if="limit.lineTotal">
          <template slot-scope="scope">
            {{scope.row.ofoFlowWorkLimitShow.preLineTotal | comdify}}
          </template>
        </el-table-column>
        <!-- 费用金额 -->
        <el-table-column
          prop="total"
          label="费用金额"
          min-width="200"
          align="right"
          v-if="ofoFlowWork.costmanagetype == '4'">
          <template slot-scope="scope">{{scope.row.price | comdify}}</template>
        </el-table-column>
        <!-- 税率 -->
        <el-table-column
          prop="vatgroup"
          label="税率"
          align="center"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <DictTag :options="dropDown.invoiceVat" :value="scope.row.vatgroup"/>
          </template>
        </el-table-column>
        <!-- 税额 -->
        <el-table-column
          prop="totalVat"
          label="税额"
          align="right"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">{{scope.row.totalVat | comdify}}</template>
        </el-table-column>
        <!-- 票种 -->
        <el-table-column
          prop="invoicetype"
          label="票种"
          align="center"
          min-width="200"
          v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
          <template slot-scope="scope">
            <DictTag :options="dropDown.invoiceType" :value="scope.row.invoicetype"/>
          </template>
        </el-table-column>
        <!-- 成本单价 -->
        <el-table-column
          prop="cost"
          align="right"
          min-width="150"
          label="成本单价（含税）"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">{{comdifyPrams(scope.row.cost)}}</template>
        </el-table-column>
        <!-- 成本金额 -->
        <el-table-column
          prop="costTotal"
          align="right"
          min-width="150"
          label="成本金额（含税）"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">{{scope.row.costTotal | comdify}}</template>
        </el-table-column>
        <!-- 成本税额 -->
        <el-table-column
          prop="costTotalVat"
          label="成本税额"
          align="right"
          min-width="100"
          v-if="ofoFlowWork.costmanagetype == '2'">
          <template slot-scope="scope">{{scope.row.costTotalVat | comdify}}</template>
        </el-table-column>
        <!-- 自定义 -->
        <el-table-column
          :prop="'customize' + item.ofoFlowWorkConfigId"
          min-width="150"
          align="center"
          v-for="(item, index) in workConfig.workConfigList4"
          :key="item.ofoFlowWorkConfigId"
          :label="item.name">
          <template slot-scope="scope">
            <DictTag :options="JSON.parse(scope.row.workConfigList4[index].options)" :value="scope.row.workConfigList4[index].configValue" v-if="scope.row.workConfigList4[index].showType == '2'"/>
            <template v-else-if="scope.row.workConfigList4[index].showType == '1'">
              {{scope.row.workConfigList4[index].configValue}}
              <span v-if="scope.row.workConfigList4[index].showType == '1' && scope.row.workConfigList4[index].configValue">%</span>
            </template>
            <!-- <template v-else-if="scope.row.workConfigList4[index].showType == '5'">
              {{scope.row.workConfigList4[index].configValue}}
            </template> -->
            <template v-else>
              {{scope.row.workConfigList4[index].configValue}}
            </template>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150">
        </el-table-column>
      </el-table>
    
      <el-pagination
        v-if="disabled"
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pageSize"
        layout="->, prev, pager, next, sizes, jumper, total"
        :total="total"
        style="margin: 16px 0"
      >
      </el-pagination>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '4'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat2 | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="ofoFlowWork.costmanagetype == '1' || ofoFlowWork.costmanagetype == '2' || ofoFlowWork.costmanagetype == '3' || ofoFlowWork.costmanagetype == '5'">
        <tr style="height: 30px">
          <td style="width: 150px">合计</td>
          <td>金额：{{ptotalNovat2 | comdify}}</td>
          <td>税额：{{ptotalVat2 | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>价税合计: {{ptotal2 | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="limit.total && limit.total.limitRate">
        <tr style="height: 30px">
          <td style="width: 150px">控制合计</td>
          <td>{{thingLimit | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%" v-if="limit.total">
        <tr style="height: 30px">
          <td style="width: 150px">前置合计</td>
          <td>{{thingPreLimit | comdify}}</td>
        </tr>
      </table>
      <table style="width: 100%; margin-top:10px;border-top: 1px solid #eeeeee" v-if="ofoFlowWork.costmanagetype == '2'">
        <tr style="height: 30px">
          <td style="width: 150px">成本合计</td>
          <td>成本金额：{{ctotalNovat2 | comdify}}</td>
          <td>成本税额：{{ctotalVat2 | comdify}}</td>
        </tr>
        <tr>
          <td style="width: 150px"></td>
          <td>成本价税合计: {{ctotal2 | comdify}}</td>
        </tr>
      </table>
    </template>
    <!-- 复制从列表对话框 -->
    <el-dialog
      :title="`从 ${ofoFlowWork.beforeworkName} 复制到 ${ofoFlowWork.name}`"
      :visible.sync="dialogCopyFromVisible"
      width="70%"
      append-to-body
      top="59px">
      <div slot="title">
        {{`从 ${ofoFlowWork.beforeworkName} 复制到 ${ofoFlowWork.name}`}}
        <!-- <el-button size="mini" style="margin-left:16px" @click="selectAll">全选</el-button> -->
      </div>
      <el-table
        :data="copyFromList"
        height="100%"
        style="width: 100%;overflow: auto"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading"
        ref="tables"
        @cell-click="cellClick"
        @select="selectFather"
        @select-all="selectAllFather"
        @expand-change="expandChange"
      >
      <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :data="props.row.ofoWorkResourceList"
              @select="selectChild"
              @select-all="selectAllChild($event,props.$index)"
              :ref="'tableCopyFrom' + props.$index"
              @row-click="(row,column,e)=>handleCopyFromRowClick(row,column,e,props.$index)"
              :row-key="getRowKeyCopyFrom"
              style="width:97%; float:right"
            >
              <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true">
              </el-table-column>
              <el-table-column
                prop="ofrresourceName"
                label="产品名称">
              </el-table-column>
              <el-table-column
                prop="cbowhName"
                label="位置">
              </el-table-column>
              <template v-if="!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('1') != -1">
                <!-- 数量 -->
                <el-table-column
                  prop="quantity"
                  align="right"
                  label="数量">
                </el-table-column>
                <!-- 剩余数量 -->
                <el-table-column
                  prop="openqty"
                  align="right"
                  label="剩余数量">
                  <template slot-scope="scope">
                    <strong style="font-size:18px">{{scope.row.openqty}}</strong>
                  </template>
                </el-table-column>
              </template>
              <template v-if="!ofoFlowWork.beforeworkConfig || ofoFlowWork.beforeworkConfig.indexOf('3') != -1">
                <!-- 金额 -->
                <el-table-column
                  prop="total"
                  align="right"
                  label="金额（含税）">
                  <template slot-scope="scope">{{scope.row.total | comdify}}</template>
                </el-table-column>
                <!-- 剩余金额 -->
                <el-table-column
                  prop="openqty"
                  align="right"
                  label="剩余金额">
                  <template slot-scope="scope">
                    <strong style="font-size:18px">{{scope.row.opentotal | comdify}}</strong>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <!-- 数量 -->
        <el-table-column
          prop="code"
          label="编号">
        </el-table-column>
        <!-- 金额 -->
        <!-- <el-table-column
          prop="total"
          align="right"
          label="金额（含税）">
          <template slot-scope="scope">{{scope.row.ptotal | comdify}}</template>
        </el-table-column> -->
        <!-- 活动日期 -->
        <el-table-column
          prop="total"
          align="center"
          label="日期">
          <template slot-scope="scope">{{scope.row.workDate | filter_handleDate}}</template>
        </el-table-column>
        <!-- 交易方 -->
        <el-table-column
          prop="dealName"
          label="交易方"
         >
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remarks"
          label="备注">
        </el-table-column>
        <!-- <el-table-column
          align="right"
          width="150">
          <template slot="header">
            <el-input
              v-model="search1"
              size="small"
              placeholder="输入名称搜索"
              clearable/>
          </template>
        </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCopyFromVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCopyFrom">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 多选 -->
    <el-dialog :title="ofoFlowWork.thingtypeName + '（明细）'" :visible.sync="dialogTableVisible" append-to-body top="59px" width="60%">
      <el-table 
        :data="ofrThingsList.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.resourceName.toLowerCase().includes(search.toLowerCase()))"
        @selection-change="handleSelectionChange"
        ref="tableMultiple"
        @row-click="handleRowClick"
        :row-key="getRowKeyOfTarget"
        :height="'70vh'"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"> </el-table-column>
        <el-table-column property="ofrThingId" label="编号" width="100"></el-table-column>
        <el-table-column property="name" label="名称">
          <template slot-scope="scope"> {{scope.row.resourceName + ' - ' + scope.row.name}}</template>
        </el-table-column>
        <el-table-column width="240" v-if="ofoFlowWork.isShowThingStock == '0'">
          <template slot="header">
            <div style="display:flex;justify-content:space-between">
              <div>位置</div>
              <div>库存</div>
            </div>
          </template>
          <template slot-scope="scope">
            <el-table 
              v-if="scope.row.ofrOwhThingList.length"
              :data="scope.row.ofrOwhThingList"
              :show-header="false"
            >
            
              <el-table-column property="owhName" width="120"></el-table-column>
              <el-table-column property="thingQuantity" width="100" align="right" header-align="center"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column property="thingQuantity" label="总库存" width="100" align="right" header-align="center" v-if="ofoFlowWork.isShowThingStock == '0'">
          <template slot-scope="scope">
            {{calcStock(scope.row.ofrOwhThingList)}}
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          width="150">
          <template slot="header">
            <el-input
              v-model="search"
              size="small"
              placeholder="输入名称搜索"
              clearable/>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="display:flex;justify-content:space-between">
        <div>已选{{goods.length}}行</div>
        <div>
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
        
      </div>
    </el-dialog>
    <!-- 新增内容 -->
    <el-dialog
      :title="'新增内容'"
      :visible.sync="dialogThingVisible"
      append-to-body
      top="59px" >
      <el-form
        :model="thingForm"
        :rules="rulesThing"
        ref="thingForm"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="资源类型" prop="cbRestypeId">
              <el-select v-model="thingForm.cbRestypeId" filterable style="width: 100%" placeholder="请选择资源类型" @change="changeRes">
                <el-option 
                  v-for="(item, index) in cbResourceTypeList"
                  :key="index"
                  :label="item.restypeName"
                  :value="item.cbRestypeId"
                  v-if="item.isAdd == '0'"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择资源" prop="ofrResourceId">
              <el-select v-model="thingForm.ofrResourceId" filterable style="width: 100%" placeholder="请选择资源">
                <el-option 
                v-for="(item, index) in resourceList"
                :key="index"
                :label="item.resourceName"
                :value="item.ofrResourceId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="thingForm.name"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="remarks">
              <el-input
                v-model="thingForm.remarks"
                placeholder="请输入描述"
                style="width:97%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form :formList="thingFormList" :gutter="20" :ruleForm="thingForm"/>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogThingVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitThingForm('thingForm')" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择税率 票种 位置 -->
    <el-dialog
      :title="(sel == 'vatgroup') ? '选择税率' : ((sel == 'invoicetype') ? '选择票种' : ((sel == 'cbowhId') ? '选择位置' : ''))"
      :visible.sync="dialogSelectVisible"
      append-to-body
      class="res-dialog"
      width="25%"
      top="59px">
      <!-- <el-select v-model="select" clearable style="width:100%" v-if="sel == 'vatgroup'">
        <el-option 
        v-for="item in dropDown.invoiceVat"
        :label="item.dictLabel"
        :value="item.dictValue"
        :key="item.dictValue"
        ></el-option>
      </el-select>
      <el-select v-model="select" clearable style="width:100%" v-if="sel == 'invoicetype'">
        <el-option 
          v-for="item in dropDown.invoiceType"
          :label="item.dictLabel"
          :value="item.dictValue"
          :key="item.dictValue"
        ></el-option>
      </el-select> -->
      <el-radio-group v-model="select" v-if="sel == 'vatgroup'">
        <el-radio v-for="(item, index) in dropDown.invoiceVat" :key="index" :label="item.dictValue" style="margin-bottom:16px">{{item.dictLabel}}</el-radio>
      </el-radio-group>
      <el-radio-group v-model="select" v-if="sel == 'invoicetype'">
        <el-radio v-for="(item, index) in dropDown.invoiceType" :key="index" :label="item.dictValue" style="margin-bottom:16px">{{item.dictLabel}}</el-radio>
      </el-radio-group>
      <el-tree
        v-if="sel == 'cbowhId'"
        ref="treeList"
        :data="warehouseList1"
        :props="{label: 'name', id: 'cbOwhId'}"
        node-key="cbOwhId"
        check-strictly
        highlight-current
        :expand-on-click-node="false"
        @node-click="handleNodeClick"
        default-expand-all
        >
      </el-tree>
      <!-- <el-radio-group v-model="select" v-if="sel == 'cbowhId'">
        <el-radio v-for="(item, index) in warehouseList" :key="index" :label="item.cbOwhId" style="margin-bottom:16px">{{item.name}}</el-radio>
      </el-radio-group> -->
      <!-- <el-select v-model="select" filterable clearable style="width:100%" v-if="sel == 'cbowhId'">
        <el-option 
        v-for="(item, index) in warehouseList"
        :key="index"
        :label="item.name"
        :value="item.cbOwhId"
        ></el-option>
      </el-select> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSelectVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSelect">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import DimeTag from '@/components/DimeTag.vue'
import Treeselect from "@riophae/vue-treeselect";
import handleTree from '@/utils/handleTree'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {mapState} from 'vuex'
import Form from '@/components/Form.vue'
import handleList from '@/utils/handleList'
import handleWorkConfigList from '@/utils/handleWorkConfigList'
import qs from 'qs'
  export default {
    name: 'subsidiary',
    components: {
      DictTag,
      Form,
      DimeTag,
      Treeselect
    },
    props: {
      disabled: Boolean,
      ofoFlowWork: Object,
      thingsList: Array,
      // obussDimeList: Array,
      // documentstatus: String,
      ofoWorkId: [Number, String],
      total: [Number, String],
      pageNum: [Number, String],
      pageSize: [Number, String],
      ctotal2: [Number, String],
      ctotalNovat2: [Number, String],
      ctotalVat2: [Number, String],
      ptotal2: [Number, String],
      ptotalNovat2: [Number, String],
      ptotalVat2: [Number, String],
      workConfig: Object,
      limit: {
        type: Object,
        default: ()=>({})
      },
      thingLimit: {
        type: [Number, String],
        default: ''
      },
      thingPreLimit: {
        type: [Number, String],
        default: ''
      },
      params: {
        type: Object,
        default: () => ({
          priceAccuracy: 2,
          quantityAccuracy: 8
        })
      }
    },
    data() {
      return {
        isTax: true, // 是否含税
        // thingsList: [], //明细列表
        // thingsList:[],
        warehouseList: [], // 仓库列表
        warehouseList1: [], // 仓库列表
        goodsList: [], // 货物或应税、服务名称列表
        dialogTableVisible: false,
        goods: [],
        // ofrthingId: []
        startDate: '',
        endDate: '',
        startCount: '',
        endCount: '',
        ofrThingsList: [],
        search: '',
        tableOption: [],
        dialogThingVisible: false,
        loading: false,
        thingForm: {
          name: '',
          remarks: '',
          documentstatus: '1',
          ofrResourceId: '',
          cbRestypeId: '',
          dataSourceConfig: []
        },
        rulesThing: {
          name: [{ required: true, message: "请输入内容名称", trigger: "change" }],
          ofrResourceId: [{ required: true, message: "请选择资源", trigger: "change" }]
        },
        thingFormList: [],
        cbResourceType: {},
        flag: true,
        selectedId: [],
        // pageNum: 1,
        // pageSize: 10,
        select: '',
        dialogSelectVisible: false,
        index: 0,
        isAll: true,
        sel: '',
        dialogCopyFromVisible: false, // 复制从对话框
        copyFromList: [], // 复制从列表
        comfirmCopyFromList: [], // 勾选的复制从列表
        selectAllChildMap: new Map(),
        isAdd: '',
        cbResourceTypeList: [],
        resourceList: [],
        ofrThingId: '',
        isAddRes: false,
        editResIndex: 0,
        configValue: '',
      }
    },
    mounted () {
      this.getList();
    },
    async updated () {
    },
    methods: {
      configComdify(n, index, rowId) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        this.thingsList[rowId].workConfigList4[index].configValue = `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      configDelComdify(n, index, rowId) {
        // let obj = JSON.parse(JSON.stringify(item))
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        this.thingsList[rowId].workConfigList4[index].configValue = numS.replace(/,/gi, '');
      },
      calcStock(arr) {
        if(arr.length) {
          return arr.reduce((prev, cur, index, arr) => {
            return prev + cur.thingQuantity
          }, 0)
        }else {
          return ''
        }
      },
      handleNodeClick(data, node, component) {
        this.select = data.cbOwhId
      },
      // 新增内容 
      addThing() {
        this.thingForm.name = ''
        this.thingForm.remarks = ''
        this.thingForm.ofrResourceId = ''
        this.thingForm.dataSourceConfig = []
        this.cbResourceTypeList.some(item => {
          if(item.isAdd == '0') {
            this.thingForm.cbRestypeId = item.cbRestypeId
            this.getCbussDimeList()
            return true
          }
        })
        this.$nextTick(() => {
          // 清除表单校验效果
          if(this.$refs.thingForm) { 
            this.$refs.thingForm.clearValidate();
          }
        })
        this.dialogThingVisible = true
        
      },
      changeRes() {
        this.getCbussDimeList()
      },
      getCbussDimeList() {
        this.resourceList = []
        this.goodsList.forEach(item => {
          if(item.cbRestypeId == this.thingForm.cbRestypeId) {
            this.resourceList.push(item)
          }
        })
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.thingForm.cbRestypeId, dimetype: '2'}}).then(res => {
          this.thingFormList = this.handleFormList(res.data.data)
          this.thingForm.dataSourceConfig = this.thingFormList
        })
      },
      // 提交内容
      submitThingForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            let dimes = []
            if(this.thingFormList.length) {
              dimes = this.handleDimes(this.thingFormList)
            }
            delete this.thingForm.dataSourceConfig
            this.$http.post(url.addOfrThing, qs.stringify({
              ...this.thingForm,
              dimes: JSON.stringify(dimes)
            })).then(res => {
              this.loading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.ofrThingId = res.data.data
                this.dialogThingVisible = false
                this.$http.get(url.getResTypesAndList, {params:
                  {
                    cbRestypeIds: this.ofoFlowWork.thingtype
                  }
                }).then(res => {
                  // this.goodsList = this.handleDimes(res.data.data.list, 'resourceNameDimes', 'resourceName')
                  this.goodsList = res.data.data.list

                  this.ofrThingsList = []
                  let index = 0
                  this.goodsList.forEach((item, resIndex) => {
                    item.ofrThingList.forEach(el => {
                      let obj = el
                      obj.ofrResourceId = item.ofrResourceId
                      obj.resourceName = item.resourceName
                      this.ofrThingsList.push(obj)
                    })
                    // if(this.thingForm.ofrResourceId == item.ofrResourceId) {
                    //   index = resIndex
                    // }
                  })
                  let ofrresourceId = ''
                  let ofrresourceName = ''
                  let ofrthingName = ''
                  this.ofrThingsList.forEach(item => {
                    if(item.ofrThingId == this.ofrThingId) {
                      ofrresourceId = item.ofrResourceId
                      ofrresourceName = item.resourceName
                      ofrthingName = item.name
                    }
                  })
                  // // this.ofrThingsList.forEach(item => {
                  // //   item.thingName = item.name
                  // // })
                  // // this.ofrThingsList = this.handleDimes(this.ofrThingsList, 'name', 'name')
                  // let length = this.goodsList[index].ofrThingList.length
                  this.thingsList.push({
                    cbowhId: (this.ofoFlowWork.chooseOwh == '0' && this.warehouseList.length) ? this.warehouseList[0].cbOwhId : '',
                    cbowhName: (this.ofoFlowWork.chooseOwh == '0' && this.warehouseList.length) ? this.warehouseList[0].name : '',
                    ofrresourceId: ofrresourceId,
                    ofrresourceName: ofrresourceName,
                    ofrthingId: this.ofrThingId,
                    ofrthingName: ofrthingName,
                    quantity: this.ofoFlowWork.costmanagetype == '4' ? 1 : '',
                    price: '',
                    total: '',
                    vatgroup: this.dropDown.invoiceVat[0].dictValue,
                    totalVat: '',
                    invoicetype: this.dropDown.invoiceType[0].dictValue,
                    cost: '',
                    costTotal: '',
                    costTotalVat: '',
                    beginValue: '',
                    endValue: '',
                    beginValue: '',
                    endValue: '',
                    workConfigList1: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList1) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList1)) : [],
                    workConfigList2: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList2) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList2)) : [],
                    workConfigList3: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList3) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList3)) : [],
                    workConfigList4: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList4) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList4)) : [],
                    // isVat: 
                  })
                })
              }
            })
          }
        })
      },
      // 点击复制从按钮
      async copyFromAdd() {
        this.loading = true
        let ofoflowworkId = this.ofoFlowWork.ofoFlowWorkId
        this.dialogCopyFromVisible = true
        this.comfirmCopyFromList = []
        await this.$http.get(url.getWorkFromList, {params: {ofoflowworkId}}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.copyFromList = res.data.data
            this.copyFromList.forEach((item, index) => {
              item.childIdList = []
              // item.ofoWorkResourceList = this.handleDimes(item.ofoWorkResourceList, 'ofrresourceName', 'ofrresourceName')
              item.ofoWorkResourceList = item.ofoWorkResourceList
              if(item.ofoWorkResourceList.length) {
                item.ofoWorkResourceList.forEach(val => {
                  val.parentId = item.ofoWorkId
                  item.childIdList.push(val.ofoWorkResourceId)
                })
              }
            })
          }
        })
        // this.copyFromList.forEach((item, index) => {
        //   this.$refs['tableCopyFrom' + index].clearSelection()
        // })
      },
      // 确认复制从
      confirmCopyFrom() {
        // console.log(1111);
        this.dialogCopyFromVisible = false
        // console.log(this.copyFromList);
        let obj = {}
        let thingsList = []
        this.copyFromList.forEach(item => {
          if(item.ofoWorkResourceList.length) {
            item.ofoWorkResourceList.forEach(val => {
              if(val.ofoWorkResourceId == this.comfirmCopyFromList[0].ofoWorkResourceId) {
                obj = item
              }
            })
          }
        })
        // console.log(code);
        this.comfirmCopyFromList = handleList(this.comfirmCopyFromList, {ofoDealId: '1'}, '1', this.ofoFlowWork, this.warehouseList, 'foWorkResourceId', this.params)
        this.comfirmCopyFromList = handleWorkConfigList(this.ofoFlowWork.checkConfig, this.comfirmCopyFromList, this.workConfig)
        this.comfirmCopyFromList.forEach(item => {
          // item.baseOfoWorkResourceId = item.ofoWorkResourceId
          // item.ofoWorkResourceId = ''
          // item.baseOfoWorkId = item.ofoworkId
          // if(this.ofoFlowWork.costmanagetype == '4') {
          //   // 数量
          //   item.quantity = 1
          //   // 金额
          //   item.total = this.comdify(item.opentotal)
          //   item.totalVat = 0
          //   item.vatgroup = '0.00'
          //   item.price = item.total
          //   item.cost = 0
          //   item.costTotal = 0
          //   item.costTotalVat = 0
          // }else {
          //   // 数量
          //   item.quantity = item.openqty
          //   // 金额
          //   item.total = this.comdify(item.opentotal)
          //   // 单价
          //   item.price = this.comdify( this.delComdify(item.total) / item.quantity)
          //   // 税额
          //   item.totalVat = this.comdify(this.delComdify(item.total) / (1 + Number(item.vatgroup)) * item.vatgroup) 
          //   // 成本单价
          //   item.cost = this.comdify(item.cost)
          //   // 成本金额
          //   item.costTotal = this.comdify(this.delComdify(item.cost) * item.quantity)
          //   // 成本税额
          //   item.costTotalVat = this.comdify(this.delComdify(item.costTotal) / (1 + Number(item.vatgroup)) * item.vatgroup) 
          // }
          item.ofoWorkThingList.forEach(val => {
             thingsList.push(val)
          })
        })
        
        thingsList = handleList(thingsList, {ofoDealId: '1'}, '1', this.ofoFlowWork, this.warehouseList, 'foWorkThingId', this.params)
        thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, thingsList, this.workConfig)

        console.log(thingsList);
        // thingsList.forEach(item => {
        //   item.baseOfoWorkThingId = item.ofoWorkThingId
        //   item.ofoWorkThingId = ''
        //   item.baseOfoWorkId = item.ofoworkId
        //   if(this.ofoFlowWork.costmanagetype == '4') {
        //     // 数量
        //     item.quantity = 1
        //     // 金额
        //     item.total = this.comdify(item.opentotal)
        //     item.totalVat = 0
        //     item.vatgroup = '0.00'
        //     item.price = item.total
        //     item.cost = 0
        //     item.costTotal = 0
        //     item.costTotalVat = 0
        //   }else {
        //     // 数量
        //     item.quantity = item.openqty
        //     // 金额
        //     item.total = this.comdify(item.opentotal)
        //     // 单价
        //     item.price = this.comdify( this.delComdify(item.total) / item.quantity)
        //     // 税额
        //     item.totalVat = this.comdify(this.delComdify(item.total) / (1 + Number(item.vatgroup)) * item.vatgroup) 
        //     // 成本单价
        //     item.cost = this.comdify(item.cost)
        //     // 成本金额
        //     item.costTotal = this.comdify(this.delComdify(item.cost) * item.quantity)
        //     // 成本税额
        //     item.costTotalVat = this.comdify(this.delComdify(item.costTotal) / (1 + Number(item.vatgroup)) * item.vatgroup) 
        //   }
        // })
        // console.log(this.comfirmCopyFromList);
        // console.log(thingsList);
        // this.productsList = this.comfirmCopyFromList
        this.$emit('copyFromConfirm', {productsList: this.comfirmCopyFromList, obj, thingsList})
      },
      getRowKeyCopyFrom(row) {
        return row.ofoWorkResourceId
      },
      // 点击行折叠展开
      cellClick(row, column, event) {
        this.$refs.tables.toggleRowExpansion(row)
      },
      selectAll() {
        // console.log(this.copyFromList);
        this.$nextTick(() => {
          // console.log(this.$refs);
        })
        // this.comfirmCopyFromList = []
        // this.copyFromList.forEach(item => {

        // })
      },
      selectFather(selection, row) {
        const isCheck = selection.includes(row)
        this.copyFromList.forEach((item, index) => {
          if (item.ofoWorkId === row.ofoWorkId) {
            this.$refs.tables.toggleRowExpansion(item, true)
            const tempList = row.ofoWorkResourceList
            this.$nextTick(() => {
              if (tempList.length !== 0) {
                tempList.forEach((childItem) => {
                  this.selectAllChildMap.set(index, item)
                  this.$refs['tableCopyFrom' + index].toggleRowSelection(childItem, isCheck)
                })
              }
            })
          }
        })
        if (isCheck) {
          this.validIs(row.ofoWorkResourceList)
        } else {
          this.cleanIs(null, row)
        }
      },
      selectAllFather(selection) {
        this.copyFromList.forEach(async (item, index) => {
          await this.$refs['tables'].toggleRowExpansion(item, true)
          if (selection.length !== 0) {
            this.selectFather([item], item)
            this.selectAllChild(item.ofoWorkResourceList, index)
          } else {
            // console.log(item);
            this.cleanIs(null, item)
          }
          this.$refs['tableCopyFrom' + index].clearSelection()
        })
        // 
      },
      expandChange(row, expandedRows) {
        // console.log(row);
        // console.log(expandedRows);
        const expandedRowsIds = expandedRows.map(m => m.ofoWorkId)
        this.$nextTick(() => {
          const ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          this.copyFromList.forEach((item, index) => {
            if(item.ofoWorkId == row.ofoWorkId && expandedRowsIds.includes(row.ofoWorkId)) {
              row.ofoWorkResourceList.forEach(val => {
                if(ids.includes(val.ofoWorkResourceId)) {
                  this.$refs['tableCopyFrom' + index].toggleRowSelection(val, true)
                }
              })
            }
          })
        })
        
      },
      selectChild(selection, row) {
        //校验明细中是否存在  存在则删除 否则添加
        if (this.comfirmCopyFromList.length === 0) {
          this.comfirmCopyFromList.push(row)
        } else {
          const ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          ids.includes(row.ofoWorkResourceId) ?
          this.comfirmCopyFromList = this.comfirmCopyFromList.filter(f => f.ofoWorkResourceId !== row.ofoWorkResourceId) : this.comfirmCopyFromList.push(row)
        }
        // --
        const isCheck = selection.length > 0
        this.copyFromList.forEach((item, index) => {
          if (item.ofoWorkId === row.parentId) {
            this.selectAllChildMap.set(index, item)
            this.$refs.tables.toggleRowSelection(item, isCheck)
          }
        })
      },
      selectAllChild(selection, clickIndex) {
        if (selection.length > 0) {
          const fatherRow = this.copyFromList.find(item => {
            return item.ofoWorkId === selection[0].parentId
          })
          this.selectAllChildMap.set(clickIndex, fatherRow)
          this.$refs.tables.toggleRowSelection(this.selectAllChildMap.get(clickIndex), true)
          // 非空时候 检验明细是否存在
          this.validIs(selection)
        } else {
          this.cleanIs(clickIndex)
          this.$refs.tables.toggleRowSelection(this.selectAllChildMap.get(clickIndex), false)
          this.selectAllChildMap.delete(clickIndex)
        }
      },
      //一级勾选框和子级头部勾选框检验
      validIs (selection) {
        // 非空时候 检验明细是否存在
        if (this.comfirmCopyFromList.length === 0) {
          this.comfirmCopyFromList.push(...selection)
        } else {
          let ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          selection.forEach(f => {
            if (ids.indexOf(f.ofoWorkResourceId) !== -1) {
              delete this.comfirmCopyFromList[ids.indexOf(f.ofoWorkResourceId)]
            }
          })
          this.comfirmCopyFromList = this.comfirmCopyFromList.filter(f => f.ofoWorkResourceId)
          ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
          this.comfirmCopyFromList.push(...selection)
        }
      },
      //一级勾选框和子级头部勾选清空方法
      cleanIs (clickIndex, fatherRow) {
        const childIdList = clickIndex || clickIndex === 0 ? this.copyFromList[clickIndex].childIdList : fatherRow.childIdList
        let ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
        childIdList.forEach(f => {
          if (ids.indexOf(f) !== -1) {
            delete this.comfirmCopyFromList[ids.indexOf(f)]
          }
        })
        this.comfirmCopyFromList = this.comfirmCopyFromList.filter(f => f.ofoWorkResourceId)
        ids = this.comfirmCopyFromList.map(f => f.ofoWorkResourceId)
      },


      changeVatgroup(type, sel, value, id) {
        this.dialogSelectVisible = true
        this.sel = sel
        if(type == 'all') {
          this.select = ''
          if(this.sel == 'cbowhId') {
            this.$nextTick(function () {
              this.$refs.treeList.setCurrentKey(null);
            });
          }
          this.isAll = true
        }else {
          this.select = value
          if(this.sel == 'cbowhId') {
            this.$nextTick(function () {
              this.$refs.treeList.setCurrentKey(value);
            });
          }
          
          this.index = id
          this.isAll = false
        }
      },
      submitSelect() {
        if(this.select) {
          if(this.isAll) {
            if(this.thingsList.length) {
              this.thingsList.forEach(row => {
                row[this.sel] = this.select
                if(this.sel == 'vatgroup') {
                  this.compute4(row)
                }else if(this.sel == 'cbowhId') {
                  row.cbowhName = this.changeCbowh(row.cbowhId)
                }
                
              })
            }
          }else {
            this.thingsList[this.index][this.sel] = this.select
            if(this.sel == 'vatgroup') {
              this.compute4( this.thingsList[this.index])
            }else if(this.sel == 'cbowhId') {
              this.thingsList[this.index].cbowhName = this.changeCbowh(this.select)
            }
          }
        }
        this.dialogSelectVisible = false
      },
      changeCbowh(e) {
        let name = ''
        this.warehouseList.forEach(item => {
          if(item.cbOwhId == e) {
            name = item.name
          }
        })
        return name
      },
      handleSizeChange(val) {
        this.$emit('getWorkThingList', {ofoWorkId: this.ofoWorkId, pageNum: 1,pageSize: val})
      },
      handleCurrentChange(val) {
        this.$emit('getWorkThingList', {ofoWorkId: this.ofoWorkId, pageNum: val, pageSize: this.pageSize})
      },
      async getList() {
        // if((this.documentstatus == '0' || this.documentstatus == '1') && this.flag) {
        // if(this.flag) {
        //   this.flag = false
          // 通过内容获取明细仓库
          if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
            if(this.ofoFlowWork.chooseOwh == '0') {
              this.$http.get(url.getCbOwhByCbResType, {params: {cbRestypeIds: this.ofoFlowWork.thingtype}}).then(res => {
                if(res.data.code == 200) {
                  this.warehouseList1 = handleTree(res.data.data, 'cbOwhId', 'pid')
                  this.warehouseList = res.data.data
                  // console.log(this.warehouseList);
                }
              })
            }
            
            this.$http.get(url.getResTypesAndList, {params: {cbRestypeIds: this.ofoFlowWork.thingtype}}).then(res => {
              if(res.data.code == 200) {
                this.cbResourceTypeList = res.data.data.cbResourceTypeList
                // this.goodsList = this.handleDimes(res.data.data.list, 'resourceNameDimes', 'resourceName')
                this.goodsList = res.data.data.list
                this.isAdd = res.data.data.isAdd
                this.ofrThingsList = []
                this.goodsList.forEach(item => {
                  item.ofrThingList.forEach(el => {
                    let obj = el
                    obj.ofrResourceId = item.ofrResourceId
                    obj.resourceName = item.resourceName
                    this.ofrThingsList.push(obj)
                  })
                })
                if(!this.disabled && (this.cbResourceTypeList.find(arr => arr.kind == '1'))) {
                  let index = this.ofrThingsList.findIndex(val => val.ofrThingId == JSON.parse(sessionStorage.getItem('loginUser')).bussId)
                  this.goods.push(this.ofrThingsList[index])
                  this.isAddRes = true
                  this.confirm()
                }
              }
            })
          }
        // }
        
        // 设置税率
        if(!this.dropDown.invoiceVat) {
          this.$http.get(url.getDataList, {params: {type: 'buss_invoice_vat'}}).then(res => {
            // console.log(res);
            // this.invoiceVat = res.data.data
            this.$store.commit('setInvoiceVat', res.data.data)
          })
        }
        // 设置发票类型
        if(!this.dropDown.invoiceType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_invoice_type'}}).then(res => {
            // console.log(res);
            // this.invoiceVat = res.data.data
            this.$store.commit('setInvoiceType', res.data.data)
          })
        }
      },
      // 多选
      selectMore(isEdit, row, index) {
        this.dialogTableVisible = true
        if(this.$refs.tableMultiple) {
          this.$refs.tableMultiple.clearSelection()
        }
        if(isEdit) {
          this.isAddRes = false
          this.editResIndex = index
          this.ofrThingsList.forEach(item => {
            if(item.ofrThingId == row.ofrthingId) {
              this.$nextTick(() => {
                this.$refs.tableMultiple.toggleRowSelection(item, true)
              })
            }
          })
        }else {
          this.isAddRes = true
        }
      },
      getRowKeyOfTarget(row) {
        return row.ofrThingId
      },
      handleSelectionChange(rows) {
        if(this.isAddRes) {
          this.goods = rows
        }else {
          if(rows.length >= 2) {
            this.$refs.tableMultiple.toggleRowSelection(rows[0], false);
            this.goods = [rows[1]]
          }else {
            this.goods = rows
          }
        }
      },
      //点击行触发，选中或不选中复选框
      handleRowClick(row, column, event) {
        this.$refs.tableMultiple.toggleRowSelection(row);
      },
      // 确定多选
      confirm() {
        if(this.goods.length) {
          this.dialogTableVisible = false
          if(this.isAddRes) { // 新增资源行
            this.goods.forEach(item => {
              this.goodsList.forEach(val => {
                val.ofrThingList.forEach(el => {
                  if(item.ofrThingId == el.ofrThingId) {
                    this.thingsList.push({
                      cbowhId: (this.ofoFlowWork.chooseOwh == '0' && this.warehouseList.length) ? this.warehouseList[0].cbOwhId : '',
                      cbowhName: (this.ofoFlowWork.chooseOwh == '0' && this.warehouseList.length) ? this.warehouseList[0].name : '',
                      ofrresourceId: val.ofrResourceId,
                      ofrresourceName: val.resourceName,
                      ofrthingId: el.ofrThingId,
                      ofrthingName: el.name,
                      quantity: this.ofoFlowWork.costmanagetype == '4' ? 1 : '',
                      price: '',
                      total: '',
                      vatgroup: this.dropDown.invoiceVat[0].dictValue,
                      totalVat: '',
                      invoicetype: this.dropDown.invoiceType[0].dictValue,
                      cost: '',
                      costTotal: '',
                      costTotalVat: '',
                      beginValue: '',
                      endValue: '',
                      beginValue: '',
                      endValue: '',
                      workConfigList1: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList1) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList1)) : [],
                      workConfigList2: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList2) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList2)) : [],
                      workConfigList3: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList3) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList3)) : [],
                      workConfigList4: (this.ofoFlowWork.checkConfig && this.workConfig.workConfigList4) ? JSON.parse(JSON.stringify(this.workConfig.workConfigList4)) : [],
                      // isVat: 
                    })
                  }
                })
              })
            })
          }else { // 修改资源行名称
            this.thingsList[this.editResIndex].ofrresourceId = this.goods[0].ofrResourceId
            this.thingsList[this.editResIndex].ofrresourceName = this.goods[0].resourceName
            this.thingsList[this.editResIndex].ofrthingId = this.goods[0].ofrThingId
            this.thingsList[this.editResIndex].ofrthingName = this.goods[0].name
          }
        }else {
          this.$message.warning('请选择资源行');
        }
      },
      // 明细增行
      addList() {
        this.thingsList.push({
          cbowhId: (this.ofoFlowWork.chooseOwh == '0' && this.warehouseList.length) ? this.warehouseList[0].cbOwhId : '',
          ofrresourceId: this.ofrThingsList.length ? this.ofrThingsList[0].ofrResourceId : '',
          ofrthingId: this.ofrThingsList.length ? this.ofrThingsList[0].ofrThingId : '',
          quantity: this.ofoFlowWork.costmanagetype == '4' ? 1 : '',
          price: '',
          total: '',
          vatgroup: this.dropDown.invoiceVat[0].dictValue,
          totalVat: '',
          invoicetype: this.dropDown.invoiceType[0].dictValue,
          cost: '',
          costTotal: '',
          costTotalVat: '',
          beginValue: '',
          endValue: '',
          beginValue: '',
          endValue: '',
          remark: ''
          // isVat: 
        })

      },
      // 明细减行
      handleDelete(index) {
        this.thingsList.splice(index, 1)
      },
      // 表格的选中框事件
      handleRowChange(rows) {
        if(rows.length) {
          let selectedId = []
          rows.forEach(item => {
            selectedId.push(item.index)
          })
          this.selectedId = selectedId
        }else {
          this.selectedId = []
        }
      },
      // 删除已选
      deleteMore() {
        for(var i = this.selectedId.length - 1; i >= 0; i--) {
          this.thingsList.splice(this.selectedId[i], 1)
        }
      },
      // 添加千分位
      comdify(n) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        // console.log(`${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`);
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 添加千分位
      comdifyPrams(n) {
        if(!n) return '';
        n = Number(n).toFixed(this.params.priceAccuracy)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        // console.log(`${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`);
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 添加千分位
      comdifyPrams1(n) {
        if(!n) return '';
        n = Number(n).toFixed(this.params.quantityAccuracy)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        // console.log(`${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`);
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      // 去掉千分位
      delComdify(n) {
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        numS = numS.replace(/,/gi, '');
        return numS;
      },
      // 处理只能输入数字
      handlePrice(n) {
        return n.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3')
      },
      // 处理只能输入数字
      handlePriceParams(n) {
        return n.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{'+this.params.priceAccuracy+'}).*$'), '$1$2.$3')
      },
      // 计算税额
      computeTax(row) {
        if(this.isTax) {
          // 税额
          row.totalVat = this.comdify(this.delComdify(row.total) / (1 + Number(row.vatgroup)) * row.vatgroup)
        }else {
          row.totalVat = this.comdify(this.delComdify(row.total) * row.vatgroup)
        }
      },
      // 计算成本税额
      computeCostTax(row) {
        if(this.isTax) {
          // 税额
          row.costTotalVat = this.comdify(this.delComdify(row.costTotal) / (1 + Number(row.vatgroup)) * row.vatgroup)
        }else {
          row.costTotalVat = this.comdify(this.delComdify(row.costTotal) * row.vatgroup)
        }
      },
      // 处理计算符
      handleCalcu(calcu) {
        if(calcu == '1') {
          return '+'
        }else if(calcu == '2') {
          return '-'
        }else if(calcu == '3') {
          return '*'
        }else if(calcu == '4') {
          return '/'
        }else {
          return ''
        }
      },
      submitConfigValue(item, index, prop) {
        this.$nextTick(() => {
          this.$refs[`popover${item.ofoFlowWorkConfigId}`][1].showPopper = false
        })
        this.thingsList.forEach(val => {
          val[prop][index].configValue = this.configValue
          if(prop != 'workConfigList4') {
            this.computeSl(val, index, prop)
          }
        })
      },
      // 输入数量
      computeSl(row, index, prop) {
        // console.log(JSON.parse(JSON.stringify(row.workConfigList3)));
        if(prop == 'workConfigList1') {
          row.workConfigList1[index].configValue = row.workConfigList1[index].configValue.replace(/[^\d.\-]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{'+this.params.quantityAccuracy+'}).*$'), '$1$2.$3')
        }
        let length = row[prop].length
        if(length > 1) {
          let arr = []
          for(let i = 0; i < (length - 1); i++) {
            arr.push(row[prop][i].brackets && row[prop][i].brackets.includes('(') ? row[prop][i].brackets : '', row[prop][i].configValue ? (row[prop][i].showType == '1' ? (row[prop][i].configValue / 100) : row[prop][i].configValue) : 0, row[prop][i].brackets && row[prop][i].brackets.includes(')') ? row[prop][i].brackets : '', this.handleCalcu(row[prop][i].calcu))
          }
          arr.push(row[prop][length - 1].brackets && row[prop][length - 1].brackets.includes('(') ? row[prop][length - 1].brackets : '', row[prop][length - 1].configValue ? (row[prop][length - 1].showType == '1' ? (row[prop][length - 1].configValue / 100) : row[prop][length - 1].configValue) : 0, row[prop][length - 1].brackets && row[prop][length - 1].brackets.includes(')') ? row[prop][length - 1].brackets : '')
          // console.log(arr.join(''));
          let total = eval(arr.join('')).toFixed(4)
          // let total = 1
          if(prop == 'workConfigList1') {
            row.quantity = total
            if(row.price || row.total) {
              if(!row.price) {
                row.price = this.comdifyPrams(this.delComdify(row.total) / row.quantity)
              }else {
                // 金额
                row.total = this.comdify(row.quantity * this.delComdify(row.price))
                this.computeTax(row)
              }
            }
            if(row.cost || row.total) {
              if(!row.cost) {
                row.cost = this.comdifyPrams(this.delComdify(row.costTotal) / row.quantity)
              }else {
                // 金额
                row.costTotal = this.comdify(row.quantity * this.delComdify(row.cost))
                this.computeCostTax(row)
              }
            }
          }else if(prop == 'workConfigList2') {
            row.price = total
            if(row.quantity || row.total) {
              if(!row.quantity) {
                row.quantity = (this.delComdify(row.total) / row.price).toFixed(this.params.quantityAccuracy)
              }else {
                // 金额
                row.total = this.comdify(row.quantity * row.price)
                this.computeTax(row)
              }
            }
          }else {
            row.total = total
            if(row.quantity || row.price) {
              if(row.quantity) {
                row.price = this.comdifyPrams(row.total / row.quantity)
              }else {
                row.quantity = (row.total / this.delComdify(row.price)).toFixed(this.params.quantityAccuracy)
              }
            }
            this.computeTax(row)
          }
        }
      },
      // 输入数量
      compute1(row) {
        row.quantity = row.quantity.replace(/[^\d.\-]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(new RegExp('^(\\-)*(\\d+)\\.(\\d{'+this.params.quantityAccuracy+'}).*$'), '$1$2.$3')
        if(!row.quantity) {
          row.total = ''
          row.totalVat = ''
          row.costTotal = ''
          row.costTotalVat = ''
        }else {
          if(row.bomId < 0 && row.children) {
            this.changeChildrenQuantity(row, row.quantity)
          }
          if(row.price || row.total) {
            if(!row.price) {
              row.price = this.comdifyPrams(this.delComdify(row.total) / row.quantity)
            }else {
              // 金额
              row.total = this.comdify(row.quantity * this.delComdify(row.price))
              this.computeTax(row)
            }
          }
          if(row.cost || row.total) {
            if(!row.cost) {
              row.cost = this.comdifyPrams(this.delComdify(row.costTotal) / row.quantity)
            }else {
              // 金额
              row.costTotal = this.comdify(row.quantity * this.delComdify(row.cost))
              this.computeCostTax(row)
            }
          }
        }
      },
      // 输入单价
      compute2(row) {
        // console.log(el);
        row.price = this.handlePriceParams(row.price)
        // row.price = this.comdify(row.price)
        if(!row.price) {
          row.total = ''
          row.totalVat = ''
        }else {
          if(row.quantity || row.total) {
            if(!row.quantity) {
              row.quantity = (this.delComdify(row.total) / row.price).toFixed(this.params.quantityAccuracy)
            }else {
              // 金额
              row.total = this.comdify(row.quantity * row.price)
              this.computeTax(row)
            }
          }
        }
      },
      compute22(row) {
        row.price = this.comdifyPrams(row.price)
      },
      compute222(row) {
        row.price = this.delComdify(row.price)
      },
      // 输入金额
      compute3(row) {
        row.total = this.handlePrice(row.total)
        if(!row.total) {
          row.price = ''
          row.totalVat = ''
        }else {
          if(row.quantity || row.price) {
            if(row.quantity) {
              row.price = this.comdifyPrams(row.total / row.quantity)
            }else {
              row.quantity = (row.total / this.delComdify(row.price)).toFixed(this.params.quantityAccuracy)
            }
          }
          this.computeTax(row)
        }
      },
      compute33(row) {
        row.total = this.comdify(row.total)
      },
      compute333(row) {
        row.total = this.delComdify(row.total)
      },
      // 输入税率
      compute4(row) {
        if(!row.total) {
          row.total = this.comdify(row.quantity * this.delComdify(row.price))
        }
        if(!row.costTotal) {
          row.costTotal = this.comdify(row.quantity * this.delComdify(row.cost))
        }
        this.computeTax(row)
        this.computeCostTax(row)
      },
      // 输入成本单价
      compute5(row) {
        row.cost = this.handlePriceParams(row.cost)
        if(!row.cost) {
          row.costTotal = ''
          row.totalVat = ''
        }else {
          if(row.quantity || row.costTotal) {
            if(!row.quantity) {
              row.quantity = (this.delComdify(row.costTotal) / row.cost).toFixed(this.params.quantityAccuracy)
            }else {
              // 金额
              row.costTotal = this.comdify(row.quantity * row.cost)
              this.computeCostTax(row)
            }
          }
        }
      },
      compute55(row) {
        row.cost = this.comdifyPrams(row.cost)
      },
      compute555(row) {
        row.cost = this.delComdify(row.cost)
      },
      // 输入成本金额
      compute6(row) {
        row.costTotal = this.handlePrice(row.costTotal)
        if(!row.costTotal) {
          row.cost = ''
          row.costTotalVat = ''
        }else {
          if(row.quantity || row.cost) {
            if(row.quantity) {
              row.cost = this.comdifyPrams(row.costTotal / row.quantity)
            }else {
              row.quantity = (row.costTotal / this.delComdify(row.cost)).toFixed(this.params.quantityAccuracy)
            }
          }
          this.computeCostTax(row)
        }
      },
      compute66(row) {
        row.costTotal = this.comdify(row.costTotal)
      },
      compute666(row) {
        row.costTotal = this.delComdify(row.costTotal)
      },
      // 计算时间
      handleChangeSj1(row) {
        // console.log(row);
        this.startDate = new Date(row.beginValue)
        // console.log(this.startDate);
        if(row.endValue) {
          this.endDate = new Date(row.endValue)
          row.quantity = this.endDate - this.startDate
          // console.log(this.endDate - this.startDate);
        }
      },
      handleChangeSj2(row) {
        // console.log(row);
        this.endDate = new Date(row.endValue)
        // console.log(this.endDate);
        if(row.beginValue) {
          this.startDate = new Date(row.beginValue)
          row.quantity = this.endDate - this.startDate
          
        }
      },
      // 计算数量
      handleCount1(row) {
        this.startCount = row.beginValue
        if(row.endValue) {
          row.quantity = this.endCount - this.startCount
        }
      },
      handleCount2(row) {
        this.endCount = row.endValue
        if(row.beginValue) {
          row.quantity = this.endCount - this.startCount
        }
      },
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown
      }),
      // 税额合计
      ptotalVat() {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let total = 0
          this.thingsList.forEach(item => {
            total += Number(this.delComdify(item.totalVat))
          })
          return total.toFixed(2)
        }
      },
      // 成本税额合计
      ctotalVat() {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let costTotal = 0
          this.thingsList.forEach(item => {
            costTotal += Number(this.delComdify(item.costTotalVat))
          })
          return costTotal.toFixed(2)
        }
      },
      // 金额合计
      ptotalNovat() {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let total = 0
          if(this.isTax) {
            this.thingsList.forEach(item => {
              total = total + Number(this.delComdify(item.total)) - Number(this.delComdify(item.totalVat))
            })
          }else {
            this.thingsList.forEach(item => {
              total += Number(this.delComdify(item.total))
            })
          }
          return total.toFixed(2)
        }
      },
      // 成本金额合计
      ctotalNovat() {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let costTotal = 0
          if(this.isTax) {
            this.thingsList.forEach(item => {
              costTotal = costTotal + Number(this.delComdify(item.costTotal)) - Number(this.delComdify(item.costTotalVat))
            })
          }else {
            this.thingsList.forEach(item => {
              costTotal += Number(this.delComdify(item.costTotal))
            })
          }
          return costTotal.toFixed(2)
        }
      },
      // 价税合计
      ptotal() {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let total = 0
          if(this.isTax) {
            this.thingsList.forEach(item => {
              total = total + Number(this.delComdify(item.total))
            })
          }else {
            this.thingsList.forEach(item => {
              total = total + Number(this.delComdify(item.total)) + Number(this.delComdify(item.totalVat))
            })
          }
          return total.toFixed(2)
        }
      },
      // 成本价税合计
      ctotal() {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          let costTotal = 0
          if(this.isTax) {
            this.thingsList.forEach(item => {
              costTotal = costTotal + Number(this.delComdify(item.costTotal))
            })
          }else {
            this.thingsList.forEach(item => {
              costTotal = costTotal + Number(this.delComdify(item.costTotal)) + Number(this.delComdify(item.costTotalVat))
            })
          }
          return costTotal.toFixed(2)
        }
      },
    },
    watch: {
      'ofoFlowWork': {
        handler(newValue, oldValue) {
          // this.thingsList = []
          // this.getList()
        }
      },
      'isTax': {
        handler(newValue, oldValue) {
          if(oldValue) { // 含税 -> 不含税
            this.thingsList.forEach(item => {
              // console.log(item.total);
              let oldMoneyTax = this.delComdify(item.total)
              let oldMoneyCostTax = this.delComdify(item.costTotal)
              item.total = this.comdify(oldMoneyTax - this.delComdify(item.totalVat))
              item.costTotal = this.comdify(oldMoneyCostTax - this.delComdify(item.costTotalVat))
              if(item.quantity) {
                item.price = this.comdify(this.delComdify(item.total) / item.quantity)
                item.cost = this.comdify(this.delComdify(item.costTotal) / item.quantity)
              }
              
            })
          }else { // 不含税 -> 含税
            this.thingsList.forEach(item => {
              // console.log(item.total);
              let oldMoneyTax = this.delComdify(item.total)
              let oldMoneyCostTax = this.delComdify(item.costTotal)
              item.total = this.comdify(Number(oldMoneyTax) + Number(this.delComdify(item.totalVat)))
              item.costTotal = this.comdify(Number(oldMoneyCostTax) + Number(this.delComdify(item.costTotalVat)))
              if(item.quantity) {
                item.price = this.comdify(this.delComdify(item.total) / item.quantity)
                item.cost = this.comdify(this.delComdify(item.costTotal) / item.quantity)
              }
            })
          }
        },
        deep: true
      },
      'thingsList': {
        handler(newValue, oldValue) {
          // console.log(newValue);
          let list2 = []
          if(this.thingsList) {
            this.thingsList.forEach(item => {
              this.goodsList.forEach(val => {
                val.ofrThingList.forEach(el => {
                  if(el.ofrThingId == item.ofrthingId) {
                    item.ofrresourceId = val.ofrResourceId
                  }
                })
              })
              let obj = {}
              obj.cbowhId = item.cbowhId
              obj.ofrresourceId = item.ofrresourceId
              obj.ofrresourceName = item.ofrresourceName
              obj.ofrthingName = item.ofrthingName
              obj.ofrthingId = item.ofrthingId
              obj.quantity = item.quantity
              obj.cost = ''
              obj.vatgroup = item.vatgroup
              obj.invoicetype = item.invoicetype
              obj.totalVat = this.delComdify(item.totalVat)
              obj.isVat = '0'
              // obj.cost = item.cost
              // obj.costTotal = item.costTotal
              obj.costTotalVat = this.delComdify(item.costTotalVat)
              obj.beginValue = item.beginValue
              obj.endValue = item.endValue
              obj.beginValue = item.beginValue
              obj.endValue = item.endValue
              obj.ofoWorkThingId = item.ofoWorkThingId
              obj.baseOfoWorkThingId = item.baseOfoWorkThingId
              obj.baseOfoWorkId = item.baseOfoWorkId
              obj.remark = item.remark
              obj.configValueList = [...item.workConfigList1, ...item.workConfigList2, ...item.workConfigList3, ...item.workConfigList4]
              if(!this.isTax) {
                let oldMoneyTax = this.delComdify(item.total)
                obj.total = Number(oldMoneyTax) + Number(this.delComdify(item.totalVat))
                let oldMoneyCostTax = this.delComdify(item.costTotal)
                obj.costTotal = Number(oldMoneyCostTax) + Number(this.delComdify(item.costTotalVat))
                if(item.quantity) {
                  obj.price = (obj.total / item.quantity).toFixed(this.params.priceAccuracy)
                  obj.cost = (obj.costTotal / item.quantity).toFixed(this.params.priceAccuracy)
                }
              }else {
                obj.price = this.delComdify(item.price)
                obj.total = this.delComdify(item.total)
                obj.cost = this.delComdify(item.cost)
                obj.costTotal = this.delComdify(item.costTotal)
              }
              obj.cbowhName = this.changeCbowh(item.cbowhId)
              list2.push(obj)
            })
          }
          let ptotalObj = {
            ptotal: this.ptotal,
            ptotalNovat: this.ptotalNovat,
            ptotalVat: this.ptotalVat
          }
          let ctotalObj = {
            ctotal: this.ctotal,
            ctotalNovat: this.ctotalNovat,
            ctotalVat: this.ctotalVat
          }
          let thingsList = [list2, ptotalObj, ctotalObj]
          this.$emit('sendThingsList', thingsList)
        },
        deep: true
      }
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  margin: 0 auto;
  /* max-height: calc(100% - 72px); */
  height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  padding: 10px 0 10px 20px;
  /* max-height: calc(100vh - 206px); */
  height: calc(100vh - 206px);
  overflow-y: auto;
  overflow-x: hidden;
}
.res-dialog {
  ::v-deep .el-dialog {
    margin: 0 auto;
    max-height: calc(100% - 72px);
    height: auto;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px 10px 20px;
    max-height: calc(100vh - 206px);
    height: auto;
    overflow: auto;
  }
}
.right-input {
  ::v-deep .el-input__inner {
    text-align: right;
  }
}
.vatgroup {
  /* color: red; */
  cursor: pointer;
}
.vatgroup:hover {
  color: rgb(32, 160, 255);
}
::v-deep .el-table--border td {
  border-right: 0;
}
</style>