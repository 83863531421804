<template>
  <div class="resource">
    <div class="sift">
      <div class="title">位置查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入名称"
              clearable
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <strong slot="label">类型</strong>
            <el-select v-model="formInline.cbRestypeId" placeholder="请选择类型" filterable clearable @change="inquire" >
              <el-option-group
                v-for="group in resource"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" style="background:#0C75E5;color:white" @click="inquire">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-button icon="el-icon-plus" @click="handleAdd('')" type="primary" plain size="small">新建</el-button>
        <!-- <el-button icon="el-icon-edit-outline" class="edit">编辑</el-button> -->
      </div>
    </div>
    <div class="detail">
      <div class="title">位置列表</div>
      <el-table
        :data="tableData"
        row-key="cbOwhId"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="code"
          label="代码">
        </el-table-column>
        <el-table-column
          prop="cbRestypeName"
          label="类型">

        </el-table-column>
        <el-table-column 
          label="操作"
          fixed="right"
          width="180">
          <template slot-scope="scope">
            <el-button
              size="mini" icon="el-icon-plus" type="text"
              @click="handleAdd(scope.row)">新建</el-button>
            <el-button
              size="mini" icon="el-icon-edit" type="text"
              @click="handleEdit(scope.row)">编辑</el-button>
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="470px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="父级" prop="pid">
          <treeselect v-model="ruleForm.pid" :options="options" :normalizer="normalizer" placeholder="请选择父级" />
        </el-form-item>
        <el-form-item label="资源类型" prop="cbRestypeId">
          <el-select
            v-model="ruleForm.cbRestypeId"
            placeholder="请选择资源类型"
            style="width: 100%"
            filterable
          >
            <el-option-group
              v-for="group in resource"
              :key="group.id"
              :label="group.label">
              <el-option
                v-for="item in group.children"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <template v-if="user.screen == '0'">
          <el-form-item label="是否展示点位" prop="isOnscreen">
            <el-radio v-model="ruleForm.isOnscreen" label="0">不展示</el-radio>
            <el-radio v-model="ruleForm.isOnscreen" label="1">作为点展示</el-radio>
            <el-radio v-model="ruleForm.isOnscreen" label="2">作为下级展示</el-radio>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" @click="handleCoord">选取坐标</el-button>
          </el-form-item>
          <el-form-item label="横坐标" prop="horizontalCoord">
            <el-input-number v-model="ruleForm.horizontalCoord" controls-position="right" :min="0" :max="100" style="width:100%"></el-input-number>
          </el-form-item>
          <el-form-item label="纵坐标" prop="verticalCoord">
            <el-input-number v-model="ruleForm.verticalCoord" controls-position="right" :min="0" :max="100" style="width:100%"></el-input-number>
          </el-form-item>
        </template>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isDisabled">确 定</el-button>
      </div>
    </el-dialog>
    <div class="modal" v-show="isShowModal">
      <div class="close" @click="handleClose">
        <i class="el-icon-close"></i>
      </div>
      <img :src="$base_url + user.screenUrl" alt="" @click="getTarget($event)" ref="image" />
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
  export default {
    components: {
      Treeselect,
      DictTag
    },
    data() {
      return {
        formInline: {
          name: "",
          cbRestypeId: "",
        },
        tableData: [],
        dialogFormVisible: false,
        ruleForm: {
          name: "",
          pid: 0,
          cbRestypeId: "",
          isOnscreen: '0',
          horizontalCoord: null,
          verticalCoord: null
        },
        rules: {
          name: [
            { required: true, message: "请输入名称", trigger: "submit" },
          ],
          pid: [
            { required: true, message: "请选择层级", trigger: "submit" },
          ],
          cbRestypeId: [{ required: true, message: "请选择资源类型", trigger: "submit" }],
        },
        // 树选项
        options: [],
        resource: [],
        title: '',
        loading: true,
        isDisabled: false,
        user: JSON.parse(sessionStorage.getItem('loginUser')),
        isShowModal: false
      }
    },
    created() {
      this.getData()
      this.getList()
    },
    methods: {
      handleCoord() {
        this.isShowModal = true
      },
      handleClose() {
        this.isShowModal = false
      },
      getTarget(e) {
        let x = (e.offsetX / this.$refs.image.offsetWidth * 100).toFixed(2)
        let y = (e.offsetY / this.$refs.image.offsetHeight * 100).toFixed(2)
        this.ruleForm.horizontalCoord = x
        this.ruleForm.verticalCoord = y
        this.isShowModal = false
      },
      // 查询按钮
      inquire() {
        this.loading = true
        this.getList()
      },
      // 清空按钮
      reset() {
        this.formInline.name = this.formInline.cbRestypeId = ''
        this.loading = true
        this.getList()
      },
      handleNodeClick(data) {
        // console.log(data);
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isDisabled = true
            // console.log(this.ruleForm);
            if(this.title === '新增位置') {
              this.$http.post(url.addCbOwh, qs.stringify(this.ruleForm)).then(res => {
                if(res.data.code === 200) {
                  this.isDisabled = false
                  this.dialogFormVisible = false
                  this.$message.success(res.data.msg);
                  this.getList()
                }
              })
            }else {
              this.$http.post(url.editCbOwh, qs.stringify(this.ruleForm)).then(res => {
                if(res.data.code === 200) {
                  this.isDisabled = false
                  this.dialogFormVisible = false
                  this.$message.success(res.data.msg);
                  this.getList()
                }
              })
            }
            
          } else {
            return false;
          }
        });
      },
      handleAdd(row) {
        // console.log(row);
        this.title = "新增位置"
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        this.dialogFormVisible = true
        // this.getList()
        // if(this.options.length === 0) {
          // this.getOptions(this.options)
        // }
        for(let key in this.ruleForm) {
          if(key === 'pid') {
            if(row) {
              this.ruleForm[key] = row.cbOwhId
            }else {
              this.ruleForm[key] = 0
            }
          }else {
            this.ruleForm[key] = ''
          }
        }
      },
      getList() {
        this.options = []
        // 获取位置列表
        this.$http.get(url.getCbOwhTree, {params: {
          cbRestypeId: this.formInline.cbRestypeId,
          name: this.formInline.name
        }}).then(res => {
          if(res.data.code == 200) {
            this.tableData = res.data.data
            this.loading = false
            const data = {id: 0, label: '顶级节点', children: [] };
            data.children = this.getTree(this.tableData);
            this.options.push(data)
          }
        })
      },
      // 获取位置树
      getData() {
        // 获取资源类型列表
        this.$http.get(url.getResTypeTree).then(res => {
          this.resource = res.data.data
        })
      },
      // 获取位置树
      getOptions(options) {
        if(!options.length) {
          const data = {id: 0, label: '顶级节点', children: [] };
          data.children = this.getTree(this.tableData);
          options.push(data)
        }else {
          return
        }
        
      },
      /*转化tree标准格式*/
      getTree(tree) {
        let arr = [];
        if (tree.length !== 0) {
          tree.forEach(item => {
            let obj = {};
            obj.label = item.name;
            obj.id = item.cbOwhId;
            if (item.children.length > 0) {
              obj.children = this.getTree(item.children);
            }
            arr.push(obj);
          });
        }
        return arr
      },
      /** 转换数据结构 */
      normalizer(node) {
        this.$nextTick(() => {
          if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.cbOwhId,
          label: node.name,
          children: node.children
        };
        })
      },
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.cbOwhId
        this.title = "编辑位置"
        // this.getOptions(this.options)
        this.getList()
        this.dialogFormVisible = true
        this.$http.get(url.getCbOwh, {params: {cbOwhId: id}}).then(res => {
          if(res.data.code === 200) {
            let data = res.data.data
            for(let key in data) {
              this.ruleForm[key] = data[key]
            }
          }
        })
      },
      handleDel(row) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
          this.$http.post(url.removeCbOwh, qs.stringify({cbOwhId: row.cbOwhId})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      handleSelectionChange(rows) {
        console.log(rows);
      }
    },
    watch: {
      $route(to,from) {
        // console.log(to.path);
      }
    }
  }
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">
</style>