<template>
  <div class="tag">
    <!-- <el-scrollbar> -->
      <!-- <el-tag
      v-for="(tag, index) in tags"
      :key="index"
      :closable="tag.name !== 'index'"
      :effect="tag.children.length ? ($route.path == tag.children[0].path ? 'dark' : 'plain') : $route.path == tag.path ? 'dark' : 'plain'"
      @click="changeMenu(tag)"
      @close="handleClose(tag,index)"
      @contextmenu.prevent.native="openMenu(tag,index,$event)"
      > -->
      <el-tag
        v-for="(tag, index) in tags"
        :key="index"
        :closable="tag.name !== 'index'"
        :effect="(/\d/).test($route.path) ? ($route.path == tag.path ? 'dark' : 'plain') : ((/\d/).test(tag.path) ? 'plain' : ($route.path.split('/')[1] == tag.path.split('/')[1] ? 'dark' : 'plain'))"
        @click="changeMenu(tag)"
        @close="handleClose(tag,index)"
        @contextmenu.prevent.native="openMenu(tag,index,$event)"
      >
      {{tag.label}}
    </el-tag>
    <!-- </el-scrollbar> -->
    

    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li @click="refreshSelectedTag(selectedTag)"><i class="el-icon-refresh-right"></i> 刷新页面</li>
      <li v-if="tagIndex !== 0" @click="closeSelectedTag"><i class="el-icon-close"></i> 关闭当前</li>
      <li @click="closeOthersTags"><i class="el-icon-circle-close"></i> 关闭其他</li>
      <li v-if="(tagIndex !== 0) && (tagIndex !== 1)" @click="closeLeftTags"><i class="el-icon-back"></i> 关闭左侧</li>
      <li v-if="(tagIndex !== tags.length - 1)" @click="closeRightTags"><i class="el-icon-right"></i> 关闭右侧</li>
      <li @click="closeAllTags"><i class="el-icon-circle-close"></i> 全部关闭</li>
    </ul>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
export default {
  name: 'TagNavigationComponent',
  data() {
    return {
      // 是否显示菜单
      visible: false,
      // 右键菜单距离浏览器左边的距离
      top: 0,
      // 右键菜单距离浏览器上边的距离
      left: 0,
      // 当前右键点击的tag对象
      selectedTag: {},
      // 当前右键点击的tag索引
      tagIndex: 0,
      menuList: JSON.parse(sessionStorage.getItem('menuList'))
    }
  },
  computed: {
    ...mapState({
      tags: (state) => state.tags.tagsList,
      currentMenu: (state) => state.tags.currentMenu,
      currentIndex: (state) => state.tags.currentIndex,
      secondMenu: (state) => state.secondAside.secondMenu,
    }),
  },
  created() {
    
  },
  inject: ["reload"], //注入   和methods同级
  methods: {
    ...mapMutations({
      close: 'closeTag',
    }),
    changeMenu(item) {
      /**
       * 切换组件
       * currentMenu
       */
      this.$store.commit('selectMenu', item)
      this.$store.commit('changeMenu', item)
      this.$store.commit('setJumpData', {})
      this.$router.push(item.path)
      // if(item.path != '/accounting' && item.children.length) {
      //   this.$router.push({path: item.children[0].path})
      // }else {
      //   this.$router.push({path: item.path})
      // }
      this.visible = false
      if(item.path == '/collect' || item.path == '/share' || item.path == '/process' || item.path == '/work' || item.path == '/process') {
        this.$store.commit('setSecondMenu', [])
      }else {
        this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
        this.menuList.forEach(el => {
          if(item.path.indexOf(el.path) == 0) {
            this.$store.commit('setSecondMenu', el.children)
          }
        });
      }
      
    },
    // 点击关闭
    handleClose(tag, index) {
      this.$store.commit('setJumpData', {})
      const length = this.tags.length - 1
      this.close(tag)
      if(this.$route.path.indexOf(tag.path) == -1) {
        if(this.$route.path != '/resource/kind') {
          return
        }
      }
      if(index === length) {
        if(length === 0) {
          if(this.$route.path !== '/index') {
            this.$router.push({path: '/index'})
            this.$store.commit('changeMenu', {
              children: [],
              icon: "iconfont icon-gongzuotai",
              label: "桌面",
              name: "index",
              path: "/index"
            })
          }
        }else{
          this.$router.push({
            path: this.tags[index - 1].path
          })
          // if(this.tags[index - 1].children[0].path != '/accounting' && this.tags[index - 1].children[0].children.length) {
          //   this.$router.push({
          //     path: this.tags[index - 1].children[0].path
          //   })
          // }else {
          //   this.$router.push({
          //     path: this.tags[index - 1].path
          //   })
          // }
          // this.$router.go(-1)
          if(this.tags[index - 1].path == '/collect' || this.tags[index - 1].path == '/share' || this.tags[index - 1].path == '/work' || this.tags[index - 1].path == '/process') {
            this.$store.commit('setSecondMenu', [])
          }else {
            this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
            this.menuList.forEach(el => {
              if(this.tags[index - 1].path.indexOf(el.path) == 0) {
                this.$store.commit('setSecondMenu', el.children)
              }
            });
          }
          // console.log(111);
          // console.log(this.tags.length);
          // console.log(this.tags[index - 1]);
          this.$store.commit('selectMenu', this.tags[index - 1])
          this.$store.commit('changeMenu', this.tags[index - 1])
        }
      }else {
        this.$router.push({
          path: this.tags[index].path
        })
        // if(this.tags[index].children[0].path != '/accounting' && this.tags[index].children[0].children.length) {
        //     this.$router.push({
        //       path: this.tags[index].children[0].path
        //     })
        //   }else {
        //     this.$router.push({
        //       path: this.tags[index].path
        //     })
        //   }
        if(this.tags[index].path == '/collect' || this.tags[index].path == '/share' || this.tags[index].path == '/work' || this.tags[index].path == '/process') {
          this.$store.commit('setSecondMenu', [])
        }else {
          this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
          this.menuList.forEach(el => {
            if(this.tags[index].path.indexOf(el.path) == 0) {
              this.$store.commit('setSecondMenu', el.children)
            }
          });
        }
        this.$store.commit('changeMenu', this.tags[index])
      }
    },
    // 右键菜单位置
    openMenu(tag, index, e) {
      // console.log(index);
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY - 52
      this.visible = true
      this.selectedTag = tag
      // console.log(tag);
      this.tagIndex = index
    },
    // 刷新页面
    refreshSelectedTag(view) {
      if(this.currentIndex === this.tagIndex) {
        this.reload(); //局部刷新
      }else {
        this.$router.push(this.selectedTag.path)
        if(this.selectedTag.path == '/collect' || this.selectedTag.path == '/share' || this.selectedTag.path == '/work' || this.selectedTag.path == '/process') {
          this.$store.commit('setSecondMenu', [])
        }else {
          this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
          this.menuList.forEach(el => {
            if(this.selectedTag.path.indexOf(el.path) == 0) {
              this.$store.commit('setSecondMenu', el.children)
            }
          });
        }
        this.$store.commit('selectMenu', this.selectedTag)
      }
    },
    // 关闭当前
    closeSelectedTag() {
      this.$store.commit('setJumpData', {})
      if (this.tags.length === 1) return
      this.handleClose(this.selectedTag, this.tagIndex)
    },
    // 关闭其他
    closeOthersTags() {
      this.$store.commit('setJumpData', {})
      // console.log(11111);
      if(this.tagIndex === 0) {
        this.closeAllTags()
      }else {
        let newTagList = [this.tags[0]].concat([this.selectedTag])
        // console.log(newTagList);
        this.$store.commit('closeMoreTag', newTagList)
        this.$router.push(this.selectedTag.path)
        this.$store.commit('selectMenu', this.selectedTag)
        if(this.selectedTag.path == '/collect' || this.selectedTag.path == '/share' || this.selectedTag.path == '/work' || this.selectedTag.path == '/process') {
          this.$store.commit('setSecondMenu', [])
        }else {
          this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
          this.menuList.forEach(el => {
            if(this.selectedTag.path.indexOf(el.path) == 0) {
              this.$store.commit('setSecondMenu', el.children)
            }
          });
        }
      }
    },
    // 关闭左侧
    closeLeftTags() {
      this.$store.commit('setJumpData', {})
      let newTagList = [this.tags[0]].concat(this.tags.slice(this.tagIndex, this.tags.length))
      this.$store.commit('closeMoreTag', newTagList)
      if(this.tagIndex > this.currentIndex) {
        this.$router.push(this.selectedTag.path)
        this.$store.commit('selectMenu', this.selectedTag)
      }
    },
    // 关闭右侧
    closeRightTags() {
      this.$store.commit('setJumpData', {})
      let newTagList = this.tags.slice(0, this.tagIndex + 1)
      this.$store.commit('closeMoreTag', newTagList)
      if(this.tagIndex < this.currentIndex) {
        this.$router.push(this.selectedTag.path)
        if(this.selectedTag.path == '/collect' || this.selectedTag.path == '/share' || this.selectedTag.path == '/work' || this.selectedTag.path == '/process') {
          this.$store.commit('setSecondMenu', [])
        }else {
          this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
          this.menuList.forEach(el => {
            if(this.selectedTag.path.indexOf(el.path) == 0) {
              this.$store.commit('setSecondMenu', el.children)
            }
          });
        }
        this.$store.commit('selectMenu', this.selectedTag)
      }
    },
    // 全部关闭
    closeAllTags() {
      this.$store.commit('setJumpData', {})
      let newTagList = [this.tags[0]]
      // console.log(newTagList);
      this.$store.commit('closeMoreTag', newTagList)
      this.$router.push({path: '/index'})
      this.$store.commit('changeMenu', {
        children: [],
        icon: "iconfont icon-gongzuotai",
        label: "桌面",
        name: "index",
        path: "/index"
      })
      this.$store.commit('setSecondMenu', [])
    }
  },
  mounted () {
    document.addEventListener("click", (e) => {
      const target = e.target
      if (target.nodeName !== 'SPAN') { // 只要点击的不是el-tag就可以关闭，因为el-tag是用span标签实现的
        this.visible = false
      };
    })
  },
}
</script>

<style scoped lang="scss">
.tag {
  height: 30px;
  background-color: #fff;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  /* width: calc(100% - 82px); */
  /* width: calc(100% - 82px); */
  box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
  /* overflow-x: auto; */
  -webkit-overflow-scrolling: touch;
  display: flex;
  position: relative;
  z-index: 3000;
  /* .el-scrollbar {
    
    display: flex;
    width: 100%;
    height: 30px;
    overflow-x: hidden;
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  ::v-deep .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view{
  white-space: nowrap;
  display: inline-block;
} */
  .el-tag {
    cursor: pointer;
    list-style: none;
    height: 20px;
    border-radius: 10px;
    line-height: 17px;
    font-size: 11px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #A0A0A0;
    padding: 0 9px;
    margin-right: 16px;
    background-color: #fff;
    border-color: #A0A0A0;
    flex-shrink: 0;
    flex-wrap: nowrap;
  }
  .el-tag--dark {
    background-color: #0C75E5;
    color: #fff;
    border: 0;
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
/* .tag::-webkit-scrollbar {
  display: none;
} */
</style>