export default function downFile(fileUrl, fileName) {
  // console.log(file);
  // console.log(file);
  let type = fileName.split('.').pop()
  console.log(type);
  getBlob(fileUrl).then(blob => {
    saveAs(blob, fileName)
  })
}

//通过文件下载url拿到对应的blob对象
function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}

//下载文件 　　js模拟点击a标签进行下载
function saveAs(blob, filename) {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}