<template>
  <div class="user">
    <div class="main">
      <div class="title">用户列表</div>
      <el-form :inline="true" :model="siftForm" class="demo-form-inline"  size="small">
        <el-form-item>
          <strong slot="label">账号</strong>
          <el-input
            v-model="siftForm.userName"
            placeholder="请输入账号"
            clearable
            @keyup.enter.native="inquire"
            @clear="inquire"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <strong slot="label">名称</strong>
          <el-input
            v-model="siftForm.nickName"
            placeholder="请输入名称"
            clearable
            @keyup.enter.native="inquire"
            @clear="inquire"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
          <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd">新增</el-button>
      <el-button type="success" plain size="small" icon="el-icon-edit" :disabled="!edit" @click="handleEdit">编辑</el-button>
      <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;margin-top:16px"
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="userName"
          label="账号">
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="phonenumber"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="bussName"
          label="员工">
        </el-table-column>
        <el-table-column
          prop="orgName"
          label="公司">
        </el-table-column>
        <!-- <el-table-column
          label="关联人员"
          prop="editType">
          <template slot-scope="scope">
            <DictTag :options="dropDown.editType" :value="scope.row.editType" :isStyle="true"/>
          </template>
        </el-table-column> -->
        <el-table-column 
          label="操作"
          fixed="right"
          width="120">
          <template slot-scope="scope">
            <el-button
              size="mini" icon="el-icon-edit" type="text"
              @click="handleEdit(scope.row)">编辑</el-button>
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="50%"
    >
      <el-form
        :model="ruleForm"
        :rules="password ? rules1 : rules2"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号" prop="userName">
              <el-input v-model="ruleForm.userName" placeholder="请输入账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="nickName">
              <el-input v-model="ruleForm.nickName" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="密码" prop="password1">
              <el-input v-model="ruleForm.password1" placeholder="请输入密码" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="password2">
              <el-input v-model="ruleForm.password2" placeholder="请再次输入密码" type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号" prop="phonenumber">
              <el-input v-model="ruleForm.phonenumber" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="人员" prop="bussId">
              <el-select v-model="ruleForm.bussId" placeholder="请选择关联人员" style="width:100%" filterable>
                <!-- <el-option v-for="(item, index) in dropDown.stepType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option> -->
                <el-option-group
                  v-for="group in userList"
                  :key="group.ofrResourceId"
                  :label="group.resourceName">
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
import {mapState} from 'vuex'
import DictTag from '@/components/DictTag.vue'
  export default {
    components: {
      DictTag,
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      var validatePass = (rule, value, callback) => {
        // console.log(rule);
        // console.log(value);
        if(rule.required) { // 新增，密码必填
          if (!value) {
            callback(new Error('请输入密码'));
          } else {
            // if (this.ruleForm.password2 !== '') {
            //   this.$refs.ruleForm.validateField('password2');
            // }
            callback();
          }
        }else {
          callback();
        }
        
      };
      var validatePass2 = (rule, value, callback) => {
        if(rule.required) {
          if (!value) {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.ruleForm.password1) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        }else {
          callback();
        }
        
      };
      var validatePhone = (rule, value, callback) => {
        let reg = /^1[35789]\d{9}$/
        // console.log(rule);
        if(value) {
          if (!reg.test(value)) {
            callback(new Error('请输入正确的手机号格式'));
          } else {
            callback();
          }
        }else {
          callback();
        }
        
      };
      return {
        tableData: [],
        dialogFormVisible: false,
        ruleForm: {
          userName: '',
          nickName: '',
          password1: '',
          password2: '',
          phonenumber: '',
          bussId: ''
        },
        rules1: {
          userName: [{ required: true, message: "请输入账号", trigger: "submit" }],
          nickName: [{ required: true, message: "请输入名称", trigger: "submit" }],
          password1: [{ required: true, validator: validatePass, trigger: "submit" }],
          password2: [{ required: true, validator: validatePass2, trigger: "blur" }],
          phonenumber: [{ required: false, validator: validatePhone, trigger: "blur" }],
          bussId: [{ required: true, message: "请选择关联人员", trigger: "submit" }],
        },
        rules2: {
          userName: [{ required: true, message: "请输入账号", trigger: "submit" }],
          nickName: [{ required: true, message: "请输入名称", trigger: "submit" }],
          password1: [{ required: false, validator: validatePass, trigger: "submit" }],
          password2: [{ required: false, validator: validatePass2, trigger: "blur" }],
          phonenumber: [{ required: false, validator: validatePhone, trigger: "blur" }],
          bussId: [{ required: true, message: "请选择关联人员", trigger: "submit" }],
        },
        // 维度
        options: [],
        // 弹出层标题
        title: '',
        edit: false,
        del: false,
        multipleSelection: [],
        ids: [],
        loading: true,
        isLoading: false,
        userList: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        password: true,
        siftForm: {
          nickName: '',
          userName: ''
        }
      }
    },
    created() {
      this.getData()
      this.getList()
    },
    methods: {
      getData() {
        // 获取关联人员列表
        this.$http.get(url.getPostAndUserList).then(res => {
          if(res.data.code == 200) {
            this.userList = res.data.data
          }
        })
      },
      // 获取用户列表
      getList() {
        this.$http.get(url.getUserList, {params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.siftForm
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
            this.total = res.data.data.total
          }
        })
      },
      inquire() {
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      reset() {
        this.siftForm.nickName = ''
        this.siftForm.userName = ''
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      // 表格选中框事件
      handleSelectionChange(rows) {
        // this.multipleSelection = rows
        this.ids = rows.map(item => item.userId)
        this.edit = rows.length === 1
        this.del = rows.length
      },
      // 表格的修改按钮
      handleEdit(row) {
        this.password = false
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.userId || this.ids[0]
        this.title = '编辑用户'
        this.dialogFormVisible = true
        // this.selected = row
        this.$http.get(url.getUser, {params: {userId: id}}).then(res => {
          this.ruleForm = res.data.data
        })
      },
      // 表格的删除按钮
      handleDel(row) {
        // this.selected = row
        const ids = row.userId || this.ids.toString()
        this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeUser, qs.stringify({userIds: ids})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.pageNum = 1
              this.loading = true
              this.getList()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            if(!this.ruleForm.userId) {
              this.$http.post(url.addUser, qs.stringify(this.ruleForm)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.pageNum = 1
                  this.loading = true
                  this.getList()
                }
              })
            }else {
              this.$http.post(url.editUser, qs.stringify(this.ruleForm)).then(res => { 
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.pageNum = 1
                  this.loading = true
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleAdd() {
        this.password = true
        this.title = '新增用户'
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        this.dialogFormVisible = true
        // 初始化表单内容
        for(let key in this.ruleForm) {
          this.ruleForm[key] = ""
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.loading = true
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.loading = true
        this.getList()
      },
    }
  }
</script>

<style lang="scss" scoped>
.user {
  /* padding: 16px; */
  .main {
    background: #ffffff;
    box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add {
      margin-bottom: 16px;
    }
    .el-table {
      margin-bottom: 39px;
    }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>