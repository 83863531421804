import Home from '@/views/home/Index.vue'
import Collect from '@/views/collect/Index.vue'
import Share from '@/views/share/Index'

// import Deal from '@/views/deal/Index.vue'
import Manage from '@/views/manage/Index.vue'
// 待开发
import Develop from '@/views/develop/Index.vue'
// 驾驶舱
import Screen from '@/views/screen/Index.vue'
// 审批
import Process from '@/views/process/Index.vue'
// 活动
import Work from '@/views/work/Index.vue'
// 数据看板
import Dashboard from '@/views/dashboard/Index.vue'

const routes = [
  {
    path: '/index',
    name: 'index',
    meta: {
      keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    },
    component: Home
  },
  {
    path: '/collect',
    name: 'collect',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Collect
  },
  {
    path: '/share',
    name: 'share',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Share
  },
  // {
  //   path: '/mine',
  //   name: 'mine',
  //   // meta: {
  //   //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
  //   // },
  //   component: Deal
  // },
  {
    path: '/manage',
    name: 'manage',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Manage
  },
  
  {
    path: '/screen',
    name: 'screen',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Screen
  },
  {
    path: '/process',
    name: 'process',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Process
  },
  {
    path: '/work',
    name: 'work',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Work
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Dashboard
  },
  {
    path: '*',
    name: 'develop',
    component: Develop
  },
]

export default routes