<template>
  <div class="process">
    <div class="main">
      <div class="title">流程列表</div>
      <!-- <el-button type="primary" plain class="add" icon="el-icon-plus" @click="handleAdd" size="small">新增</el-button> -->
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <el-table-column prop="showName" label="流程名称">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.showName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="代码"></el-table-column>
        <el-table-column prop="code" label="菜单类型">
          <template slot-scope="scope">
            <!-- <DictTag :options="dropDown.itemType" :value="scope.row.itemType" :isStyle="true"/> -->
            {{scope.row.itemType == 'project' ? '项目' : '标准'}}
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="描述" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column label="操作" align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click.native.stop="drawer = true"
            >编辑
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              @click.native.stop="handleDel(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :modal-append-to-body="false">
      <span>我来啦!</span>
    </el-drawer> -->
    <el-dialog
      title="新增维度"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="50%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请选择维度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布类型" prop="documentstatus">
              <el-select v-model="ruleForm.documentstatus" placeholder="请选择发布类型" style="width:100%">
                <!-- <el-option v-for="(item, index) in dropDown.thingDimeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="流程组件" prop="documentstatus">
              <el-select v-model="ruleForm.documentstatus" placeholder="请选择发布类型" style="width:100%">
                <!-- <el-option v-for="(item, index) in dropDown.thingDimeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="流程" prop="documentstatus">
              <el-select v-model="ruleForm.documentstatus" placeholder="请选择发布类型" style="width:100%">
                <!-- <el-option v-for="(item, index) in dropDown.thingDimeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单类型" prop="itemType">
              <el-select v-model="ruleForm.itemType" placeholder="请选择维度描述类型" style="width:100%">
                <!-- <el-option v-for="(item, index) in dropDown.dimeRemarksType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述" prop="remarks">
            <el-input type="textarea" v-model="ruleForm.remarks" placeholder="请输入描述"></el-input>
          </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isDisabled">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
  export default {
    components: {
      DictTag,
    },
    data() {
      return {
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        ruleForm: {
          name: '',
          documentstatus: "",
          itemType: "",
          remarks: ''
        },
        rules: {
          name: [{ required: true, message: "请输入名称", trigger: "submit" }],
          documentstatus: [{ required: true, message: "请选择发布类型", trigger: "submit" }],
          itemType: [{ required: true, message: "请选择菜单类型", trigger: "submit" }],
        },
        // options: [],
        // 弹出层标题
        title: '',
        isDisabled: false,
        drawer: false
      }
    },
    created () {
      this.getList()
    },
    methods: {
      getList() {
        this.$http.get(url.getCflowItemList).then(res => {
          if(res.data.code == 200) {
            this.loading = false
            this.tableData = res.data.data
          }
        })
      },
      handleAdd() {
        this.dialogFormVisible = true
      },
      handleDetail(row) {
        this.$router.push({path: '/set/process/' + row.cfFlowItemId, query: {name: row.showName}})
        let obj = {
          path: '/set/process/' + row.cfFlowItemId,
          name: 'processDetail',
          label: '流程详情',
          children: []
        }
        this.$store.commit("selectMenu", obj);
      },
      handleEdit() {},
      handleDel() {},
      handleSelectionChange() {},
    },
  }
</script>

<style lang="scss" scoped>
.process {
  /* padding: 16px; */
  .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add{
      margin-bottom: 16px;
    }
    .el-table {
      /* margin-bottom: 39px; */
      .el-button {
        color: #0c75e5;
        border: 0;
        padding: 0;
      }
    }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>