<template>
  <div class="import">
    <div class="sift" v-show="isShowFormInline">
      <div class="title">项目筛选查询</div>
      <el-form :inline="true" :model="siftForm" class="demo-form-inline" @submit.native.prevent size="small">
        <el-form-item>
          <span slot="label">
            <strong>名称</strong>
          </span>
          <el-input
            v-model="siftForm.restypeName"
            placeholder="请输入名称"
            clearable
            @keyup.enter.native="inquire"
            @clear="inquire"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <strong>种类</strong>
          </span>
          <el-select v-model="siftForm.kind" placeholder="请选择种类" clearable filterable @change="inquire">
            <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
          <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      
      
    </div>
    <div class="main">
      <div style="display:flex;justify-content: space-between">
        <div class="title">导入资源</div>
        <el-tooltip class="item" effect="dark" :content="isShowFormInline ? '隐藏搜索' : '显示搜索'" placement="top-end">
          <el-button class="hidden" icon="el-icon-search" @click="isShowFormInline = !isShowFormInline" style="border: 0;padding:0"></el-button>
        </el-tooltip>
      </div>
      
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <el-table-column prop="restypeName" label="资源类型" width="180">
        </el-table-column>
        <el-table-column prop="dime" label="维度" show-overflow-tooltip> </el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              size="small"
              icon="el-icon-download"
              type="text"
              @click="exportBtn(scope.row)"
              >导出</el-button
            >
            
            <el-button size="mini" type="text" icon="el-icon-upload2" @click="importBtn(scope.row)">导入</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      width="400px">
      <el-upload
        class="upload-demo"
        action="#"
        :auto-upload="false"
        :limit="1"
        :file-list="fileList"
        :on-change="handleChange"
        drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpload" :disabled="isDisabled" :loading="isLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
// import XLSX from 'xlsx'
import * as XLSX from 'xlsx'
import { mapState } from 'vuex'
import Upload from '@/components/upload/index.vue'
export default {
  components: {
    Upload,
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      fileList: [],
      dialogVisible: false,
      title: '',
      cbRestypeId: '',
      loading: true,
      isDisabled: false,
      isLoading: false,
      isShowFormInline: false,
      siftForm: {
        kind: '',
        restypeName: ''
      },
      loadProgress: 0, // 动态显示进度条
      progressFlag: false,
      proTimer: null
    };
  },
  created() {
    this.getList();
  },
  destroyed () {
    this.proTimer ? clearInterval(this.proTimer) : ''
  },
  methods: {
    getList() {
      // 获取种类下拉
      if(!this.dropDown.kind){
        this.$http.get(url.getDataList, {params: {type: 'buss_kind'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setKind', res.data.data)
          }
        })
      }
      this.$http.get(url.getImportResTypeList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.siftForm
        },
      }).then((res) => {
        this.loading = false
        if(res.data.code == 200) {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    inquire() {
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    reset() {
      this.siftForm.kind = ''
      this.siftForm.restypeName = '',
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.loading = true
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.loading = true
      this.getList();
    },
    // 导出按钮
    exportBtn(row) {
      this.$confirm(`此操作将导出${row.restypeName}模板, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        
        let tableData = row.dime.split(', ')
        if(row.kind == '1') {
          tableData.push('部门')
        }
        if(row.isPrivate == '0') {
          tableData.push('归属人')
        }
        let workSheet = XLSX.utils.aoa_to_sheet([tableData]);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, row.restypeName) // 工作簿名称
        let name = row.restypeName + '.xlsx'
        XLSX.writeFile(bookNew, name) // 保存的文件名
      }).catch(() => {   
      });
    },
    // 导入按钮
    importBtn(row) {
      this.dialogVisible = true
      // console.log(row);
      this.title = row.restypeName
      this.cbRestypeId = row.cbRestypeId
      this.fileList = []
      this.loadProgress = 0
      this.progressFlag = false
    },
    confirmUpload() {
      this.isDisabled = true
      this.isLoading = true
      this.progressFlag = true
      // console.log(this.fileList);
      const formData = new FormData()
      formData.append('file', this.fileList[0].raw)
      formData.append('cbRestypeId', this.cbRestypeId)
      let time = new Date().getTime() + Math.random()
      formData.append('key', time)
      this.getProgress(time)
      this.$http.post(url.importResTypeList, formData).then(res => {
        this.isDisabled = false
        this.isLoading = false
        this.proTimer ? clearInterval(this.proTimer) : ''
        this.loadProgress = 100
        if(res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.dialogVisible = false
        }
      })
    },
    getProgress(key) {
      this.proTimer = setInterval(() => {
        this.$http.get(url.progress + key).then(res => {
          // console.log(res);
          this.loadProgress = res.data.data
        })
      }, 2000);
      
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    handlePreview(file) {
      // console.log(file);
    }
  },
};
</script>

<style lang="scss" scoped>
.import {
  /* padding: 16px; */
  .sift, .main {
    background: #ffffff;
    box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    margin-bottom: 16px;
  }
  .main {
    margin-bottom: 0;
    .el-table {
      margin-bottom: 39px;
    }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>