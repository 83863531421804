<template>
  <div class="app">
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/> -->
    <router-view v-if="isRouterAlive"/>
    <!-- <router-view /> -->
  </div>
</template>

<script>
var vm
export default {
  name: "app",
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    //提供
    return {
      reload: this.reload,
    };
  },
  mounted () {
    // console.log(this.$route);
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL); //这里有没有都无所谓，最好是有以防万一
    //   window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
    // }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    goBack() {
      // console.log(111);
      this.$router.replace({
        path:'/index'
      })
    }
  },
  destroyed(){
    // window.removeEventListener('popstate', this.goIndex, false);
  }
  // watch: {
  //   this.$router.history(newValue, oldValue) {
      
  //   }
  // },
}
</script>

<style lang="scss">

html, body, #app, .app{
  height: 100%;
  /* overflow: hidden; */
}
* {
  margin: 0;
  padding: 0;
}
.link {
  color: #337ab7;
  cursor: pointer;
}
.link:hover {
  color: rgb(32, 160, 255);
}
::-webkit-scrollbar {
      width: 10px;
      height: 10px; 
      background-color: #fff;
  }

  /* 定义滚动条轨道 内阴影 */
  ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: #ddd;
  }

  /* 定义滑块 内阴影 */
  ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: #aaa;
      border-radius: 10px;
  }
.show-mask {
  width: 12px;
  height: 30px;
  border-left: 12px solid #ddd;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: relative;
  cursor: pointer;

  >i {
    color: #999;
    position: absolute;
    top: 7px;
    left: -14px;
    font-size: 14px;

  }

  &:hover {
    border-left: 12px solid #409eff;

    >i {
      color: #fff;
    }
  }
}

</style>