<template>
  <div class="process-bar">
    <!-- <el-menu
      :default-active="currentMenu.path"
      class="el-menu-vertical-demo"
      text-color="#282828"
      active-text-color="#0C75E5"
      active-background-color="#E5F3FF"
    > -->
    <el-menu
      :default-active="currentMenu ? ('/' + currentMenu.path.split('/')[1] + '/' + currentMenu.path.split('/')[2]): ''"
      class="el-menu-vertical-demo"
      text-color="#282828"
      active-text-color="#0C75E5"
      active-background-color="#E5F3FF"
    >
      <el-menu-item
        v-for="item in secondMenu"
        :key="item.path"
        :index="item.path"
        @click="clickMenu(item)"
      >
        <i :class="item.icon"></i>
        <span>{{item.label}}</span>
        <span class="badge" v-if="item.badge">{{approvalCount}}</span>
      </el-menu-item>
    </el-menu>
    
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState({
      currentMenu: (state) => state.tags.currentMenu,
      secondMenu: (state) => state.secondAside.secondMenu,
      tagsList: (state) => state.tags.tagsList,
      // path: (state) => state.secondAside.path 
    }),
  },
  data() {
    return {
      router: true,
      // path: '/process/approval',
      // menu: [
      //   {
      //     path: '/process/approval',
      //     name: 'approval',
      //     label: '待我审批',
      //     icon: 'iconfont icon-tianshenpi',
      //     badge: true
      //   },
      //   {
      //     path: '/process/done',
      //     name: 'done',
      //     label: '已完成',
      //     icon: 'iconfont icon-yiwancheng',
      //   },
      //   {
      //     path: '/process/mine',
      //     name: 'mine',
      //     label: '我发起的',
      //     icon: 'iconfont icon-31wode',
      //   },
      // ],
      approvalCount: 0
    }
  },
  created() {
    this.path = this.$router.history.current.path
    this.$eventBus.$on('getApprovalCount',data => {
      // console.log(data);
      if(data) {
        this.approvalCount = data
      }else {
        this.approvalCount = 0
      }
    })
  },
  methods: {
    clickMenu(item) {
      this.$router.push({
        path: item.path
      })
      // console.log(item);
      this.tagsList.forEach((val, index) => {
        
        if(item.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path.split('/')[3])) {
          // console.log(val);
          for(let key in val) {
            if(key != 'label' && key != 'icon') {
            // if(key != 'children' && key != 'label' && key != 'icon') {
              val[key] = item[key]
            }
          }
          this.$store.commit('selectMenu', val)
        }

      })
      // console.log(1111);
      // console.log(this.tagsList);
      this.$store.commit('changeMenu', item)




      // console.log(item);
      // console.log(this.tagsList);

      // this.$store.commit('selectMenu', item)
      // this.tagsList.forEach((val, index) => {
      // //   // console.log(val.path.split('/'));
        
      //   if(item.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path.split('/')[3])) {
      //     // console.log(val);
      //     for(let key in val) {
      //       if(key != 'children' && key != 'label' && key != 'icon') {
      //         val[key] = item[key]
      //       }
      //     }
      //     this.$store.commit('selectMenu', val)
      //   }else {
      //     // this.$store.commit('selectMenu', item)
      //   }
      // })
      // this.$store.commit('changeMenu', item)
    }
  }
};
</script>

<style scoped lang="scss">
.process-bar {
  height: 100%;
  /* position: fixed; */

  >.el-menu {
    /* height: 100%; */
    width: 160px;
    /* padding: 0 8px; */
    /* box-shadow: 2px 3px 8px 0px rgba(208,208,208,1); */
    border: 0;
    > .el-menu-item {
      height: 30px;
      /* line-height: 30px; */
      margin: 8px;
      font-size: 13px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      line-height: 30px;
      display: flex;
      align-items: center;
      padding:7px !important;
      margin-bottom: 16px;
      .iconfont {
        margin-right: 8px;
      }
    }
  }
  .el-menu-item.is-active {
   background-color: #E5F3FF !important;
  }
  .badge {
    position: absolute;
    /* z-index: 99; */
    /* top: 57px; */
    right: 19px;
    height: 17px;
    font-size: 14px;
    font-family: Helvetica;
    color: #FA0000;
    line-height: 17px;
  }
  /* .approval-badge {
    top: 18px;
  }
  .done-badge {
    top: 96px;
  } */
}


</style>