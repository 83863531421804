import Project from '@/views/project/Index.vue'
import Detail from '@/views/project/detail.vue'

const routes = [
  {
    path: '/project',
    name: 'project',
    meta: {
      keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    },
    component: Project,
  },
  {
    path: '/project/:ofoItemId',
    name: 'proDetail',
    // meta: {
    //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
    // },
    component: Detail
  },
  {
    path: '/project/activity/:ofoWorkId',
    name: 'activityDetail',
    component: () => import('@/views/project/activity.vue')
  }
]

export default routes