<template>
  <div class="entry-detail">
    <div class="main">
      <el-button icon="el-icon-printer" type="text" style="float:right;padding: 0;" @click="clickPrint" v-print="print"></el-button>
      <div id="print">
        <div style="text-align:center;margin-bottom:16px;font-weight:600;font-size:18px">记账凭证</div>
        <el-descriptions :column=3>
          <el-descriptions-item label="编号">{{account ? ojdtData.accountCode : ojdtData.code}}</el-descriptions-item>
          <el-descriptions-item label="序号">{{ojdtData.offOjdtId}}</el-descriptions-item>
          <el-descriptions-item label="发生日期">{{ojdtData.ojdtDate | filter_handleDate}}</el-descriptions-item>
          <el-descriptions-item label="所属期间">{{ojdtData.tbperdName}}</el-descriptions-item>
          <el-descriptions-item label="活动详情">
            <a @click="goProject(ojdtData)" class="link">{{ojdtData.ofoworkName}}</a>
          </el-descriptions-item>
        </el-descriptions>
        <table border="1" cellSpacing="0" style="width:100%;border: 1px solid #abc8df">
          <tr style="background: #e1f0fc" height="30">
            <th rowspan="2" width="32">行</th>
            <th rowspan="2" width="20%">摘要</th>
            <th rowspan="2">会计科目</th>
            <th colspan="13">借方金额</th>
            <th colspan="13">贷方金额</th>
          </tr>
          <tr style="background: #e1f0fc" height="30">
            <th width="16">百</th>
            <th width="16" class="border-right">十</th>
            <th width="16" class="border-left">亿</th>
            <th width="16">千</th>
            <th width="16" class="border-right">百</th>
            <th width="16" class="border-left">十</th>
            <th width="16">万</th>
            <th width="16" class="border-right">千</th>
            <th width="16" class="border-left">百</th>
            <th width="16">十</th>
            <th width="16" class="border-right">元</th>
            <th width="16" class="border-left">角</th>
            <th width="16">分</th>
            <th width="16">百</th>
            <th width="16" class="border-right">十</th>
            <th width="16" class="border-left">亿</th>
            <th width="16">千</th>
            <th width="16" class="border-right">百</th>
            <th width="16" class="border-left">十</th>
            <th width="16">万</th>
            <th width="16" class="border-right">千</th>
            <th width="16" class="border-left">百</th>
            <th width="16">十</th>
            <th width="16" class="border-right">元</th>
            <th width="16" class="border-left">角</th>
            <th width="16">分</th>
          </tr>
          <!-- v-if="item.debit1.length != 0 && item.credit1.length != 0" -->
          <tr height="60" v-for="(item, index) in ojdtacctList" :key="index" v-if="item.debit1 || item.credit1">
            <!-- <td>{{item.debit1.length}}</td>
            <td>{{item.credit1.length}}</td> -->
            <td align="center">{{index + 1}}</td>
            <td style="padding:8px">{{item.remarks}}</td>
            <td style="padding:8px">
              {{item.acctName + ' : ' +  item.acctCode}}
              <!-- <div style="color: #1a92ff;margin-top:8px">余额：{{item.debit | comdify}}</div> -->
            </td>
            <td align="right">{{item.debit1[item.debit1.length - 13]}}</td>
            <td class="border-right" align="right">{{item.debit1[item.debit1.length - 12]}}</td>
            <td class="border-left" align="right">{{item.debit1[item.debit1.length - 11]}}</td>
            <td align="right">{{item.debit1[item.debit1.length - 10]}}</td>
            <td class="border-right" align="right">{{item.debit1[item.debit1.length - 9]}}</td>
            <td class="border-left" align="right">{{item.debit1[item.debit1.length - 8]}}</td>
            <td align="right">{{item.debit1[item.debit1.length - 7]}}</td>
            <td class="border-right" align="right">{{item.debit1[item.debit1.length - 6]}}</td>
            <td class="border-left" align="right">{{item.debit1[item.debit1.length - 5]}}</td>
            <td align="right">{{item.debit1[item.debit1.length - 4]}}</td>
            <td class="border-right" align="right">{{item.debit1[item.debit1.length - 3]}}</td>
            <td class="border-left" align="right">{{item.debit1[item.debit1.length - 2]}}</td>
            <td align="right">{{item.debit1[item.debit1.length - 1]}}</td>
            <td align="right">{{item.credit1[item.credit1.length - 13]}}</td>
            <td class="border-right" align="right">{{item.credit1[item.credit1.length - 12]}}</td>
            <td class="border-left" align="right">{{item.credit1[item.credit1.length - 11]}}</td>
            <td align="right">{{item.credit1[item.credit1.length - 10]}}</td>
            <td class="border-right" align="right">{{item.credit1[item.credit1.length - 9]}}</td>
            <td class="border-left" align="right">{{item.credit1[item.credit1.length - 8]}}</td>
            <td align="right">{{item.credit1[item.credit1.length - 7]}}</td>
            <td class="border-right" align="right">{{item.credit1[item.credit1.length - 6]}}</td>
            <td class="border-left" align="right">{{item.credit1[item.credit1.length - 5]}}</td>
            <td align="right">{{item.credit1[item.credit1.length - 4]}}</td>
            <td class="border-right" align="right">{{item.credit1[item.credit1.length - 3]}}</td>
            <td class="border-left" align="right">{{item.credit1[item.credit1.length - 2]}}</td>
            <td align="right">{{item.credit1[item.credit1.length - 1]}}</td>
          </tr>
          <tr height="60" style="background: #e1f0fc">
            <td colspan="3" style="padding-left:8px;font-weight:600;">合计：{{ojdtData.totalText}}</td>


            <td align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 13]}}</td>
            <td class="border-right" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 12]}}</td>
            <td class="border-left" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 11]}}</td>
            <td align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 10]}}</td>
            <td class="border-right" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 9]}}</td>
            <td class="border-left" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 8]}}</td>
            <td align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 7]}}</td>
            <td class="border-right" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 6]}}</td>
            <td class="border-left" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 5]}}</td>
            <td align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 4]}}</td>
            <td class="border-right" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 3]}}</td>
            <td class="border-left" align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 2]}}</td>
            <td align="right">{{ojdtData.debitTotal1[ojdtData.debitTotal1.length - 1]}}</td>
            <!-- <td align="right">{{ojdtData.debitTotal1}}</td> -->



            <td align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 13]}}</td>
            <td class="border-right" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 12]}}</td>
            <td class="border-left" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 11]}}</td>
            <td align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 10]}}</td>
            <td class="border-right" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 9]}}</td>
            <td class="border-left" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 8]}}</td>
            <td align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 7]}}</td>
            <td class="border-right" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 6]}}</td>
            <td class="border-left" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 5]}}</td>
            <td align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 4]}}</td>
            <td class="border-right" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 3]}}</td>
            <td class="border-left" align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 2]}}</td>
            <td align="right">{{ojdtData.creditTotal1[ojdtData.creditTotal1.length - 1]}}</td>
          </tr>
        </table>
        
        <el-descriptions :column=3 style="margin-top:16px">
          <el-descriptions-item label="审核人">{{ojdtData.checkUserName}}</el-descriptions-item>
          <el-descriptions-item label="记账人">{{ojdtData.accountUserName}}</el-descriptions-item>
          <el-descriptions-item label="注释">{{ojdtData.remarks}}</el-descriptions-item>
          <el-descriptions-item label="制单">{{ojdtData.ofrthingName}}</el-descriptions-item>
        </el-descriptions>
      </div>
      
      <!-- <el-table
        :data="ojdtacctList"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}" ref="multipleTable" style="margin-top: 20px">
        <el-table-column type="selection" width="55"> </el-table-column>
        科目
        <el-table-column
          prop="acctName"
          label="科目">
          <template slot-scope="scope">
            {{scope.row.acctName + ' : ' +  scope.row.acctCode}}
          </template>
        </el-table-column>
        借方
        <el-table-column
          prop="debit"
          label="借方"
          width="150"
          align="right">
          <template slot-scope="scope">
            {{scope.row.debit | comdify('no')}}
          </template>
        </el-table-column>
        贷方
        <el-table-column
          prop="credit"
          label="贷方"
          width="150"
          align="right">
          <template slot-scope="scope">
            {{scope.row.credit | comdify('no')}}
          </template>
        </el-table-column>
        项目	
        <el-table-column
          prop="ofoitemId"
          label="项目"
          width="200">
        </el-table-column>
        描述
        <el-table-column
          prop="remarks"
          label="描述">
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import {toChies} from '@/utils/toChies'
  export default {
    data() {
      return {
        offOjdtId: this.$route.params.offOjdtId,
        ojdtData: {},
        ojdtacctList: [],
        account: this.$route.query.account,
        
        print: {
          id: 'print',
          popTitle: '',
        },
      }
    },
    created () {
      this.getdata();
      // console.log(toChies(0.00));
      // this.numToArr(11290.1)
    },
    methods: {
      clickPrint() {},
      numToStr(num) {
        if(num == null || num == 0) {
          return ''
        }else {
          let str = num + ''
          if(str.indexOf('.') == -1) {
            str = str + '.00'
          }else {
            let last = str.substring(str.indexOf('.') + 1, str.length)
            // console.log(last);
            if(last.length == 1) {
              str = str + '0'
            }
          }
          return str.replace('.', '').replace(/\b(0+)/gi,'')
        }
        
      },
      getdata() {
        this.$http.get(url.getOffOjdt, {params: {offOjdtId: this.offOjdtId}}).then(res => {
          this.ojdtData = res.data.data.ojdt
          if(this.ojdtData.debitTotal) {
            this.ojdtData.debitTotal1 = this.numToStr(this.ojdtData.debitTotal)
          }else {
            this.ojdtData.debitTotal1 = '000'
          }
          if(this.ojdtData.creditTotal) {
            this.ojdtData.creditTotal1 = this.numToStr(this.ojdtData.creditTotal)
          }else {
            this.ojdtData.creditTotal1 = '000'
          }
          
          this.ojdtData.totalText = toChies( Math.abs(this.ojdtData.creditTotal))
          // this.ojdtData.totalText = toChies(0)
          this.ojdtacctList = res.data.data.ojdtacctList
          this.ojdtacctList.forEach(item => {
            item.debit1 = this.numToStr(item.debit)
            item.credit1 = this.numToStr(item.credit)
          })
        })
      },
      goProject(item) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: item.ofoworkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            this.$store.commit("setJumpData", {...res.data.data, turn: true})
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            this.$store.commit('setSecondMenu', [])
            this.$store.commit("selectMenu", obj);
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.entry-detail {
  /* padding: 16px; */
  .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    .border-right {
      border-right: 1px solid #f6b8b4;
    }
    .border-left {
      border-left: 1px solid #f6b8b4;
    }
  }
}
</style>