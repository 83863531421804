<template>
  <div class="project">
    <el-form :inline="true" :model="siftForm" class="demo-form-inline" size="small" @submit.native.prevent v-if="isShowFormInline">
      <el-form-item>
        <span slot="label">
          <strong>名称</strong>
        </span>
        <el-input
          v-model="siftForm.name"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="inquire"
          @clear="inquire"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="header">
      <!-- <div class="title">项目列表</div> -->
      <div>
        <el-tooltip class="item" effect="dark" :content="isShowFormInline ? '隐藏搜索' : '显示搜索'" placement="top-end">
          <el-button class="hidden" icon="el-icon-search" @click="isShowFormInline = !isShowFormInline"></el-button>
        </el-tooltip>
        <!-- <el-button class="tab" size="mini" @click="change" :icon="tab.showCard? 'el-icon-tickets': 'el-icon-s-grid'"></el-button> -->
      </div>
    </div>
    <!-- 表格形式 -->
    <!-- <div class="main" v-show="tab.showTable">
      <el-table
        :data="showList"
        ref="multipleTable"
        style="width: 100%"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading"
      >
          <el-table-column
            prop="name"
            label="项目名称"
            min-width="200"
            show-overflow-tooltip
            >
            <template slot-scope="scope">
              <a class="link" @click="handleDetail(scope.row)">{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="状态"
            width="100"
            align="center"
            >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isView == '1'">已读</el-tag>
              <el-tag type="danger" v-else>未读</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="userName"
            label="人员"
            align="center"
            width="100">
          </el-table-column>
          
          
          <el-table-column
            label="发布"
            prop="documentstatus"
            align="center"
            width="150"
            >
            <template slot-scope="scope">
              <DictTag :options="dropDown.documentstatus" :value="scope.row.documentstatus" :isStyle="true"/>
            </template>
          </el-table-column>
          <el-table-column
            prop="itemDate"
            label="项目时间"
            align="center"
            width="150">
            <template slot-scope="scope">
              {{scope.row.itemDate | filter_handleDate}}
            </template>
          </el-table-column>
          <el-table-column
            prop="cfflowitemName"
            label="类型"
            show-overflow-tooltip
            align="center">
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="描述"
            show-overflow-tooltip
            align="center">
          </el-table-column>
          <el-table-column 
            label="操作"
            fixed="right"
            width="70">
            <template slot-scope="scope">
              <el-button size="mini" type="text" icon="el-icon-view" @click="handleDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        
      </el-table>
    </div> -->
    <!-- 块形式 -->
    <div class="item" v-loading="loading">
      <div style="text-align:center;color:#999" v-if="!showList.length">暂无数据</div>
      <el-card
        shadow="always"
        v-for="(item, index) in showList"
        :key="index"
        :class="(index + 1) % 4 == 0 ? 'noright' : ''"
        @click.native="handleDetail(item)"
        :body-style="{padding: '10px'}"
        v-if="item"
      >
        <div slot="header" class="clearfix">
          <span>{{ item.name }}</span>
          <!-- <div>
            <el-tag type="success" v-if="item.isView == '1'">已读</el-tag>
            <el-tag type="danger" v-else>未读</el-tag>
          </div> -->
        </div>
        <div class="line">
          <div class="line-left">状态:</div>
          <div class="line-right"><DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/></div>
        </div>
        <div class="line">
          <div class="line-left">人员:</div>
          <div class="line-right">{{ item.userName }}</div>
        </div>
        <div class="line">
          <div class="line-left">时间:</div>
          <div class="line-right">{{ item.itemDate | filter_handleDate }}</div>
        </div>
        <div class="line">
          <div class="line-left">类型:</div>
          <div class="line-right">{{ item.cfflowitemName }}</div>
        </div>
        <div class="line">
          <div class="line-left">描述:</div>
          <div class="line-right">{{ item.remarks }}</div>
        </div>
        <i class="iconfont icon-yidu iconStatus" v-if="item.isView == '1'"></i>
        <i class="iconfont icon-weidu1 iconStatus" v-else></i>
        <div class="last-line">
          <el-button class="view" @click="handleDetail(item)">查看详情</el-button>
        </div>
      </el-card>
    </div>
    <div class="bottom">
      <!-- 显示第 1 条到第 {{ total }} 条记录总金额：<span>{{ total_money }}.00元</span> -->
      <el-pagination
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="->, prev, pager, next, sizes, jumper, total"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="项目详情" :visible.sync="dialogVisible" :append-to-body="true" :width="'90%'" top="59px" custom-class="dialogClass">
      <div class="dialog-content">
        <Detail :ofoItemId="ofoItemId" :isShare="'0'"/>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">关 闭</el-button> -->
        <!-- <el-button type="primary" @click="submitForm('ruleForm')" v-show="!disabled" :disabled="isDisabled">确 定</el-button> -->
        <el-button icon="el-icon-check" type="primary" @click="handleEditShareStatus">已 阅</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/config/url'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Form from '@/components/Form.vue'
import Detail from '@/views/project/detail.vue'
export default {
  components: {
    DictTag,
    Form,
    Detail
  },
  computed: {
    ...mapState({
      dropDown: (state) => state.dropDown.dropDown
    })
  },
  data() {
    return {
      siftForm: {
        name: "",
      },
      pageSize: 8,
      currentPage: 1,
      // 总条数，总共有多少条数据
      total: 0,
      total_money: 0,
      // 展示的数据
      showList: [],
      loading: true,
      dialogShareVisible: false,
      form: {
        toUserIds: [],
        obussId: ''
      },
      userList: [],
      isShowFormInline: false,
      dialogVisible: false,
      ofoItemId: null
    }
  },
  created () {
    this.getList()
  },
 
  methods: {
    handleEditShareStatus() {
        // // console.log(111);
        // // this.$eventBus.$emit('editShareStatus', true)
        // this.$http.post(url.editShareStatus, qs.stringify({flowType: 'w', obussId: this.ruleForm.ofoWorkId})).then(res => {
        //   if(res.data.code == 200) {
        //     this.dialogFormVisible = false
        //     this.getList()
        //   }
        // })
      },
    // 发送请求，获取项目列表
    getList() {
      this.$http.get(url.getShareList, {
        params: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          name: this.siftForm.name,
          flowType: 'i'
        }
      }).then(res => {
        this.loading = false
        if(res.data.code == 200) {
          this.showList = res.data.data.list
          this.total = res.data.data.total;
        } 
      })
      // 菜单类型下拉
      if(!this.dropDown.itemType) {
        this.$http.get(url.getDataList, {params: {type: 'buss_item_type' }}).then(res => {
          this.$store.commit('setItemType', res.data.data)
        })
      }
      // 发布下拉
      if(!this.dropDown.documentstatus) {
        this.$http.get(url.getDataList, {params: {type: 'buss_document_status' }}).then(res => {
          this.$store.commit('setDocumentstatus', res.data.data)
        })
      }
    },
    // 查询按钮
    inquire() {
      this.currentPage = 1
      this.loading = true
      this.getList()
    },
    // 重置按钮
    reset() {
      this.currentPage = 1
      this.siftForm.name = ''
      this.loading = true
      this.getList()
    },
    handleDetail(val) {
      // console.log(val);
      // this.dialogVisible = true
      // this.ofoItemId = val.ofoItemId
      this.$store.commit('setJumpData', {})
      this.$router.push({path: '/project/' + val.ofoItemId, query: {isShare: '0'}});
      let obj = {
        path: "/project/" + val.ofoItemId,
        name: "proDetail",
        label: "项目详情",
        ofoItemId: val.ofoItemId,
        children: []
      };
      this.$store.commit("selectMenu", obj);
    },
    // 表格形式和卡片形式切换
    change() {
      this.$store.commit('setStates')
      this.currentPage = 1
      // if(this.tab.showCard) {
        this.pageSize = 8
      // }else {
      //   this.pageSize = 10
      // }
      this.loading = true
      this.getList()
    },
    // 表格形式的序号
    indexMethod(index) {
      let id = index + 1 + (this.currentPage - 1) * this.pageSize
      return id < 10 ? ('0' + parseInt(id)) : id;
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.loading = true
      this.getList()
    },
    handleCollection(item, type, index) {
      
      // let list = [...this.showList]
      // this.$set(this.showList[index], 'isCollect', '1')
      if(type == 's') {
        this.dialogShareVisible = true
        this.form.obussId = item.ofoItemId
        if(!this.userList.length) {
          // 获取关联人员列表
          this.$http.get(url.getPostAndUserList).then(res => {
            if(res.data.code == 200) {
              this.userList = res.data.data
            }
          })
        }
        
      }else { // 收藏按钮
        this.$http.post(url.cancelShareCollect, qs.stringify({
          bussType: 'c',
          flowType: 'i',
          obussId: item.ofoItemId
        })).then(res => {
          if(res.data.code == 200) {
            this.$message.success('取消收藏')
            this.currentPage = 1
            this.loading = true
            this.getList()
          }
        })
      }
    },
    submitShare() {
      this.$http.post(url.shareCollect, qs.stringify({
        bussType: 's',
        flowType: 'i',
        obussId: this.form.obussId,
        toUserIds: this.form.toUserIds.toString()
      })).then(res => {
        if(res.data.code == 200) {
          this.$message.success('分享成功')
          this.dialogShareVisible = false
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.project {
  .header {
    display: flex;
    justify-content: flex-end;
    .hidden {
      padding: 6px;
      border-radius: 50%;
      margin-bottom: 0 !important;
    }
    /* .tab {
      padding: 0;
      border: 0;
      font-size: 18px;
      margin-bottom: 16px;
    } */
  }
  .el-table {
    margin-bottom: 39px;
  }
  .item {
    overflow: hidden;
    /* margin-bottom: 39px; */
    .el-card {
      width: calc(25% - 14px);
      float: left;
      margin-bottom: 16px;
      margin-right: 16px;
      position: relative;
      cursor: pointer;
      ::v-deep .el-card__header {
        padding: 10px 10px 10px 20px;
      }
      .clearfix {
        display: flex;
        justify-content: space-between;
        align-items: center;
        >span {
          display: inline-block;
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .line {
        font-size: 13px;
        margin-bottom: 6px;
        width: 98%;
        display: flex;
        margin-left: 8px;
        .line-left {
          margin-right: 5px;
        }
        .line-right {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .last-line {
        display: flex;
        justify-content: flex-end;
      }
      .view {
        padding: 6px 10px;
        font-size: 12px;
        background-color: #0c75e5;
        color: #fff;
        border: 0;
      }
      .iconStatus {
        position: absolute;
        bottom: 40px;
        right: 10px;
        font-size: 50px;
      }
      .icon-yidu {
        color: #67c23a;
      }
      .icon-weidu1 {
        color: #f56c6c;
      }
    }
    .noright {
      margin-right: 0;
    }
  }
  .title {
    height: 16px;
    font-size: 16px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 16px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
    margin-top: 16px;
  }
}
::v-deep .el-dialog {
  margin: 0 auto;
  max-height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
  max-height: calc(100vh - 206px);
  overflow: auto;
}
</style>