<template>
  <div class="total">
    <el-container class="container">
      <el-header height="52px" class="header">
        <Header @openDrawer="openDrawer" />
      </el-header>
      <el-container>
        <el-aside width="75px" class="aside">
          <Aside/>
        </el-aside>
        <el-container>
          <el-header height="30px" class="second-header">
            <Tag />
          </el-header>
          <el-main v-if="!secondAside">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"/>
          </el-main>
          <el-container v-if="secondAside">
            <el-aside width="160px" v-if="isShowLeft" class="second-aside">
              <SecondAside/>
            </el-aside>
            <div :class="isShowLeft ? 'middle iconfont icon-zhedie-l' : 'middle iconfont icon-zhedie1'" @click="isShowLeft = !isShowLeft">
    </div>
            <!-- <div class="show-mask" @click="isShowLeft = !isShowLeft">
              <i :class="isShowLeft ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"></i>
            </div> -->
            <el-main>
              <keep-alive>
                <router-view class="contain1" v-if="$route.meta.keepAlive"/>
              </keep-alive>
              <router-view class="contain1" v-if="!$route.meta.keepAlive"/>
            </el-main>
          </el-container>
        </el-container>
        
      </el-container>
    </el-container>



    <!-- <Header class="header" @openDrawer="openDrawer" /> -->
    <!-- <div class="total-bottom">
      <Aside class="aside"/>
      <div class="total-right">

        <Tag class="tag"/>
        <div class="total-second-bottom">
          <template v-if="secondAside"> 
            <SecondAside class="second-aside"/>
            <keep-alive>
              <router-view class="contain" v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <router-view class="contain" v-if="!$route.meta.keepAlive"/>
          </template>
          <template v-if="!secondAside"> 
            <keep-alive>
              <router-view class="contain" v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <router-view class="contain" v-if="!$route.meta.keepAlive"/>
          </template>
        </div>
      
      </div>
    </div> -->
    <!-- <Aside class="aside"/>
    <Tag class="tag"/>
    <template v-if="secondAside"> 
      <SecondAside class="second-aside"/>
      <keep-alive>
        <router-view class="contain" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view class="contain" v-if="!$route.meta.keepAlive"/>
    </template>
    <template v-if="!secondAside"> 
      <keep-alive>
        <router-view class="contain" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view class="contain" v-if="!$route.meta.keepAlive"/>
    </template> -->
    <el-drawer
      title="消息列表"
      :visible.sync="drawer"
      :modal="true">
      <div slot="title" class="drawer-title">
        <span class="title-left">消息列表</span>
        <span class="title-right" @click="goMessage">查看更多</span>
      </div>
      <div 
        style="padding:0 16px;height: calc(100vh - 100px); overflow-y: auto" 
        v-infinite-scroll="loadMore"  
        infinite-scroll-throttle-delay="500"
        :infinite-scroll-disabled="busy"
        infinite-scroll-distance="0.8"
      >
        <el-row :gutter="16" style="margin: 16px 0">
          <el-col :span="9">
            <el-select v-model="formInline.msgType" placeholder="消息类别" size="small" @change="handleChange" clearable>
              <el-option v-for="(item, index) in dropDown.msgType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-col>
          <el-col :span="9">
            <el-select v-model="formInline.status" placeholder="状态" size="small" @change="handleChange" clearable>
              <el-option v-for="(item, index) in dropDown.msgStatus" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-button type="primary" @click="allRead" size="small">全部已读</el-button>
          </el-col>
        </el-row>
        <div
          class="msg" 
          v-loading="loading"
        >
          <div class="message" v-for="(item, index) in msgList" :key="index" @click="viewMore(item)" style="">
            <div class="left">
              <div class="title" :style="item.status == '1' ? 'color: #999999' : 'color: #333333'">{{item.msgTitle}}</div>
              <div class="time">{{item.createTime | filter_handleDateTime}}</div>
            </div>
            <div class="right">
              <DictTag :options="dropDown.msgType" :value="item.msgType" :isStyle="true"/>
            </div>
            <div class="dot" v-if="item.status == '0'"></div>
          </div>
        </div>
        <div class="list-load-end">
          <span>{{dataLoadNomore ? "没有更多了~" : "加载中..."}}</span>
        </div>

      </div>
    </el-drawer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Aside from '@/components/Aside.vue'
import Tag from '@/components/Tag.vue'
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import SecondAside from '@/components/SecondAside.vue'
import Content from '@/views/content.vue'

  export default {
    // directives: {infiniteScroll},
    computed: {
      ...mapState({
        dropDown: (state) => state.dropDown.dropDown,
        secondMenu: (state) => state.secondAside.secondMenu,
      }),
    },
    components: {
      Header,
      Aside,
      Tag,
      DictTag,
      SecondAside,
      Content
    },
    data() {
      return {
        drawer: false,
        value: '',
        formInline: {
          msgType: '',
          status: ''
        },
        msgList: [],
        loading: false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        busy: true, // 是否执行loadMore函数
        dataLoadNomore: false,
        secondAside: false,
        isShowLeft: true,
        appList: [
          {
            label: '待我审批',
            path: '/process',
            name: 'approval',
            badge: null,
            class: 'icon icon1',
            icon: 'iconfont icon-daiban',
            children: [],
            isShow: true,
            appstatus: '3'
          },
          {
            label: '完成审批',
            path: '/process',
            badge: null,
            name: 'done',
            class: 'icon icon4',
            icon: 'iconfont icon-yiwancheng-yiban-02',
            children: [],
            isShow: true,
            appstatus: '1'
          },
          {
            label: '我发起的',
            path: '/process',
            name: 'mine',
            badge: null,
            class: 'icon icon2',
            icon: 'iconfont icon-wofaqide1',
            children: [],
            isShow: true,
            appstatus: '99'
          },
          {
            label: '抄送给我',
            path: '/process',
            name: 'copy',
            badge: null,
            class: 'icon icon3',
            icon: 'iconfont icon-ziyuan200',
            children: [],
            isShow: true,
            appstatus: '9'
          },
          
        ],
      }
    },
    created () {
      // console.log(this.$route.meta.keepAlive);;
      if(this.secondMenu.length) {
        if(this.secondMenu[0].path.indexOf('/accounting') == 0 || this.secondMenu[0].path.indexOf('/report') == 0) {
        // if(this.secondMenu[0].path.indexOf('/accounting') == 0) {
          this.secondAside = false
        }else {
          this.secondAside = true
        }
        
      }else {
        this.secondAside = false
      }
      this.$eventBus.$on('warnMsg', (data) => {
        // console.log(data);
        if(data) {
          // console.log(2222);
          this.formInline.msgType = '4'
          this.formInline.status = '0'
          this.drawer = !this.drawer
          if(this.drawer) {
            this.msgList = []
            this.pageNum = 1
            this.getMsgList()
          }
        }
      })
    },
    methods: {
      goMessage() {
        this.drawer = false
        JSON.parse(sessionStorage.getItem('menuList')).forEach(el => {
          if(el.label == '我的') {
            this.$router.push({path: el.path})
            this.$store.commit('setSecondMenu', el.children)
            this.$store.commit('selectMenu', el)
            
            this.$store.commit("setMineData", {isMsg: true})
          }
        })
      },
      viewMore(item) {
        this.drawer = false
        this.$http.get(url.getMsg, {params: {id: item.id}}).then(res => {
          this.$eventBus.$emit('getMsgCount', true)
        })
        if(item.msgType == '1') { // 审批消息
          if(item.msgTitle.indexOf('待') != -1) {
            this.$router.push(this.appList[0].path)
            this.$store.commit('selectMenu1', this.appList[0])
          }else if(item.msgTitle.indexOf('完成') != -1) {
            this.$router.push(this.appList[1].path)
            this.$store.commit('selectMenu1', this.appList[1])
          }else if(item.msgTitle.indexOf('抄送') != -1) {
            this.$router.push(this.appList[3].path)
            this.$store.commit('selectMenu1', this.appList[3])
          }else {
            this.$router.push(this.appList[2].path)
            this.$store.commit('selectMenu1', this.appList[2])
          }
          // this.$router.push('/process/approval')
          // JSON.parse(sessionStorage.getItem('menuList')).forEach(el => {
          //   if(el.label == '审批') {
          //     this.$router.push({path: el.path})
          //     this.$store.commit('setSecondMenu', el.children)
          //     this.$store.commit('selectMenu', el)
          //   }
          // })
        }else if(item.msgType == '2') { // 分享消息
          this.$router.push({path: '/share'})
          this.$store.commit('setSecondMenu', [])
          this.$store.commit('selectMenu', {
            children: [],
            class: "icon icon7",
            icon: "iconfont icon-fenxianggeiwo",
            label: "分享给我",
            name: "share",
            path: "/share"
          })
        }else if(item.msgType == '3' && item.msgBussId) { // 协同消息
          this.$http.get(url.getMsgJump, {params: {
            msgBussId: item.msgBussId
          }}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit("setJumpData", {...res.data.data, isNotice: true})
              this.$router.push({
                path: '/project/' + res.data.data.ofoItemId,
              });
              let obj = {
                path: "/project/" + res.data.data.ofoItemId,
                name: "proDetail",
                label: "项目详情",
                ofoItemId: res.data.data.ofoItemId,
                children: []
              };
              this.$store.commit('setSecondMenu', [])
              this.$store.commit("selectMenu", obj);
            }
          })
        }else if(item.msgType == '4' && item.msgBussId) { // 预警消息
          this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: item.msgBussId}}).then(res => {
            // console.log(res);
            if(res.data.code == 200) {
              this.$router.push({
                path: '/project/' + res.data.data.ofoItemId,
              });
              this.$store.commit("setJumpData", {...res.data.data, turn: true})
              let obj = {
                path: "/project/" + res.data.data.ofoItemId,
                name: "proDetail",
                label: "项目详情",
                ofoItemId: res.data.data.ofoItemId,
                children: []
              };
              this.$store.commit('setSecondMenu', [])
              this.$store.commit("selectMenu", obj);
            }
          })
        }else {
          JSON.parse(sessionStorage.getItem('menuList')).forEach(el => {
            if(el.label == '个人中心') {
              this.$router.push({path: el.path})
              this.$store.commit('setSecondMenu', el.children)
              this.$store.commit('selectMenu', el)
            }
          })
        }
      },
      loadMore() {
        // console.log(11111);
        // this.busy = true/
        this.getMsgList()
        
      },
      openDrawer(val) {
        // console.log(val);
        if(val) {
          this.drawer = !this.drawer
          if(this.drawer) {
            this.formInline.msgType = ''
            this.formInline.status = ''
            this.msgList = []
            this.pageNum = 1
            this.getMsgList()
          }
        }
      },
      handleChange() {
        // console.log(1111);
        this.loading = true
        this.msgList = []
        this.pageNum = 1
        this.getMsgList()
      },
      getMsgList() {
        // if(this.pageNum == 1 || page) {
          this.loading = true
          this.$http.get(url.getMsgList, {params: {
            msgType: this.formInline.msgType,
            status: this.formInline.status,
            pageNum: this.pageNum,
            pageSize: this.pageSize
          }}).then(res => {
            if(res.data.code == 200) {
              this.msgList = [...this.msgList, ...res.data.data.list]
              this.total = res.data.data.total
              if(Math.ceil(this.total / this.pageSize) > this.pageNum) {
                this.pageNum ++
                this.busy = false
                this.dataLoadNomore = false
              }
              else {
                this.busy = true
                this.dataLoadNomore = true
              }
              this.loading = false
            }
          })
        
        // 消息类型下拉
        if(!this.dropDown.msgType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_msg_type' }}).then(res => {
            this.$store.commit('setMsgType', res.data.data)
          })
        }
        // 消息状态下拉
        if(!this.dropDown.msgStatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_msg_status' }}).then(res => {
            this.$store.commit('setMsgStatus', res.data.data)
          })
        }
      },
      // 全部已读
      allRead() {
        this.$confirm('此操作将以下消息标记为已读, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.changeRead).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.msgList = []
              this.pageNum = 1
              this.getMsgList()
              this.$eventBus.$emit('getMsgCount', true)
            }
          })
          
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      }
    },
    watch: {
      secondMenu: {
        handler(newVal, oldVal) {
          // console.log(newVal);
          if(newVal.length) {
            if(newVal[0].path.indexOf('/accounting') == 0 || newVal[0].path.indexOf('/report') == 0) {
            // if(newVal[0].path.indexOf('/accounting') == 0) {
              this.secondAside = false
            }else {
              this.secondAside = true
            }
            // this.secondAside = true
          }else {
            this.secondAside = false
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped lang="scss">
.total {
  height: 100%;
  /* overflow: hidden; */
  .el-container {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .el-header {
    padding: 0;
  }
  .aside {
    box-shadow: 2px 3px 8px 0px rgb(208, 208, 208);
    /* margin-right: 2px; */
    position: relative;
    z-index: 1;
  }
  .second-header {
    box-shadow: 2px 2px 5px 0px rgba(208, 208, 208);
    position: relative;
    z-index: 2;
  }
  .el-main {
    background: #F2F4F5;
    padding: 0;
  }
  .middle {
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #79bbff;
    /* opacity: 1; */
    background-color: #F2F4F5;
  }
  .middle:hover {
    color: #0c75e5;
  }
  .contain1 {
    padding: 16px;
    padding-left: 0;
    /* min-height: 100%; */
    box-sizing: border-box;
    /* overflow: hidden; */
    /* height: 100%; */
  }
  .second-aside {
    box-shadow: 2px 3px 8px 0px rgb(208, 208, 208);
    position: relative;
    
  }
  .el-aside {
    overflow-x: hidden;
  }
  /* display: flex;
  flex-direction: column;
  overflow: hidden;
  .total-bottom {
    flex: 1;
    display: flex;
    .total-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      .total-second-bottom {
        flex: 1;
        .contain {
          background-color: #F2F4F5;
          overflow: auto;
          background-color: #F2F4F5;
          height: 100%;
          box-sizing: border-box;
        }
      }
    }
  } */



  /* .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .aside {
    position: fixed;
    top: 52px;
    left: 0;
    z-index: 2;
  }
  .tag {
    position: fixed;
    top: 52px;
    left: 82px;
    z-index: 2;
  }
  .contain {
    position: fixed;
    top: 82px;
    left: 82px;
    overflow: auto;
    background-color: #F2F4F5;
    height: calc(100% - 82px);
    width: calc(100% - 82px);
    box-sizing: border-box;
  }
  .second-aside {
    position: fixed;
    top: 82px;
    left: 82px;
    z-index: 1;
  } */
  ::v-deep .el-drawer__wrapper {
    top: 52px;
  }
  ::v-deep .el-drawer__header {
    padding: 12px 16px;
    border-bottom: #dddddd 1px solid;
    align-items: flex-start;
    margin-bottom: 0;
    .drawer-title {
      padding-right: 16px;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #000000;
      font-weight: 600;
      .title-right {
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  .message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: pink; */
    padding: 12px 0 12px 8px;
    border-bottom: #dddddd 1px solid;
    position: relative;
    cursor: pointer;
    .left {
      width: calc(100% - 78px);
      margin-right: 8px;
      .title {
        color: #999999;
        width: 100%;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      .time {
        color: #aaaaaa;
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .right {
      width: 70px;
    }
    /* .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
    } */
    .dot {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: red;
      top: 20px;
      left: -5px;
    }
  }
  .list-load-end {
    text-align: center;
    padding: 10px 0;
    color: #999999;
  }
}
</style>