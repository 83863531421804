<template>
  <div style="width:100%">
    <template v-if="dimeObj.editType != '8'">
      <!-- <div @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="text">{{dimeValue}}</div>
      <el-tooltip effect="dark" :content="dimeValue" placement="top" v-if="isShowText">
        <div class="text" @mouseout="hideTip">{{dimeValue}}</div>
      </el-tooltip> -->
      {{dimeObj.dimeText}}
      <!-- <div @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="text">{{dimeObj.dimeText}}</div>
      <el-tooltip effect="dark" :content="dimeObj.dimeText" placement="top" v-if="isShowText">
        <div class="text" @mouseout="hideTip">{{dimeObj.dimeText}}</div>
        <template slot="content">
          <div style="max-width:500px">{{dimeObj.dimeText}}</div>
        </template>
      </el-tooltip> -->
    </template>
    <template v-if="dimeObj.editType == '8'">
      <!-- 文件列表 -->
      <fileList :fileList="dimeObj.dimeValue" />
    </template>
  </div>
</template>

<script>
import textRange from '@/utils/textRange.js'
  export default {
    props: {
      dimeObj: Object,
    },
    data() {
      return {
        dimeValue: '',
        baseUrl: this.$base_url,
        dimeValueList: [],
        isShowText: false
      }
    },
    methods: {
      isShowToltip (e) {
        // console.log(e);
        const bool = textRange(e.target)
        this.isShowText = bool
      },
      hideTip () {
        // console.log(11);
        this.isShowText = false
      },
      zz() {
        // console.log(this.dimeObj);
        if(this.dimeObj.editType == '3' || this.dimeObj.editType == '6') { // 下拉框 || 单选框
          if(this.dimeObj.options) {
            JSON.parse(this.dimeObj.options).forEach(el => {
              if(this.dimeObj.dimeValue == el.optionValue) {
                this.dimeValue = el.optionLabel
              }
            })
          }
          if(!this.dimeValue) {
            this.dimeValue = this.dimeObj.dimeValue
          }
        }else if(this.dimeObj.editType == '7' && this.dimeObj.dimeValue) { // 多选框
          console.log(this.dimeObj.dimeValue);
          // console.log(JSON.parse(this.dimeObj.dimeValue));
          if(typeof(this.dimeObj.dimeValue) != 'object' && this.dimeObj.dimeValue.includes('[')) {
            console.log(222);
            this.dimeObj.dimeValue = JSON.parse(this.dimeObj.dimeValue)
          }else {
            this.dimeObj.dimeValue = this.dimeObj.dimeValue.split(', ')
          }
          let str = ''
          if(this.dimeObj.options) {
            JSON.parse(this.dimeObj.options).forEach(val => {
              this.dimeObj.dimeValue.forEach(el => {
                if(val.optionValue == el) {
                  str += val.optionLabel + ', '
                }
              })
            })
          }else {
            this.dimeObj.dimeValue.forEach(val => {
              str += val + ', '
            })
          }
          this.dimeValue = str.substr(0, str.length - 2);
        }else if(this.dimeObj.editType == '8' && this.dimeObj.dimeValue) { // 附件
          if(typeof(this.dimeObj.dimeValue) != 'object') {
            this.dimeValueList = JSON.parse(this.dimeObj.dimeValue)
          }else {
            this.dimeValueList = this.dimeObj.dimeValue
          }
        }else if(this.dimeObj.editType == '11' && this.dimeObj.dimeValue) { // 百分比
          this.dimeValue = this.dimeObj.dimeValue + '%'
        }else {
          this.dimeValue = this.dimeObj.dimeValue
        }
      }
    },

    watch: {
      dimeObj: {
        handler(newValue, oldValue) {
          // console.log('1111111:' + (newValue != oldValue));
          // if(newValue.dimeValue) {
            // console.log(newValue.dimeValue);
            // this.zz();
          // }else {
          //   return
          // }
        },
        deep: true
      }
    },
    created () {
      // console.log(this.dimeObj);

      if(this.dimeObj.dimeValue){
        // console.log("ssssssssssss");
        // this.zz();
      }else {
        return
      }
    },
    
  }
</script>

<style lang="scss" scoped>
.text {
  width: 100%;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}
</style>