// 处理权限
export default function handleDutys(dutys, type) {
  let loginUser = JSON.parse(window.sessionStorage.getItem('loginUser'))
  // console.log(loginUser.isAdmin);
  // console.log(6666666);
  if(loginUser.isAdmin == '1') { // '1'不是管理员，有权限
    if(dutys) { // 不为空
      if(dutys[type] == '1') {
        return false
      }else {
        return true
      }
      // console.log(1111111);
      // return true
    }else { // 权限为空
      // console.log(2222);
      return false
    }
  }else { // '0'管理员，没有权限
    return true
  }
}