<template>
  <div>
    <!-- <template v-for="item in options">
      {{item.dictValue == value ? item.dictLabel : ''}}
    </template> -->
    <template v-for="(item, index) in options">
      <template v-if="!isStyle">
        {{item.dictValue == value ? item.dictLabel : ''}}
      </template>
      <template v-if="isStyle && values.includes(item.dictValue)">
        <span
          v-if="item.listClass == 'default' || item.listClass == ''"
          :key="item.dictValue"
          :index="index"
          >{{ item.dictLabel }}</span
        >
        <el-tag
          v-else
          :disable-transitions="true"
          :key="item.dictValue"
          :index="index"
          :type="item.listClass == 'primary' ? '' : item.listClass"
        >
          {{ item.dictLabel }}
        </el-tag>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "DictTag",
  props: {
    options: {
      type: Array,
      default: null,
    },
    value: [Number, String, Array],
    isStyle: Boolean
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [String(this.value)];
      } else {
        return [];
      }
    },
  },
  created () {
    // console.log(this.options);
    // console.log("aa"+this.value);
    // console.log("aaaa" + this.values);

  },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>