<template>
  <div class="container">
    <div class="title">基础配置</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="right"
    >
      <el-button type="primary" size="small" @click="handleSave('ruleForm')" :loading="isLoading" style="margin: 0 0 16px 66px">保存</el-button>
      <el-tabs tab-position="left">
        <el-tab-pane label="人员配置">
          <el-row>
            <el-col :span="3">
              <el-form-item label="自动审核" prop="auto_check">
                <el-switch
                  v-model="ruleForm.auto_check"
                  active-value="1"
                  inactive-value="0"
                  @change="handleSwitch1">
                </el-switch>
            
              </el-form-item>
            </el-col>
            <el-col :span="21">
              
              <el-form-item label="审核人" prop="check_user" v-if="ruleForm.auto_check == '1'">
                <el-select v-model="ruleForm.check_user" placeholder="请选择审核人" @change="handleChange1" filterable>
                  <el-option-group
                    v-for="group in ofrthingList"
                    :key="group.ofrResourceId"
                    :label="group.resourceName">
                    <el-option
                      v-for="item in group.ofrThingList"
                      :key="item.ofrThingId"
                      :label="item.name"
                      :value="item.ofrThingId + ''">
                    </el-option>
                  </el-option-group>
                </el-select>
                
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">
              <el-form-item label="自动记账" prop="auto_account">
                <el-switch
                  v-model="ruleForm.auto_account"
                  active-value="1"
                  inactive-value="0"
                  @change="handleSwitch">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="21">
              <el-form-item label="记账人" prop="account_user" v-if="ruleForm.auto_account == '1'">
                <el-select v-model="ruleForm.account_user" placeholder="请选择记账人" @change="handleChange" filterable>
                  <el-option-group
                    v-for="group in ofrthingList"
                    :key="group.ofrResourceId"
                    :label="group.resourceName">
                    <el-option
                      v-for="item in group.ofrThingList"
                      :key="item.ofrThingId"
                      :label="item.name"
                      :value="item.ofrThingId + ''">
                    </el-option>
                  </el-option-group>
                </el-select>
                
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="期末结账配置">
          <el-form-item label="结账科目" prop="close_account_subject_code">
            <treeselect :multiple="true" v-model="ruleForm.close_account_subject_code" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择结账科目" :appendToBody="true" />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="现金配置">
          <el-form-item label="现金科目" prop="cash_subject">
            <treeselect :multiple="true" v-model="ruleForm.cash_subject" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择现金科目" :appendToBody="true" />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="往来配置">
          <el-form-item label="往来应付科目" prop="current_payable">
            <treeselect :multiple="true" v-model="ruleForm.current_payable" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择应付科目" :appendToBody="true" />
          </el-form-item>
          <el-form-item label="往来应收科目" prop="current_receivable">
            <treeselect :multiple="true" v-model="ruleForm.current_receivable" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择应收科目" :appendToBody="true" />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="资产配置">
          <el-form-item label="资产科目" prop="assets_subject">
            <treeselect :multiple="true" v-model="ruleForm.assets_subject" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择资产科目" :appendToBody="true" />
          </el-form-item>
          <el-form-item label="折旧科目" prop="depreciation_subject">
            <treeselect :multiple="true" v-model="ruleForm.depreciation_subject" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择折旧科目" :appendToBody="true" />
          </el-form-item>
          <el-form-item label="资源类型" prop="resource_type">
            <el-select v-model="ruleForm.resource_type" placeholder="请选择资源类型" filterable @change="changeRes" >
              <el-option-group
                v-for="group in resTypeTree"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="使用月数维度" prop="months_dime_num">
            <el-select v-model="ruleForm.months_dime_num" placeholder="请选择使用月数维度" clearable filterable >
              <el-option v-for="(item, index) in cbussDimeList" :key="index" :label="item.dimeName" :value="item.cbDimeId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="原值维度" prop="origin_dime_num">
            <el-select v-model="ruleForm.origin_dime_num" placeholder="请选择原值维度" clearable filterable>
              <el-option v-for="(item, index) in cbussDimeList" :key="index" :label="item.dimeName" :value="item.cbDimeId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="累计折旧维度" prop="initial_dime_num">
            <el-select v-model="ruleForm.initial_dime_num" placeholder="请选择累计折旧维度" clearable filterable>
              <el-option v-for="(item, index) in cbussDimeList" :key="index" :label="item.dimeName" :value="item.cbDimeId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="月折旧额维度" prop="per_month_dime_num">
            <el-select v-model="ruleForm.per_month_dime_num" placeholder="请选择月折旧额维度" clearable filterable>
              <el-option v-for="(item, index) in cbussDimeList" :key="index" :label="item.dimeName" :value="item.cbDimeId"></el-option>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="存货配置">
          <el-form-item label="存货科目" prop="inventory_subject">
            <treeselect :multiple="true" v-model="ruleForm.inventory_subject" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择存货科目" />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="成本配置">
          <el-form-item label="成本科目" prop="cost_subject">
            <treeselect :multiple="true" v-model="ruleForm.cost_subject" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择成本科目" />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import url from '@/config/url'
import handleTree from '@/utils/handleTree'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      Treeselect
    },
    data() {
      return {
        ruleForm: {
          auto_account: '',
          account_user: '',
          account_user_name: '',
          assets_subject: null,
          auto_check: '',
          check_user: '',
          check_user_name: '',
          cash_subject: null,
          close_account_subject_code: null,
          cost_subject: null,
          current_payable: null,
          current_receivable: null,
          depreciation_subject: null,
          resource_type: '',
          initial_dime_num: '',
          inventory_subject: null,
          months_dime_num: '',
          origin_dime_num: '',
          per_month_dime_num: ''
        },
        rules: {
          account_user: [{ required: true, message: "请选择记账人", trigger: "submit" }],
          check_user: [{ required: true, message: "请选择审核人", trigger: "submit" }],
        },
        ofrthingList: [],
        cbSubjectList: [],
        isLoading: false,
        resTypeTree: [],
        cbussDimeList: []
      }
    },
    created () {
      this.getData();
    },
    methods: {
      handleSave(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // console.log(this.ruleForm);
            this.isLoading = true
            let obj = {}
            for(let key in this.ruleForm) {
              if(key == 'assets_subject' || key == 'cash_subject' || key == 'close_account_subject_code' || key == 'current_payable' || key == 'current_receivable' || key == 'depreciation_subject' || key == 'inventory_subject' || key == 'cost_subject') {
                if(this.ruleForm[key]) {
                  obj[key] = this.ruleForm[key].join(',')
                }else {
                  obj[key] = ''
                }
              }else {
                obj[key] = this.ruleForm[key]
              }
            }
            this.$http.post(url.updateConfigs, qs.stringify(obj)).then(res => {
              this.isLoading = false
              if(res.data.code == 200) {
                this.$message.success(res.data.msg);

              }
            })
          } else {
            return false;
          }
        });
        
      },
      handleChange() {
        this.ofrthingList.forEach(item => {
          item.ofrThingList.forEach(val => {
            if(val.ofrThingId == this.ruleForm.account_user) {
              this.ruleForm.account_user_name = val.name
            }
          })
        })
      },
      handleChange1() {
        this.ofrthingList.forEach(item => {
          item.ofrThingList.forEach(val => {
            if(val.ofrThingId == this.ruleForm.check_user) {
              this.ruleForm.check_user_name = val.name
            }
          })
        })
      },
      handleSwitch(e) {
        if(e == '0') {
          this.ruleForm.account_user = ''
          this.ruleForm.account_user_name = ''
        }
      },
      handleSwitch1(e) {
        if(e == '0') {
          this.ruleForm.check_user = ''
          this.ruleForm.check_user_name = ''
        }
      },
      changeRes() {
        this.getCbussDimeList()
      },
      getCbussDimeList() {
        this.$http.get(url.getCbussDimeList, {params: {
          cbussId: this.ruleForm.resource_type,
          dimetype: '2'
        }}).then(res => {
          if(res.data.code == 200) {
            this.cbussDimeList = res.data.data
          }
        })
      },
      getData() {
        // 获取科目列表
        this.$http.get(url.getCbSubjectList).then(res => {
          if(res.data.code == 200) {
            this.cbSubjectList = handleTree(res.data.data, 'cbSubjectId', 'pid')
          }
        })
        // 获取岗位和人员列表
        this.$http.get(url.getPostAndUserList).then(res => {
          if(res.data.code == 200) {
            this.ofrthingList = res.data.data
          }
        })
        // 获取资源类型列表
        this.$http.get(url.getResTypeTree).then(res => {
          if(res.data.code == 200) {
            this.resTypeTree = res.data.data
          }
        })
        this.$http.get(url.getConfigs).then(res => {
          if(res.data.code == 200) {
            for(let key in res.data.data) {
              if(key == 'assets_subject' || key == 'cash_subject' || key == 'close_account_subject_code' || key == 'current_payable' || key == 'current_receivable' || key == 'depreciation_subject' || key == 'inventory_subject' || key == 'cost_subject') {
                if(res.data.data[key]) {
                  this.ruleForm[key] = res.data.data[key].split(',')
                }else {
                  this.ruleForm[key] = null
                }
              }else {
                this.ruleForm[key] = res.data.data[key]
              }
            }
          }
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
        //   if (node.children && !node.children.length) {
        //   delete node.children;
        // }
        return {
          // id: node.cbSubjectId,
          id: node.subjectNumber,
          label: node.subjectName + ': ' + node.subjectNumber,
          children: node.children
        };
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .title {
    text-align: center;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 16px;
  }
}
.el-form {
  height: calc(100% - 44px);
}
.el-tabs {
  height: calc(100% - 44px);
  ::v-deep .el-tabs__content {
    height: 100%;
    overflow: auto;
  }
}
.vue-treeselect, .el-select {
  width: 98%;
}
</style>