<template>
  <div class="container">
    <div class="main">
      <div class="title">
        {{ofoFlowDeal.name}} -- {{ofoFlowWork.name}}
        <div style="text-align:left;float:right">
          <el-button icon="el-icon-time" type="text" @click="() => {isShowHistory = !isShowHistory; productsList = []; thingsList = []}"></el-button>
        </div>
      </div>
      <el-form :model="ruleForm" label-width="120px" :rules="rules" ref="ruleForm" v-if="!isShowHistory">
        <!-- 名称 编号 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="ruleForm.name" style="width: 100%" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="code">
              <el-autocomplete
                class="inline-input"
                v-model="ruleForm.code"
                :fetch-suggestions="codeSearch"
                placeholder="请输入编号"
                @select="handleCodeSelect"
                clearable
                style="width: 100%"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
        <el-row v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">
          <el-col :span="12">
            <el-form-item :label="ofoFlowDeal.restype1Name" prop="dealId">
              <template slot="label">
                {{ofoFlowDeal.restype1Name}}
                <!-- <el-button type="text" @click="addResource" v-if="resourceType.isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button> -->
              </template>
              <el-select 
                v-model="ruleForm.dealId"
                :placeholder="`请选择${ofoFlowDeal.restype1Name}`"
                style="width: 100%"
                @change="getResChange"
                filterable
                clearable
              >
                <el-option v-for="(item, index) in resourceList" :key="index" :label="item.resourceName" :value="item.ofrResourceId" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item >
              <div slot="label" style="display:flex;justify-content:flex-end">
                <template v-if="ofoFlowWork.thingDimeType">
                  <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
                </template>
                <template v-if="!ofoFlowWork.thingDimeType">
                  内容
                </template>
                <!-- <el-button type="text" size="mini" @click="addThing" v-if="resourceType.isAdd == '0'" icon="el-icon-circle-plus-outline"></el-button> -->
              </div>
              
              <el-select
                v-model="ruleForm.dealownId"
                placeholder="请选择内容"
                style="width: 100%"
                filterable
                clearable
                @change="getDealownChange"
              >
                <el-option v-for="(item, index) in thingList" :key="index" :label="item.name" :value="item.ofrThingId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
        <el-row v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">
          <el-col :span="12">
            <el-form-item :label="ofoFlowDeal.restype1Name" prop="dealowhId">
              <el-select
                v-model="ruleForm.dealowhId"
                :placeholder="`请选择${ofoFlowDeal.restype1Name}`"
                style="width: 100%"
                @change="getDealowhChange"
                filterable
              >
                <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人">
              <el-select
                v-model="ruleForm.dealownId"
                placeholder="请选择联系人"
                style="width: 100%"
                filterable
                clearable
              >
                <el-option v-for="(item, index) in contactList" :key="index" :label="item.ofrthingName" :value="item.ofrthingId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 项目时间  是否预警 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="活动时间" prop="workDate">
              <el-date-picker
                v-model="ruleForm.workDate"
                type="date"
                placeholder="选择活动时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                :clearable='false'
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="ofoFlowWork.isNotice == '0'">
            <el-form-item label="是否协同">
              <el-checkbox v-model="ruleForm.isNotice" :true-label="'0'" :false-label="'1'"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否预警">
              <el-checkbox v-model="ruleForm.ifalert" :true-label="'0'" :false-label="'1'"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 是否协同 -->
        <el-row v-if="ruleForm.isNotice == '0'">
          <el-col :span="12">
            <el-form-item label="协同人">
              <el-select
                v-model="ruleForm.noticeUsers"
                placeholder="请选择协同人"
                style="width: 100%"
                multiple
                filterable
                clearable
              >
                <el-option-group
                  v-for="group in postAndUserList"
                  :key="group.ofrResourceId"
                  :label="group.resourceName">
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId + ''">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="协同活动">
              <el-select
                v-model="ruleForm.noticeFlowWork"
                placeholder="请选择协同活动"
                style="width: 100%"
                filterable
                clearable
              >
                <el-option v-for="(item, index) in workCopyToList" :key="index" :label="item.name" :value="item.ofoFlowWorkId  + ''"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 是否预警 -->
        <el-row v-if="ruleForm.ifalert == '0'">
          <el-col :span="12">
            <el-form-item label="预警时间" prop="alertDate">
              <el-date-picker
                v-model="ruleForm.alertDate"
                type="date"
                placeholder="选择预警时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                :picker-options="pickerBeginOption"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        
        <!-- 导航栏 -->
        <div class="nav-tabs">
          <div v-for="(item, index) in navList" :key="index" @click="checked(index)" :class="['nav-title', isActive == index ? 'isActive' : '']" v-show="item.isShow">
            <div class="nav-icon">
              <span :class="item.icon"></span>
            </div>
            <div class="nav-text">{{item.text}}</div>
          </div>
        </div>
        <!-- 明细 -->
        <div class="nav-content" v-show="isActive == 0">
          <keep-alive>
            <Subsidiary 
              :disabled="false" 
              :ofoFlowWork="ofoFlowWork" 
              :thingsList="thingsList"
              @copyFromConfirm="copyFromConfirm"
              @sendThingsList="receiveThingsList"
              :workConfig="workConfig"
              :key="timer"/>
          </keep-alive>
        </div>
        <!-- 产品及服务 -->
        <div class="nav-content" v-show="isActive == 1">
          <keep-alive>
            <Product 
              :disabled="false"
              :ofoFlowWork="ofoFlowWork" 
              :productsList="productsList"
              @copyFromConfirm="copyFromConfirm"
              @sendProductsList="receiveProductsList"
              :workConfig="workConfig"
              :key="timer"/>
          </keep-alive>
        </div>
        <!-- 活动内容 -->
        <div class="nav-content" v-show="isActive == 2">
          <keep-alive>
            <Content 
              :disabled="false" 
              ref="children" 
              :resource="resource" 
              :resourceDimes="resourceDimes"
              :ofoFlowWork="ofoFlowWork"
              :resTypesList="resTypesList"
              @getCbussDimeList="getCbussDimeList"/>
          </keep-alive>
        </div>
        <!-- 文档 -->
        <div class="nav-content" v-show="isActive == 3">
          <keep-alive>
            <Document :disabled="false" :documentsList="documentsList" :ofoFlowWork="ofoFlowWork" :ruleForm="ruleForm" />
          </keep-alive>
          
        </div>
        <!-- 附件 -->
        <div class="nav-content" v-show="isActive == 4">
          <keep-alive>
            <Annex :fileList="fileList" :disabled="false" />
          </keep-alive>
        </div>
        <!-- 单位 部门 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="单位" prop="selforgId">
              <el-select
                v-model="ruleForm.selforgId"
                placeholder="请选择单位"
                style="width: 100%"
              >
                <el-option v-for="(item, index) in orgList" :key="index" :label="item.resourceName" :value="item.ofrResourceId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门" prop="selfowhId">
              <el-select
                v-model="ruleForm.selfowhId"
                placeholder="请选择部门"
                style="width: 100%"
                @change="getDeptChange"
                filterable
              >
                <el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.cbOwhId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 单据负责人 注释/操作人 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="单据负责人">
              <el-select
                v-model="ruleForm.selfownId"
                placeholder="请选择单据负责人"
                style="width: 100%"
                clearable
                filterable
              >
                <el-option v-for="(item, index) in leaderList" :key="index" :label="item.ofrthingName" :value="item.ofrthingId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注释">
              <el-input type="textarea" v-model="ruleForm.remarks" style="width: 100%" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="text-align:center">
          <el-button type="primary" @click="submitForm('ruleForm')" :disabled="isDisabled">提 交</el-button>
        </div>
        
      </el-form>
      <template v-if="isShowHistory">
        <Activity :ofoFlowDeal="ofoFlowDeal" :ofoFlowWork="ofoFlowWork" :ofoItemName="''" :siftForm="siftForm" ref="activity" :isShare="isShare" :restaurants="codeList" :resourceList1="resourceList1" @changeCopy="changeCopy"/>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Activity from '@/components/project/activity.vue'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
// 明细
import Subsidiary from '@/components/project/activity/subsidiary.vue'
// 产品及服务
import Product from '@/components/project/activity/product.vue'
// 文档
import Document from '@/components/project/activity/document.vue'
// 活动内容
import Content from '@/components/project/activity/content.vue'
// // 审批
// import Approval from '@/components/project/activity/approval.vue'
// // 凭证
// import Voucher from '@/components/project/activity/voucher.vue'
// 附件
import Annex from '@/components/project/activity/annex.vue'
import url from '@/config/url'
  export default {
    components: {
      DictTag,
      Product,
      Content,
      Subsidiary,
      Annex,
      Document,
      Activity
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    data() {
      return {
        ofoFlowDeal: {},
        ofoFlowWork: {},
        isShowHistory: false,
        ruleForm: {
          code: '',
          name: '',
          workDate: '',
          ifalert: '1',
          alertDate: '',
          dealId: '',
          dealowhId: '',
          selforgId: '',
          selfowhId: '',
          dealownId: '',
          selfownId: '',
          dealownName: '',
          isNotice: '1',
          noticeUsers: '',
          noticeFlowWork: '',
          ofoflowworkId: '',
          dataSourceConfig: []
        }, // 表单对象
        // 表单规则
        rules: {
          workDate: [{ required: true, message: "请选择日期", trigger: "change" }],
          dealId: [{ required: true, message: "请选择资源", trigger: "change" }],
          selforgId: [{ required: true, message: "请选择单位", trigger: "change" }],
          selfowhId: [{ required: true, message: "请选择部门", trigger: "change" }],
          dealowhId: [{ required: true, message: "请选择部门", trigger: "change" }],
          resourceName: [{ required: true, message: "请输入资源名称", trigger: "change" }]
        },
        codeList: [], // 编码列表
        resourceList: [], // 资源列表
        thingList: [], // 内容列表
        departmentList: [], // 甲方资源类型为‘管理’，人员列表
        contactList: [], // 甲方资源类型为‘管理’，联系人列表
        postAndUserList: [], // 协同人列表
        workCopyToList: [], // 协同活动列表
        // 限制预警只能选择今天之后
        pickerBeginOption: {
          disabledDate: (time) => {
            return time.getTime() < Date.now()-1 * 24 * 60 * 60 * 1000
          }
        },
        // 导航列表
        navList: [],
        isActive: '',
        // 明细列表
        thingsList: [],
        // 产品列表
        productsList: [],
        list: [],
        list2: [],
        ptotalObj: {},
        ctotalObj: {},
        ptotalObj2: {},
        ctotalObj2: {},
        resource: {
          cbRestypeId: '',
          resourceName: '',
          remarks: '',
          dataSourceConfig: []
        }, // 资源对象
        resourceDimes: [], // 资源维度
        documentsList: [], // 文档列表
        fileList: [], // 附件列表
        orgList: [], // 单位列表
        leaderList: [], // 负责人列表
        isDisabled: false,
        resourceList1: [],
        resTypesList: [],
        siftForm: {
          code: '',
          name: '',
          dealId: '',
          dealowhId: ''
        },
        isShare: this.$route.query.isShare ? this.$route.query.isShare : '1',
        workConfig: {},
        timer: ''
      }
    },
    mounted () {
      this.ofoFlowDeal = this.currentMenu.ofoFlowDeal
      this.ofoFlowWork = this.currentMenu.ofoFlowWork
      this.getData()
    },
    methods: {
      async getData() {
        this.productsList = []
        this.thingsList = []
        this.timer = new Date().getTime()
        this.ruleForm.name = this.ofoFlowWork.name
        this.ruleForm.workDate = this.parseTime(Date.now(), '{y}-{m}-{d}')
        this.ruleForm.ifalert = '1'
        this.ruleForm.alertDate = ''
        this.ruleForm.isNotice = ''
        this.ruleForm.noticeUsers = ''
        this.ruleForm.noticeFlowWork = ''
        this.navList = [
          {
            icon: 'iconfont icon-lvzhou_mingxi',
            text: this.ofoFlowWork.thingtypeName + '（明细）',
            isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
          },
          {
            icon: 'iconfont icon-chanpin',
            text: this.ofoFlowWork.kindtypeName,
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
          },
          {
            icon: 'iconfont icon-demand',
            text: '活动内容',
            isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0'
          },
          {
            icon: 'iconfont icon-wendang',
            text: '文档',
            isShow: this.ofoFlowWork.kinddocutype
          },
          {
            icon: 'iconfont icon-fujian',
            text: '附件',
            isShow: false
          },
        ]
        
        // async getItemWorkConfig(ofoFlowWork) {
          // 获取资源行配置
          if(this.ofoFlowWork.checkConfig) {
          await this.$http.get(url.getItemWorkConfig, {params: {
              ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
            }}).then(res => {
              if(res.data.code == 200) {
                this.workConfig = res.data.data
              }
            })
          }else {
            this.workConfig = {}
          }
        // },
        await this.$http.get(url.getOfoFlowWorkfileList, {params: {ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.fileList = res.data.data
            
          }
        })
        // console.log(this.fileList);
        // console.log(this.navList);
        if(this.fileList.length) {
          this.navList[4].isShow = true
        }else {
          this.navList[4].isShow = false
        }
        let navArr = []
        this.navList.forEach((item, index) => {
          if(item.isShow) {
            navArr.push(index)
          }
        })
        this.isActive = navArr[0]
        if(this.ofoFlowWork.isNotice == '0') {
          // 获取关联人员列表
          this.$http.get(url.getPostAndUserList).then(res => {
            if(res.data.code == 200) {
              this.postAndUserList = res.data.data
            }
          })
          // 获取活动复制到列表（协同用到）
          this.$http.get(url.getWorkCopyToList, {params: {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              this.workCopyToList1 = res.data.data
            }
          })
        }
        // 获取资源以及内容
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name) {
          // console.log(111);
          // console.log(this.ofoFlowWork.thingDimeType);
          this.$http.get(url.getResTypeAndList, {params: {cbRestypeId: this.ofoFlowDeal.restype1, thingDimeType: this.ofoFlowWork.thingDimeType}}).then(res => {
            if(res.data.code == 200) {
              this.resourceType = res.data.data.cbResourceType
              this.resourceList = res.data.data.list
              this.resourceList.forEach(item => {
                if(item.ofrThingList.length) {
                  // item.ofrThingList =  this.handleDimes(item.ofrThingList, 'name', 'name')
                  item.ofrThingList = item.ofrThingList
                }
              })
              if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind != '6' && this.ofoFlowDeal.restype1Name) {
                this.ruleForm.dealId = this.resourceList.length ? this.resourceList[0].ofrResourceId : ''
                this.getResChange(this.ruleForm.dealId)
              }
            }
          })
        }
        // 获取资源维度，活动核算对象为对象管理
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0') {
          this.$http.get(url.getResTypes, {params: {cbRestypeIds: this.ofoFlowWork.kindtype}}).then(res => {
            if(res.data.code == 200) {
              this.resTypesList = res.data.data
              this.resource.cbRestypeId = this.resource.cbRestypeId ? this.resource.cbRestypeId : this.resTypesList[0].cbRestypeId
              this.getCbussDimeList()
            }
          })
        }
        if(this.ofoFlowWork.kinddocutype && this.documentstatus != '0') {
          // console.log(111);
          this.$http.get(url.getCbussDimeList, {params: {cbussId: this.ofoFlowWork.kinddocutype, dimetype: '2'}}).then(res => {
            if(res.data.code == 200) {
              this.documentsList = this.handleFormList(res.data.data)
              this.ruleForm.dataSourceConfig = this.documentsList
            }
          })
        }
        // 获取单位列表
        this.$http.get(url.getOrgList, {params: {restype2: this.ofoFlowDeal.restype2}}).then(res => {
          if(res.data.code == 200) {
            this.orgList = res.data.data
            this.orgList.forEach(item => {
              if(item.resourceName == JSON.parse(window.sessionStorage.getItem('loginUser')).orgName) {
                this.ruleForm.selforgId = item.ofrResourceId
              }
            })
            if(!this.ruleForm.selforgId) {
              this.ruleForm.selforgId = this.orgList.length ? this.orgList[0].ofrResourceId : ''
            }
          }
        })
        // 获取部门及人员列表
        this.$http.get(url.getDeptAndUser).then(res => {
          if(res.data.code == 200) {
            this.departmentList = res.data.data
            this.ruleForm.selfowhId = JSON.parse(window.sessionStorage.getItem('loginUser')).deptId ? JSON.parse(window.sessionStorage.getItem('loginUser')).deptId : this.departmentList[0].cbOwhId
            this.getDeptChange(this.ruleForm.selfowhId)
            this.ruleForm.selfownId = JSON.parse(window.sessionStorage.getItem('loginUser')).bussId
            if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Kind == '6' && this.ofoFlowDeal.restype1Name) {
              this.ruleForm.dealowhId = this.departmentList.length ? this.departmentList[0].cbOwhId : ''
              this.getDealowhChange(this.ruleForm.dealowhId)
            }
          }
        })
        // 获取文档维度列表
        // console.log(this.documentstatus!= '0');
        // 获取上级列表
        if(this.ofoFlowWork.beforework && this.ofoFlowWork.beforeworkName) {
          this.$http.get(url.getWorkCodeList, {params: {
            ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
          }}).then(res => {
            if(res.data.code == 200) {
              this.codeList = res.data.data.map(item => {
                return {
                  value: `${item.name} - ${item.code}`,
                  ofoWorkId: item.ofoWorkId,
                  code: item.code
                }
              })
            }
          })
        }else {
          this.codeList = []
        }
        // 获取交易下拉列表
        if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name && this.ofoFlowDeal.restype1Kind == '6') {
          this.$http.get(url.getDeptAndUser).then(res => {
            this.departmentList = res.data.data
          })
        }else if(this.ofoFlowDeal.restype1 && this.ofoFlowDeal.restype1Name && this.ofoFlowDeal.restype1Kind != '6') {
          this.$http.get(url.getDealList, {params: {ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId}}).then(res => {
            this.resourceList1 = res.data.data
          })
        }
        // 获取审批状态下拉框
        if(!this.dropDown.appStatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_app_status' }}).then(res => {
            this.$store.commit('setAppStatus', res.data.data)
          })
        }
        // 获取内容维度类型下拉框
        if(!this.dropDown.thingDimeType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_thing_dimeType' }}).then(res => {
            this.$store.commit('setThingDimeType', res.data.data)
          })
        }
      },
      changeCopy(ofoDeal, ofoFlowWork) {
        this.ofoFlowDeal = ofoDeal
        this.ofoFlowWork = ofoFlowWork
        // this.getData()
      },
      codeSearch(queryString, cb) {
        var codeList = this.codeList;
        var results = queryString ? codeList.filter(this.createFilter(queryString)) : codeList;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (codeList) => {
          return (codeList.value.toLowerCase().includes(queryString.toLowerCase()));
        };
      },
      handleCodeSelect(e) {
        // console.log(item.value.split(' - '));
        this.ruleForm.code = e.value.split(' - ')[1]
      },
      // 资源选择框发生改变时内容也改变
      getResChange(value, string) {
        this.resourceList.forEach(item => {
          if(item.ofrResourceId == value) {
            if(item.ofrThingList.length) {
              this.thingList = item.ofrThingList
              if(!string) {
                this.ruleForm.dealownId = item.ofrThingList[0].ofrThingId
                this.ruleForm.dealownName = item.ofrThingList[0].name
              }
            }else {
              this.thingList = []
              this.ruleForm.dealownId = ''
              this.ruleForm.dealownName = ''
            }
          }
        })
      },
      // 切换供应商内容
      getDealownChange(e) {
        let index = this.thingList.findIndex(item => item.ofrThingId == e)
        this.ruleForm.dealownName = this.thingList[index].name
      },
      // 交易组件有甲方资源类型并且资源种类为‘管理’ 选择框发生改变时，联系人也发生改变
      getDealowhChange(value, string) {
        this.departmentList.forEach(item => {
          if(item.cbOwhId == value) {
            if(item.owhThingList) {
              this.contactList = item.owhThingList
              if(!string) {
                this.ruleForm.dealownId = item.owhThingList[0].ofrthingId
              }
              // this.ruleForm.dealownId = this.ruleForm.dealownId ? this.ruleForm.dealownId : item.owhThingList[0].ofrthingId
            }else {
              this.contactList = []
              this.ruleForm.dealownId = ''
            }
          }
        })
      },
      // 切换导航
      checked(index) {
        this.isActive = index
      },
      // 确认复制从列表
      copyFromConfirm(val) {
        this.ruleForm.code = this.ruleForm.code ? this.ruleForm.code : ((val.obj.code.indexOf('OFW') == 0 ) ? '' : val.obj.code)
        this.ruleForm.dealId = val.obj.dealId
        this.ruleForm.dealowhId = val.obj.dealowhId
        this.ruleForm.dealownId = val.obj.dealownId
        this.ruleForm.selforgId = val.obj.selforgId
        // this.ruleForm.code = 
        val.productsList.forEach(item => {
          this.productsList.push(item)
          // 
        })
        val.thingsList.forEach(item => {
          this.thingsList.push(item)
        })
      },
      // 接收产品列表
      receiveProductsList(val) {
        this.productsList.forEach((item, index) => {
          item.index = index
        })
        this.list = val[0]
        this.ptotalObj = val[1]
        this.ctotalObj = val[2]
      },
      // 接收明细列表
      receiveThingsList(val) {
        this.thingsList.forEach((item, index) => {
          item.index = index
        })
        this.list2 = val[0]
        this.ptotalObj2 = val[1]
        this.ctotalObj2 = val[2]
      },
      // 获取活动内容的维度列表
      getCbussDimeList(val) {
        // this.resource.cbRestypeId = val.cbRestypeId
        // console.log(this.resource.cbRestypeId);
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resource.cbRestypeId, dimetype: '1'}}).then(res => {
          if(res.data.code == 200) {
            this.resourceDimes = this.handleFormList(res.data.data)
            this.resource.dataSourceConfig = this.resourceDimes
          }
        })
      },
      // 部门选择框发生改变时，负责人也发生改变
      getDeptChange(value, string) {
        this.departmentList.forEach(item => {
          if(item.cbOwhId == value) {
            if(item.owhThingList) {
              this.leaderList = item.owhThingList
              if(!string) {
                this.ruleForm.selfownId = item.owhThingList[0].ofrthingId
              }
            }else {
              this.leaderList = []
              this.ruleForm.selfownId = ''
            }
          }
        })
      },
      // 提交表单
      submitForm(formName) {
        let location1 = true
        let location2 = true
        let res = true
        let fileRequired = true
        let resRequired = true
        // 活动为对象管理
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0') {
          this.$refs.children.$refs.resource.validate((resValid, resErr) => {
            if(resValid) {
              resRequired = true
            }else {
              resRequired = false
            }
          })
        }
        // 产品位置
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.chooseOwh == '0' && this.list.length) { 
          this.list.some((item, index) => {
            if(!item.cbowhId) {
              this.$message.error(`请选择第 ${index + 1} 行仓库`)
              location1 = false
              return true
            }else {
              location1 = true
            }
          })
        }else {
          location1 = true
        }
        // 明细位置
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.chooseOwh == '0' && this.list2.length) { 
          this.list2.some((item, index) => {
            if(!item.cbowhId) {
              this.$message.error(`请选择第 ${index + 1} 行仓库`)
              location2 = false
              return true
            }else {
              location2 = true
            }
          })
        }else {
          location2 = true
        }
        // 判断资源行是否选中多个资源
        this.list.some((item, index) => {
          item.ofrresourceId = item.ofrresourceId + ''
          if(item.ofrresourceId.includes(',')) {
            this.$message.error(`第 ${index + 1} 行资源选中多条，请选择一条`)
            res = false
            return true
          }else {
            res = true
          }
        })
        // 判断附件必填
        if(this.fileList.length) {
          this.fileList.some((item, index) => {
            if(item.isRequired == '1' && !item.fileList.length) {
              this.$message.error(`请上传${item.name}`)
              fileRequired = false
              return true
            }else {
              fileRequired = true
            }
          })
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (valid && location1 && location2 && res && fileRequired && resRequired) {
              // 格式化文档列表
              let documentsList = []
              if(this.documentsList) {
                documentsList = this.handleDimes(this.documentsList)
              }
              let resourceDimes = []
              if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0' && this.resourceDimes) {
                resourceDimes = this.handleDimes(this.resourceDimes)
              }
              // 表格价税合计
              let ptotalObj = {}
              if(this.thingsList.length) {
                ptotalObj = this.ptotalObj2
              }else {
                ptotalObj = this.ptotalObj
              }
              
              // 表格成本价税合计
              let ctotalObj = {}
              if(this.thingsList.length) {
                ctotalObj = this.ctotalObj2
              }else {
                ctotalObj = this.ctotalObj
              }
              // 禁用提交按钮
              this.isDisabled = true
              let query = {
                ...this.ruleForm,
                documents: JSON.stringify(documentsList),
                list: JSON.stringify(this.list),
                list2: JSON.stringify(this.list2),
                ...ptotalObj,
                ...ctotalObj,
                resourceName: this.$refs.children.resource.resourceName,
                resourceDimes: JSON.stringify(resourceDimes),
                resourceRemarks: this.$refs.children.resource.remarks,
                cbRestypeId: this.$refs.children.resource.cbRestypeId,
                files: JSON.stringify(this.fileList)
              }
              this.$http.post(url.addWork, qs.stringify({...query, ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId})).then(res => {
                // console.log(res);
                this.isDisabled = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.getData()
                }
              })
            }
          } else {
            for(let key in err) {
              if(key.includes('dataSourceConfig')) {
                 this.$message.error(`${this.documentsList[key.split('.')[1]].dimeName}不能为空`)
                 return false;
              }
            }
            return false;
          }
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
.container {
  padding: 16px;
  .main {
    overflow: hidden;
    padding: 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 11px;
    .title {
      height: 14px;
      font-size: 14px;
      font-family: STHeitiSC-Medium, STHeitiSC;
      font-weight: 500;
      color: #282828;
      line-height: 15px;
      padding-left: 8px;
      border-left: #0C75E5 2px solid;
      margin-bottom: 16px;
      font-weight: bolder;
    }
    .nav-tabs {
      display: flex;
      /* overflow: hidden; */
      /* height: 50px; */
      /* background-color: yellow; */
      margin-bottom: 16px;
      margin-right: 20px;
      border-bottom: 1px solid #eeeeee;
      .nav-title {
        display: flex;
        padding-bottom: 16px;
        /* border-bottom: #409eff 2px solid; */
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        .nav-icon {
          width: 40px;
          height: 40px;
          background-color: #FFFFFF;
          border-radius: 50%;
          margin-right: 8px;
          text-align: center;
          line-height: 40px;
          border: #409eff 1px solid;
          color: #409eff;
          .iconfont {
            font-size: 18px;
          }
        }
      }
      .isActive {
        border-bottom: #409eff 3px solid;
        color: #409eff;
        .nav-icon {
          background-color: #409eff;
          color: #FFFFFF;
        }
      }
    }
    .nav-content {
      margin-bottom: 16px;
      margin-right: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
    }
  }
}
</style>