<template>
  <div class="approval">
    <div class="sift">
      <div class="title">条件查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="名称">
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入名称"
              clearable
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码">
            <strong slot="label">代码</strong>
            <el-input
              v-model="formInline.code"
              placeholder="请输入代码"
              clearable
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button>
        <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button>
      </div>
    </div>
    <div class="main">
      <div class="title">条件列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="名称" >
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.name}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="代码"></el-table-column>
        <el-table-column prop="remarks" label="描述"></el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click.native.stop="handleEdit(scope.row)"
            >编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click.native.stop="handleDel(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="60px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="代码" prop="code">
          <el-input v-model="ruleForm.code" placeholder="请输入代码"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="remarks">
          <el-input type="textarea" v-model="ruleForm.remarks" placeholder="请输入描述"></el-input>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import {mapState} from 'vuex'
import qs from 'qs'
  export default {
    computed: {
      ...mapState({
        dropDown: (state) => state.dropDown.dropDown
      })
    },
    components: {
      DictTag,
    },
    data() {
      return {
        formInline: {
          name: '',
          code: ''
        },
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        ruleForm: {
          name: '',
          code: "",
          remarks: ''
        },
        rules: {
          name: [{ required: true, message: "请输入名称", trigger: "submit" }],
          code: [{ required: true, message: "请输入代码", trigger: "submit" }],
        },
        // options: [],
        // 弹出层标题
        title: '',
        isLoading: false,
        edit: false,
        del: false,
        ids: [],
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    },
    created () {
      this.getList()
    },
    methods: {
      getList() {
        this.$http.get(url.getCfAppUcList, {params: {
          name: this.formInline.name,
          code: this.formInline.code,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            // console.log(res);
            this.tableData = res.data.data.list
            this.total = res.data.data.total
          }
        })
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // 新增条件
            if(!this.ruleForm.cfAppUnitcondId) {
              this.$http.post(url.addCfAppUc, qs.stringify(this.ruleForm)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.pageNum = 1
                  this.loading = true
                  this.getList()
                }
              })
            }else { // 编辑条件
              this.$http.post(url.editCfAppUc, qs.stringify(this.ruleForm)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.pageNum = 1
                  this.loading = true
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      // 新增
      handleAdd() {
        this.dialogFormVisible = true
        this.title = '新增条件'
        this.$nextTick(() => {
        // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        // 初始化表单内容
        for(let key in this.ruleForm) {
          this.ruleForm[key] = ''
        }
      },
      // 修改
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        let cfAppUnitcondId = row.cfAppUnitcondId || this.ids[0]
        // console.log(row);
        this.dialogFormVisible = true
        this.title = '修改条件'
        this.$http.get(url.getCfAppUc, {params: {cfAppUnitcondId}}).then(res => {
          // console.log(res);
          this.ruleForm = res.data.data
        })
      },
      // 删除
      handleDel(row) {
        let cfAppUnitcondIds = row.cfAppUnitcondId || this.ids.toString()
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeCfAppUc, qs.stringify({cfAppUnitcondIds})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.pageNum = 1
              this.loading = true
              this.getList()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      // 查看详情
      handleDetail(row) {
        // this.$router.push('/set/approval/' + row.cfAppUnitcondId)
        // let obj = {
        //   path: '/set/approval/' + row.cfAppUnitcondId,
        //   name: 'approvalDetail',
        //   label: '审批详情',
          // children: []
        // }
        // this.$store.commit("selectMenu", obj);
      },
      // 查询按钮
      inquire() {
        this.loading = true
        this.pageNum = 1
        this.getList()
      },
      // 重置按钮
      reset() {
        for(let key in this.formInline) {
          this.formInline[key] = ""
        }
        this.pageNum = 1
        this.loading = true
        this.getList()
      },
      // 选择框事件
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.cfAppUnitcondId)
        this.edit = rows.length === 1
        this.del = rows.length >= 1
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.loading = true
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.loading = true
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
.approval {
  /* padding: 16px; */
  .sift, .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    margin-bottom: 16px;
    .el-table {
      margin-bottom: 39px;
    }
  }
  .main {
    margin-bottom: 0;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>