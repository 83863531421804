

const routes = [
  // {
  //   path: '/process',
  //   name: 'process',
  //   redirect: '/process/approval',
  //   component: Process,
  //   children: [
  //     {
  //       path: 'approval',
  //       name: 'approval',
  //       component: Approval
  //     },
  //     {
  //       path: 'approval/detail',
  //       name: 'detail',
  //       component: Detail,
  //     },
  //     {
  //       path: 'done',
  //       name: 'done',
  //       component: Done
  //     },
  //     {
  //       path: 'mine',
  //       name: 'mine',
  //       component: Mine
  //     },
  //     {
  //       path: 'copy',
  //       name: 'copy',
  //       component: Copy
  //     },
  //     {
  //       path: '*',
  //       name: 'develop',
  //       component: Develop
  //     },
  //   ]
  // }
  // {
  //   path: '/process',
  //   name: 'process',
  //   redirect: '/process/approval',
  //   component: Process,
  //   children: [
  //     {
  //       path: 'approval',
  //       name: 'approval',
  //       component: Approval
  //     },
  //     {
  //       path: 'approval/detail',
  //       name: 'detail',
  //       component: Detail,
  //     },
  //     {
  //       path: 'done',
  //       name: 'done',
  //       component: Done
  //     },
  //     {
  //       path: 'mine',
  //       name: 'mine',
  //       component: Mine
  //     },
  //   ]
  // }
]

export default routes