<template>
  <div class="resource">
    <div class="sift">
      <div class="title">消息查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="消息类别">
            <el-select v-model="formInline.msgType" placeholder="消息类别" clearable>
              <el-option v-for="(item, index) in dropDown.msgType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="formInline.status" placeholder="状态" clearable>
              <el-option v-for="(item, index) in dropDown.msgStatus" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" plain size="small" :disabled="!read" @click="handleRead">标为已读</el-button>
        <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button>
      </div>
    </div>
    <div class="detail">
      <div class="title">消息列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="msgTitle" label="标题" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row, scope.$index)" class="link">{{scope.row.msgTitle}}</a>
          </template>
        </el-table-column>
        <el-table-column label="消息类别" align="center">
          <template slot-scope="scope">
            <DictTag :options="dropDown.msgType" :value="scope.row.msgType" :isStyle="true"/>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <DictTag :options="dropDown.msgStatus" :value="scope.row.status" :isStyle="true"/>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            {{scope.row.createTime | filter_handleDateTime}}
          </template>
        </el-table-column>
        <el-table-column 
          label="操作"
          fixed="right"
          align="right">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini" icon="el-icon-del"
              @click="handleEdit(scope.row)">编辑</el-button> -->
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFromVisible"
      width="30%"
      append-to-body
      top="59px"
      center >
      {{msgContent}}
      <span slot="footer" class="dialog-footer">
        <!-- <el-form> -->
        <el-button type="primary" @click="dialogFromVisible = false">确 定</el-button>
        <!-- </el-form> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
export default {
  components: {
    DictTag
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        msgType: "",
        status: "",
      },
      tableData: [],
      // 编辑按钮不可点
      del: false,
      read: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      ids: [],
      isDisabled: false,
      dialogFromVisible: false,
      msgContent: '',
      title: ''
    };
  },
  created() {
    this.getList();
  },
  mounted () {
  },
  methods: {
    // 查询按钮
    inquire() {
      this.loading = true
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.loading = true
      this.getList()
    },
    // 表格的选中框事件
    handleSelectionChange(rows) {
      this.ids = rows.map(item => item.id)
      this.del = rows.length >= 1
      this.read = rows.length >= 1
    },
    // 获取消息列表
    getList() {
      this.$http.get(url.getMsgList, {params: {
          msgType: this.formInline.msgType,
          status: this.formInline.status,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }}).then(res => {
          if(res.data.code == 200) {
            this.loading = false
            this.total = res.data.data.total
            this.tableData = res.data.data.list

          }
        })
        // 消息类型下拉
        if(!this.dropDown.msgType) {
          this.$http.get(url.getDataList, {params: {type: 'buss_msg_type' }}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setMsgType', res.data.data)
            }
          })
        }
        // 消息状态下拉
        if(!this.dropDown.msgStatus) {
          this.$http.get(url.getDataList, {params: {type: 'buss_msg_status' }}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setMsgStatus', res.data.data)
            }
          })
        }
    },
    // 查看详情
    handleDetail(row, index) {
      // console.log(index);
      // console.log(3333333);
      this.dialogFromVisible = true
      this.tableData[index].status = '1'
      this.$http.get(url.getMsg, {params: {id: row.id}}).then(res => {
        if(res.data.code == 200) {
          this.title = res.data.data.msgTitle
          this.msgContent = res.data.data.msgContent
          this.$eventBus.$emit('getMsgCount', true)
        }
      })
    },
    // 标记已读
    handleRead() {
      let ids = this.ids.toString()
      this.$confirm('此操作将以下消息标记为已读, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.changeRead, qs.stringify({ids})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '标记成功!'
            });
            this.getList()
            this.$eventBus.$emit('getMsgCount', true)
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 删除操作
    handleDel(row) {
      let ids = row.id || this.ids.toString()
      this.$confirm('此操作将永久删除该消息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeMsg, qs.stringify({ids})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
  },
};
</script>

<style scoped lang="scss">
.resource {
  /* padding: 16px; */
  .sift,.detail {
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
    border-radius: 11px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 15px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
  .sift {
    overflow: hidden;
    ::v-deep .el-form-item__label {
      font-weight: 900;
    }
    .date {
      ::v-deep .el-form-item__content {
        width: 80%;
      }
      ::v-deep .el-input__inner {
        width: 90%;
      }
    }
  }
  .detail {
    margin-bottom: 0;
    .el-table {
      margin-bottom: 39px;
    }
  }
}
</style>