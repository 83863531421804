<template>
  <div>
    <el-form :inline="true" :model="form" class="demo-form-inline" size="small" @submit.native.prevent v-show="isShowFormInline">
      <el-form-item>
        <span slot="label">
          <strong>名称</strong>
        </span>
        <el-input
          v-model="form.name"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="inquire"
          @clear="inquire"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-tooltip class="item" effect="dark" :content="isShowFormInline ? '隐藏搜索' : '显示搜索'" placement="top-end">
      <el-button style="padding: 6px;border-radius: 50%;float:right" icon="el-icon-search" @click="isShowFormInline = !isShowFormInline"></el-button>
    </el-tooltip>
    <div style="width:100%;overflow: hidden">
      <div style="text-align:center;color:#999" v-if="!workList.length">暂无数据</div>
      <el-card class="box-card" v-for="(item,index) in workList" :key="index" @click.native="handleView (item)" :class="(index + 1) % 5 == 0 ? 'noright' : ''">
        <span :style="item.documentstatus == '0' ? 'color: #0063FB;background-color: #E5F3FF' : (item.documentstatus == '1' ? 'color: #13ce66;background-color: #e7faf0' : (item.documentstatus == '2' ? 'color: #ffba00;background-color: #fff8e6' : 'color: #ff4949;background-color: #ffeded'))" class="status">
          <DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/> 
        </span>
        <i class="iconfont icon-yidu iconStatus" v-if="item.isView == '1'"></i>
        <i class="iconfont icon-weidu1 iconStatus" v-else></i>
        <span class="code">{{item.code}}</span>
        <div class="text header">
          <span class="text-left">名称:</span>
          <el-tooltip popper-class="atooltip" 
            effect="dark" 
            :content="item.name" 
            placement="top-start">
            <span class="text-right">{{item.name}}</span>
          </el-tooltip>
        </div>
        <div class="text">
          <span class="text-left">日期:</span>
          <span class="text-right">{{item.workDate | filter_handleDate}}</span>
        </div>
        <template v-if="item.ofoWorkShowList">
          <div class="text" v-for="(val, id) in item.ofoWorkShowList" :key="id">
            <span class="text-left">{{val.name + ':'}}</span>
            <span class="text-right">
              <template v-if="val.type == 'b'">
                <template v-if="val.column == 'ptotal'">
                  {{item[val.column] | comdify}}
                </template>
                <template v-else>
                  {{item[val.column]}}
                </template>
              </template>
              <template v-else>{{item.workShowMap[val.column]}}</template>
            </span>
          </div>
        </template>
        <template v-else>
          <div class="text">
            <span class="text-left">数量:</span>
            <span class="text-right">{{item.quantity}}</span>
          </div>
          <div class="text">
            <span class="text-left">金额:</span>
            <span class="text-right">{{item.ptotal | comdify}}</span>
          </div>
        </template>
        <!-- <div v-if="(ofoFlowWork.checkType == '1' || ofoFlowWork.checkType == '2')" style="height: 18px">
          <el-progress :percentage="item.percentage" style="margin: 0" v-if="item.openTotal != null && item.percentage != 100 && item.checkStatus != '0'" :format="format"></el-progress>
        </div> -->
      </el-card>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      background
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="->, prev, pager, next, sizes, jumper, total"
      :total="total"
    >
    </el-pagination>
    <el-dialog :visible.sync="dialogFormVisible" :append-to-body="true" :width="'90%'" top="59px" custom-class="dialogClass" :show-close="false">
      <div slot="title" class="dialog-title">
        <div>
          <i :class="isShowNav ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="isShowNav = !isShowNav" style="font-size:20px"></i>
          <span style="font-weight:400;font-size:20px">{{title}}</span>
        </div>
        <div style="display: flex">
          <el-button icon="el-icon-close" @click="dialogFormVisible = false" type="text" style="padding: 0; font-size:18px; color: #666666"></el-button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="content-left" v-show="isShowNav" >
          <el-timeline>
            <el-timeline-item v-for="(item, index) in processList" :key="index" placement="top" timestamp="" :color="item.color">
              <el-card>
                <div
                  v-for="(val, id) in item"
                  :key="id"
                  :style="{
                    ...{
                      border:'1px solid #EBEEF5',
                      'border-radius':'4px',
                      'box-shadow': '0 2px 12px 0 rgba(0,0,0,.1)',
                      padding: '22px 16px 0',
                      'margin-bottom':'10px',
                      position: 'relative'
                    },
                    ...{
                      background: (val.ofoWorkId == ruleForm.ofoWorkId) ? '#ecf5ff' : '#fff'
                    }
                  }"
                >
                  <div :style="{
                    ...{
                      position:'absolute',
                      left: 0,
                      top:0,
                      padding:'2px 5px',
                      'border-radius': '4px 0px 4px 0px',
                      color:'#282828',
                      'font-size': '13px'
                    },
                    ...{
                      color: (val.ofoWorkId == ruleForm.ofoWorkId) ? '#fff' : '#000',
                      background: (val.ofoWorkId == ruleForm.ofoWorkId) ? 'rgb(64, 158, 255)' : '#F2F2F2',
                    }
                  }">{{val.ofoflowworkName}}</div>
                  <el-descriptions :column="1">
                    <el-descriptions-item label="名称">
                      <div @click="handleView(val)" class="link" v-if="val.ofoWorkId != ruleForm.ofoWorkId">{{val.name}}</div>
                      <div v-show="val.ofoWorkId == ruleForm.ofoWorkId">{{val.name}}</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="编号">
                      {{val.code}}
                    </el-descriptions-item>
                  </el-descriptions>
                  <el-descriptions :column="2">
                    <el-descriptions-item label="数量">{{val.quantity}}</el-descriptions-item>
                    <el-descriptions-item label="金额">{{val.ptotal | comdify}}</el-descriptions-item>
                  </el-descriptions>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="content-right">
          
          <el-form :model="ruleForm" label-width="110px" ref="ruleForm" :disabled="disabled">
            <el-descriptions :column="3">
              <el-descriptions-item label="名称">{{ruleForm.name}}</el-descriptions-item>
              <el-descriptions-item label="编号">{{ruleForm.code}}</el-descriptions-item>
              <!-- 交易组件有甲方资源类型并且资源种类不为‘管理’ -->
              <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealName}}</el-descriptions-item>
              <el-descriptions-item v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind != '6' && ofoFlowDeal.restype1Name">
                <div slot="label">
                  <template v-if="ofoFlowWork.thingDimeType">
                    <DictTag :options="dropDown.thingDimeType" :value="ofoFlowWork.thingDimeType" style="margin-right:3px"/>
                  </template>
                  <template v-if="!ofoFlowWork.thingDimeType">
                    内容
                  </template>
                </div>
                {{ruleForm.dealownName}}
              </el-descriptions-item>
              <!-- 交易组件有甲方资源类型并且资源种类为‘管理’ -->
              <el-descriptions-item :label="ofoFlowDeal.restype1Name" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">{{ruleForm.dealowhName}}</el-descriptions-item>
              <el-descriptions-item label="联系人" v-if="ofoFlowDeal.restype1 && ofoFlowDeal.restype1Kind == '6' && ofoFlowDeal.restype1Name">
                {{ruleForm.dealownName}}
              </el-descriptions-item>
              <el-descriptions-item label="活动时间">{{ruleForm.workDate}}</el-descriptions-item>
              <el-descriptions-item label="是否预警">{{ruleForm.ifalert == '0' ? '是' : '否'}}</el-descriptions-item>
              <el-descriptions-item label="预警人" v-if="ruleForm.ifalert == '0'">{{ruleForm.alertUsersName}}</el-descriptions-item>
              <template v-if="ofoFlowWork.isNotice == '0'">
                <el-descriptions-item label="是否协同">{{ruleForm.isNotice == '0' ? '是' : '否'}}</el-descriptions-item>
                <el-descriptions-item label="协同人" v-if="ruleForm.isNotice == '0'">{{ruleForm.noticeUsersName}}</el-descriptions-item>
                <el-descriptions-item label="协同活动" v-if="ruleForm.isNotice == '0'">
                  {{ruleForm.noticeFlowWorkName}}
                </el-descriptions-item>
              </template>
            </el-descriptions>
            <!-- 导航栏 -->
            <div class="nav-tabs">
              <div v-for="(item, index) in navList" :key="index" @click="checked(index)" :class="['nav-title', isActive == index ? 'isActive' : '']" v-show="item.isShow">
                <div class="nav-icon">
                  <span :class="item.icon"></span>
                </div>
                <div class="nav-text">{{item.text}}</div>
              </div>
            </div>
            <!-- 明细 -->
            <div class="nav-content" v-show="isActive == 0">
              <keep-alive>
                <Subsidiary 
                  :disabled="disabled" 
                  :ofoFlowWork="ofoFlowWork" 
                  :thingsList="thingsList"
                  @sendThingsList="receiveThingsList"
                  :obussDimeList="obussDimeList2"
                  @getWorkThingList="getWorkThingList"
                  :ofoWorkId="ruleForm.ofoWorkId"
                  :total="total2"
                  :pageNum="pageNum2"
                  :pageSize="pageSize2"
                  :ctotal2="subTotal.ctotal"
                  :ctotalNovat2="subTotal.ctotalNovat"
                  :ctotalVat2="subTotal.ctotalVat"
                  :ptotal2="subTotal.ptotal"
                  :ptotalNovat2="subTotal.ptotalNovat"
                  :ptotalVat2="subTotal.ptotalVat"
                  ref="subsidiary"
                  @copyFromConfirm="copyFromConfirm"
                  :workConfig="workConfig"/>
              </keep-alive>
            </div>
            <!-- 产品及服务 -->
            <div class="nav-content" v-show="isActive == 1">
              <keep-alive>
                <Product 
                  :disabled="disabled"
                  :ofoFlowWork="ofoFlowWork" 
                  :productsList="productsList"
                  @sendProductsList="receiveProductsList"
                  @copyFromConfirm="copyFromConfirm"
                  @getWorkResList="getWorkResList"
                  :ofoWorkId="ruleForm.ofoWorkId"
                  :total="total1"
                  :pageNum="pageNum1"
                  :pageSize="pageSize1"
                  :ctotal1="ruleForm.ctotal"
                  :ctotalNovat1="ruleForm.ctotalNovat"
                  :ctotalVat1="ruleForm.ctotalVat"
                  :ptotal1="ruleForm.ptotal"
                  :ptotalNovat1="ruleForm.ptotalNovat"
                  :ptotalVat1="ruleForm.ptotalVat"
                  ref="product"
                  :workConfig="workConfig"/>
              </keep-alive>
            </div>
            <!-- 活动内容 -->
            <div class="nav-content" v-show="isActive == 2">
              <keep-alive>
                <Content 
                  :disabled="disabled" 
                  ref="children" 
                  :resource="resource" 
                  :resourceDimes="resourceDimes" 
                  :ofoWorkId="ruleForm.ofoWorkId + ''" 
                  :ofoFlowWork="ofoFlowWork"
                  :isShare="'0'"/>
              </keep-alive>
            </div>
            <!-- 文档 -->
            <div class="nav-content" v-show="isActive == 3">
              <keep-alive>
                <Document :disabled="disabled" :documentsList="documentsList" :ofoFlowWork="ofoFlowWork" />
              </keep-alive>
              
            </div>
            <!-- 凭证 -->
            <div class="nav-content" v-show="isActive == 4">
              <keep-alive>
                <Voucher :ojdtacctList="ojdtacctList"/>
              </keep-alive>
            </div>
            <!-- 附件 -->
            <div class="nav-content" v-show="isActive == 5">
              <keep-alive>
                <Annex :fileList="fileList" :disabled="disabled" />
              </keep-alive>
            </div>
            <!-- 审批 -->
            <div class="nav-content" v-if="isActive == 6">
              <keep-alive>
                <Approval :approvalList="approvalList" @refreshGetwork="refreshGetwork"/>
              </keep-alive>
            </div>
            <el-descriptions :column="3">
              <el-descriptions-item label="单位">{{ruleForm.selforgName}}</el-descriptions-item>
              <el-descriptions-item label="部门">{{ruleForm.selfowhName}}</el-descriptions-item>
              <el-descriptions-item label="单据负责人">{{ruleForm.selfownName}}</el-descriptions-item>
              <el-descriptions-item label="操作人">{{ruleForm.userName}}</el-descriptions-item>
              <el-descriptions-item label="注释">{{ruleForm.remarks}}</el-descriptions-item>
            </el-descriptions>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">关 闭</el-button> -->
        <!-- <el-button type="primary" @click="submitForm('ruleForm')" v-show="!disabled" :disabled="isDisabled">确 定</el-button> -->
        <el-button icon="el-icon-check" type="primary" @click="handleEditShareStatus">已 阅</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import url from '@/config/url'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
// 产品及服务
import Product from '@/components/project/activity/product.vue'
// 明细
import Subsidiary from '@/components/project/activity/subsidiary.vue'
// 活动内容
import Content from '@/components/project/activity/content.vue'
// 文档
import Document from '@/components/project/activity/document.vue'
// 审批
import Approval from '@/components/project/activity/approval.vue'
// 凭证
import Voucher from '@/components/project/activity/voucher.vue'
// 附件
import Annex from '@/components/project/activity/annex.vue'
import handleWorkConfigList from '@/utils/handleWorkConfigList'
  export default {
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown
      }),
    },
    components: {
      DictTag,
      Product,
      Approval,
      Content,
      Subsidiary,
      Voucher,
      Annex,
      Document
    },
    data() {
      return {
        workList: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        loading: true,
        form: {
          name: ''
        },
        dialogFormVisible: false,
        isShowNav: true,
        title: '查看详情',
        processList: [],
        ruleForm: {},
        disabled: true,
        navList: [],
        isActive: 0,
        ofoFlowDeal: {},
        ofoFlowWork: {},
        thingsList: [],
        obussDimeList2: [],
        documentstatus: '',
        productsList: [],
        obussDimeList: [],
        ojdtacctList: [],
        resource: {},
        resourceDimes: [],
        documentsList: [],
        fileList: [],
        approvalList: [],
        isShowFormInline: false,
        postAndUserList: [],
        workCopyToList1: [],
        workConfig: {},
        pageNum1: 1,
        pageNum2: 1,
        pageSize1: 10,
        pageSize2: 10,
        total1: 0,
        total2: 0,
        
        subTotal: {
          ctotal: 0,
          ctotalNovat: 0,
          ctotalVat: 0,
          ptotal: 0,
          ptotalNovat: 0,
          ptotalVat: 0,
        },
      }
    },
    created () {
      // 发布下拉
      if(!this.dropDown.documentstatus) {
        this.$http.get(url.getDataList, {params: {type: 'buss_document_status' }}).then(res => {
          this.$store.commit('setDocumentstatus', res.data.data)
        })
      }
      this.getList()
    },
    methods: {
      handleEditShareStatus() {
        // console.log(111);
        // this.$eventBus.$emit('editShareStatus', true)
        this.$http.post(url.editShareStatus, qs.stringify({flowType: 'w', obussId: this.ruleForm.ofoWorkId})).then(res => {
          if(res.data.code == 200) {
            this.dialogFormVisible = false
            this.getList()
          }
        })
      },
      getList() {
        this.loading = true
        this.$http.get(url.getShareList, {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            name: this.form.name,
            flowType: 'w'
          }
        }).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.workList = res.data.data.list
            this.total = res.data.data.total;
          } 
        })
      },
      async handleView(item) {
        this.dialogFormVisible = true
        await this.$http.get(url.getFlowDealAndWork, {params: {ofoWorkId: item.ofoWorkId}}).then(res => {
          if(res.data.code == 200) {
            this.ofoFlowDeal = res.data.data.ofoFlowDeal
            this.ofoFlowWork = res.data.data.ofoFlowWork
            this.navList = [
              {
                icon: 'iconfont icon-lvzhou_mingxi',
                text: this.ofoFlowWork.thingtypeName + '（明细）',
                isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
              },
              {
                icon: 'iconfont icon-chanpin',
                text: this.ofoFlowWork.kindtypeName,
                isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
              },
              {
                icon: 'iconfont icon-demand',
                text: '活动内容',
                isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype == '0'
              },
              {
                icon: 'iconfont icon-wendang',
                text: '文档',
                isShow: this.ofoFlowWork.kinddocutype
              },
              {
                icon: 'iconfont icon-ziyuan-xianxing',
                text: '凭证',
                isShow: false,
              },
              {
                icon: 'iconfont icon-fujian',
                text: '附件',
                isShow: false
              },
              {
                icon: 'iconfont icon-tianshenpi',
                text: '审批',
                isShow: false
              },
              {
                icon: 'iconfont icon-hexiao',
                text: '核销',
                isShow: false
              },
            ]
            // 获取关联人员列表
            this.$http.get(url.getPostAndUserList).then(res => {
              if(res.data.code == 200) {
                this.postAndUserList = res.data.data
              }
            })
            if(this.ofoFlowWork.isNotice == '0') {
              // 获取活动复制到列表（协同用到）
              this.$http.get(url.getWorkCopyToList, {params: {
                ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
              }}).then(res => {
                if(res.data.code == 200) {
                  this.workCopyToList1 = res.data.data
                }
              })
            }
            // 获取资源行配置
            if(this.ofoFlowWork.checkConfig) {
              this.$http.get(url.getItemWorkConfig, {params: {
                ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
              }}).then(res => {
                if(res.data.code == 200) {
                  this.workConfig = res.data.data
                }
              })
            }else {
              this.workConfig = {}
            }
          }
        })
        this.getWorkResList(item)
        this.getWorkThingList(item)
        await this.getWork(item)
      },
      // 获取产品列表
      getWorkResList(item) {
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
          this.$http.get(url.getWorkResAndThingList, {params: {
            ofoWorkId: item.ofoWorkId,
            pageNum: item.pageNum ? item.pageNum : 1,
            pageSize: item.pageSize ? item.pageSize : 10,
            type: '1'
          }}).then(res => {
            if(res.data.code == 200) {
              this.productsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
              this.total1 = res.data.data.total
              this.pageNum1 = item.pageNum ? item.pageNum : 1
              this.pageSize1 = item.pageSize ? item.pageSize : 10
            }
          })
        }
      },
      // 获取明细列表
      getWorkThingList(item) {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          this.$http.get(url.getWorkResAndThingList, {params: {
            ofoWorkId: item.ofoWorkId,
            pageNum: item.pageNum ? item.pageNum : 1,
            pageSize: item.pageSize ? item.pageSize : 10,
            type: '2'
          }}).then(res => {
            if(res.data.code == 200) {
              this.total2 = res.data.data.total
              this.pageNum2 = item.pageNum ? item.pageNum : 1
              this.pageSize2 = item.pageSize ? item.pageSize : 10
              for(let key in this.subTotal) {
                this.subTotal[key] = res.data.data[key]
              }
              this.thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
            }
          })
        }
      },
      async getWork(item) {
        await this.$http.get(url.getWork, {params: {ofoWorkId: item.ofoWorkId, isShare: '0'}}).then(res => {
          if(res.data.code == 200) {
            this.ruleForm = res.data.data.work
            if(this.ruleForm.alertUsers) {
              this.ruleForm.alertUsersName = []
              this.ruleForm.alertUsers = this.ruleForm.alertUsers.split(',')
              this.postAndUserList.forEach(item => {
                if(item.ofrThingList.length) {
                  item.ofrThingList.forEach(val => {
                    this.ruleForm.alertUsers.forEach(el => {
                      if(el == val.ofrThingId) {
                        this.ruleForm.alertUsersName.push(val.name)
                      }
                    })
                  })
                }
              })
              this.ruleForm.alertUsersName = this.ruleForm.alertUsersName.toString()
            }
            if(this.ruleForm.noticeUsers) {
              this.ruleForm.noticeUsersName = []
              this.ruleForm.noticeUsers = this.ruleForm.noticeUsers.split(',')
              this.postAndUserList.forEach(item => {
                if(item.ofrThingList.length) {
                  item.ofrThingList.forEach(val => {
                    this.ruleForm.noticeUsers.forEach(el => {
                      if(el == val.ofrThingId) {
                        this.ruleForm.noticeUsersName.push(val.name)
                      }
                    })
                  })
                }
              })
              this.ruleForm.noticeUsersName = this.ruleForm.noticeUsersName.toString()
            }
            if(this.ruleForm.noticeFlowWork) {
              this.workCopyToList1.forEach(item => {
                if(item.ofoFlowWorkId == this.ruleForm.noticeFlowWork) {
                  this.ruleForm.noticeFlowWorkName = item.name
                }
              })
            }
            this.isCollect = res.data.data.isCollect
            // 初始化活动附件列表
            if(res.data.data.files) {
              this.fileList = res.data.data.files
              this.navList[5].isShow = true
            }else {
              this.fileList = []
              this.navList[5].isShow = false
            }
            this.documentstatus = this.ruleForm.documentstatus
            // 是否有凭证
            if(this.ofoFlowWork.ojdttype == '' || this.ofoFlowWork.ojdttype == null || this.ofoFlowWork.ojdttype == '1') {
                this.navList[4].isShow = false
            }else {
              if(this.ruleForm.documentstatus == '0') {
                this.navList[4].isShow = false
              }else {
                this.navList[4].isShow = true
              }
            }
            // 凭证列表
            if(res.data.data.ojdtacctList) {
              this.ojdtacctList = res.data.data.ojdtacctList
            }else {
              this.ojdtacctList = []
            }
            // 是否展示审批
            if(this.ruleForm.appstatus == null || this.ruleForm.appstatus == '') {
              this.navList[6].isShow = false
            }else {
              this.navList[6].isShow = true
            }
            // 初始化审批列表
            if(res.data.data.appStepList) {
              this.approvalList = res.data.data.appStepList
            }else {
              this.approvalList = []
            }
            // 初始化项目时间
            this.ruleForm.workDate = this.parseTime(res.data.data.work.workDate, '{y}-{m}-{d}')
            // 初始化文档列表
            this.documentsList = this.handleFormList(res.data.data.documents)
            // 初始化资源（活动为对象管理）
            if(res.data.data.resource) {
              this.resource = res.data.data.resource
            }else {
              this.resource = {}
            }
            // 获取资源维度列表
            if(res.data.data.resourceDimes) {
              this.resourceDimes = this.handleFormList(res.data.data.resourceDimes)
            }else {
              this.resourceDimes = []
            }
            let navArr = []
            this.navList.forEach((item, index) => {
              if(item.isShow) {
                navArr.push(index)
              }
            })
            this.isActive = navArr[0]
          }
        })
        this.$http.get(url.getWorkProcess, {params: {ofoWorkId: item.ofoWorkId}}).then(res => {
          // console.log(res);
          this.processList = res.data.data
          this.processList.forEach(val => {
            val.forEach(el => {
              if(el.ofoWorkId == item.ofoWorkId) { 
                val.color = "#409eff"
                // val.color = "#666"
              }
            })
          })
        })
      },
      tableRowClassName({row, rowIndex}) {
        if (row.ofoWorkId == this.ruleForm.ofoWorkId) {
          return 'success-row';
        }
        return '';
      },
      checked(index) {
        this.isActive = index
      },
      receiveThingsList() {},
      receiveProductsList() {},
      copyFromConfirm() {},
      refreshGetwork(val) {
        
      },
      inquire() {
        this.pageNum = 1
        this.getList()
      },
      reset() {
        this.form.name = ''
        this.pageNum = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  margin: 0 auto;
  max-height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
  max-height: calc(100vh - 206px);
  overflow: auto;
}
.dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-icon-s-fold, .el-icon-s-unfold {
  cursor: pointer;
  margin-right: 5px;
}
.el-icon-s-fold:hover {
  color: #0C75E5;
}
.el-icon-s-unfold:hover {
  color: #0C75E5;
}
.dialog-content {
  display: flex;
  .content-left {
    width: 350px;
    margin-right: 16px;
    ::v-deep .el-descriptions__body {
      background: rgba(0,0,0,0);
    }
  }
  .content-right {
    flex: 1;
    overflow: hidden;
  }
}
.nav-tabs {
  display: flex;
  margin-bottom: 16px;
  margin-right: 20px;
  border-bottom: 1px solid #eeeeee;
  .nav-title {
    display: flex;
    padding-bottom: 16px;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    .nav-icon {
      width: 50px;
      height: 50px;
      background-color: #FFFFFF;
      border-radius: 50%;
      margin-right: 8px;
      text-align: center;
      line-height: 50px;
      border: #409eff 1px solid;
      color: #409eff;
      .iconfont {
        font-size: 25px;
      }
    }
  }
  .isActive {
    border-bottom: #409eff 3px solid;
    color: #409eff;
    .nav-icon {
      background-color: #409eff;
      color: #FFFFFF;
    }
  }
}
.nav-content {
  margin-bottom: 16px;
  margin-right: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
}
.box-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 3px 0px rgba(208,208,208,1);
  border-radius: 9px;
  width: calc(20% - 16px);
  /* height: 93px; */
  position: relative;
  padding: 0;
  margin-right: 16px;
  float: left;
  margin-bottom: 16px;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px; 
    /* background-color: #fff; */
  }

  /* 定义滑块 内阴影 */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  ::v-deep .el-card__body {
    padding: 0;
    height: 133px;
    overflow: auto;
    .status {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      height: 21px;
      font-size: 12px;
      line-height: 21px;
      border-radius: 0px 9px 0px 9px;
      display: flex;
      padding: 2px 5px;
      /* flex-wrap: nowrap; */
    }
    .iconStatus {
      position: absolute;
      bottom: 5px;
      right: 10px;
      font-size: 45px;
    }
    .icon-yidu {
      color: #67c23a;
    }
    .icon-weidu1 {
      color: #f56c6c;
    }
    .code {
      position: absolute;
      left: 0; 
      top: 0;
      border: 0;
      padding: 0;
      color: #282828;
      font-size: 12px;
      background-color: #F2F2F2;
      padding: 3px 5px;
      border-radius: 9px 0px 9px 0px;
    }
    .text {
      font-size: 13px;
      line-height: 22px;
      padding: 0 10px;
      /* height: 22px; */
      display: flex;
      .text-left {
        /* display: inline-block; */
        /* width: 45px; */
        color: #8E8E8E;
        letter-spacing: 4px;
      }
      .text-right {
        /* display: inline-block; */
        /* width: calc(100% - 45px); */
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .header {
      margin-top: 24px;
    }
  }
}
</style>