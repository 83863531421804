<template>
  <div class="header">
    <div class="left" @click="refresh">
      <img src="../assets/images/logo.png" alt="" v-if="isDefaultLogo" />
      <img :src="$base_url + user.logo" alt="" v-if="!isDefaultLogo" />
      <span>{{user.orgName}}</span>
    </div>
    <div class="right">
      <!-- <el-input
        placeholder="请输入搜索内容"
        v-model="sousuo"
        @input="change($event)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input> -->
      <div class="bell" @click="openDrawer" ref="bell">
        <el-badge :value="msgCount" :max="99" class="item" :hidden="msgCount == 0">
          <i class="el-icon-message-solid" style="color:#ffffff; font-size:18px"></i>
        </el-badge>
      </div>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <img src="../assets/images/header/avatar.png" alt=""  class="avatar" style="margin-right:5px" />
          {{this.user.bussName}}
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a" icon="el-icon-user">个人中心</el-dropdown-item>
          <el-dropdown-item command="b" icon="el-icon-switch-button">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    
  </div>
</template>

<script>
import url from "../config/url";
import qs from 'qs'
export default {
  data() {
    return {
      sousuo: "",
      user: {},
      drawer: false,
      msgCount: 0,
      isDefaultLogo: true,
      isShowUserMenu: false
    };
  },
  created () {
    this.init()
  },
  mounted() {
    // document.addEventListener("click", (e) => {
    //   // console.log(e);
    //   if(this.isShowUserMenu) {
    //     this.isShowUserMenu = !this.isShowUserMenu
    //   }
    // })
  },
  methods: {
    handleCommand(command) {
      if(command == 'a') {
        this.toCenter()
      }else {
        this.exit()
      }
    },
    init() {
      this.$http.get(url.getLoginUser).then(res => {
        this.user = res.data.data
        
        if(this.user.logo) {
						this.isDefaultLogo = false
					}else {
						this.isDefaultLogo = true
					}
        window.sessionStorage.setItem('loginUser', JSON.stringify(res.data.data))
      })
      // let msgCount = document.querySelector('.number')
      // console.log(msgCount);
      this.getMsgCount()
      this.$eventBus.$on('getMsgCount', data => {
        // console.log(data);
        if(data) {
          this.getMsgCount()
        }
      })
    },
    // 获取未读消息数量
    getMsgCount() {
      this.$http.get(url.getMsgCount).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.msgCount = res.data.data
        }
      })
    },
    refresh() {
      this.$router.push({path: '/index'})
      this.$store.commit('setSecondMenu', [])
      this.$store.commit('selectMenu', {
        children: [],
        icon: "iconfont icon-gongzuotai",
        label: "桌面",
        name: "index",
        path: "/index"
      })
    },
    change(e) {
      // this.$forceUpdate();
    },
    exit() {
      const h = this.$createElement;
      this.$msgbox({
        title: '提示',
        message: h('p', null, '确定注销登录吗？'),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // sessionStorage.clear()
            sessionStorage.removeItem('token')
            instance.confirmButtonText = '注销中...';
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
                this.$router.push('/login');
                this.$store.commit('clearMenu', [{
                  children: [],
                  icon: "iconfont icon-gongzuotai",
                  label: "桌面",
                  name: "index",
                  path: "/index"
                }])
                this.$store.commit('changeMenu',{
                  children: [],
                  icon: "iconfont icon-gongzuotai",
                  label: "桌面",
                  name: "index",
                  path: "/index"
                })
                this.$store.commit('setSecondMenu', [])
                sessionStorage.removeItem("token")
                sessionStorage.removeItem("dropDown")
              }, 30);
            }, 30);
          } else {
            done();
          }
        }
      })
    },
    // 去个人中心
    toCenter() {
      let item = {
        badge: null,
        children: [],
        icon: "iconfont icon-wode",
        label: "我的",
        menuType: "M",
        name: "ucenter",
        path: "/ucenter"
      }
      this.$router.push("/ucenter")
      JSON.parse(sessionStorage.getItem('menuList')).forEach(el => {
        if(item.path.indexOf(el.path) != -1) {
          this.$store.commit('setSecondMenu', el.children)
          item.children = el.children
        }
      });
      this.$store.commit("selectMenu", item);
      // this.$eventBus.$emit('goCenter', 'u')
      this.$store.commit("setMineData", {isUcenter: true})
    },
    openDrawer() {
      // this.drawer = true
      this.$emit('openDrawer', true)
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 52px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #0C75E5;
  width: 100%;
  box-sizing: border-box;
  .left {
    display: flex;
    align-items: center;
    cursor: pointer;
    > img {
      width: 28px;
      margin-right: 6px;
    }
    > span {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }
  .right {
    /* width: 300px; */
    display: flex;
    align-items: center;
    > .el-input {
      width: 266px;
      height: 25px;
      font-size: 12px;
      margin-right: 13px;
      ::v-deep .el-input__inner {
        height: 25px !important;
        background-color: #0363ca;
        border: 0;
        line-height: 25px;
        color: #ffffff !important;
      }
      ::v-deep .el-input__inner::placeholder {
        color: #fff;
      }
      ::v-deep .el-input__icon {
        line-height: 25px;
        color: #ffffff;
      }
    }
    .bell {
      margin-right: 16px;
      cursor: pointer;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #FFFFFF;
      display: flex;
      align-items: center;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
</style>