<template>
  <div class="contain">
    <el-tabs v-model="activeName" @tab-click="inquire">
      <el-date-picker
        v-model="date"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        format="yyyy-MM"
        value-format="yyyy-MM"
        size="mini"
        style="margin-bottom: 16px;margin-right:16px"
        :clearable="false"
        @change="inquire">
      </el-date-picker>
      <el-select v-model="accountType" placeholder="请选择账套" filterable @change="inquire" size="mini" clearable>
        <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
      </el-select>
      <el-tab-pane label="现金总账" name="total">
        <el-table
          :data="totalList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'total'"
        >
          <el-table-column prop="subjectNumber" label="科目编码" header-align="center"></el-table-column>
            <el-table-column prop="subjectName" label="科目名称" header-align="center"></el-table-column>
            <el-table-column prop="period" label="期间" width="100" align="center"></el-table-column>
            <el-table-column prop="abstractStr" label="摘要" header-align="center"></el-table-column>
            <el-table-column prop="debit" label="借方" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.debit | comdify('no')}}
              </template>
            </el-table-column>
            <el-table-column prop="credit" label="贷方" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.credit | comdify('no')}}
              </template>
            </el-table-column>
            <el-table-column prop="direction" label="方向" align="center" width="50">
            </el-table-column>
            
            <el-table-column prop="amount" label="余额" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.amount | comdify('no')}}
              </template>
            </el-table-column>
          
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="现金明细" name="detail">
        <el-table
          :data="detailList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'detail'"
        >
          <el-table-column prop="period" label="日期" width="100" align="center"></el-table-column>
          <el-table-column prop="accountCode" label="凭证字号" align="center" width="180">
            <template slot-scope="scope">
              <a @click="handleDetail(scope.row)" class="link">{{scope.row.accountCode}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="abstractStr" label="摘要" header-align="center"></el-table-column>
          <el-table-column prop="debit" label="借方" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.debit | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="credit" label="贷方" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.credit | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="direction" label="方向" align="center" width="50">
          </el-table-column>
          
          <el-table-column prop="amount" label="余额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.amount | comdify('no')}}
            </template>
          </el-table-column>
          
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
        accountType: '1',
        totalList: [],
        detailList: [],
        loading: true,
        activeName: 'total',
        tableHeight: window.innerHeight - 245
      }
    },
    created () {
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      this.getTotalList()
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        // this.inquire()
        if(this.activeName == 'total') {
          this.getTotalList()
        }else {
          this.getDetailList()
        }
      })
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 245;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 245;
        })()
      }
    },
    methods: {
      getTotalList(){
        this.loading = true
        this.$http.get(url.cashTotalAccount, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.totalList = res.data.data.list
          }
        })
      },
      getDetailList(){
        this.loading = true
        this.$http.get(url.cashDetailAccount, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.detailList = res.data.data.list
          }
        })
      },
      inquire() {
        if(this.activeName == 'total') {
          this.getTotalList()
        }else {
          this.getDetailList()
        }
        
      },
      handleDetail(row) {
        this.$router.push({
          path: '/accounting/vDetail/' + row.offOjdtId, query: {
            account: true
        }})
        let obj = {
          path: '/accounting/vDetail/' + row.offOjdtId,
          name: 'voucherDetail',
          label: '凭证详情',
          offOjdtId: row.offOjdtId,
          children: []
        }
        this.$store.commit("selectMenu", obj);
      },
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
}
</style>