<template>
  <div>
    <div style="text-align:center;font-weight:900;font-size: 16px;margin-bottom:16px">{{ofoFlowWork.kinddocutypeName}}</div>
    <template v-if="!disabled">
      <!-- <el-form :v-model="ruleForm" size="small"> -->
        <Form v-if="documentsList" :formList="documentsList" :gutter="20" :ruleForm="ruleForm"/>
      <!-- </el-form> -->
    </template>
    <template v-if="disabled">
      <el-descriptions :column="3">
        <el-descriptions-item v-for="(item, index) in documentsList" :key="index" :label="item.dimeName + '*'" :labelStyle="{'max-width': '100px'}" :contentStyle="{'background': item.dimeText ? 'rgb(240, 250, 255)' : '#fff'}">
          <template v-if="item.editType != '8'">{{item.dimeText}}</template>
          <template v-else>
            <fileList v-if="item.dimeText" :fileList="JSON.parse(item.dimeText)" />
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </template>
  </div>
</template>

<script>

import DimeTag from '@/components/DimeTag.vue'

import Form from '@/components/Form.vue'
  export default {
    props: {
      disabled: Boolean,
      documentsList: Array,
      ofoFlowWork: Object,
      ruleForm: {
        type: Object,
        default: () => ({
          dataSourceConfig: []
        })
      },
    },
    data() {
      return {
        baseUrl: this.$base_url,
      }
    },
    components: {
      DimeTag,
      Form
    },
  }
</script>

<style lang="scss" scoped>
/* ::v-deep .el-descriptions-item__label {
  min-width: 40px;
}
::v-deep .el-descriptions-item__content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */
</style>