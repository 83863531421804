<template>
  <div class="bg">
    <div class="main">
      <div class="box">
        <div class="left">
          <div class="logo">
            <img src="../assets/images/login/logo.png" alt="" />
            <div>MyWork管理平台</div>
          </div>
        </div>
        <div class="right">
          <div class="password-login">
            <h3>绑定账号～</h3>
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
              @keyup.enter.native="submitForm('ruleForm')"
            >
              <el-form-item prop="userName">
                <el-input
                  placeholder="请输入账号"
                  prefix-icon="el-icon-user-solid"
                  v-model="ruleForm.userName"
                >
                </el-input>
              </el-form-item>

              <el-form-item prop="password">
                <el-input
                  type="password"
                  placeholder="请输入密码"
                  prefix-icon="el-icon-s-goods"
                  v-model="ruleForm.password"
                >
                </el-input>
              </el-form-item>

              <div class="psw">
                <el-checkbox v-model="rememberme">记住密码</el-checkbox>
                <span>忘记密码？</span>
              </div>
              <el-button round @click="submitForm('ruleForm')" :disabled="isDisabled">立即绑定</el-button
              >
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span
        >Copyright © 2022 南京拓意管理软件咨询有限公司 All Rights
        Reserved.</span
      >
    </div>
  </div>
</template>

<script>
import url from "../config/url";
import qs from "qs";
import '@/config/wxLogin'
export default {
  name: "login",
  data() {
    return {
      imgUrl: '',
      ruleForm: {
        userName: "",
        password: "",
        unionid: this.$route.query.unionid
      },
      rememberme: true,
      rules: {
        userName: [{ required: true, message: "请输入账号", trigger: "submit" },],
        password: [{ required: true, message: "请输入密码", trigger: "submit" }],
      },
      isDisabled: false
    };
  },
  created() {
    if(!this.$route.query.unionid) {
      this.$router.push('/login')
    }
  },
  methods: {
    // 提交表单
    submitForm(formName) {
      // this.$router.push("/index");
      this.$refs[formName].validate((valid) => {
        if (valid) { // 表单校验成功
          this.isDisabled = true
          // 发送登录接口请求
          this.$http.post(url.bindUser, qs.stringify(this.ruleForm)).then(res => {
            if(res.data.code === 200) { // 登录成功（用户名和密码正确）
              this.isDisabled = false
              this.$message.success(res.data.msg); // 提示登录成功信息
              // 跳转到首页
              this.$router.push('/index')
              // 将该用户携带的token存到sessionStorage
              window.sessionStorage.setItem('token', res.data.data.token)
            }else { // 登录失败
              this.isDisabled = false
              // 提示信息：用户名或密码错误
              // this.$message.error(res.data.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
  },
  mounted() {},
  // head: {
  //   script: [
  //     { src: "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js" }
  //   ]
  // }
};
</script>

<style scoped lang="scss" src="../assets/styles/login.scss">
</style>