export default {
  state: {
    dropDown: sessionStorage.getItem('dropDown') ? JSON.parse(sessionStorage.getItem('dropDown')) : {}
  },
  mutations: {
    // 设置单据状态(发布)
    setDocumentstatus(state, val) {
      state.dropDown.documentstatus = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置菜单类型
    setItemType(state, val) {
      state.dropDown.itemType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置菜单类型（流程）
    setMenuType(state, val) {
      state.dropDown.menuType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置流程发布类型
    setPublishType(state, val) {
      state.dropDown.publishType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置资源种类
    setKind(state, val) {
      state.dropDown.kind = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置资源交易类型
    setKindDealType(state, val) {
      state.dropDown.kindDealType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置编辑类型
    setEditType(state, val) {
      state.dropDown.editType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置内容维度类型
    setThingDimeType(state, val) {
      state.dropDown.thingDimeType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置维度描述类型
    setDimeRemarksType(state, val) {
      state.dropDown.dimeRemarksType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置账套类型
    setSubjectAccountType(state, val) {
      state.dropDown.subjectAccountType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置凭证类型
    setOjdtType(state, val) {
      state.dropDown.ojdtType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置成本类型
    setCostmanangeType(state, val) {
      state.dropDown.costmanangeType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置活动类型
    setFlowWorkkindmanageType(state, val) {
      state.dropDown.flowWorkkindmanageType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置子交易类型
    setSubdealType(state, val) {
      state.dropDown.subdealType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置库存类型
    setStockacctType(state, val) {
      state.dropDown.stockacctType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置复制类型
    setWorkcopyType(state, val) {
      state.dropDown.workcopyType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置活动附件类型
    setWorkFiletype(state, val) {
      state.dropDown.workFiletype = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置税率
    setInvoiceVat(state, val) {
      state.dropDown.invoiceVat = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置发票类型
    setInvoiceType(state, val) {
      state.dropDown.invoiceType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置发布类型
    setReletype(state, val) {
      state.dropDown.reletype = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置步骤类型
    setStepType(state, val) {
      state.dropDown.stepType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置审批状态
    setAppStatus(state, val) {
      state.dropDown.appStatus = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置消息类型
    setMsgType(state, val) {
      state.dropDown.msgType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置消息状态
    setMsgStatus(state, val) {
      state.dropDown.msgStatus = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置财务类型
    setFinanceType(state, val) {
      state.dropDown.financeType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置移动端是否能编辑
    setAppEdit(state, val) {
      state.dropDown.appEdit = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置是否
    setYesNo(state, val) {
      state.dropDown.yesNo = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置计算方式
    setSettleType(state, val) {
      state.dropDown.settleType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置核销类型
    setCheckType(state, val) {
      state.dropDown.checkType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置科目类型
    setSubjectType(state, val) {
      state.dropDown.subjectType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置报表类型
    setReptType(state, val) {
      state.dropDown.reptType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置正负
    setZfType(state, val) {
      state.dropDown.zfType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置凭证状态
    setOjdtStatus(state, val) {
      state.dropDown.ojdtStatus = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置账套类型(没有基础)
    setSubjectAccountType2(state, val) {
      state.dropDown.subjectAccountType2 = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置审批步骤条件类型
    setAppCondType(state, val) {
      state.dropDown.appCondType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置审批步骤条件比较 
    setAppCondThan(state, val) {
      state.dropDown.appCondThan = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置审批类型
    setStepFlowType(state, val) {
      state.dropDown.stepFlowType = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
    // 设置货币类型
    setMoney(state, val) {
      state.dropDown.money = val
      sessionStorage.setItem('dropDown', JSON.stringify(state.dropDown))
    },
  }
}