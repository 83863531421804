<template>
  <div>
    <template v-for="(item, index) in formList">
      <el-form-item :key="index" :label="item.dimeName + '*'" :prop="item.prop">
        <!-- 1 文本框 -->
        <el-input 
          v-if="item.editType == '1'" 
          :placeholder="'请输入' + item.dimeName" 
          v-model="item['dimeValue']"
          clearable
        ></el-input>
        <!-- 2 文本域 -->
        <el-input 
          v-if="item.editType == '2'" 
          type="textarea" 
          :placeholder="'请输入' + item.dimeName" 
          v-model="item['dimeValue']"
          clearable
        ></el-input>
        <!-- 3 下拉框 -->
        <el-select 
          v-if="item.editType == '3'" 
          v-model="item['dimeValue']" 
          style="width: 100%"
          clearable
          :placeholder="'请选择' + item.dimeName"
          filterable >
          <el-option 
            v-for="(drop, id) in JSON.parse(item.options)" 
            :key="id" 
            :label="drop.optionLabel" 
            :value="drop.optionValue + ''"
          ></el-option>
        </el-select>
        <!-- 4 数字 -->
        <!-- <el-input-number 
          v-if="item.editType == '4'" 
          v-model="item['dimeValue']"
          label="描述文字"
          style="width: 100%"
          controls-position="right"
        ></el-input-number> -->
        <el-input 
          v-if="item.editType == '4'" 
          type="number" 
          :placeholder="'请输入' + item.dimeName" 
          v-model="item['dimeValue']"
          clearable
        ></el-input>
        <!-- 5 日期 -->
        <el-date-picker
          v-if="item.editType == '5'" 
          v-model="item['dimeValue']" 
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 100%">
        </el-date-picker>
        <!-- 6 单选框 -->
        <template
          v-if="item.editType == '6'">
          <br/>
          <el-radio 
            v-for="(val, index) in JSON.parse(item.options)" 
            :key="index"
            v-model="item['dimeValue']" 
            :label="val.optionValue + ''">{{val.optionLabel}}</el-radio>
        </template>
        <!-- 7 多选框 -->
        <template v-if="item.editType == '7'">
          <br/>
          <el-select
            v-if="item.editType == '7'"
            v-model="item['dimeValue']"
            multiple
            clearable
            filterable
            style="width: 100%"
            :placeholder="'请选择' + item.dimeName"
            @change="change(item, $event, index)">
            <el-option 
              v-for="(drop, id) in JSON.parse(item.options)" 
              :key="id" 
              :label="drop.optionLabel" 
              :value="drop.optionValue"
            ></el-option>
          </el-select>
        </template>
        <!-- 8 附件 -->
        <template v-if="item.editType == '8'">
          <br/>
          <Upload v-model="item['dimeValue']" :isShowTip="false" />
        </template>
        <!-- 9 日期时间 -->
        <el-date-picker
          v-if="item.editType == '9'"
          v-model="item['dimeValue']"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="width: 100%">
        </el-date-picker>
        <!-- 10 金额 -->
        <el-input
          v-if="item.editType == '10'"
          v-model="item['dimeValue']"
          class="right-input"
          @input="item['dimeValue']=item['dimeValue'].replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
          @blur="comdify(item, item['dimeValue'], index)"
          @focus="delComdify(item, item['dimeValue'], index)"
          clearable>
        </el-input>
        <!-- 11 百分比 -->
        <!-- <el-input
          v-if="item.editType == '11'"
          v-model="item['dimeValue']"
          :placeholder="'请输入' + item.dimeName + '(只填数字)'"
          class="right-input"
          @input="item['dimeValue'] = item['dimeValue'].replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')">
          <template slot="append">%</template>
        </el-input> -->
        <el-input
          v-if="item.editType == '11'"
          v-model="item['dimeValue']"
          class="right-input">
          <template slot="append">%</template>
        </el-input>
        <!-- 12 选择资源 -->
        <el-select 
          v-if="item.editType == '12'" 
          style="width: 97%"
          v-model="item['dimeValue']"
          clearable
          filterable
          multiple
          :placeholder="'请选择' + item.dimeName"
        >
          <el-option 
            v-for="(drop, id) in item.options" 
            :key="id" 
            :label="drop.resourceName" 
            :value="drop.ofrResourceId + ''"
          ></el-option>
        </el-select>
        <!-- 13 选择内容 -->
        <el-select 
          v-if="item.editType == '13'" 
          style="width: 97%"
          v-model="item['dimeValue']"
          clearable
          filterable
          multiple
          :placeholder="'请选择' + item.dimeName"
        >
          <el-option-group
            v-for="(group, id1) in item.options"
            :key="id1"
            :label="group.resourceName">
            <el-option
              v-for="(drop, id) in group.ofrThingList"
              :key="id"
              :label="drop.name"
              :value="drop.ofrThingId + ''">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
    </template>
  </div>
</template>

<script>
import url from '@/config/url'
import Upload from '@/components/upload/index.vue'
  export default {
    components: {
      Upload,
    },
    props: {
      formList: {
        type: Array,
      },
      gutter: Number
    },
    data() {
      return {
        fileList: [
        ],
        files: '',
        // value0: []
        checkList: []
      }
    },
    created() {
      // console.log(111);
      // console.log(this.formList);
      // this.getList()
    },
    mounted() {
      // console.log(3);
    },
    updated () {
      // console.log(22222222);
    },
    watch: {
    },
    methods: {
      getList() {
        if(this.formList.length) {
          this.formList.forEach(item => {
            if(item.editType == '12') {
              this.$http.get(url.getResTypeAndList, {params: {
                cbRestypeId: item.resourceType
              }}).then(res => {
                item.options = res.data.data.list
              })
            }
          })
        }
       
      },
      change(item, value, index) {
        this.$set(this.formList, index, item)
      },
      change1(item, value, index) {
        let arr = [...this.formList[index].dimeValue]
        if (arr.indexOf(value) > -1) {
          arr.splice(arr.indexOf(value), 1)
        } else {
          arr.push(value)
        }
        item.dimeValue = arr
        this.$set(this.formList, index, item)
      },
      // 添加千分位
      comdify(item, n, index) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        item.dimeValue = `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
        this.$set(this.formList, index, item)
      },
      // 去掉千分位
      delComdify(item, n, index) {
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        item.dimeValue = numS.replace(/,/gi, '');
        this.$set(this.formList, index, item)
      },
      // 添加千分位
      comdify1(item, n, index) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        item.dimeValue = `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
        this.$set(this.formList, index, item)
      },
      // 去掉千分位
      delComdify1(item, n, index) {
        if(!n) return '';
        let numS = n;
        numS = numS.toString();
        item.dimeValue = numS.replace(/,/gi, '');
        this.$set(this.formList, index, item)
      },
    },
  }
</script>

<style lang="scss" scoped>
.right-input {
  ::v-deep .el-input__inner {
    text-align: right;
  }
}
</style>