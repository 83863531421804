<template>
  <div>
    <el-table
      :data="fileList"
      style="width: 100%;overflow: auto"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      ref="table"
      v-show="!disabled"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="附件类型">
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="上传要求及说明">
      </el-table-column>
      <el-table-column
        prop="type"
        label="附件上传">
        <template slot-scope="scope">
          <Upload v-model="scope.row.fileList" :isShowTip="false" />
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="fileList"
      style="width: 100%;overflow: auto"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      ref="table"
      v-show="disabled"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="附件类型">
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="上传要求及说明">
      </el-table-column>
      <el-table-column
        prop="type"
        label="文件上传">
        <template slot-scope="scope">
          <!-- 文件列表 -->
          <fileList :fileList="scope.row.fileList" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Upload from '@/components/upload/index.vue'
  export default {
    props: {
      fileList: {
        type: Array,
        default: []
      },
      disabled: Boolean
    },
    components: {
      Upload,
    },
    data() {
      return {
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>

</style>