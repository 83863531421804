<template>
  <div>
    <div class="explain">
      <div class="item">
        <div class="icon">
          <i class="iconfont icon-chanpin"></i>
        </div>
        <div class="text">产品</div>
      </div>
      <div class="item">
        <div class="icon">
          <i class="iconfont icon-wendang"></i>
        </div>
        <div class="text">文档</div>
      </div>
      <div class="item">
        <div class="icon">
          <i class="iconfont icon-ziyuan-xianxing"></i>
        </div>
        <div class="text">凭证</div>
      </div>
    </div>
    <div class="explain">
      <div class="item">
        <div class="square" style="background:#d9ecff"></div>
        <div class="text">基础</div>
      </div>
      <div class="item">
        <div class="square" style="background:#e1f3d8"></div>
        <div class="text">核算</div>
      </div>
      <div class="item">
        <div class="square" style="background:#faecd8"></div>
        <div class="text">合约</div>
      </div>
      <div class="item">
        <div class="square" style="background:#fde2e2"></div>
        <div class="text">计划</div>
      </div>
    </div>
    <div class="main1">
      <div v-for="(item, index) in allData.ofoFlowDealList" :key="index" style="width: 21%">
        <!-- <div class="iconfont icon-arrow" v-if="index != 0"></div> -->
        <div class="deal">{{item.name}}</div>
        <div style="margin-top: 16px">
          <div v-for="(val, id) in item.ofoFlowWorkList" :key="id" class="work" @click="clickOfoFlowWork(item, index, val)" :style="{'background': val.accttype == '1' ? '#e1f3d8' : (val.accttype == '2' ? '#faecd8' : (val.accttype == '3' ? '#fde2e2' : '#d9ecff'))}">
            <div>{{val.name}}</div>
            <div style="display:flex;justify-content:flex-end">
              <template v-if="val.kindtype && val.workkindmanagetype != '0'"><i class="iconfont icon-chanpin"></i></template>
              <template v-if="val.kinddocutype"><i class="iconfont icon-wendang"></i></template>
              <template v-if="val.ojdttype == '0' || val.ojdttype == '01' || val.ojdttype == '02'"><i class="iconfont icon-ziyuan-xianxing"></i></template>
              <!-- <template v-if="val.kinddocutype"><i class="iconfont icon-fujian"></i></template> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      allData: Object,
    },
    methods: {
      clickOfoFlowWork(ofoDeal, dealIndex, ofoFlowWork) {
        // console.log(item);
        let data = {
          ofoDeal,
          dealIndex,
          ofoFlowWork
        }
        this.$emit('clickOfoFlowWork', data)
      }
    },
  }
</script>

<style lang="scss" scoped>
.explain {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: end;
  .item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .square {
      width: 25px;
      height: 15px;
      margin-right: 3px;
    }
    .icon {
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }
    .text {
      color: #999;
    }
  }
  
  
}
.main1 {
  display: flex;
  /* justify-content: space-between; */
  .deal {
    /* width: 25%; */
    color: #fff;
    background-color: #0C75E5;
    padding: 10px 32px 10px 32px;
    height: 20px;
    font-weight: 400;
    line-height: 20px;
    box-sizing: content-box;
    position: relative;
    margin-right: 20px;
    text-align: center;
  }
  .deal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 20px solid rgba($color: #000000, $alpha: 0);
    border-left: 20px solid #fff;
  }
  .deal::after {
    content: '';
    position: absolute;
    top: 0;
    right: -40px;
    border: 20px solid rgba($color: #000000, $alpha: 0);
    border-left: 20px solid #0C75E5;
  }
  .work {
    width: 80%;
    background: #d9ecff;
    /* border: 1px solid #0C75E5 !important; */
    margin: 16px;
    padding: 6px 10px;
    /* text-align: center; */
    margin-right: 0;
    box-shadow: 5px 3px 10px 0px rgb(208, 208, 208);
    cursor: pointer;
    .iconfont {
      margin-right: 5px;
    }
  }
  .work:hover {
    /* background-color: #0C75E5;
    color: #fff; */
    transform: scale(1.1);
  }
}
</style>