<template>
  <div>
    <div class="main">
      <div style="text-align:center;margin-bottom:16px;font-weight: 900;font-size: 20px">{{name}}</div>
      <div style="text-align:right;margin-bottom:16px;font-size: 14px;margin-right:16px;display:flex;justify-content:flex-end">
        {{date | filter_handleDate}}
        <!-- <el-button type="text" icon="el-icon-download" style="padding:0" @click="handleExport"></el-button> -->
        <download-excel
          class="export-excel-wrapper"
          :data="list"
          :fields="json_fields"
          :header="name"
          :name="`${name}.xls`"
        >
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <el-button type="text" icon="el-icon-download" style="padding:0" >导出</el-button>
        </download-excel>
      </div>
      <template v-if="repttype == '1'">
        <el-row>
          <el-col :span="12">
            <el-table
              :data="leftTable"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
              v-loading="loading"
              border
            >
              <el-table-column prop="name" label="名称" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.spaceNum" :key="index" v-if="scope.row.spaceNum">&nbsp;</span>
                  {{scope.row.name}}
                </template>
              </el-table-column>
              <el-table-column prop="lineid" label="行号"  width="50" align="center"></el-table-column>
              <el-table-column prop="scope" label="计划" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.jihua | comdify('no')}}
                </template>
              </el-table-column>
              <el-table-column prop="heyue" label="合约" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.heyue | comdify('no')}}
                </template>
              </el-table-column>
              <el-table-column prop="hesuan" label="核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.hesuan | comdify('no')}}
                </template>
              </el-table-column>
              
              <el-table-column prop="scope" label="本年核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.hesuanYear | comdify('no')}}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="12">
            <el-table
              :data="rightTable"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
              v-loading="loading"
              border
            >
              <el-table-column prop="name" label="名称" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.spaceNum" :key="index" v-if="scope.row.spaceNum">&nbsp;</span>
                  {{scope.row.name}}
                </template>
              </el-table-column>
              <el-table-column prop="lineid" label="行号"  width="50" align="center"></el-table-column>
              <el-table-column prop="scope" label="计划" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.name">{{scope.row.jihua | comdify('no')}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="heyue" label="合约" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.name">{{scope.row.heyue | comdify('no')}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="hesuan" label="核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.name">{{scope.row.hesuan | comdify('no')}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="scope" label="本年核算" align="right" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.name">{{scope.row.hesuanYear | comdify('no')}}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
      <div id="el-table" v-else>
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          :height="'69vh'"
        >
          <el-table-column prop="name" label="名称">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.spaceNum" :key="index" v-if="scope.row.spaceNum">&nbsp;</span>
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column prop="lineid" label="行号"  width="50" align="center"></el-table-column>
          <el-table-column prop="scope" label="计划" width="200" align="right" v-if="repttype != '5'">
            <template slot-scope="scope">
              {{scope.row.jihua | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="heyue" label="合约" width="200" align="right" v-if="repttype != '5'">
            <template slot-scope="scope">
              {{scope.row.heyue | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="hesuan" label="核算" width="200" align="right">
            <template slot-scope="scope">
              {{scope.row.hesuan | comdify('no')}}
            </template>
          </el-table-column>
          
          <el-table-column prop="hesuanYoY" label="同比" width="200" align="right" v-if="repttype == '5'">
            <template slot-scope="scope">
              {{scope.row.hesuanYoY | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="hesuanMoM" label="环比" width="200" align="right" v-if="repttype == '5'">
            <template slot-scope="scope">
              {{scope.row.hesuanMoM | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="scope" label="本年核算" width="180" align="right" v-if="repttype != '5'">
            <template slot-scope="scope">
              {{scope.row.hesuanYear | comdify('no')}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    
  </div>
</template>

<script>
import url from '@/config/url'
  export default {
    data() {
      return {
        tableData: [],
        loading: true,
        cfReptResultId: this.$route.params.cfReptResultId,
        name: '',
        date: '',
        repttype: '',
        leftTable: [],
        rightTable: [],
        json_fields: {},
        json_fields1: {
          "名称":'name',
          "计划":'jihua',
          "合约":'heyue',
          "核算":'hesuan',
          "本年核算":'hesuanYear',
        },
        json_fields2: {
          "名称":'name1',
          "计划":'jihua1',
          "合约":'heyue1',
          "核算":'hesuan1',
          "本年核算":'hesuanYear1',
          "名称 ":'name2',
          "计划 ":'jihua2',
          "合约 ":'heyue2',
          "核算 ":'hesuan2',
          "本年核算 ":'hesuanYear2',
        },
        json_fields5: {
          "核算":'hesuan',
          "本年核算":'hesuanYear',
          "同比":'hesuanYoY',
          "环比":'hesuanMoM',
        },
        list: []
      }
    },
    created () {
      this.getData();
    },
    methods: {
      getData() {
        this.$http.get(url.getCffreptCountDetail, {params: {
          id: this.cfReptResultId
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.name = res.data.data.countName
            this.date = res.data.data.countDate
            this.repttype = res.data.data.repttype
            if(this.repttype == '1') {
              this.leftTable = JSON.parse(res.data.data.countResult).lList
              this.rightTable = JSON.parse(res.data.data.countResult).rList
              this.json_fields = this.json_fields2
              for(let i = 0; i < this.leftTable.length; i++) {
                let obj = {}
                obj.name1 = this.leftTable[i].name
                if(this.leftTable[i].spaceNum) {
                  for(let j = 0; j < this.leftTable[i].spaceNum; j++) {
                    obj.name1 = ' ' + obj.name1
                  }
                }
                obj.jihua1 = this.leftTable[i].jihua ? this.leftTable[i].jihua : ''
                obj.heyue1 = this.leftTable[i].heyue ? this.leftTable[i].heyue : ''
                obj.hesuan1 = this.leftTable[i].hesuan ? this.leftTable[i].hesuan : ''
                obj.hesuanYear1 = this.leftTable[i].hesuanYear ? this.leftTable[i].hesuanYear : ''
                obj.name2 = this.rightTable[i].name
                if(this.rightTable[i].spaceNum) {
                  for(let j = 0; j < this.leftTable[i].spaceNum; j++) {
                    obj.name2 = ' ' + obj.name2
                  }
                }
                obj.jihua2 = this.rightTable[i].jihua ? this.rightTable[i].jihua : ''
                obj.heyue2 = this.rightTable[i].heyue ? this.rightTable[i].heyue : ''
                obj.hesuan2 = this.rightTable[i].hesuan ? this.rightTable[i].hesuan : ''
                obj.hesuanYear2 = this.rightTable[i].hesuanYear ? this.rightTable[i].hesuanYear : ''
                this.list.push(obj)
              }
              // this.leftTable.forEach(item => {
              //   obj.name1 = 
              // })
            }else {
              this.tableData = JSON.parse(res.data.data.countResult)
              this.list = JSON.parse(res.data.data.countResult)
              if(this.repttype == '5') {
                this.json_fields = this.json_fields5
              }else {
                this.json_fields = this.json_fields1
              }
            }
          }
        })
      },
      // 导出按钮
      handleExport() {
        this.$confirm(`此操作将导出${this.name}模板, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // let tableData = row.dime.split(', ')
          // console.log(this.tableData);
          // let workSheet = XLSX.utils.aoa_to_sheet(this.tableData);
          // let bookNew = XLSX.utils.book_new();
          // XLSX.utils.book_append_sheet(bookNew, workSheet, this.name) // 工作簿名称
          // let name = this.name + '.xlsx'
          // XLSX.writeFile(bookNew, name) // 保存的文件名
          const wb = XLSX.utils.table_to_book(
                  document.querySelector("#el-table")
          );
          const wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            FileSaver.saveAs(
                    new Blob([wbout], {type: "application/octet-stream"}),
                    "参赛人员数据.xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
          }
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消导出'
          // });          
        });
      }
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-table__body tr:hover>td.el-table__cell {
  background-color: #fff;
}
::v-deep .el-table__cell{
 height: 48px;
}
.main {
  background: #FFFFFF;
  box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
  border-radius: 11px;
  padding: 16px;
  /* position: relative; */
}
</style>