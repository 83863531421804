<template>
  <div class="import">
    <div class="main">
      <div class="title">导入项目</div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <el-table-column prop="showName" label="流程名称" width="180">
        </el-table-column>
        <el-table-column prop="dime" label="维度" show-overflow-tooltip> </el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              size="small"
              icon="el-icon-download"
              type="text"
              @click="exportBtn(scope.row)"
              >导出</el-button
            >
            
            <el-button size="mini" type="text" icon="el-icon-upload2" @click="importBtn(scope.row)">导入</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      width="400px">
      <el-upload
        class="upload-demo"
        action="#"
        :auto-upload="false"
        :limit="1"
        :file-list="fileList"
        :on-change="handleChange"
        drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress>
      <el-checkbox v-model="isCheck" true-label="0" false-label="1">校验重复</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpload" :disabled="isDisabled" :loading="isLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
// import XLSX from 'xlsx'
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      fileList: [],
      dialogVisible: false,
      title: '',
      cfFlowItemId: '',
      loading: true,
      isDisabled: false,
      isLoading: false,
      isCheck: '0',
      loadProgress: 0, // 动态显示进度条
      progressFlag: false,
      proTimer: null
    };
  },
  created() {
    this.getList();
  },
  destroyed () {
    this.proTimer ? clearInterval(this.proTimer) : ''
  },
  methods: {
    getList() {
      this.$http
        .get(url.getImportCflowItemList).then((res) => {
          if(res.data.code == 200) {
            this.loading = false
            this.tableData = res.data.data;
            // this.total = res.data.data.total;
          }
          
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.loading = true
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.loading = true
      this.getList();
    },
    // 导出按钮
    exportBtn(row) {
      this.$confirm(`此操作将导出${row.showName}模板, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let tableData = row.dime.split(', ')
        let workSheet = XLSX.utils.aoa_to_sheet([tableData]);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, row.showName) // 工作簿名称
        let name = row.showName + '.xlsx'
        XLSX.writeFile(bookNew, name) // 保存的文件名
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消导出'
        });          
      });
      // console.log(row.dime.split(','));
      

    },
    // 导入按钮
    importBtn(row) {
      this.dialogVisible = true
      // console.log(row);
      this.title = row.name
      this.cfFlowItemId = row.cfFlowItemId
      this.fileList = []
      this.isCheck = '0'
      this.loadProgress = 0
      this.progressFlag = false
    },
    confirmUpload() {
      this.isDisabled = true
      this.isLoading = true
      this.progressFlag = true
      const formData = new FormData()
      formData.append('file', this.fileList[0].raw)
      formData.append('cfFlowItemId', this.cfFlowItemId)
      formData.append('isCheck', this.isCheck)
      let time = new Date().getTime() + Math.random()
      formData.append('key', time)
      this.getProgress(time)
      this.$http.post(url.importCflowItemList, formData).then(res => {
        this.isDisabled = false
        this.isLoading = false
        if(res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.dialogVisible = false
        }
        
      })
    },
    getProgress(key) {
      this.proTimer = setInterval(() => {
        this.$http.get(url.progress + key).then(res => {
          // console.log(res);
          this.loadProgress = res.data.data
        })
      }, 2000);
      
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
  },
};
</script>

<style lang="scss" scoped>
.import {
  /* padding: 16px; */
  .main {
    background: #ffffff;
    box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    .el-table {
      margin-bottom: 39px;
    }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>