<template>
  <div class="contain">
    <el-tabs v-model="activeName" @tab-click="inquire">
      <el-date-picker
        v-model="date"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        format="yyyy-MM"
        value-format="yyyy-MM"
        size="mini"
        style="margin-bottom: 16px;margin-right:16px"
        :clearable="false"
        @change="inquire">
      </el-date-picker>
      <el-select v-model="accountType" placeholder="请选择账套" filterable @change="inquire" size="mini" clearable style="margin-bottom: 16px;margin-right:16px">
        <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
      </el-select>
      <el-input v-model="assetsName" placeholder="请输入资产名称" size="mini" clearable @keyup.enter.native="inquire" @clear="inquire" v-if="activeName == 'assetsDetail'" style="width:200px;margin-right:16px"></el-input>
      <el-input v-model="assetsNumber" placeholder="请输入资产编码" size="mini" clearable @keyup.enter.native="inquire" @clear="inquire" v-if="activeName == 'assetsDetail'" style="width:200px"></el-input>
      
      <el-tab-pane label="折旧汇总" name="depreciationTotal">
        <el-table
          :data="depreciationTotalList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'depreciationTotal'"
        >
          <el-table-column label="行次" align="center" width="50">
            <template slot-scope="scope">
              {{depreciationTotalList.length == (scope.$index + 1) ? '' : scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column label="资产类别" prop="assetsType" align="center"></el-table-column>
          <el-table-column prop="originalAmount" label="原值" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.originalAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="initialDepreciationAmount" label="期初累计折旧" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.initialDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="periodDepreciationAmount" label="本期折旧额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.periodDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="yearDepreciationAmount" label="本年累计折旧" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.yearDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="endDepreciationAmount" label="期末累计折旧" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.endDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="endAmount" label="期末净值" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.endAmount | comdify('no')}}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="折旧明细" name="depreciationDetail">
        <el-table
          :data="depreciationDetailList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'depreciationDetail'"
        >
          <el-table-column label="行次" align="center" width="50">
            <template slot-scope="scope">
              {{depreciationDetailList.length == (scope.$index + 1) ? '' : scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column label="资产编码" prop="assetsNumber" align="center" width="160">
            <template slot-scope="scope">
              <a class="link" @click="goAssetsDetail(scope.row)">{{scope.row.assetsNumber}}</a>
            </template>
          </el-table-column>
          <el-table-column label="资产名称" prop="assetsName" header-align="center" min-width="150"></el-table-column>
          <el-table-column label="开始使用日期" prop="startDate" align="center" width="100"></el-table-column>
          <el-table-column prop="usedMonth" label="使用年限（月）" align="right" header-align="center"></el-table-column>
          <el-table-column prop="originalAmount" label="原值" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.originalAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="initialDepreciationAmount" label="期初累计折旧" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.initialDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="monthDepreciationAmount" label="月折旧额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.monthDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="periodDepreciationAmount" label="本期折旧额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.periodDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="depreciationChangeAmount" label="本期折旧变动" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.depreciationChangeAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="endDepreciationAmount" label="期末累计折旧" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.endDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="yearDepreciationAmount" label="本年累计折旧" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.yearDepreciationAmount | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="endAmount" label="资产净值" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.endAmount | comdify('no')}}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="资产明细" name="assetsDetail">
        <el-table
          :data="assetsDetailList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'assetsDetail'"
        >
          <el-table-column label="期间" prop="period" header-align="center" width="100"></el-table-column>
          <el-table-column prop="assetsNumber" label="资产编码" align="center" width="160"></el-table-column>
          <el-table-column prop="assetsName" label="资产名称" header-align="center" min-width="150"></el-table-column>
          <el-table-column prop="accountCode" label="凭证号" align="center" width="180">
            <template slot-scope="scope">
              <a class="link" @click="handleDetail(scope.row)">{{scope.row.accountCode}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="abstractTxt" label="摘要" header-align="center" min-width="150"></el-table-column>
          <el-table-column label="原值" header-align="center">
            <el-table-column prop="originalDebit" label="借方" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.originalDebit | comdify('no')}}
              </template>
            </el-table-column>
            <el-table-column prop="originalCredit" label="贷方" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.originalCredit | comdify('no')}}
              </template>
            </el-table-column>
            <el-table-column prop="originalAmount" label="余额" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.originalAmount | comdify('no')}}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="累计折旧" header-align="center">
            <el-table-column prop="depreciationDebit" label="借方" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.depreciationDebit | comdify('no')}}
              </template>
            </el-table-column>
            <el-table-column prop="depreciationCredit" label="贷方" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.depreciationCredit | comdify('no')}}
              </template>
            </el-table-column>
            <el-table-column prop="depreciationAmount" label="余额" align="right" width="150" header-align="center">
              <template slot-scope="scope">
                {{scope.row.depreciationAmount | comdify('no')}}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="endAmount" label="净值" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.endAmount | comdify('no')}}
            </template>
          </el-table-column>
          
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
        // date: ['2013-01', '2023-12'],
        accountType: '',
        assetsName: '',
        assetsNumber: '',
        assetsDetailList: [],
        depreciationTotalList: [],
        depreciationDetailList: [],
        loading: true,
        activeName: 'depreciationTotal',
        tableHeight: window.innerHeight - 245
      }
    },
    created () {
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      this.getDepreciationTotalList()
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        // this.inquire()
        if(this.activeName == 'assetsDetail') {
          this.getAssetsDetailList()
        }else if(this.activeName == 'depreciationTotal') {
          this.getDepreciationTotalList()
        }else {
          this.getDepreciationDetailList()
        }
      })
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 245;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 245;
        })()
      }
    },
    methods: {
      getAssetsDetailList(){
        this.loading = true
        this.$http.get(url.assetsDetail, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : '',
          assetsName: this.assetsName,
          assetsNumber: this.assetsNumber
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.assetsDetailList = res.data.data.list
          }
        })
      },
      getDepreciationTotalList(){
        this.loading = true
        this.$http.get(url.depreciationTotal, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.depreciationTotalList = res.data.data.list
            this.depreciationTotalList.push(res.data.data.total)
          }
        })
      },
      getDepreciationDetailList(){
        this.loading = true
        this.$http.get(url.depreciationDetail, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.depreciationDetailList = res.data.data.list
            this.depreciationDetailList.push(res.data.data.total)
          }
        })
      },
      inquire() {
        if(this.activeName == 'assetsDetail') {
          this.getAssetsDetailList()
        }else if(this.activeName == 'depreciationTotal') {
          this.getDepreciationTotalList()
        }else {
          this.getDepreciationDetailList()
        }
      },
      handleDetail(row) {
        this.$router.push({
          path: '/accounting/vDetail/' + row.offOjdtId, query: {
            account: true
        }})
        let obj = {
          path: '/accounting/vDetail/' + row.offOjdtId,
          name: 'voucherDetail',
          label: '凭证详情',
          offOjdtId: row.offOjdtId,
          children: []
        }
        this.$store.commit("selectMenu", obj);
      },
      goAssetsDetail(row) {
        this.assetsNumber = row.assetsNumber
        this.activeName = 'assetsDetail'
        this.getAssetsDetailList()
      }
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  /* height: 100%;
  box-sizing: border-box; */
}
</style>