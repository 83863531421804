<template>
  <div class="report">
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/> -->
    <div class="left" v-if="$route.path.split('/')[1] == 'report' && isShowLeft">
      <div v-for="(item, index) in secondMenu" :key="index" :class="['item', isActive == item.menuId ? 'isDeal' : '']" @click="changeDeal(item, true)" v-if="item.path != '/report/org'">
        <i :class="item.icon" style="margin-right:5px"></i>
        {{item.label}}
      </div>
      <el-divider content-position="left"></el-divider>
      
      <div v-for="(item, index) in orgReptList" :key="index + secondMenu.length" :class="['item', isActive == `${secondMenu.find(val => val.path == '/report/org').menuId}-${item.cfFlowReptId}` ? 'isDeal' : '']" @click="changeDeal(item, false)">
        <i class="iconfont icon-baobiaozhongxin" style="margin-right:5px"></i>
        {{item.showName}}
      </div>
    </div>
    <div class="show-mask" @click="isShowLeft = !isShowLeft">
      <i :class="isShowLeft ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"></i>
    </div>
    <div class="right">
      <!-- <template v-if="secondMenu.map(item =>item.menuId).includes(isActive)"> -->
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive">
          </router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"/>
      <!-- </template>
      <template v-else>
        {{isActive}}
      </template> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import url from '@/config/url'
  export default {
    computed: {
      ...mapState({
        currentMenu: (state) => state.tags.currentMenu,
        secondMenu: (state) => state.secondAside.secondMenu,
        tagsList: (state) => state.tags.tagsList,
      }),
    },
    data() {
      return {
        isShowLeft: true,
        isActive: null,
        orgReptList: []
      }
    },
    async created () {
      await this.getCfOrgReptList();
      // console.log(this.orgReptList);
      // console.log(this.currentMenu);
      if(this.currentMenu.path == '/report/org') {
        if(this.currentMenu.cfFlowReptId) {
          this.isActive = this.currentMenu.menuId + '-' + this.currentMenu.cfFlowReptId
          
          this.changeDeal(this.orgReptList.find(item => item.cfFlowReptId == this.currentMenu.cfFlowReptId), false)
        }else {
          // console.log(this.orgReptList[0].cfFlowReptId);
          this.isActive = this.currentMenu.menuId + '-' + this.orgReptList[0].cfFlowReptId
          this.changeDeal(this.orgReptList[0], false)
        }
        // this.isActive = this.currentMenu.menuId + '-' + this.currentMenu.cfFlowReptId ? this.currentMenu.cfFlowReptId : (this.orgReptList.length ? this.orgReptList[0].cfFlowReptId : '')
      }else {
        this.isActive = this.currentMenu.menuId
      }
    },
    methods: {
      async getCfOrgReptList() {
        await this.$http.get(url.getCfOrgReptList).then(res => {
          if(res.data.code == 200) {
            this.orgReptList = res.data.data
          }
        })
      },
      changeDeal(item, isRouter) {
        if(isRouter) {
          this.isActive = item.menuId
          this.$router.push({
            path: item.path
          })
          this.tagsList.forEach((val, index) => {
            if(item.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path.split('/')[3])) {
              // console.log(val);
              for(let key in val) {
                if(key != 'children' && key != 'label' && key != 'icon') {
                  val[key] = item[key]
                }
              }
            }
          })
          this.$store.commit('changeMenu', item)
        }else {
          // console.log(this.secondMenu.find(item => item.path == '/report/org1'))
          let obj = this.secondMenu.find(item => item.path == '/report/org')
          if(obj) {
            this.isActive = obj.menuId + '-' + item.cfFlowReptId
            this.$router.push({
              path: obj.path,
              query: {
                cfFlowReptId: item.cfFlowReptId
              }
            })
            obj.cfFlowReptId = item.cfFlowReptId
            this.tagsList.forEach((val, index) => {
              if(obj.path.indexOf('/' + val.path.split('/')[1]) == 0 && !(/\d/).test(val.path.split('/')[3])) {
                // console.log(val);
                for(let key in val) {
                  if(key != 'children' && key != 'label' && key != 'icon') {
                    val[key] = obj[key]
                  }
                }
                val.cfFlowReptId = item.cfFlowReptId
              }
              // 
            })
            this.$store.commit('changeMenu', obj)
          }
          
        }
        
      }
    },
    watch: {
      currentMenu(newValue, oldValue) {
        // console.log(13456);
        // console.log(JSON.parse(JSON.stringify(newValue)));
        if(newValue.path == '/report/org') {
          this.isActive = newValue.menuId + '-' + newValue.cfFlowReptId
           this.$router.push({
            path: newValue.path,
            query: {
              cfFlowReptId: newValue.cfFlowReptId
            }
          })
        }else if(newValue.path.includes('/report')) {
          this.isActive = newValue.menuId
          this.$router.push({
            path: newValue.path
          })
        }
        
      }
    },
  }
</script>

<style scoped lang="scss">
.report {
  display: flex;
  height: 100%;
  overflow: hidden;
  align-items: center;
  .left {
    width: 160px;
    background-color: #fff;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,1);
    ::v-deep .el-divider--horizontal {
      margin: 8px;
      width: auto;
    }
    ::v-deep .el-divider__text {
      padding: 0 8px;
      font-size: 13px;
    }
    ::v-deep .el-divider__text.is-left {
      left: 10px;
    }
    .item {
      /* padding: 0 15px 0 6px; */
      font-size: 13px;
      font-family: STHeitiSC-Light, STHeitiSC;
      font-weight: 300;
      color: #282828;
      margin: 8px;
      padding: 7px;
      cursor: pointer;
    }
    .item:hover {
      background-color: #E5F3FF;
    }
    .isDeal {
      color: #177BE6;
      background-color: #E5F3FF;
    }
  }
  .right {
    padding: 16px;
    /* margin: 16px; */
    flex: 1;
    overflow: auto;
    padding-left: 0;
    height: calc(100% - 32px);
  }
}
</style>