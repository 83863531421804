<template>
  <div>
    <!-- <el-button type="text" icon="el-icon-printer" style="float:right" v-print="print"></el-button> -->
    <el-table
      :data="approvalList"
      style="width: 100%; margin-bottom: 16px"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}">
      <el-table-column
        label="序号">
        <template slot-scope="scope">
          {{scope.row.osappId}}-{{scope.row.order}}
        </template>
      </el-table-column>
      <el-table-column
        prop="ofrthingName"
        label="审批人">
      </el-table-column>
      <el-table-column
        prop="appstatus"
        label="审批状态"
        align="center">
        <template slot-scope="scope">
          <DictTag :options="dropDown.appStatus" :value="scope.row.appstatus" :isStyle="true" v-if="scope.row.steptype == '1' || scope.row.steptype == '9' "/>
          <span v-if="scope.row.steptype == '2'" style="color: #909399;background-color: #f4f4f5;padding: 0 10px; font-size: 12px;height: 32px;display:inline-block;line-height:32px;border-radius:4px; border: 1px solid #e9e9eb">抄送</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        align="center"
        width="110">
        <template slot-scope="scope">
          {{scope.row.createTime | filter_handleDateTime}}
        </template>
      </el-table-column>
      <el-table-column
        label="审批时间"
        align="center"
        width="110">
        <template slot-scope="scope">
          {{scope.row.updateTime | filter_handleDateTime}}
        </template>
      </el-table-column>
      <el-table-column
        label="签名"
        align="center">
        <template slot-scope="scope">
          <img :src="$base_url + scope.row.signUrl" alt="" style="width:30px;transform: rotate(-90deg)" />
        </template>
      </el-table-column>
      <el-table-column
        label="审批途径"
        align="center">
        <template slot-scope="scope">
          {{scope.row.way == '0' ? '电脑端' : (scope.row.way == '1' ? '小程序' : '')}}
        </template>
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="审批意见"
        :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div v-html="scope.row.remarks"></div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="70"
        fixed="right">
        <template slot-scope="scope" v-if="(scope.row.isApp == '1' || scope.row.isApp == '3') && scope.row.appstatus == '3'">
          <el-form size="mini">
            <el-button type="primary" size="mini" plain @click="handleAudit(scope.row)">审批</el-button>
          </el-form>
        </template>
      </el-table-column>
    </el-table>
    <div 
      v-show="false"
      style="overflow:hidden; height:100%">
      <el-table
        id="print"
        border
        :data="approvalList"
        style="width: 100%"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}">
        <el-table-column
          label="序号"
          width="80">
          <template slot-scope="scope">
            {{scope.row.osappId}}-{{scope.row.order}}
          </template>
        </el-table-column>
        <el-table-column
          prop="ofrthingName"
          label="审批人"
          align="center"
          width="100">
        </el-table-column>
        <el-table-column
          prop="appstatus"
          label="审批状态"
          align="center"
          width="80">
          <template slot-scope="scope">
            <DictTag :options="dropDown.appStatus" :value="scope.row.appstatus" v-if="scope.row.steptype == '1'"/>
            <span v-if="scope.row.steptype == '2'">抄送</span>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          width="100">
          <template slot-scope="scope">
            {{scope.row.createTime | filter_handleDateTime}}
          </template>
        </el-table-column>
        <el-table-column
          label="审批时间"
          align="center"
          width="100">
          <template slot-scope="scope">
            {{scope.row.updateTime | filter_handleDateTime}}
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="审批意见">
          <template slot-scope="scope">
            <div id="editor-content-view" class="editor-content-view" v-html="scope.row.remarks"></div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <el-dialog
      title="审批"
      :visible.sync="dialogFromVisible"
      width="1000"
      append-to-body
      top="59px" >
      <el-form :model="form" label-width="70px">
        <el-form-item label="审批结果">
          <el-select v-model="form.appstatus" style="width: 100%">
            <el-option label="同意" value="1"></el-option>
            <el-option label="不同意" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批人" v-if="isShowSprs">
          <el-select v-model="form.sprs" placeholder="请选择下一步审批人" style="width:100%" filterable multiple >
            <el-option-group
              v-for="group in chooseList"
              :key="group.ofrResourceId"
              :label="group.resourceName">
              <el-option
                v-for="item in group.ofrThingList"
                :key="item.ofrThingId"
                :label="item.name"
                :value="item.ofrThingId">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见">
          <!-- <el-input type="textarea" :rows="3" placeholder="审批意见" v-model="form.remarks"></el-input> -->
          <div style="border: 1px solid #ccc;">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden;"
              v-model="form.remarks"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-form>
        <el-button type="primary" @click="submitAudit" :disabled="false">提 交</el-button>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DictTag from '@/components/DictTag.vue'
import {mapState} from 'vuex'
import url from '@/config/url'
import qs from 'qs'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import Vue from 'vue'
// import { DomEditor } from '@wangeditor/editor'
// const toolbar = DomEditor.getToolbar(editor)

// const curToolbarConfig = toolbar.getConfig()
// console.log( curToolbarConfig.toolbarKeys ) // 当前菜单排序和分组
let v = new Vue();
const editorConfig = {                       // JS 语法
    MENU_CONF: {}

    // 其他属性...
}
// console.log(v.$base_url);
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF['uploadImage'] = {
  server: v.$base_url + '/api/utils/uploadFile',
  fieldName: 'file',
  headers: {
    token: window.sessionStorage.getItem('token')
  },
  customInsert(res, insertFn) {
    insertFn(v.$base_url + res.data)
  },
}
// const toolbar = Toolbar.getToolbar(Editor)

// const curToolbarConfig = toolbar.getConfig()
// console.log( curToolbarConfig.toolbarKeys )
const toolbarConfig = {                        // JS 语法
  /* 工具栏配置 */
  excludeKeys: [
    "group-video"
  ]

}
  export default {
    components: {
      DictTag,
      Editor,
      Toolbar
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown
      }),
    },
    props: {
      approvalList: Array,
    },
    data() {
      return {
        form: {
          appstatus: '1',
          remarks: '',
          osAppStepId: '',
          sprs: []
        },
        dialogFromVisible: false,
        print: {
          id: 'print',
          popTitle: '审批'
        },
        chooseList: [],
        isShowSprs: false,
        editor: null,
        html: '<p>hello</p>',
        toolbarConfig: toolbarConfig,
        editorConfig: editorConfig,
        mode: 'default', // or 'simple'
      }
    },
    created () {
      if(!this.dropDown.appStatus) {
        this.$http.get(url.getDataList, {params: {type: 'buss_app_status' }}).then(res => {
          this.$store.commit('setAppStatus', res.data.data)
        })
      }
      
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
      handleAudit(row) {
        this.isShowSprs = row.isApp == '3'
        this.dialogFromVisible = true
        this.form.appstatus = '1'
        this.form.remarks = ''
        this.form.sprs = []
        this.form.osAppStepId = row.osAppStepId
        this.chooseList = row.chooseList ? row.chooseList : []
      },
      submitAudit() {
        let obj = Object.assign({}, this.form)
        obj.sprs = this.form.sprs.toString()
        obj.way = '0'
        this.$http.post(url.submitOsApp, qs.stringify(obj)).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.dialogFromVisible = false
            this.$emit('refreshGetwork', true)
          }  
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    margin: 0 auto;
  max-height: calc(100% - 72px);
  // height: calc(100% - 72px);
}
::v-deep .el-dialog__body {
  /* padding: 10px 0 10px 20px; */
  /* margin-right: 20px; */
  max-height: calc(100vh - 246px);
  // height: calc(100vh - 206px);
  overflow: auto;
}
</style>