<template>
  <div id="analyse">

  </div>
</template>

<script>
import * as echarts from 'echarts';
  export default {
    methods: {
      initCharts2() {
        var chartDom = document.getElementById('analyse');
        var myChart = echarts.init(chartDom, null, {
          height: 254
        });
        var option;

        option = {
          color: ['#5B8FF9'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            // data: ['财务'],
            left: 20,
            // show: true
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value'
              
            }
          ],
          yAxis: [
            {
              type: 'category',
              data: ['内容五', '内容四', '内容三', '内容二', '内容一'],
              axisTick: {
                alignWithLabel: true,
                // 隐藏刻度
                show: false
              }
            }
          ],
          series: [
            {
              name: '财务',
              type: 'bar',
              barWidth: '12',
              data: [760, 202, 736, 846, 958],
              // 实现数字展示在柱状图
              label: {
                show: true,
                position: 'insideLeft',
                padding: [0,0,0,5],
                color: '#fff'
              }
            }
          ]
        };
        option && myChart.setOption(option);
        window.onresize = myChart.resize
      }
    },
    mounted() {
      this.initCharts2()
    }
  }
</script>

<style scoped>

</style>