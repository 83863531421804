<template>
  <div class="tab">
    <div class="title">2022年中小企业资产负债表</div>
    <div class="des">
      <div class="order">编号：xxxxxxxxxxx</div>
      <div class="type">类型：xxxxxxxxxxx</div>
      <div class="time">时间：xxxxxxxxxxx</div>
    </div>
    <el-button icon="el-icon-plus" class="add">报表内容</el-button>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        width="100">
      </el-table-column>

      <el-table-column
        prop="name"
        label="名称">
      </el-table-column>

      <el-table-column
        prop="level"
        label="层级">
      </el-table-column>

      <el-table-column
        prop="self"
        label="本级">
      </el-table-column>

      <el-table-column
        prop="parent"
        label="父级">
      </el-table-column>

      <el-table-column
        prop="lineNum"
        label="显示行号">
      </el-table-column>

      <el-table-column
        prop="compute"
        label="计算">
      </el-table-column>

      <el-table-column
        prop="cash"
        label="现金流">
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            class="del"
            @click="delData(scope.$index)"
            >删除
          </el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tableData: [
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          {
            name: 'xxxxxxxxxxx',
            level: 'xxxxxxxxxx',
            self: 'xxxxxxxxx',
            parent: 'xxx',
            lineNum: 'xxxxxxxx',
            compute: 'xxx',
            cash: 'xxx'
          },
          
        ]
      }
    },
    methods: {
      indexMethod(index) {
        return index < 9 ? ('0' + parseInt(index + 1)) : index + 1;
      },
      delData(index) {
        console.log(index);
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableData.splice(index,1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
    }
  }
</script>

<style scoped lang="scss">
.tab {
  max-height: 100%;
  padding: 16px;
  background-color: #fff;
  box-shadow: 2px 3px 8px 0px rgba(208,208,208,1);
  border-radius: 11px;
  overflow: auto;
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 15px;
    text-align: center;
    margin-bottom: 16px;
  }
  .des {
    display: flex;
    justify-content: space-between;
    height: 13px;
    font-size: 13px;
    font-family: STHeitiSC-Light, STHeitiSC;
    font-weight: 300;
    color: #282828;
    line-height: 13px;
    margin-bottom: 16px;
  }
  .add {
    background: #E5F3FF;
    border-radius: 3px;
    font-size: 13px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #0C75E5;
    line-height: 13px;
    padding: 6px 11px;
    border: 0;
    margin-bottom: 16px;
  }
  ::v-deep .el-table thead {
    color: #282828;
    background-color: #ddd;
    >tr {
      background-color: #ddd;
    }
  }
  .del {
    border: 0;
    color: #0C75E5;
    padding: 0;
  }
  ::v-deep .el-table__row--striped .el-button{
    background-color: #F9F9FB;
  }
}
</style>