<template>
  <div class="project">
    <el-form :inline="true" :model="siftForm" class="demo-form-inline" size="small" @submit.native.prevent v-if="isShowFormInline">
      <el-form-item>
        <span slot="label">
          <strong>名称</strong>
        </span>
        <el-input
          v-model="siftForm.name"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="inquire"
          @clear="inquire"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" style="background:#0C75E5" @click="inquire">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="header">
      <!-- <div class="title">项目列表</div> -->
      <div>
        <el-tooltip class="item" effect="dark" :content="isShowFormInline ? '隐藏搜索' : '显示搜索'" placement="top-end">
          <el-button class="hidden" icon="el-icon-search" @click="isShowFormInline = !isShowFormInline"></el-button>
        </el-tooltip>
        <el-button class="tab" size="mini" @click="change" :icon="tab.showCard? 'el-icon-tickets': 'el-icon-s-grid'"></el-button>
      </div>
      
    </div>
    <!-- 表格形式 -->
    <div class="main" v-show="tab.showTable">
      <el-table
        :data="showList"
        ref="multipleTable"
        style="width: 100%"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
        v-loading="loading"
      >
        <el-table-column
          prop="name"
          label="项目名称"
          min-width="200"
          show-overflow-tooltip
          >
          <template slot-scope="scope">
            <a class="link" @click="handleDetail(scope.row)">{{scope.row.name}}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="人员"
          align="center"
          width="100">
        </el-table-column>
        
        
        <el-table-column
          label="发布"
          prop="documentstatus"
          align="center"
          width="150"
          >
          <template slot-scope="scope">
            <DictTag :options="dropDown.documentstatus" :value="scope.row.documentstatus" :isStyle="true"/>
          </template>
        </el-table-column>
        <el-table-column
          prop="itemDate"
          label="项目时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            {{scope.row.itemDate | filter_handleDate}}
          </template>
        </el-table-column>
        <el-table-column
          prop="cfflowitemName"
          label="类型"
          show-overflow-tooltip
          align="center">
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="描述"
          show-overflow-tooltip
          align="center">
        </el-table-column>
        <el-table-column 
          label="操作"
          fixed="right"
          width="70">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-view" @click="handleDetail(scope.row)">查看</el-button>
            <!-- <el-button size="mini" type="text" icon="el-icon-star-on" @click="handleCollection(scope.row)" style="">取消</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 块形式 -->
    <div class="item" v-loading="loading" v-show="tab.showCard">
      <div style="text-align:center;color:#999" v-if="!showList.length">暂无数据</div>
      <el-card
        shadow="always"
        v-for="(item, index) in showList"
        :key="index"
        :class="(index + 1) % 4 == 0 ? 'noright' : ''"
        @click.native="handleDetail(item)"
        :body-style="{padding: '10px'}"
      >
        <div slot="header" class="clearfix">
          <!-- <div class="header-title">{{ item.name }}</div> -->
          <div @mouseenter="isShowToltip" @mouseout="hideTip" v-if="!isShowText" class="header-title">{{item.name}}</div>
          <el-tooltip effect="dark" :content="item.name" placement="top" v-if="isShowText">
            <div class="header-title">{{item.name}}</div>
          </el-tooltip>
          <!-- <div style="max-width: 45px">
            <el-button icon="el-icon-edit-outline" class="edit" @click.native.stop="handleEdit(item)" v-if="handleDutys(item.dutys, 'updType')" type="text"></el-button>
            <el-button icon="el-icon-delete" class="edit" @click.native.stop="handleDel(item)" v-if="handleDutys(item.dutys, 'delType')" type="text"></el-button>
          </div> -->
        </div>
        <div class="line">
          <div class="line-left">状态:</div>
          <div class="line-right"><DictTag :options="dropDown.documentstatus" :value="item.documentstatus"/></div>
        </div>
        <div class="line">
          <div class="line-left">人员:</div>
          <div class="line-right">{{ item.userName }}</div>
        </div>
        <div class="line">
          <div class="line-left">时间:</div>
          <div class="line-right">{{ item.itemDate | filter_handleDate }}</div>
        </div>
        <div class="line">
          <div class="line-left">类型:</div>
          <div class="line-right">{{ item.cfflowitemName }}</div>
        </div>
        <div class="line">
          <div class="line-left">描述:</div>
          <div class="line-right">{{ item.remarks }}</div>
        </div>
        <div class="last-line">
          <!-- <div style="margin-left:8px"> -->
            <!-- <el-button icon="el-icon-share" @click.native.stop="handleCollection(item, 's')" type="text" style="padding: 0"></el-button> -->
            <!-- <el-tooltip class="item" effect="dark" :content="item.isCollect == '0' ? '取消收藏' : '收藏'" placement="top-end"> -->
              <!-- <el-button :icon="item.isCollect == '0' ? 'el-icon-star-on' : 'el-icon-star-off'" @click.native.stop="handleCollection(item, 'c', index)" type="text" style="padding: 0;font-size:20px"></el-button> -->
            <!-- </el-tooltip> -->
          <!-- </div> -->
          <el-button class="view" @click="handleDetail(item)">查看详情</el-button>
        </div>
      </el-card>
    </div>
    <div class="bottom">
      <!-- 显示第 1 条到第 {{ total }} 条记录总金额：<span>{{ total_money }}.00元</span> -->
      <el-pagination
        @size-change="handleSizeChange"
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="tab.showCard ? [8, 16, 24, 32] : [10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, prev, pager, next, sizes, jumper, total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/config/url'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
import Form from '@/components/Form.vue'
import textRange from '@/utils/textRange.js'
export default {
  components: {
    DictTag,
    Form
  },
  computed: {
    ...mapState({
      tab: (state) => state.tab.tab,
      dropDown: (state) => state.dropDown.dropDown
    })
  },
  data() {
    return {
      siftForm: {
        name: "",
      },
      pageSize: 8,
      currentPage: 1,
      // 总条数，总共有多少条数据
      total: 0,
      // 展示的数据
      showList: [],
      loading: true,
      isShowFormInline: false,
      ruleForm: {
        name: "",
        itemDate: "",
        // userId: "",
        cfFlowItemId: "",
        documentstatus: "",
        itemType: "",
        remarks: "",
      },
      rules: {
        name: [ { required: true, message: "请输入项目名称", trigger: "change" }],
        itemDate: [{ required: true, message: "请输入项目时间", trigger: "change" }],
        // userId: [{ required: true, message: "请选择人员", trigger: "change" }],
        cfFlowItemId: [{ required: true, message: "请选择类型", trigger: "change" }],
        documentstatus: [{ required: true, message: "请选择发布形式", trigger: "change" }],
        itemType: [{ required: true, message: "请选择菜单类型", trigger: "change" }],
      },
      // 弹出层标题
      title: '',
      isLoading: false,
      formList: [],
      isShowText: false,
      cfflowitem: [],
    }
  },
  created () {
    this.getList()
  },
 
  methods: {
    // 发送请求，获取项目列表
    getList() {
      this.loading = true
      this.$http.get(url.getCollectList, {
        params: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          name: this.siftForm.name,
          flowType: 'i'
        }
      }).then(res => {
        this.loading = false
        if(res.data.code == 200) {
          this.showList = res.data.data.list
          this.showList.forEach(item => {
            item.isCollect = '0'
          })
          this.total = res.data.data.total;
        } 
      })
      // 菜单类型下拉
      if(!this.dropDown.itemType) {
        this.$http.get(url.getDataList, {params: {type: 'buss_item_type' }}).then(res => {
          this.$store.commit('setItemType', res.data.data)
        })
      }
      // 发布下拉
      if(!this.dropDown.documentstatus) {
        this.$http.get(url.getDataList, {params: {type: 'buss_document_status' }}).then(res => {
          this.$store.commit('setDocumentstatus', res.data.data)
        })
      }
    },
    // 查询按钮
    inquire() {
      this.currentPage = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      this.currentPage = 1
      this.siftForm.name = ''
      this.getList()
    },
    handleDetail(val) {
      // console.log(val);
      this.$store.commit('setJumpData', {})
      this.$router.push('/project/' + val.ofoItemId);
      let obj = {
        path: "/project/" + val.ofoItemId,
        name: "proDetail",
        label: "项目详情",
        ofoItemId: val.ofoItemId,
        children: []
      };
      this.$store.commit("selectMenu", obj);
    },
    // 表格形式和卡片形式切换
    change() {
      this.$store.commit('setStates')
      this.currentPage = 1
      if(this.tab.showCard) {
        this.pageSize = 8
      }else {
        this.pageSize = 10
      }
      this.getList()
    },
    // 表格形式的序号
    indexMethod(index) {
      let id = index + 1 + (this.currentPage - 1) * this.pageSize
      return id < 10 ? ('0' + parseInt(id)) : id;
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList()
    },
    isShowToltip (e) {
      const bool = textRange(e.target)
      this.isShowText = bool
    },
    hideTip () {
      // console.log(11);
      this.isShowText = false
    },
    handleEdit(val) {
      const id = val.ofoItemId
      this.dialogFormVisible = true
      this.title = '编辑项目'
      this.$http.get(url.getCflowItemList).then(res => {
        if(res.data.code == 200) {
          this.cfflowitem = res.data.data
        }
      })
      this.$http.get(url.getItem, {params: {ofoItemId: id}}).then(res => {
        if(res.data.code === 200) {
          let data = res.data.data
          for(let key in data) {
            if(key === 'cfflowitemId') {
              this.ruleForm.cfFlowItemId = data[key]
            }else if(key === 'itemDate') {
              this.ruleForm[key] = this.parseTime(ata[key], '{y}-{m}-{d}')
            }else if(key === 'dimeList') {
              this.formList = this.handleFormList(data.dimeList)
            }else { 
              this.ruleForm[key] = data[key]
            }
          }
        }
      })
    },
    handleDel(val) {
      // const id = val.ofoItemId
      const id = val.ofoItemId
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeItem, qs.stringify({ofoItemId: id})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        })
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.project {
  .header {
    display: flex;
    justify-content: flex-end;
    .hidden {
      padding: 6px;
      border-radius: 50%;
      margin-bottom: 0 !important;
    }
    .tab {
      padding: 0;
      border: 0;
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
  .el-table {
    margin-bottom: 39px;
  }
  .item {
    overflow: hidden;
    /* margin-bottom: 39px; */
    .el-card {
      width: calc(25% - 14px);
      float: left;
      margin-bottom: 16px;
      margin-right: 16px;
      position: relative;
      cursor: pointer;
      ::v-deep .el-card__header {
        padding: 10px 10px 10px 20px;
      }
      .clearfix {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-title {
          /* display: inline-block; */
          height: 40px;
          line-height: 40px;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .line {
        font-size: 13px;
        margin-bottom: 6px;
        width: 98%;
        display: flex;
        margin-left: 8px;
        .line-left {
          margin-right: 5px;
        }
        .line-right {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .last-line {
        display: flex;
        justify-content: flex-end;
      }
      .view {
        padding: 6px 10px;
        font-size: 12px;
        background-color: #0c75e5;
        color: #fff;
        border: 0;
      }
    }
    .noright {
      margin-right: 0;
    }
  }
  .title {
    height: 16px;
    font-size: 16px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 16px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
    margin-top: 16px;
  }
}
::v-deep .el-dialog__body {
  padding: 30px 0 30px 20px;
}
</style>