<template>
  <div class="contain">
    <!-- <div class="title">科目余额表</div> -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini">
      <el-form-item>
        <strong slot="label">月份</strong>
        <el-date-picker
          v-model="formInline.date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          format="yyyy-MM"
          value-format="yyyy-MM"
          @change="inquire">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <strong slot="label">账套</strong>
        <el-select v-model="formInline.accountType" placeholder="请选择账套" filterable @change="inquire">
          <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <strong slot="label">科目类型</strong>
        <el-select v-model="formInline.subjectType" placeholder="请选择科目类型" filterable @change="inquire" clearable>
          <el-option v-for="(item, index) in dropDown.subjectType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
      v-loading="loading"
      border
      :height="tableHeight"
    >
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-table
            :data="scope.row.children"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
            border
          >
            <el-table-column prop="resourceName" label="名称" header-align="center" min-width="100"></el-table-column>
              <el-table-column label="期初" header-align="center">
                <el-table-column prop="initialQuantity" label="数量" header-align="center" align="right" width="80">
                  <template slot-scope="scope">
                    {{scope.row.initialQuantity ? scope.row.initialQuantity : ''}}
                  </template>
                </el-table-column>
                <el-table-column prop="initialPrice" label="单价" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.initialPrice | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="initialLinetotal" label="金额" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.initialLinetotal | comdify('no')}}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="入库" header-align="center">
                <el-table-column prop="inQuantity" label="数量" header-align="center" align="right" width="80">
                  <template slot-scope="scope">
                    {{scope.row.inQuantity ? scope.row.inQuantity : ''}}
                  </template>
                </el-table-column>
                <el-table-column prop="inPrice" label="单价" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.inPrice | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="inLinetotal" label="金额" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.inLinetotal | comdify('no')}}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="出库" header-align="center">
                <el-table-column prop="outQuantity" label="数量" header-align="center" align="right" width="80">
                  <template slot-scope="scope">
                    {{scope.row.outQuantity ? scope.row.outQuantity : ''}}
                  </template>
                </el-table-column>
                <el-table-column prop="outPrice" label="单价" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.outPrice | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="outLinetotal" label="金额" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.outLinetotal | comdify('no')}}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="期末" header-align="center">
                <el-table-column prop="quantity" label="数量" header-align="center" align="right" width="80">
                  <template slot-scope="scope">
                    {{scope.row.quantity ? scope.row.quantity : ''}}
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="单价" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.price | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="linetotal" label="金额" header-align="center" align="right" width="150">
                  <template slot-scope="scope">
                    {{scope.row.linetotal | comdify('no')}}
                  </template>
                </el-table-column>
              </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="subjectName" label="存货科目" header-align="center"></el-table-column>
      <el-table-column prop="restypeNumber" label="存货编号" align="center" width="150"></el-table-column>
      <el-table-column prop="restypeName" label="名称" header-align="center" min-width="100"></el-table-column>
      <el-table-column label="期初" header-align="center">
        <el-table-column prop="initialQuantity" label="数量" header-align="center" align="right" width="80">
          <template slot-scope="scope">
            {{scope.row.initialQuantity ? scope.row.initialQuantity : ''}}
          </template>
        </el-table-column>
        <el-table-column prop="initialPrice" label="单价" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.initialPrice | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="initialLinetotal" label="金额" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.initialLinetotal | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="入库" header-align="center">
        <el-table-column prop="inQuantity" label="数量" header-align="center" align="right" width="80">
          <template slot-scope="scope">
            {{scope.row.inQuantity ? scope.row.inQuantity : ''}}
          </template>
        </el-table-column>
        <el-table-column prop="inPrice" label="单价" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.inPrice | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="inLinetotal" label="金额" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.inLinetotal | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="出库" header-align="center">
        <el-table-column prop="outQuantity" label="数量" header-align="center" align="right" width="80">
          <template slot-scope="scope">
            {{scope.row.outQuantity ? scope.row.outQuantity : ''}}
          </template>
        </el-table-column>
        <el-table-column prop="outPrice" label="单价" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.outPrice | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="outLinetotal" label="金额" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.outLinetotal | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="期末" header-align="center">
        <el-table-column prop="quantity" label="数量" header-align="center" align="right" width="80">
          <template slot-scope="scope">
            {{scope.row.quantity ? scope.row.quantity : ''}}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.price | comdify('no')}}
          </template>
        </el-table-column>
        <el-table-column prop="linetotal" label="金额" header-align="center" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.linetotal | comdify('no')}}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
  export default {
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        tableData: [],
        loading: true,
        formInline: {
          date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
          // date: ['2013-01', '2023-08'],
          accountType: '',
          subjectType: '',
        },
        tableHeight: window.innerHeight - 193
      }
    },
    created () {
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      // 获取科目类型下拉
      if(!this.dropDown.subjectType){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_type'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectType', res.data.data)
          }
        })
      }
      this.getList();
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.formInline.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        this.getList()
      })
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 193;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 193;
        })()
      }
    },
    methods: {
      getList() {
        this.tableData = []
        this.loading = true
        this.$http.get(url.inventoryAccount, {params: {
          accountType: this.formInline.accountType,
          startDate: this.formInline.date ? this.formInline.date[0] : '',
          endDate: this.formInline.date ? this.formInline.date[1] : '',
          subjectType: this.formInline.subjectType
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
          }
        })
      },
      inquire() {
        this.getList()
      }
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 16px;
  }
}
</style>