<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="10" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <ul class="list-group list-group-striped">
              <li class="list-group-item">
                <i class="el-icon-user" />用户账号
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <li class="list-group-item">
                <i class="el-icon-user-solid" />用户名称
                <div class="pull-right">{{ user.nickName }}</div>
              </li>
              <li class="list-group-item">
                <i class="el-icon-phone" />手机号码
                <div class="pull-right">{{ user.phonenumber }}</div>
              </li>
              <li class="list-group-item">
                <i class="el-icon-s-platform" />所属公司
                <div class="pull-right">{{ user.orgName }}</div>
              </li>
              <li class="list-group-item">
                <i class="
el-icon-s-opportunity" />所属角色
                <div class="pull-right">{{ user.roleName }}</div>
              </li>
              <li class="list-group-item">
                <i class="el-icon-s-custom" />所属员工
                <div class="pull-right">{{ user.bussName }}</div>
              </li>
              <!-- <li class="list-group-item">
                <i class="" />创建日期
                <div class="pull-right">{{ user.createTime }}</div>
              </li> -->
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="14" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>基本资料</span>
          </div>
          <el-tabs v-model="activeTab">
            <!-- <el-tab-pane label="基本资料" name="userinfo">
              
            </el-tab-pane> -->
            <el-tab-pane label="修改密码" name="resetPwd">
              <el-form ref="form" :model="userForm" :rules="rules" label-width="80px">
                <el-form-item label="旧密码" prop="password">
                  <el-input v-model="userForm.password" placeholder="请输入旧密码" type="password" show-password/>
                </el-form-item>
                <el-form-item label="新密码" prop="password1">
                  <el-input v-model="userForm.password1" placeholder="请输入新密码" type="password" show-password/>
                </el-form-item>
                <el-form-item label="确认密码" prop="password2">
                  <el-input v-model="userForm.password2" placeholder="请确认确认密码" type="password" show-password/>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="submit">保存</el-button>
                  <!-- <el-button type="danger" size="mini" @click="close">关闭</el-button> -->
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
  export default {
    data() {
      const equalToPassword = (rule, value, callback) => {
        if (this.userForm.password1 !== value) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      };
      return {
        activeTab: 'resetPwd',
        user: JSON.parse(sessionStorage.getItem('loginUser')),
        userForm: {
          password: '',
          password1: '',
          password2: ''
        },
        // 表单校验
        rules: {
          password: [
            { required: true, message: "旧密码不能为空", trigger: "blur" }
          ],
          password1: [
            { required: true, message: "新密码不能为空", trigger: "blur" },
            { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
          ],
          password2: [
            { required: true, message: "确认密码不能为空", trigger: "blur" },
            { required: true, validator: equalToPassword, trigger: "blur" }
          ]
        }
      }
    },
    methods: {
      submit() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            // console.log(this.userForm);
            this.$http.post(url.resetPwd, qs.stringify(this.userForm)).then(res => {
              if(res.data.code == 200) {
                this.$message.success(res.data.msg)
                // sessionStorage.removeItem('token');
                // sessionStorage.removeItem('dropDown');
                // sessionStorage.removeItem('tab');
                // sessionStorage.removeItem('currentMenu');
                // sessionStorage.removeItem('tagsList');
                // sessionStorage.removeItem('currentIndex');
                // sessionStorage.removeItem('secondMenu');
                // sessionStorage.removeItem('menuList');
                // sessionStorage.removeItem('loginUser');
                sessionStorage.clear()
                setTimeout(() => {
                  this.$router.push('/');
                  this.$store.commit('changeMenu',{
                    children: [],
                    icon: "iconfont icon-gongzuotai",
                    label: "桌面",
                    name: "index",
                    path: "/index"
                  })
                  this.$store.commit('setSecondMenu', [])
                }, 30);
              }
            })
          }
        });
      },
      close() {
        // this.$tab.closePage();
      }
    },
  }
</script>

<style lang="scss" scoped>
.container {
  /* padding: 16px; */
  .list-group {
    padding-left: 0px;
    list-style: none;
  }

  .list-group-item {
    border-bottom: 1px solid #e7eaec;
    border-top: 1px solid #e7eaec;
    margin-bottom: -1px;
    padding: 11px 0px;
    font-size: 13px;
  }
  .pull-right {
    float: right !important;
  }
}
</style>