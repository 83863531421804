import Set from '@/views/set/Index.vue'

import Process from '@/views/set/process/Index.vue'
import Approval from '@/views/set/approval/Index.vue'
import Step from '@/views/set/step/Index.vue'
import Conditon from '@/views/set/condition/Index.vue'
import ComConfig from '@/views/set/comConfig/Index.vue'

import Develop from '@/views/develop/Index.vue'

const routes = [
  {
    path: '/set',
    name: 'set',
    redirect: '/set/process',
    component: Set,
    children: [
      {
        path: 'process',
        name: 'setProcess',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Process
      },
      {
        path: 'approval',
        name: 'setApproval',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Approval
      },
      {
        path: 'step',
        name: 'setStep',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Step
      },
      {
        path: 'condition',
        name: 'setConditon',
        component: Conditon
      },
      {
        path: 'comConfig',
        name: 'comConfig',
        component: ComConfig
      },
      {
        path: 'process/:cfFlowItemId',
        name: 'processDetail',
        component: () => import('@/views/set/process/detail.vue')
      },
      {
        path: 'approval/:cfAppId',
        name: 'approvalDetail',
        component: () => import('@/views/set/approval/detail.vue')
      },
      {
        path: 'step/:cfAppUnitstepId',
        name: 'stepDetail',
        component: () => import('@/views/set/step/detail.vue')
      },
      {
        path: '*',
        name: 'develop',
        component: Develop
      },
    ]
  }
]

export default routes