<template>
  <div>
    <transition-group class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul">
      <li :key="index" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in fileList">
        <el-link :href="`${baseUrl}${file.fileUrl}`" :underline="false" target="_blank" v-if="file.fileName.split('.').pop() == 'jpg' || file.fileName.split('.').pop() == 'jpeg' || file.fileName.split('.').pop() == 'png' || file.fileName.split('.').pop() == 'pneg' || file.fileName.split('.').pop() == 'gif' || file.fileName.split('.').pop() == 'pdf' || file.fileName.split('.').pop() == 'html' || file.fileName.split('.').pop() == 'txt' ">
          <span class="el-icon-document"> {{ file.fileName }} </span>
        </el-link>
        <div class="link" v-else @click="downFile(`${baseUrl}${file.fileUrl}`, file.fileName)">
          <span class="el-icon-document"> {{ file.fileName }} </span>
        </div>
        <div class="ele-upload-list__item-content-action" v-if="isDelete">
          <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
  export default {
    props: {
      fileList: {
        type: Array,
        default: []
      },
      isDelete: {
        default: false
      },
    },
    data() {
      return {
        baseUrl: this.$base_url,
      }
    },
    methods: {
      handleDelete(index) {
        this.fileList.splice(index, 1);
        this.$emit("input", this.fileList);
      },
    },
  }
</script>

<style lang="scss" scoped>
.upload-file-list .el-upload-list__item {
  /* border: 1px solid #e4e7ed; */
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
.link {
  color: #606266;
  &:hover {
    color: #409EFF;
  }
}
</style>