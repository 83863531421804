import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/iconfont/iconfont.css'
import axios from '@/http/req'
import handleDutys from '@/utils/handleDutys'
import handleFormList from '@/utils/handleFormList'
import handleDimes from '@/utils/handleDimes'
import downFile from '@/utils/downFile'
import handleTree from '@/utils/handleTree'
import {delcommafy, comdify} from '@/utils/amountFormat'
import Print from 'vue-print-nb'
// import Print from '@/assets/print'
import UniseriateForm from '@/components/form/UniseriateForm.vue'
import { parseTime } from "@/utils/index";
import QuillEditor from "vue-quill-editor"
import fileList from '@/components/fileList/index.vue';
// import {hiPrintPlugin} from 'vue-plugin-hiprint'
// Vue.use(hiPrintPlugin, '$pluginName')
// hiPrintPlugin.disAutoConnect(); // 取消自动连接直接打印客户
import JsonExcel from'vue-json-excel'

Vue.component("downloadExcel",JsonExcel)

// 引入富文本组件样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(QuillEditor)

Vue.use(Print)
// Vue.use(Hiprint)
Vue.component('uniseriate-form', UniseriateForm)
Vue.component('fileList', fileList)

// import jquery from 'jquery'
// import Directive from '@/directive/drag'
// import infiniteScroll from 'vue-infinite-scroll'
// Vue.use(infiniteScroll)
// Directive(Vue)
// 将axios挂载到Vue的原型上
Vue.prototype.$http = axios
// Vue.prototype.jquery = $

Vue.prototype.$message = ElementUI.Message
Vue.prototype.handleDutys = handleDutys
Vue.prototype.handleFormList = handleFormList
Vue.prototype.handleDimes = handleDimes
Vue.prototype.delcommafy = delcommafy
Vue.prototype.$comdify = comdify
Vue.prototype.parseTime = parseTime
Vue.prototype.$handleTree = handleTree
Vue.prototype.downFile = downFile

Vue.config.productionTip = false

// Vue.prototype.$base_url = 'http://192.168.2.201:8081'
// Vue.prototype.$base_url = 'http://118.31.169.185:8081'
// Vue.prototype.$base_url = 'https://7k5k4jnz2pme.ngrok.xiaomiqiu123.top'
Vue.prototype.$base_url = 'https://api.mywork.world'
axios.defaults.baseURL = Vue.prototype.$base_url

const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus

Vue.prototype.pickerOptions = {
  shortcuts: [{
    text: '本周',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      var thisDay = start.getDay() - 1;
      var thisDate = start.getDate();
      if (thisDay != 0) {
        start.setDate(thisDate - thisDay);
      }
      end.setTime(start.getTime() + 3600 * 1000 * 24 * 6)
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '本月',
    onClick(picker) {
      const start = new Date();
      start.setDate(1);
      //一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      //求出上月的最后一天
      var aa = new Date();
      var thisMonth = aa.getMonth() + 1;
      //获得当前年份4位年
      var thisYear = aa.getFullYear();
      //求出下月第一天
      var firstDay = new Date(thisYear, thisMonth, 1);
      var lastDay = new Date(firstDay.getTime() - millisecond);
      const end = new Date(lastDay);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text:'本季',
    onClick(picker) {
      //获取当前时间
      var currentDate = new Date();
      //获得当前年份4位年
      var currentYear = currentDate.getFullYear();
      //获得当前月份
      var currentMonth = currentDate.getMonth() + 1;
      if(currentMonth >= 1 && currentMonth <= 3) {
        //第一季度第一天
        var start = new Date(currentYear, 0, 1);
        //第一季度最后一天
        var end = new Date(currentYear, 2, 31);
      }else if(currentMonth >= 4 && currentMonth <= 6) {
        //第二季度第一天
        var start = new Date(currentYear, 3, 1);
        //第二季度最后一天
        var end = new Date(currentYear, 5, 30);
      }else if(currentMonth >= 7 && currentMonth <= 9) {
        //第三季度第一天
        var start = new Date(currentYear, 6, 1);
        //第三季度最后一天
        var end = new Date(currentYear, 8, 30);
      }else {
        //第四季度第一天
        var start = new Date(currentYear, 9, 1);
        //第四季度最后一天
        var end = new Date(currentYear, 11, 31);
      }
      picker.$emit("pick", [start, end]);
    }
  }, {
    text:'本年',
    onClick(picker) {
      //获取当前时间
      var currentDate = new Date();
      //获得当前年份4位年
      var currentYear = currentDate.getFullYear();
      //本年第一天
      const start = new Date(currentYear,0,1);
      //本年最后一天
      const end = new Date(currentYear,11,31);
      picker.$emit("pick", [start, end]);
    }
  }]
}

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'date') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

// 日期过滤器
Vue.filter('filter_handleDate', (value, type) => {
  if(value) {
    const time = new Date(value)
    if(type == 'time') {
      return time.getFullYear() + '-' + ((time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1)) + '-' + (time.getDate() < 10 ? '0' + time.getDate() : time.getDate()) + ' ' + (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':' + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':' + (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds())
    }else {
      return time.getFullYear() + '-' + ((time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1)) + '-' + (time.getDate() < 10 ? '0' + time.getDate() : time.getDate())
    }

  }

})
// 日期时间过滤器
Vue.filter('filter_handleDateTime', (value) => {
  if(value) {
    const time = new Date(value)
    return time.getFullYear() + '-' + ((time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1)) + '-' + (time.getDate() < 10 ? '0' + time.getDate() : time.getDate()) + ' ' + (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':' + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':' + (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds())
  }

})

// 时间期间过滤器
Vue.filter('filter_handleTimeRange', (value) => {
  if(!value) return ''
  if(value) {
    let difftime = value / 1000
    let days = parseInt(difftime / 86400)
    let hours = parseInt(difftime / 3600) - 24 * days
    let minutes = parseInt(difftime % 3600 / 60)
    let seconds = parseInt(difftime % 60)
    if(days) {
      return `${days}天${hours}时${minutes}分`
    }else if(hours) {
      return `${hours}时${minutes}分`
    }else if(minutes) {
      return `${minutes}分`
    }
  }
})
Vue.filter('comdify', (n, isShow) => {
  if(isShow == 'no') {
    if(!n) return '';
    n = Number(n).toFixed(2)
    // console.log(n);
    let res01 = n.split(".")[0];
    let res02 = n.split(".")[1];
    return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
  }else {
    if(!n) return '0.00';
    n = Number(n).toFixed(2)
    // console.log(n);
    let res01 = n.split(".")[0];
    let res02 = n.split(".")[1];
    return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
  }
})

Vue.directive('drag', {
  bind(el, binding, vnode, oldVnode) {
    const minWidth = 400
    const dragDom = el.querySelector('.el-card')
    console.log(dragDom);
    dragDom.style.overflow = 'auto'

    const resizeElL = document.createElement('div')
    const img = new Image(24, 38)
    img.src = require('@/assets/images/stretch.png')
    dragDom.appendChild(img)
    dragDom.appendChild(resizeElL)
    resizeElL.style.cursor = 'w-resize'
    resizeElL.style.position = 'absolute'
    resizeElL.style.height = '100%'
    resizeElL.style.width = '10px'
    resizeElL.style.left = '0px'
    resizeElL.style.top = '0px'
    img.style.position = 'absolute'
    img.style.left = '-12px'
    img.style.top = '50%'

    resizeElL.onmousedown = (e) => {
      const elW = dragDom.clientWidth
      const EloffsetLeft = dragDom.offsetLeft
      const clientX = e.clientX
      document.onmousemove = function(e) {
        e.preventDefault()
        // 左侧鼠标拖拽位置
        if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
          // 往左拖拽
          if (clientX > e.clientX) {
            dragDom.style.width = elW + (clientX - e.clientX) + 'px'
          }
          // 往右拖拽
          if (clientX < e.clientX) {
            if (dragDom.clientWidth >= minWidth) {
              dragDom.style.width = elW - (e.clientX - clientX) + 'px'
            }
          }
        }
      }
      // 拉伸结束
      document.onmouseup = function(e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})

Vue.use(ElementUI);
ElementUI.Dialog.props.closeOnClickModal.default = false

window.vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
