<template>
  <div class="resource">
    <div class="sift">
      <div class="title">维度查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入名称"
              clearable
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <strong slot="label">种类</strong>
            <el-select v-model="formInline.type" placeholder="请选择种类" clearable @change="inquire">
              <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button>
        <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button>
      </div>
    </div>
    <div class="detail">
      <div class="title">维度列表</div>
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="dimeNumber" label="代码"></el-table-column>
        <el-table-column prop="dimeName" label="名称">
          <!-- <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.restypeName}}</a>
          </template> -->
        </el-table-column>
        <el-table-column prop="kind" label="资源种类" show-overflow-tooltip>
          <template slot-scope="scope">
            <DictTag :options="dropDown.kind" :value="scope.row.kind"/>
          </template>
        </el-table-column>
        <el-table-column prop="kindDealType" label="编辑类型">
          <template slot-scope="scope">
            <DictTag :options="dropDown.editType" :value="scope.row.editType"/>
          </template>
        </el-table-column>
        <el-table-column 
          label="操作"
          fixed="right"
          width="120">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini" icon="el-icon-edit"
              @click="handleDetail(scope.row)">详情</el-button> -->
            <el-button
              size="mini" icon="el-icon-edit" type="text"
              @click="handleEdit(scope.row)">编辑</el-button>
            <el-button
              size="mini" icon="el-icon-delete" type="text"
              @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="名称" prop="dimeName">
          <el-input v-model="ruleForm.dimeName" placeholder="请输入维度名称"></el-input>
        </el-form-item>
        <el-form-item label="种类" prop="kind">
          <el-select v-model="ruleForm.kind" placeholder="请选择资源种类" style="width:100%">
            <el-option v-for="(item, index) in dropDown.kind" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编辑类型" prop="editType">
          <el-select v-model="ruleForm.editType" placeholder="请选择编辑类型" style="width:100%">
            <el-option v-for="(item, index) in dropDown.editType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import qs from 'qs'
import DictTag from '@/components/DictTag.vue'
export default {
  components: {
    DictTag
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        name: "",
        type: "",
      },
      tableData: [],
      dialogFormVisible: false,
      // 弹出层标题
      title: "",
      ruleForm: {
        dimeName: "",
        kind: "",
        editType: "",
      },
      rules: {
        dimeName: [{ required: true, message: "请输入维度名称", trigger: "submit" }],
        kind: [{ required: true, message: "请选择资源种类", trigger: "submit" }],
        editType: [{ required: true, message: "请选择编辑类型", trigger: "submit" }]
      },
      // 编辑按钮不可点
      edit: false,
      // 删除按钮不可点
      del: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      ids: [],
      isLoading: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询按钮
    inquire() {
      this.loading = true
      this.pageNum = 1
      this.getList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.loading = true
      this.pageNum = 1
      this.getList()
    },
    // 表格的选中框事件
    handleSelectionChange(rows) {
      this.ids = rows.map(item => item.cbDimeId)
      this.edit = rows.length === 1
      this.del = rows.length === 1
    },
    // 获取字典数据
    getList() {
      // 获取种类下拉
      if(!this.dropDown.kind){
        this.$http.get(url.getDataList, {params: {type: 'buss_kind'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setKind', res.data.data)
          }
        })
      }
      // 获取编辑类型
      if(!this.dropDown.editType){
        this.$http.get(url.getDataList, {params: {type: 'buss_edit_type'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setEditType', res.data.data)
          }
        })
      }
      // 获取列表数据
      this.$http.get(url.getCbDimeList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          dimeName: this.formInline.name,
          kind: this.formInline.type
        }
      }).then(res => {
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    // 小于10补0
    add0(n) {
      return n < 10 ? '0' + n : n
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          // 新增项目
          if(!this.ruleForm.cbDimeId) {
            this.$http.post(url.addCbDime, qs.stringify(this.ruleForm)).then(res => {
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.loading = true
                this.pageNum = 1
                this.getList()
              }
            })
          }else { // 编辑资源
            this.$http.post(url.editCbDime, qs.stringify(this.ruleForm)).then(res => {
              // console.log(res);
              this.isLoading = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg);
                this.dialogFormVisible = false;
                this.loading = true
                this.pageNum = 1
                this.getList()
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 新增项目按钮
    handleAdd() {
      this.title = "新增维度"
      this.dialogFormVisible = true
      this.$nextTick(() => {
        // 清除表单校验效果
        this.$refs.ruleForm.clearValidate();
      })
      // 初始化表单内容
      for(let key in this.ruleForm) {
        this.ruleForm[key] = ""
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
    // 编辑按钮
    handleEdit(row) {
      if(this.$refs.ruleForm) {
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
      }
      const id = row.cbDimeId || this.ids[0]
      this.dialogFormVisible = true
      this.title = '编辑维度'
      this.$http.get(url.getCbDime, {params: {cbDimeId: id}}).then(res => {
        if(res.data.code === 200) {
          let data = res.data.data
          for(let key in data) {
            this.ruleForm[key] = data[key]
          }
        }
      })
    },
    // 删除按钮
    handleDel(row) {
      const id = row.cbDimeId || this.ids[0]
      this.$confirm('此操作将永久删除该维度, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(url.removeCbDime, qs.stringify({cbDimeId: id})).then(res => {
          if(res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.pageNum = 1
            this.loading = true
            this.getList()
          }
        })
        
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    // 查看详情按钮
    // handleDetail(row) {
    //   let cbRestypeId = row.cbRestypeId
    //   this.$router.push("/resource/kind/" + cbRestypeId)
    //   let obj = {
    //     path: "/resource/kind/" + cbRestypeId,
    //     name: "kindDetail",
    //     label: "资源详情",
    //     cbRestypeId: cbRestypeId
    //   };
    //   this.$store.commit("selectMenu", obj);
    // },
  },
};
</script>

<style scoped lang="scss" src="../../../assets/styles/resource.scss">
</style>