<template>
  <div class="contain">
    <el-tabs v-model="activeName" @tab-click="inquire">
      <el-date-picker
        v-model="date"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        format="yyyy-MM"
        value-format="yyyy-MM"
        size="mini"
        style="margin-bottom: 16px;margin-right:16px"
        :clearable="false"
        @change="inquire">
      </el-date-picker>
      <el-select v-model="accountType" placeholder="请选择账套" filterable @change="inquire" size="mini" clearable>
        <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
      </el-select>
      <el-tab-pane label="往来应收" name="receive">
        <el-table
          :data="receiveList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'receive'"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.children"
                tooltip-effect="dark"
                style="width: 96%;float:right"
                :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
                border
              >
                <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                <el-table-column prop="dealName" label="客户" header-align="center"></el-table-column>
                <el-table-column prop="workType" label="活动类型" width="100" align="center"></el-table-column>
                <el-table-column prop="workDate" label="单据日期" width="160" align="center"></el-table-column>
                <el-table-column prop="code" label="单据号码" align="center" width="160"></el-table-column>
                <el-table-column prop="debit" label="本期发生金额" align="right" width="150" header-align="center">
                  <template slot-scope="scope">
                    {{scope.row.debit | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="credit" label="本期已收金额" align="right" width="150" header-align="center">
                  <template slot-scope="scope">
                    {{scope.row.credit | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="往来金额" align="right" width="150" header-align="center">
                  <template slot-scope="scope">
                    {{scope.row.amount | comdify('no')}}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
          <el-table-column prop="dealName" label="客户" header-align="center"></el-table-column>
          <el-table-column prop="ofoworkName" label="活动" align="center" min-width="200">
            <template slot-scope="scope">
              <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="initialBalance" label="期初余额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.initialBalance | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="debit" label="本期发生金额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.debit | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="credit" label="本期已收金额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.credit | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="往来金额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.amount | comdify('no')}}
            </template>
          </el-table-column>
          
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="往来应付" name="pay">
        <el-table
          :data="payList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading"
          border
          :height="tableHeight"
          v-if="activeName == 'pay'"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table
                :data="props.row.children"
                tooltip-effect="dark"
                style="width: 96%;float:right"
                :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
                border
              >
                <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                <el-table-column prop="dealName" label="客户" header-align="center"></el-table-column>
                <el-table-column prop="workType" label="活动类型" width="100" align="center"></el-table-column>
                <el-table-column prop="workDate" label="单据日期" width="160" align="center"></el-table-column>
                <el-table-column prop="code" label="单据号码" align="center" width="160"></el-table-column>
                <el-table-column prop="debit" label="本期发生金额" align="right" width="150" header-align="center">
                  <template slot-scope="scope">
                    {{scope.row.debit | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="credit" label="本期已付金额" align="right" width="150" header-align="center">
                  <template slot-scope="scope">
                    {{scope.row.credit | comdify('no')}}
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="往来金额" align="right" width="150" header-align="center">
                  <template slot-scope="scope">
                    {{scope.row.amount | comdify('no')}}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
          <el-table-column prop="dealName" label="客户" header-align="center"></el-table-column>
          <el-table-column prop="ofoworkName" label="活动" align="center" min-width="200">
            <template slot-scope="scope">
              <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="initialBalance" label="期初余额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.initialBalance | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="debit" label="本期发生金额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.debit | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="credit" label="本期已付金额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.credit | comdify('no')}}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="往来金额" align="right" width="150" header-align="center">
            <template slot-scope="scope">
              {{scope.row.amount | comdify('no')}}
            </template>
          </el-table-column>
          
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import url from '@/config/url'
import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))),
          JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).name : (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)))],
        accountType: '',
        receiveList: [],
        payList: [],
        loading: true,
        activeName: 'receive',
        tableHeight: window.innerHeight - 245
      }
    },
    created () {
      // 获取账套类型下拉
      if(!this.dropDown.subjectAccountType2){
        this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
          if(res.data.code == 200) {
            this.$store.commit('setSubjectAccountType2', res.data.data)
          }
        })
      }
      this.getReceiveList()
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.date = [JSON.parse(sessionStorage.getItem('date')).name, JSON.parse(sessionStorage.getItem('date')).name]
        // this.inquire()
        if(this.activeName == 'receive') {
          this.getReceiveList()
        }else {
          this.getPayList()
        }
      })
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 245;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 245;
        })()
      }
    },
    methods: {
      // 获取应收列表
      getReceiveList(){
        this.loading = true
        this.$http.get(url.currentReceivable, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.receiveList = res.data.data.list
          }
        })
      },
      // 获取应付列表
      getPayList(){
        this.loading = true
        this.$http.get(url.currentPayable, {params: {
          accountType: this.accountType,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.payList = res.data.data.list
          }
        })
      },
      inquire() {
        if(this.activeName == 'receive') {
          this.getReceiveList()
        }else {
          this.getPayList()
        }
        
      },
      handleDetail(row) {
      },
      goProject(item) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: item.ofoworkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            this.$store.commit("setJumpData", {...res.data.data, turn: true})
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            this.$store.commit('setSecondMenu', [])
            this.$store.commit("selectMenu", obj);
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.contain {
  padding: 16px;
  background-color: #fff;
  border-radius: 11px;
}
</style>