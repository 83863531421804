<template>
  <div>
    {{name}}
  </div>
</template>

<script>
  export default {
    props: {
      id: String,
      value: String,
      label: String,
      data: Array
    },
    data() {
      return {
        name: ''
      }
    },
    created () {
      // console.log(111111);
      // console.log(this.data);
      if(this.data.length) {
        this.init()
      }
    },
    methods: {
      init() {
        // console.log(4444444);
        // console.log(this.data);
        this.data.forEach(item => {
          if(item[this.id] == this.value) {
            this.name = item[this.label]
          }
        })
      }
    },
    watch: {
      data: {
        handler(newValue, oldValue) {
          // console.log(222222);
          // console.log(newValue);
          if(newValue.length) {
            // console.log(33333);
            this.init()
          }
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>id