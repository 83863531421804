<template>
  <div class="bg">
    <div class="main">
      <div class="box">
        <div class="left">
          <div class="logo">
            <img src="../assets/images/login/logo.png" alt="" />
            <div>MyWork众数易捷</div>
          </div>
        </div>
        <div class="right">
          <div class="code-login" v-show="isCodeLogin" id="code-login"></div>
          <!-- <div v-show="!passwordLogin">
            
          </div> -->
          <div class="password-login" v-show="!isCodeLogin">
            <template v-if="isUserLogin">
              <h3>欢迎登录～</h3>
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                class="demo-ruleForm"
                @keyup.enter.native="submitForm('ruleForm')"
              >
                <el-form-item prop="userName">
                  <el-input
                    placeholder="请输入账号"
                    prefix-icon="el-icon-user-solid"
                    v-model="ruleForm.userName"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item prop="password">
                  <el-input
                    type="password"
                    placeholder="请输入密码"
                    prefix-icon="el-icon-s-goods"
                    v-model="ruleForm.password"
                  >
                  </el-input>
                </el-form-item>

                <!-- <el-form-item prop="captcha" class="captcha">
                  <el-input
                    type="text"
                    placeholder="请输入图形验证码"
                    prefix-icon="el-icon-s-goods"
                    v-model="ruleForm.captcha"
                  >
                  </el-input>
                  <img :src="imgUrl" alt="" @click="imgcode"/>
                </el-form-item> -->

                <div class="psw">
                  <el-checkbox v-model="rememberme">记住密码</el-checkbox>
                  <span>忘记密码？</span>
                </div>
                <el-button round @click="submitForm('ruleForm')" :loading="loading">立即登录</el-button
                >
              </el-form>
            </template>
            <template v-if="!isUserLogin">
              <img src="../assets/images/login/program.jpg" alt="">
            </template>
          </div>
          <div class="triangle" @click="tab">
            <i class="el-icon-user-solid" v-if="isCodeLogin"></i>
            <img src="../assets/images/login/code.png" alt="" v-if="!isCodeLogin"/>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <span>Copyright © 2022 南京拓意管理软件咨询有限公司 All Rights Reserved.</span> -->
       
      <!-- <span>南京拓意管理软件咨询有限公司 © 2022拓意管理软件-众数易捷·智行·慧腾 苏ICP备2021017175号-2</span> -->
      <span>{{bottomName}}</span>
    </div>
  </div>
</template>

<script>
import url from "../config/url";
import qs from "qs";
import '@/config/wxLogin'
import DictTag from '@/components/DictTag.vue';
export default {
  components: {
    DictTag,
  },
  name: "login",
  data() {
    return {
      imgUrl: '',
      isCodeLogin: false,
      isUserLogin: true,
      ruleForm: {
        userName: "",
        password: "",
        captcha: ""
      },
      code: '',
      rememberme: true,
      rules: {
        userName: [{ required: true, message: "请输入账号", trigger: "submit" },],
        password: [{ required: true, message: "请输入密码", trigger: "submit" }],
        captcha: [{ required: true, message: "请输入验证码", trigger: "submit" }],
      },
      loading: false,
      dropDown: [
          {dictLabel: "草稿", dictValue: "0", listClass: "primary", remark: null},
          {dictLabel: "正式", dictValue: "1", listClass: "primary", remark: null},
          {dictLabel: "草稿", dictValue: "2", listClass: "primary", remark: null},
          {dictLabel: "草稿", dictValue: "3", listClass: "primary", remark: null},
      ],
      bottomName: ''
    };
  },
  created() {
    this.$http.get(url.getDataList, {params: {
      type: 'buss_client'
    }}).then(res => {
      if(res.data.code == 200) {
        res.data.data.forEach(item => {
          if(item.dictValue == 'bottomName') {
            this.bottomName = item.dictLabel
          }
        });
      }
    })
    this.imgcode()
    // console.log(1111111);
    // 判断有无token
    if(sessionStorage.getItem('token')) {
      // 有token
      this.$router.replace('/index')
    }else {
      // 无token
      // 判断code
      if(this.$route.query.code) {
        // 有code
        // 调微信登录接口
        this.$http.post(url.loginPc, qs.stringify({code: this.$route.query.code})).then(res => {
          if(res.data.code == 200) {
            // 登录成功
            this.$message.success(res.data.msg); // 提示登录成功信息
            // 将该用户携带的token存到sessionStorage
            window.sessionStorage.setItem('token', res.data.data.token)
            this.$router.replace('/index')
          }else if(res.data.code == 901) {
            this.isCodeLogin = false
            this.isUserLogin = false
            // 未绑定账号，跳到绑定账号页
            // this.$message({
            //   message: '请绑定账号'
            // });
            // this.$router.replace({
            //   path: '/wxbind',
            //   query: {
            //     unionid: res.data.data.unionid
            //   }
            // })
          }else {
            this.$router.replace('/login')
          }
        })
      }else {
        // 无code
        // 提示登录失败，跳到登录页
        this.$router.replace('/login')
      }
    }
  },
  methods: {
    // 右上角登录方式的切换
    tab() {
      this.isCodeLogin = !this.isCodeLogin;
      this.isUserLogin = true
    },
    // 提交表单
    submitForm(formName) {
      // this.$router.replace("/index");
      this.$refs[formName].validate((valid) => {
        if (valid) { // 表单校验成功
          this.loading = true
          // 发送登录接口请求
          this.$http.post(url.login, qs.stringify(this.ruleForm)).then(res => {
            this.loading = false
            if(res.data.code === 200) { // 登录成功（用户名和密码正确）
              // if(this.ruleForm.captcha.toLowerCase() === this.code) { // 比对验证码正确
                this.$message.success(res.data.msg); // 提示登录成功信息
                // 跳转到首页
                this.$router.push({
                  name: 'index',
                  params: {
                    resetPwd: res.data.data.resetPwd
                  }
                })
                // 将该用户携带的token存到sessionStorage
                window.sessionStorage.setItem('token', res.data.data.token)
              // }else { // 比对验证码错误
                // this.$message.error('验证码错误')
              // }
            }else { // 登录失败
              // this.loading = false
              // 提示信息：用户名或密码错误
              // this.$message.error(res.data.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    // 获取图片验证码
    imgcode() {
      // this.$http.get(url.code).then(res => {
      //   // console.log(res.data.data.verifyCode);
      //   this.imgUrl = res.data.data.verifyCodeImgUrl
      //   this.code = res.data.data.verifyCode.toLowerCase()
      // })
        
    },
  },
  mounted() {
    var obj = new WxLogin({
      self_redirect: false,
      id:"code-login", 
      appid: "wx2a2c609d51726f14", 
      scope: "snsapi_login", 
      // redirect_uri: encodeURIComponent("https://192.168.1.127/index"),
      redirect_uri: encodeURIComponent("https://cs.mywork.world/login"),
      state: Date.now(),
      style: "",
      href: ""
    });

    // console.log(obj);
  },
  // head: {
  //   script: [
  //     { src: "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js" }
  //   ]
  // }
};
</script>

<style scoped lang="scss" src="../assets/styles/login.scss">
</style>