<template>
  <div class="aside">
    <!-- <el-menu 
      :default-active="currentMenu ? ('/' + currentMenu.path.split('/')[1]): '/index'"
      class="el-menu-vertical-demo"
      text-color="#282828"
      active-text-color="#0C75E5"
      active-background-color="#E5F3FF"
      router
    >
      <el-menu-item 
        v-for="(item, index) in menuList"
        :key="index"
        :index="item.path"
        @click="clickMenu(item)"
      >
        <i :class="item.icon"></i>
        <b>{{item.label}}</b>
      </el-menu-item>
    </el-menu> -->
    <div v-for="(item, index) in menuList" :key="index" :class="['menu-item', isActive.split('/')[1] == item.path.split('/')[1] ? 'isActive' : '']" @click="clickMenu(item)">
      <i :class="item.icon"></i>
      <span>{{item.label}}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import url from '@/config/url'
  export default {
    computed: {
      ...mapState({
        currentMenu: (state) => state.tags.currentMenu,
        tagsList: (state) => state.tags.tagsList,
        level: (state) => state.tags.level,
      }),
    },
    data() {
      return {
        path: '',
        menuList: [],
        defaultActive: '',
        bar: [],
        isActive: sessionStorage.getItem('currentMenu') ? (JSON.parse(sessionStorage.getItem('currentMenu')).path) : '/index'
        // isActive: this.$route.path
      };
    },
    created () {
      // console.log(console.log(this.$route));
      this.$http.get(url.getMenuList).then(res => {
        this.menuList = res.data.data
        this.menuList.forEach(item => {
          if(item.menuType == '1') { // 管理
            item.path = '/manage'
            item.children = []
          }else if(item.menuType == '2') { // 个人
            item.path = '/mine'
            item.children = []
          }
          if(item.children.length) {
            item.children.forEach(val => {
              let index = val.path.indexOf(item.path)
              if(index == -1) {
                val.path = `${item.path}/${val.path}`
              }
            })
          }
        })
        window.sessionStorage.setItem('menuList',JSON.stringify(res.data.data))
        this.menuList.forEach(item => {
          if(this.$route.path.indexOf(item.path) == 0) {
            this.$store.commit('setSecondMenu', item.children)
          }
        })
      })
    },
    methods: {
      clickMenu(item) {
        if(item.path != '/accounting' && item.children.length) {
          this.$router.push({path: item.children[0].path})
            let obj = Object.assign({}, item)
          if(item.name == 'resource') {
            obj.path = item.children[0].path
            obj.children = item.children[0].children
            this.$store.commit('selectMenu', obj)
            
          }else {
            // let obj = Object.assign({}, item.children[0])
            // for(let key in obj.children[0]) {
            //   if(key != 'label' && key != 'icon') {
            //   // if(key != 'children' && key != 'label' && key != 'icon') {
            //     obj[key] = item[key]
            //   }
            // }
            // this.$store.commit('selectMenu', obj)
          }
          this.$store.commit('selectMenu', obj)
        }else {
          this.$router.push({path: item.path})
          this.$store.commit('selectMenu', item)
        }
        this.isActive = item.path
        this.$store.commit('setSecondMenu', item.children)
        this.$store.commit('setJumpData', {})
      }
    },
    watch: {
      currentMenu(newValue, oldValue) {
        this.isActive = newValue.path
      }
    },
  }
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0px;
  height: 0px; 
  background-color: #fff;
}

/* 定义滚动条轨道 内阴影 */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ddd;
}

/* 定义滑块 内阴影 */
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #aaa;
  border-radius: 10px;
}
.aside {
  height: 100%;
  overflow: auto;
  box-shadow: 2px 3px 8px 0px rgba(208,208,208,1);
  .menu-item {
    padding: 13px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    >span {
      font-size: 13px;
      color: #555;
      margin-top: 8px;
    }
  }
  .menu-item:hover {
    background-color: #E5F3FF;
  }
  .isActive {
    background-color: #E5F3FF;
    color: #0C75E5;
    border-left: 3px solid #0C75E5;
    >span {
      color: #0C75E5;
    }
  }
  > .el-menu {
    height: 100%;
    /* width: 82px; */
    width: 70px;
    border: 0;
    > .el-menu-item {
      padding: 13px 0 !important;
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-left: 3px solid #fff;
      line-height: 34px;
      >i {
        width: 17px;
        height: 17px;
        position: relative;
      }
      >i::before {
        position: absolute;
        top: -11px;
        left: 0;
      }
      > b {
        height: 13px;
        font-size: 13px;
        font-family: STHeitiSC-Medium, STHeitiSC;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }
  .el-menu-item.is-active {
   background-color: #E5F3FF !important;
   border-left: 3px solid #0C75E5;
}
}
</style>