<template>
  <div class="finance">
    <el-tabs :tab-position="tabPosition" style="height: 100%">
      <el-tab-pane label="一、2022年中小企业资产负债表"><Tab1 /></el-tab-pane>
      <el-tab-pane label="二、2022年中小企业资产负债表"><Tab2 /></el-tab-pane>
      <el-tab-pane label="三、2022年中小企业资产负债表"><Tab3 /></el-tab-pane>
      <el-tab-pane label="四、2022年中小企业资产负债表"><Tab4 /></el-tab-pane>
      <!-- <el-tab-pane label="五、2022年中小企业资产负债表">五、2022年中小企业资产负债表</el-tab-pane>
      <el-tab-pane label="六、2022年中小企业资产负债表">六、2022年中小企业资产负债表</el-tab-pane>
      <el-tab-pane label="七、2022年中小企业资产负债表">七、2022年中小企业资产负债表</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import Tab1 from '@/components/finance/table1.vue'
import Tab2 from '@/components/finance/table2.vue'
import Tab3 from '@/components/finance/table3.vue'
import Tab4 from '@/components/finance/table4.vue'
  export default {
    components: {
      Tab1,
      Tab2,
      Tab3,
      Tab4,
    },
    data() {
      return {
        tabPosition: 'left'
      };
    }
  }
</script>

<style scoped lang="scss">
.finance {
  ::v-deep .el-tabs__header {
    width: 182px;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,1);
  }
  ::v-deep .el-tabs--left .el-tabs__header.is-left {
    margin-right: 0;
  }
  ::v-deep .el-tabs__nav-wrap.is-left::after{
    width: 0;
  }
  ::v-deep .el-tabs__active-bar.is-left {
    width: 0;
  }
  ::v-deep .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
  }
  /* 每一项的样式 */
  ::v-deep .el-tabs__item {
    text-align: left;
    padding: 0 15px 0 6px;
    height: 38px;
    font-size: 13px;
    font-family: STHeitiSC-Light, STHeitiSC;
    font-weight: 300;
    color: #282828;
    line-height: 16px;
    margin: 10px 8px;
    white-space: normal;
  }
  /* 选中的样式 */
  ::v-deep .el-tabs__item.is-active {
    color: #177BE6;
    background-color: #E5F3FF;
  }
  /* 右边内容区 */
  ::v-deep .el-tabs__content {
    height: calc(100% - 32px);
    padding: 16px;
    overflow: auto;
  }
}
</style>