<template>
  <div class="home">
    <!-- 审批类 -->
    <div class="pro">
      <div class="title">审批类</div>
      <el-row :gutter="16">
        <el-col
          v-for="(item, index) in appList"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-if="item.isShow"
        >
          <div class="grid-content" @click="turn(item)">
            <div class="grid-left">
              <div :class="item.class">
                <i :class="item.icon"></i>
              </div>
              <b>{{item.label}}</b>
            </div>
            <div class="grid-right" :style="item.badge ? 'color: #0c75e5' : 'color: #282828'" v-if="item.badge">{{item.badge}}</div>
            <!-- <span></span> -->
          </div>
        </el-col>
      </el-row>
      
      <!-- <li 
        v-for="(item, index) in list"
        :key="index"
        :class="(index + 1) % 4 == 0 ? 'no-margin': ''"
        @click="turn(item)">
        <div :class="item.class">
          <i :class="item.icon"></i>
        </div>
        <b>{{item.label}}</b>
        <span :style="item.badge ? 'color: #0c75e5' : 'color: #282828'">{{item.badge}}</span>
      </li> -->
    </div>
    <!-- 项目类 -->
    <div class="pro">
      <div class="title">项目类</div>
      <el-row :gutter="16">
        <el-col
          v-for="(item, index) in proList"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          
        >
          <div class="grid-content" @click="turn(item)">
            <div class="grid-left">
              <div :class="item.class">
                <i :class="item.icon"></i>
              </div>
              <b>{{item.label}}</b>
            </div>
            <div class="grid-right" :style="item.badge ? 'color: #0c75e5' : 'color: #282828'" v-if="item.badge">{{item.badge}}</div>
          </div>
          
        </el-col>
      </el-row>
    </div>
    <!-- 个人类 -->
    <div class="pro" v-if="ofoFlowDealList.length">
      <div class="title">个人类</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in ofoFlowDealList" :key="index" :label="item.name" :name="String(item.ofoDealId)">
          <el-row :gutter="16">
            <el-col
              v-for="(val, id) in ofoFlowWorkList"
              :key="id"
              :xs="24"
              :sm="12"
              :md="8"
              :lg="6"
            >
              <div class="grid-content" style="cursor:pointer" @click="turnWork(item, val)">
                <div class="grid-left">
                  <div class="icon icon7">
                    <i class="iconfont icon-daiban"></i>
                  </div>
                  <span style="font-size:18px">{{val.name}}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div class="pro" v-if="manList[0].isShow">
      <div class="title">管理类</div>
      <el-row :gutter="16">
        <el-col
          v-for="(item, index) in manList"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          
        >
          <div class="grid-content" @click="turn(item)">
            <div class="grid-left">
              <div :class="item.class">
                <i :class="item.icon"></i>
              </div>
              <b>{{item.label}}</b>
            </div>
            <div class="grid-right" :style="item.badge ? 'color: #0c75e5' : 'color: #282828'">{{item.badge}}</div>
          </div>
          
        </el-col>
      </el-row>
    </div> -->
    <div class="pro" v-if="mineList[0].isShow">
      <div class="title">个人类</div>
      <el-row :gutter="16">
        <el-col
          v-for="(item, index) in mineList"
          :key="index"
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          
        >
          <div class="grid-content" @click="turn(item)">
            <div class="grid-left">
              <div :class="item.class">
                <i :class="item.icon"></i>
              </div>
              <b>{{item.label}}</b>
            </div>
            <div class="grid-right" :style="item.badge ? 'color: #0c75e5' : 'color: #282828'">{{item.badge}}</div>
          </div>
          
        </el-col>
      </el-row>
    </div>
    <!-- <div class="echarts">
      <div class="item">
        <div class="title">数据统计</div>
        <statsCharts />
      </div>
      <div class="item">
        <div class="title">季度报表分析</div>
        <analyseCharts />
      </div>
    </div> -->
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible"
      :append-to-body="true" width="30%">
      <div style="color:red;font-size:14px;margin-bottom:15px">当前密码强度较弱，请修改密码</div>
      <el-form ref="form" :model="userForm" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="password">
          <el-input v-model="userForm.password" placeholder="请输入旧密码" type="password" show-password/>
        </el-form-item>
        <el-form-item label="新密码" prop="password1">
          <el-input v-model="userForm.password1" placeholder="请输入新密码" type="password" show-password/>
        </el-form-item>
        <el-form-item label="确认密码" prop="password2">
          <el-input v-model="userForm.password2" placeholder="请确认确认密码" type="password" show-password/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import statsCharts from '@/components/echarts/stats.vue'
import analyseCharts from '@/components/echarts/analyse.vue'
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import qs from 'qs'
  export default {
    name: 'home',
    components: {
      statsCharts,
      analyseCharts,
      DictTag
    },
    computed: {
      ...mapState({
        dropDown: (state) => state.dropDown.dropDown
      })
    },
    data() {
      const equalToPassword = (rule, value, callback) => {
        if (this.userForm.password1 !== value) {
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      };
      return {
        appList: [
          {
            label: '待我审批',
            path: '/process',
            name: 'approval',
            badge: null,
            class: 'icon icon1',
            icon: 'iconfont icon-daiban',
            children: [],
            isShow: true,
            appstatus: '3'
          },
          {
            label: '完成审批',
            path: '/process',
            badge: null,
            name: 'done',
            class: 'icon icon4',
            icon: 'iconfont icon-yiwancheng-yiban-02',
            children: [],
            isShow: true,
            appstatus: '1'
          },
          {
            label: '我发起的',
            path: '/process',
            name: 'mine',
            badge: null,
            class: 'icon icon2',
            icon: 'iconfont icon-wofaqide1',
            children: [],
            isShow: true,
            appstatus: '99'
          },
          {
            label: '抄送给我',
            path: '/process',
            name: 'copy',
            badge: null,
            class: 'icon icon3',
            icon: 'iconfont icon-ziyuan200',
            children: [],
            isShow: true,
            appstatus: '9'
          },
          
        ],
        proList: [
          {
            label: '我的收藏',
            path: '/collect',
            badge: null,
            name: 'collect',
            class: 'icon icon8',
            icon: 'iconfont icon-hanhan-01-01',
            children: []
          },
          {
            label: '分享给我',
            path: '/share',
            badge: 0,
            name: 'share',
            class: 'icon icon7',
            icon: 'iconfont icon-fenxianggeiwo',
            children: []
          },
          {
            label: '预警消息',
            // path: '/share',
            badge: 0,
            name: 'warn',
            class: 'icon icon5',
            icon: 'iconfont icon-yujing',
            children: []
          },
        ],
        manList: [
          {
            label: '管理中心',
            path: '/manage',
            badge: null,
            name: 'manage',
            class: 'icon icon8',
            icon: 'iconfont icon-guanlizhongxin',
            children: [],
            isShow: false
          },
        ],
        mineList: [
          {
            label: '个人中心',
            path: '/mine',
            badge: null,
            name: 'mine',
            class: 'icon icon8',
            icon: 'iconfont icon-gerenzhongxin2',
            children: [],
            isShow: false
          },
        ],
        menuList: JSON.parse(sessionStorage.getItem('menuList')),
        activeName: '',
        ofoFlowDealList: [],
        ofoFlowWorkList: [],
        dialogFormVisible: false,
        userForm: {
          password: '',
          password1: '',
          password2: ''
        },
        // 表单校验
        rules: {
          password: [
            { required: true, message: "旧密码不能为空", trigger: "blur" }
          ],
          password1: [
            { required: true, message: "新密码不能为空", trigger: "blur" },
            { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
          ],
          password2: [
            { required: true, message: "确认密码不能为空", trigger: "blur" },
            { required: true, validator: equalToPassword, trigger: "blur" }
          ]
        }
      }
    },
    created () {
      // this.$http.get(url.getWorkResAndThingList, {params: {
      //   ofoWorkId: 840,
      //   pageNum: 1,
      //   pageSize: 10,
      //   type: '1'
      // }}).then(res => {
      //   if(res.data.code == 200) {
      //     // this.$handleTree(res.data.data.list, 'bomId', 'pid')
      //     console.log(this.$handleTree(res.data.data.list, 'bomId', 'pid'))
      //   }
      // })
      this.getFlowMenuList()
      // this.$eventBus.$off('editShareStatus')
      // this.$eventBus.$on('editShareStatus', val => {
      //   console.log(val);
      // })
      this.$http.get(url.getIndexCount).then(res => {
        if(res.data.code == 200) {
          this.appList.forEach(item => {
            item.badge = res.data.data[item.name]
          })
          this.proList.forEach(item => {
            item.badge = res.data.data[item.name]
          })
        }
      })
    },
    updated () {
      this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
      this.menuList.forEach(item => {
        if(item.menuType == '1'){ // 管理类
          this.manList[0].isShow = true
        }else if(item.menuType == '2') { // 个人类
          this.mineList[0].isShow = true
        }
      })
    },
    methods: {
      // 获取交易
      getFlowMenuList() {
        this.$http.get(url.getFlowMenuList, {params: {
          itemType: '2'
        }}).then(res => {
          if(res.data.code == 200) {
            this.ofoFlowDealList = res.data.data
            if(this.ofoFlowDealList.length) {
              this.activeName = String(this.ofoFlowDealList[0].ofoDealId)
              if(this.ofoFlowDealList[0].ofoFlowWorkList) {
                this.ofoFlowWorkList = this.ofoFlowDealList[0].ofoFlowWorkList
              }
            }
            
          }
        })
      },
      // 切换tab
      handleClick(tab, event) {
        if(this.ofoFlowDealList[tab.index].ofoFlowWorkList.length) {
          this.ofoFlowWorkList = this.ofoFlowDealList[tab.index].ofoFlowWorkList
        }else {
          this.ofoFlowWorkList = []
        }
      },
      // onresize() {
      //   window.onresize = function () {
      //     myChart1.resize();
      //     myChart.resize();
      //   };
      // },
      turn(item) {
        if(item.name != 'warn') {
          this.$router.push(item.path)
          this.$store.commit('selectMenu1', item)
        }else {
          this.$eventBus.$emit('warnMsg', 111)
        }
        
      },
      turnWork(ofoFlowDeal, ofoFlowWork) {
        let obj = {
          path: '/work',
          name: 'work',
          label: '活动',
          children: [],
          ofoFlowDeal,
          ofoFlowWork
        }
        this.$router.push(obj.path)
        this.$store.commit('selectMenu', obj)
      },
      submit() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.$http.post(url.resetPwd, qs.stringify(this.userForm)).then(res => {
              if(res.data.code == 200) {
                this.$message.success(res.data.msg)
                sessionStorage.clear()
                setTimeout(() => {
                  this.$router.push('/');
                  this.$store.commit('changeMenu',{
                    children: [],
                    icon: "iconfont icon-gongzuotai",
                    label: "桌面",
                    name: "index",
                    path: "/index"
                  })
                  this.$store.commit('setSecondMenu', [])
                }, 30);
              }
            })
          }
        });
      },
    },
    mounted() {
      if(this.$route.params.resetPwd) {
        this.dialogFormVisible = true
      }
    }
  }
</script>

<style scoped lang="scss">
.home {
  padding: 16px;
  
  .pro {
    overflow: hidden;
    padding: 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 11px;
    .el-row {
      width: 100%;
      /* margin: 0 !important; */
    }
    .el-col {
      
      margin-bottom: 16px;
      .grid-content {
        background: #fff;
        padding: 10px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        font-size: 20px;
        .grid-left {
          display: flex;
          align-items: center;
          margin-right: 16px;
        }
        .icon {
          margin-right: 16px;
          /* color: #fff;
          background-color: #0C75E5;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 5px; */
          >i {
            font-size: 38px;
          }
          
        }
        .icon-daiban {
          background: -webkit-linear-gradient(bottom right,#f16d17, #fad8c8); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-wofaqide1 {
          background: -webkit-linear-gradient(bottom right,#765fe5, #ddd8f6); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-ziyuan200 {
          background: -webkit-linear-gradient(bottom right,#3bcc51, #def4da); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-xiangmu1 {
          background: -webkit-linear-gradient(bottom right,#3ba9f1, #d8eefe); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-gongzuoliuchengtu {
          background: -webkit-linear-gradient(bottom right,#fc6089, #ffe6ed); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-ziyuanzhongxin {
          background: -webkit-linear-gradient(bottom right,#27bc9f, #dffefa); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-yiwancheng-yiban-02 {
          background: -webkit-linear-gradient(bottom right,#3d84ec, #e3f9ff); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-hanhan-01-01 {
          background: -webkit-linear-gradient(bottom right,#ffdb0f, #f0f0ee); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-guanlizhongxin {
          background: -webkit-linear-gradient(bottom right,#0fffb3, #eff0ee); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-fenxianggeiwo {
          background: -webkit-linear-gradient(bottom right,#0f9fff, #eeeff0); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-yujing {
          background: -webkit-linear-gradient(bottom right,#ff0f0f, #f0eeee); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .grid-right {
          font-size: 28px;
        }
      }
    }
    /* >li {
      list-style: none;
      width: calc(25% - 12px);
      width: 260px;
      height: 8vw;
      background-color: #fff;
      padding: 0 30px;
      box-sizing: border-box;
      float: left;
      margin-right: 16px;
      margin-bottom: 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .icon {
        width: 6vh;
        height: 6vh;
        padding: 12px;
        border-radius: 50%;
        line-height: 6vh;
        text-align: center;
        margin-right: 16px;
        > i{
          font-size: 38px;
        }
        .icon-daiban {
          background: -webkit-linear-gradient(bottom right,#f16d17, #fad8c8); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-wofaqide1 {
          background: -webkit-linear-gradient(bottom right,#765fe5, #ddd8f6); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-ziyuan200 {
          background: -webkit-linear-gradient(bottom right,#3bcc51, #def4da); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-xiangmu1 {
          background: -webkit-linear-gradient(bottom right,#3ba9f1, #d8eefe); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-gongzuoliuchengtu {
          background: -webkit-linear-gradient(bottom right,#fc6089, #ffe6ed); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-ziyuanzhongxin {
          background: -webkit-linear-gradient(bottom right,#27bc9f, #dffefa); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-fenxianggeiwo {
          background: -webkit-linear-gradient(bottom right,#3d84ec, #e3f9ff); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
        .icon-hanhan-01-01 {
          background: -webkit-linear-gradient(bottom right,#ffdb0f, #f0f0ee); 
          background-clip: text; 
          -webkit-text-fill-color: transparent; 
        }
      } */
      /* .icon1 {
        background-color: #f3782b !important;
      }
      .icon2 {
        background-color: #7965fd !important;
      }
      .icon3 {
        background-color: #41fd28 !important;
      }
      .icon4 {
        background-color: #51b2fb !important;
      }
      .icon5 {
        background-color: #ff6782 !important;
      }
      .icon6 {
        background-color: #63f9e0 !important;
      }
      .icon7 {
        background-color: #5ec0f5 !important;
      }
      .icon8 {
        background-color: #f9f94f !important;
      } */
      /* >b {
        font-size: 20px;
      }
      > span {
        font-size: 30px;
        position: absolute;
        right: 30px;
      }
    } */
    .no-margin {
      margin-right: 0;
    }
  }
  .echarts {
    height: 300px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    .item {
      width: calc(50% - 40px);
      background-color: #fff;
      border-radius: 11px;
      padding: 16px;
      #stats, #analyse{
        height: calc(100% - 30px);
      }
    }
  }
  .progress {
    overflow: hidden;
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    box-sizing: border-box;
    ::v-deep .cell {
      padding-left: 16px;
      padding-right: 0;
    }
    ::v-deep .el-table_9_column_64 {
      padding-left: 50px;
    }
    ::v-deep .el-table thead {
      color: #282828;
      background-color: #ddd;
      >tr {
        background-color: #ddd;
      }
    }
    .el-button {
      border: 0;
      color: #0C75E5;
      padding: 0;
    }
     ::v-deep .el-table__row--striped .el-button{
      background-color: #F9F9FB;
     }
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 15px;
    padding-left: 8px;
    border-left: #0C75E5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>