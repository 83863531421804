<template>
  <div id="stats">

  </div>
</template>

<script>
import * as echarts from 'echarts';
  export default {
    methods: {
      initCharts1() {
        var chartDom = document.getElementById('stats');
        var myChart1 = echarts.init(chartDom ,null, {
          height: '254'
        });
        var option1;

        option1 = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            // data: ['我的待办', '待我审批', '已完成'],
            left: 20,
            icon: 'roundRect'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '我的待办',
              type: 'line',
              data: [6, 11, 7, 10, 15, 1, 0],
              symbol: "none",
            },
            {
              name: '待我审批',
              type: 'line',
              data: [9, 1, 6, 18, 3, 1, 2],
              symbol: "none",
            },
            {
              name: '已完成',
              type: 'line',
              data: [15, 14, 13, 13, 5, 0, 3],
              symbol: "none",
            },
          ]
        };

        option1 && myChart1.setOption(option1);
        window.onresize = myChart1.resize
      },
    },
    mounted() {
      this.initCharts1()
    }
  }
</script>

<style scoped>

</style>