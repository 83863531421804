<template>
  <div class="entry">
    <div class="sift">
      <div class="title">凭证查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">日期</strong>
            <el-date-picker
              v-model="formInline.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="inquire">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <strong slot="label">账套</strong>
            <el-select v-model="formInline.accountType" placeholder="请选择账套" filterable @change="inquire">
              <el-option v-for="(item, index) in dropDown.subjectAccountType2" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">凭证类型</strong>
            <el-select v-model="formInline.financeType" placeholder="请选择凭证类型" clearable filterable @change="inquire">
              <el-option v-for="(item, index) in dropDown.financeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">现金流</strong>
            <el-select v-model="formInline.cashId" clearable filterable @change="inquire">
              <el-option 
                v-for="item in cashList"
                :label="item.name"
                :value="item.cashFlowId"
                :key="item.cashFlowId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="main">
      <div class="title">凭证列表</div>
      <!-- <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
      <el-button type="warning" plain  size="small" :disabled="!del" @click="handleDocumentStatus('1')">审核</el-button>
      <el-button type="primary" plain size="small" :disabled="!del" @click="handleDocumentStatus('2')">记账</el-button>
      <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button>
      <el-button type="info" plain size="small">作废</el-button>
      <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel">删除</el-button> -->
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
        v-loading="loading"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <!-- <el-table-column prop="name" label="流程名称">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.name}}</a>
          </template>
        </el-table-column> -->
        <el-table-column prop="accountCode" label="凭证字号" width="180">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.accountCode}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofoworkName" label="活动" align="center" min-width="200">
          <template slot-scope="scope">
            <a @click="goProject(scope.row)" class="link">{{scope.row.ofoworkName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofrthingName" label="负责人" align="center" width="120"></el-table-column>
        <el-table-column prop="ojdtDate" label="日期" align="center" width="120">
          <template slot-scope="scope">
            {{scope.row.ojdtDate | filter_handleDate}}
          </template>
        </el-table-column>
        <el-table-column prop="tbperd" label="期间" align="center" width="120"></el-table-column>
        <el-table-column prop="debitTotal" label="借方" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.debitTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="creditTotal" label="贷方" align="right" width="150">
          <template slot-scope="scope">
            {{scope.row.creditTotal | comdify}}
          </template>
        </el-table-column>
        <el-table-column prop="cashName" label="现金流" align="center" width="150"></el-table-column>
        <el-table-column prop="documentstatus" label="状态" align="center" width="100">
          <template slot-scope="scope">
            <DictTag :options="dropDown.ojdtStatus" :value="scope.row.documentstatus" :isStyle="true"/>
          </template>
        </el-table-column>
        <el-table-column prop="financeType" label="凭证类型" align="center" width="100">
          <template slot-scope="scope">
            <DictTag :options="dropDown.financeType" :value="scope.row.financeType"/>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="注释" align="center" show-overflow-tooltip min-width="200"></el-table-column>
        <el-table-column label="操作" fixed="right" width="170" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-document-remove"
              @click.native.stop="handleWritedowns(scope.row)"
              type="text"
            >冲销
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-copy-document"
              @click.native.stop="handleCopy(scope.row)"
              type="text"
            >复制
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-back"
              @click.native.stop="handleDocumentStatus(scope.row)"
              type="text"
            >退回
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="90%"
      top="59px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
        class="demo-ruleForm"
        label-position="right"
      >
         <el-row>
          <el-col :span="24">
            <el-form-item label="日期" prop="ojdtDate">
              <el-date-picker
                v-model="ruleForm.ojdtDate"
                type="date"
                placeholder="选择凭证日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
                :clearable='false'
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button @click="addList" plain type="primary" style="margin-bottom: 16px" size="small">增行</el-button>
        <el-table
          class="table"
          :data="list"
          :header-cell-style="{background:'#F9F9FB',color:'#606266'}" ref="multipleTable" style="margin-bottom: 20px">
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <!-- 科目 -->
          <el-table-column
            prop="cboactId"
            label="科目">
            <template slot-scope="scope">
              <treeselect v-model="scope.row.cboactId" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择科目" />
            </template>
          </el-table-column>
          <!-- 借方 -->
          <el-table-column
            prop="debit"
            label="借方"
            width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.debit" clearable/>
            </template>
          </el-table-column>
          <!-- 贷方 -->
          <el-table-column
            prop="credit"
            label="贷方"
            width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.credit"/>
            </template>
          </el-table-column>
          <!-- 项目	-->
          <el-table-column
            prop="ofoitemId"
            label="项目"
            width="200">
            <template slot-scope="scope">
              <el-select v-model="scope.row.ofoitemId" clearable filterable>
                <el-option-group
                  v-for="group in itemList"
                  :key="group.cfFlowItemId"
                  :label="group.showName">
                  <el-option
                    v-for="item in group.ofoFlowItemList"
                    :key="item.ofoItemId"
                    :label="item.name"
                    :value="item.ofoItemId">
                  </el-option>
                </el-option-group>
              </el-select>
            </template>
          </el-table-column>
          <!-- 现金流 -->
          <el-table-column
            prop="vatgroup"
            label="现金流">
            <template slot-scope="scope">
              <el-select v-model="scope.row.cashId" clearable filterable>
                <el-option 
                  v-for="item in cashList"
                  :label="item.name"
                  :value="item.cashFlowId"
                  :key="item.cashFlowId"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 注释 -->
          <el-table-column
            prop="remarks"
            label="注释">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remarks" clearable/>
            </template>
          </el-table-column>
          
          <el-table-column
            label="操作"
            width="80"
            >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="12">
            <el-form-item label="类型" prop="financeType">
              <el-select v-model="ruleForm.financeType" placeholder="请选择凭证类型" style="width:100%">
                <el-option v-for="(item, index) in dropDown.financeType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人" prop="ofrthingId">
              <el-select v-model="ruleForm.ofrthingId" placeholder="请选择负责人" style="width:100%">
                <el-option-group
                  v-for="group in ofrthingList"
                  :key="group.ofrResourceId"
                  :label="group.resourceName">
                  <el-option
                    v-for="item in group.ofrThingList"
                    :key="item.ofrThingId"
                    :label="item.name"
                    :value="item.ofrThingId">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="注释" prop="remarks">
            <el-input type="textarea" v-model="ruleForm.remarks" placeholder="请输入注释"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
import getCurrentDate from '@/utils/getCurrentDate'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      DictTag,
      Treeselect,
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        formInline: {
          // date: [sessionStorage.getItem('date') ? sessionStorage.getItem('date') : '', sessionStorage.getItem('date') ? sessionStorage.getItem('date') : ''],
          date: [JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).startTime : '', JSON.parse(sessionStorage.getItem('date')) ? JSON.parse(sessionStorage.getItem('date')).endTime : ''],
          accountType: '1',
          financeType: '',
          cashId: ''
        },
        ruleForm: {
          ojdtDate: '',
          documentstatus: '',
          financeType: '',
          ofrthingId: '',  
          remarks: ''
        },
        rules: {
          ojdtDate: [{ required: true, message: "请选择日期", trigger: "submit" }],
          documentstatus: [{ required: true, message: "请选择状态", trigger: "submit" }],
          financeType: [{ required: true, message: "请选择凭证类型", trigger: "submit" }],
          ofrthingId: [{ required: true, message: "请选择负责人", trigger: "submit" }],
        },
        // options: [],
        // 弹出层标题
        title: '',
        isLoading: false,
        pageNum: 1, 
        pageSize: 10,
        total: 0,
        // 编辑按钮不可点
        edit: false,
        del: false,
        list: [],
        itemList: [], // 项目列表
        cbSubjectList: [], // 科目列表
        options: [], // 树选项
        ofrthingList: [],
        cashList: []
      }
    },
    created () {
      this.getList()
      this.getData()
      window.addEventListener('setItem', ()=> {
        // console.log(JSON.parse(sessionStorage.getItem('date')));
        this.formInline.date = [JSON.parse(sessionStorage.getItem('date')).startTime, JSON.parse(sessionStorage.getItem('date')).endTime]
        this.loading = true
        this.pageNum = 1
        this.getList()
      })
    },
    methods: {
      goProject(item) {
        this.$http.get(url.getWorkJumpId, {params: {ofoWorkId: item.ofoworkId}}).then(res => {
          // console.log(res);
          if(res.data.code == 200) {
            this.$router.push({
              path: '/project/' + res.data.data.ofoItemId,
            });
            this.$store.commit("setJumpData", {...res.data.data, turn: true})
            let obj = {
              path: "/project/" + res.data.data.ofoItemId,
              name: "proDetail",
              label: "项目详情",
              ofoItemId: res.data.data.ofoItemId,
              children: []
            };
            this.$store.commit('setSecondMenu', [])
            this.$store.commit("selectMenu", obj);
          }
        })
      },
      // 查询按钮
      inquire() {
        this.pageNum = 1
        this.getList()
      },
      // 重置按钮
      reset() {
        for(let key in this.formInline) {
          this.formInline[key] = ""
        }
        this.formInline.date = [JSON.parse(sessionStorage.getItem('date')).startTime, JSON.parse(sessionStorage.getItem('date')).endTime]
        this.pageNum = 1
        this.getList()
      },
      getList() {
        // 获取凭证状态下拉
        if(!this.dropDown.ojdtStatus){
          this.$http.get(url.getDataList, {params: {type: 'buss_ojdt_status'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setOjdtStatus', res.data.data)
            }
          })
        }
        // 获取凭证类型下拉
        if(!this.dropDown.financeType){
          this.$http.get(url.getDataList, {params: {type: 'buss_finance_type'}}).then(res => {
            if(res.data.code == 200) {
              // console.log(res);
              this.$store.commit('setFinanceType', res.data.data)
            }
          })
        }
        // 获取账套类型下拉
        if(!this.dropDown.subjectAccountType2){
          this.$http.get(url.getDataList, {params: {type: 'buss_subject_account_type2'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setSubjectAccountType2', res.data.data)
            }
          })
        }
        this.loading = true
        this.$http.get(url.getOffOjdtList, {params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          documentstatusList: '2',
          startOjdtDate: this.formInline.date ? this.formInline.date[0] : '',
          endOjdtDate:  this.formInline.date ? this.formInline.date[1] : '',
          accountType: this.formInline.accountType,
          financeType: this.formInline.financeType,
          cashId: this.formInline.cashId,
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data.list
            this.total = res.data.data.total
          }
        })
      },
      // 增行
      addList() {
        this.list.push({
          cboactId: null,
          debit: '',
          credit: '',
          ofoitemId: '',
          remarks: '',
        })
      },
      // 减行
      handleDelete(index) {
        this.list.splice(index, 1)
      },
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // console.log(this.ruleForm);
            // if(!this.ruleForm.offOjdtId) { // 新增凭证
              this.$http.post(url.addOffOjdt, qs.stringify({
                documentstatus: '0',
                financeType: this.ruleForm.financeType,
                ofrthingId: this.ruleForm.ofrthingId,
                ojdtDate: this.ruleForm.ojdtDate,
                remarks: this.ruleForm.remarks,
                list: JSON.stringify(this.list)
              })).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.pageNum = 1
                  this.getList()
                }
              })
            // }else { // 修改凭证
            //   this.$http.post(url.editOffOjdt, qs.stringify(this.ruleForm)).then(res => {
            //     this.isLoading = false
            //     if(res.data.code === 200) {
            //       this.$message.success(res.data.msg);
            //       this.dialogFormVisible = false;
            //       this.pageNum = 1
            //       this.loading = true
            //       this.getList()
            //     }
            //   })
            // }
          } else {
            return false;
          }
        });
      },
      handleDetail(row) {
        this.$router.push({
          path: '/accounting/vDetail/' + row.offOjdtId, query: {
            account: true
        }})
        let obj = {
          path: '/accounting/vDetail/' + row.offOjdtId,
          name: 'voucherDetail',
          label: '凭证详情',
          offOjdtId: row.offOjdtId,
          children: []
        }
        this.$store.commit("selectMenu", obj);
      },
      handleWritedowns(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.offOjdtId || this.ids[0]
        this.dialogFormVisible = true
        this.title = '冲销凭证'
        this.$http.get(url.getReverseOffOjdt, {params: {offOjdtId: id}}).then(res => {
          if(res.data.code === 200) {
            let data = res.data.data.ojdt
            for(let key in data) {
              if(key == 'ojdtDate') {
                this.ruleForm[key] = this.parseTime(data[key], '{y}-{m}-{d}')
              }else {
                this.ruleForm[key] = data[key]
              }
            }
            this.list = res.data.data.ojdtacctList
          }
        })
      },
      handleCopy(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.offOjdtId || this.ids[0]
        this.dialogFormVisible = true
        this.title = '复制凭证'
        this.$http.get(url.getOffOjdt, {params: {offOjdtId: id}}).then(res => {
          if(res.data.code === 200) {
            let data = res.data.data.ojdt
            for(let key in data) {
              if(key == 'ojdtDate') {
                this.ruleForm[key] = this.parseTime(data[key], '{y}-{m}-{d}')
              }else {
                this.ruleForm[key] = data[key]
              }
            }
            this.list = res.data.data.ojdtacctList
          }
        })
      },
      handleDocumentStatus(row) {
        const id = row.offOjdtId || this.ids.toString()
        this.$confirm(`此操作将此凭证修改为草稿, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.get(url.batchUpdateDocumentStatus, {params:{
            offOjdtIds: id,
            documentstatus: '0'
          }}).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
              this.pageNum = 1
              this.loading = true
              this.getList()
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.offOjdtId)
        this.edit = rows.length === 1
        this.del = rows.length >= 1
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getList()
      },
      getData() {
        this.$http.get(url.getAllItemList).then(res => {
          if(res.data.code == 200) {
            this.itemList = res.data.data
          }
        })
        this.$http.get(url.getCbSubjectList).then(res => {
          if(res.data.code == 200) {
            this.cbSubjectList = handleTree(res.data.data, 'cbSubjectId', 'pid')
          }
        })
        this.$http.get(url.getPostAndUserList).then(res => {
          if(res.data.code == 200) {
            this.ofrthingList = res.data.data
          }
        })
        this.$http.get(url.getCashList).then(res => {
          if(res.data.code == 200) {
            this.cashList = res.data.data
          }
        })
      },
      /** 转换数据结构 */
      normalizer(node) {
        // this.$nextTick(() => {
        //   if (node.children && !node.children.length) {
        //   delete node.children;
        // }
        return {
          id: node.cbSubjectId,
          label: node.subjectName + ': ' + node.subjectNumber,
          children: node.children
        };
        // })
      },
    },
    // watch: {
    //   data(newValue, oldValue) {
        
    //   }
    // },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
   margin: 0 auto;
   height: calc(100% - 70px);
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
  height: calc(100% - 134px);
  overflow: auto;
}
::v-deep .table{
  overflow: visible;
}
::v-deep .table .cell{
  overflow: visible;
}
::v-deep .table .el-table__body-wrapper{
  overflow: visible;
}
.entry {
  /* padding: 16px; */
  .sift, .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    /* margin-bottom: 16px; */
    .add{
      margin-bottom: 16px;
    }
    .el-table {
      margin-top: 16px;
      margin-bottom: 39px;
    }
  }
  .sift {
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 14px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
}
</style>