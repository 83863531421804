<template>
  <div>
    <template v-if="!disabled">
      <el-form :model="resource" :rules="!disabled? rules : {}" ref="resource" :disabled="disabled" size="small">
        <el-row v-if="!isEdit">
          <el-form-item label="资源" prop="cbRestypeId">
            <el-select v-model="resource.cbRestypeId" placeholder="请选择资源" filterable @change="getChange" style="width:98%" >
              <el-option
                v-for="item in resTypesList"
                :key="item.cbRestypeId"
                :label="item.restypeName"
                :value="item.cbRestypeId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="resourceName">
              <el-input v-model="resource.resourceName" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="描述">
              <el-input v-model="resource.remarks" placeholder="请输入描述" style="width:97%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form v-if="resourceDimes" :formList="resourceDimes" :gutter="20" :ruleForm="resource"/>
      </el-form>
    </template>
    <template v-if="disabled">
      <el-descriptions>
        <el-descriptions-item label="类型">{{resource.cbRestypeName}}</el-descriptions-item>
        <el-descriptions-item label="名称">{{resource.resourceName}}</el-descriptions-item>
        <el-descriptions-item label="描述">{{resource.remarks}}</el-descriptions-item>
        <el-descriptions-item v-for="(item, index) in resourceDimes" :key="index" :label="item.dimeName">
          <DimeTag :dimeObj="item"/>
        </el-descriptions-item>
      </el-descriptions>
      <template v-if="ofoFlowWork.thingtype">
        <el-divider></el-divider>
        <el-table
          :data="workThingList"
          style="width: 100%; margin-bottom: 16px"
          :header-cell-style="{background:'#F9F9FB',color:'#606266'}">
          <el-table-column
            prop="name"
            label="名称">
          </el-table-column>
          <el-table-column
            prop="code"
            label="代码">
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="描述">
          </el-table-column>
        </el-table>
        <el-button :disabled="!disabled" type="primary" @click="isShowThingForm = !isShowThingForm" size="mini" icon="el-icon-plus" v-if="isShare == '1'">新增内容</el-button>
        <template v-if="isShowThingForm">
          <el-form :model="thingForm" :disabled="!disabled" ref="thingForm" :rules="thingFormRules" size="small">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="thingForm.name" placeholder="请输入名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="描述">
                  <el-input v-model="thingForm.remarks"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <Form v-if="thingDimeList" :formList="thingDimeList" :gutter="20" :ruleForm="thingForm"/>
            <el-button type="primary" @click="handleAddThing('thingForm')" size="mini">确定</el-button>
          </el-form>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import DimeTag from '@/components/DimeTag.vue'
import Form from '@/components/Form.vue'
import url from '@/config/url'
import qs from 'qs'
  export default {
    props: {
      disabled: Boolean,
      resource: Object,
      resourceDimes: Array,
      ofoWorkId: String,
      ofoFlowWork: Object,
      resTypesList: Array,
      isEdit: Boolean,
      isShare: {
        type: String,
        default: '1'
      }
    },
    components: {
      Form,
      DimeTag,
    },
    data() {
      return {
        // resource: {
        //   resourceName: '',
        //   resourceRemarks: ''
        // },
        // resourceDimes: [],
        workThingList: [], // 查看详情时，活动内容列表
        isShowThingForm: false, // 查看详情时是否展示新增内容表单
        thingForm: {
          name: '',
          remarks: '',
          dataSourceConfig: []
        }, // 查看详情时，新增内容的表单对象
        // 表单规则
        rules: {
          resourceName: [{ required: true, message: "请输入资源名称", trigger: "change" }],
        },
        thingFormRules: {
          name: [{ required: true, message: "请输入内容名称", trigger: "change" }],
        },
        thingDimeList: [], // 查看详情时，新增内容表单的维度列表
      }
    },
    created () {
      // console.log(this.resourceDimes);
      if(this.disabled && this.resource.cbRestypeId) {
        this.getThingDimeList()
      }
    },
    methods: {
      getChange(e) {
        // console.log(e);
        // console.log(this.resource.cbRestypeId);
        this.$emit('getCbussDimeList', {cbRestypeId: e})
      },
      // 获取活动内容列表
      getWorkThingList() {
        if(this.ofoWorkId && this.resource.ofrResourceId) {
          this.$http.get(url.getWorkOfrThingList, {params: {ofoWorkId: this.ofoWorkId, ofrResourceId: this.resource.ofrResourceId}}).then(res => {
            // console.log(res.data);
            this.workThingList = res.data.data
          })
        }
        
      },
      getThingDimeList() {
        // 通过内容获取查看详情时的内容维度列表
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype == '0') {
          this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resource.cbRestypeId, dimetype: '2'}}).then(res => {
            this.thingDimeList = this.handleFormList(res.data.data)
            this.thingForm.dataSourceConfig = this.thingDimeList
          })
        }
      },
      handleAddThing(thingForm) {
        this.$refs[thingForm].validate((valid) => {
          // console.log(valid);
          if(valid) {
            let thingDimes = []
            if(this.thingDimeList) {
              // this.thingDimeList.forEach(item => {
              //   let obj = {
              //     cbdId: item.cbdId,
              //     dimeValue: item.dimeValue
              //   }
              //   thingDimes.push(obj)
              // })
              thingDimes = this.handleDimes(this.thingDimeList)
            }
            delete this.thingForm.dataSourceConfig
            this.$http.post(url.addWorkOfrThing, qs.stringify({
              ...this.thingForm,
              ofoWorkId: this.ofoWorkId,
              ofrResourceId: this.resource.ofrResourceId,
              thingDimes: JSON.stringify(thingDimes)
            })).then(res => {
              // console.log(res.data);
              if(res.data.code == 200) {
                this.$message.success(res.data.msg);
                this.getWorkThingList()
                this.isShowThingForm = false
              }
              
              for(let key in this.thingForm) {
                this.thingForm[key] = ''
              }
              this.thingDimeList.forEach(item => {
                item.dimeValue = '',
                item.dimeText = ''
              })
              this.thingDimeList = this.handleFormList(this.thingDimeList)
              this.thingForm.dataSourceConfig = this.thingDimeList
            })
          }else {

          }
        })
        
      },
    },
    watch: {
      'ofoWorkId'(newValue, oldValue) {
        if(this.disabled && newValue) {
          this.getWorkThingList()
        }
      },
      'resource'(newValue, oldValue) {
        if(this.disabled && newValue) {
          this.getThingDimeList()
        }
      },
      // resource: {
      //   handler(newValue, oldValue) {
      //     // console.log(newValue);
      //     if(this.disabled && newValue) {
      //       this.getThingDimeList()
      //       // console.log(11111);
      //     }
      //   },
      //   deep :true
      // }
    },
  }
</script>

<style lang="scss" scoped>

</style>