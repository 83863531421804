<template>
  <div class="entry-detail">
    <div class="main">
      <div style="text-align:center;margin-bottom:16px;font-weight:600;font-size:18px">{{allData.name}}</div>
      <el-descriptions :column=3>
        <el-descriptions-item label="代码">{{allData.code}}</el-descriptions-item>
        <el-descriptions-item label="类型">
          <DictTag :options="dropDown.reptType" :value="allData.repttype"/>
        </el-descriptions-item>
      </el-descriptions>
      <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
      <el-table
        :data="tableData"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}" ref="multipleTable"
        row-key="cfReptContId"
        style="width: 100%;margin-top:16px"
        v-loading="loading"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :default-expand-all="isExpand">
        <el-table-column
          prop="name"
          label="名称">
          <!-- <template slot-scope="scope">
            {{scope.row.acctName + ' : ' +  scope.row.acctCode}}
          </template> -->
        </el-table-column>
        <el-table-column
          prop="level"
          label="层级"
          width="50"
          align="center">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          width="50"
          align="center">
        </el-table-column>
        <el-table-column
          prop="lineid"
          label="行号"
          width="50"
          align="center">
        </el-table-column>
        
        <el-table-column prop="calctype" label="计算类型" align="center" width="100">
          <template slot-scope="scope">
            <DictTag :options="dropDown.zfType" :value="scope.row.calctype"/>
          </template>
        </el-table-column>
        <el-table-column
          prop="cashName"
          label="现金流"
          align="center">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            
            <el-button
              size="mini"
              icon="el-icon-notebook-2"
              @click.native.stop="handleSub(scope.row)"
              type="text"
            >科目
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click.native.stop="handleEdit(scope.row)"
              type="text"
            >编辑
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              @click.native.stop="handleDel(scope.row)"
              type="text"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-form-item label="名称" prop="name">
          <el-input type="text" v-model="ruleForm.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="父级" prop="pid">
          <treeselect v-model="ruleForm.pid" :options="options" :normalizer="normalizer1" placeholder="请选择父级" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="ruleForm.sort" controls-position="right" :min="1" style="width:100%"></el-input-number>
        </el-form-item>
        <el-form-item label="行号" prop="lineid">
          <el-input-number v-model="ruleForm.lineid" controls-position="right" :min="1" style="width:100%" ></el-input-number>
        </el-form-item>
        <el-form-item label="计算类型" prop="calctype">
          <el-select v-model="ruleForm.calctype" placeholder="请选择计算类型" style="width:100%">
            <el-option v-for="(item, index) in dropDown.zfType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="现金流" prop="cashId">
          <el-select v-model="ruleForm.cashId" placeholder="请选择现金流" style="width:100%" clearable filterable>
            <el-option 
              v-for="item in cashList"
              :label="item.name"
              :value="item.cashFlowId"
              :key="item.cashFlowId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="drawer"
      direction="rtl"
      size="75%">
      <div style="padding: 16px">
        <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd1" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit1" size="small">编辑</el-button>
        <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel1">删除</el-button>
        <el-table
          :data="tableData1"
          tooltip-effect="dark"
          style="width: 100%;margin-top:16px"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
          v-loading="loading1"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="cbSubjectNumber" label="代码"></el-table-column>
          <el-table-column prop="cbSubjectName" label="名称"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="accttype" label="类型" align="center">
            <template slot-scope="scope">
              <DictTag :options="dropDown.subjectAccountType" :value="scope.row.accttype"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click.native.stop="handleEdit1(scope.row)"
                type="text"
              >编辑
              </el-button>
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click.native.stop="handleDel1(scope.row)"
                type="text"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 20, 30, 40]"
          :page-size="pageSize"
          layout="->, total, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-top: 16px">
        </el-pagination>
      </div>
      
    </el-drawer>
    <el-dialog
      :title="title1"
      :visible.sync="dialogFormVisible1"
      :append-to-body="true"
      width="30%"
    >
      <el-form
        :model="ruleForm1"
        :rules="rules1"
        ref="ruleForm1"
        label-width="70px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-form-item label="科目" prop="cboactId">
          <treeselect v-model="ruleForm1.cboactId" :options="cbSubjectList" :normalizer="normalizer" placeholder="请选择科目" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="ruleForm1.sort" controls-position="right" :min="1" style="width:100%"></el-input-number>
        </el-form-item>
        <el-form-item label="类型" prop="accttype">
          <el-select v-model="ruleForm1.accttype" placeholder="请选择类型" style="width:100%">
            <el-option v-for="(item, index) in dropDown.subjectAccountType" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="submitForm1('ruleForm1')" :loading="isLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import { mapState } from 'vuex'
import handleTree from '@/utils/handleTree'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import qs from 'qs'
  export default {
    components: {
      Treeselect,
      DictTag
    },
    computed: {
      ...mapState ({
        dropDown: state => state.dropDown.dropDown
      })
    },
    data() {
      return {
        cfReptId: this.$route.params.cfReptId,
        allData: {},
        tableData: [],
        loading: true,
        dialogFormVisible: false,
        ruleForm: {
          name: "",
          calctype: '',
          cashId: '',
          lineid: '',
          sort: '',
          pid: null,
          cfreptId: this.$route.params.cfReptId
        },
        rules: {
          name: [{ required: true, message: "请输入名称", trigger: "submit" }]
        },
        // options: [],
        // 弹出层标题
        title: '',
        isLoading: false,
        cashList: [],
        drawer: false,
        drawerTitle: '',
        tableData1: [],
        loading1: true,
        dialogFormVisible1: false,
        title1: '',
        isExpand: true,
        edit: false,
        del: false,
        ruleForm1: {
          cboactId: null,
          accttype: '',
          sort: ''
        },
        rules1: {
          cboactId: [{ required: true, message: "请选择科目", trigger: "submit" }]
        },
        cfReptContId: '',
        cbSubjectList: [],
        ids: [],
        options: [],
        pageNum: 1, 
        pageSize: 10,
        total: 0,
      }
    },
    created () {
      this.getdata();
      this.getList();
    },
    methods: {
      getList() {
        this.$http.get(url.cffreptContList, {params: {cfReptId: this.cfReptId}}).then(res => {
          this.loading = false
          this.options = []
          if(res.data.code == 200) {
            this.tableData = handleTree(res.data.data, 'cfReptContId', 'pid')
            const data = {id: 0, label: '顶级节点', children: [] };
            // data.children = this.getTree(this.cbSubjectList);
            data.children = this.getTree1(this.tableData)
            this.options.push(data)
          }
        })
      },
      getCffreptContacctList() {
        this.$http.get(url.cffreptContacctList, {params: {
          cfReptContId: this.cfReptContId,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }}).then(res => {
          this.loading1 = false
          if(res.data.code === 200) {
            this.tableData1 = res.data.data.list
            this.total = res.data.data.total
          }
        })
      },
      handleAdd() {
        this.dialogFormVisible = true
        this.title = '新增报表内容'
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
        // console.log(itemList.length == 0 || cbSubjectList.length == 0);
        for(let key in this.ruleForm) {
          if(key == 'pid') {
            this.ruleForm[key] = null
          }else if(key == 'cfreptId') {
            this.ruleForm[key] = this.$route.params.cfReptId
          }else {
            this.ruleForm[key] = ''
          }
        }
      },
      handleSub(row) {
        // console.log(row);
        this.drawer = true
        this.drawerTitle = row.name
        this.cfReptContId = row.cfReptContId
        this.loading1 = true
        this.getCffreptContacctList()
      },
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        // if(this.itemList.length == 0 || this.cbSubjectList.length == 0){
        //   // console.log(11111111);
        //   this.getData()
        // }
        const id = row.cfReptContId
        this.dialogFormVisible = true
        this.title = '修改报表内容'
        this.$http.get(url.getReptCont, {params: {cfReptContId: id}}).then(res => {
          if(res.data.code === 200) {
            this.ruleForm = res.data.data
          }
        })
      },
      handleDel(row) {
        const id = row.cfReptContId
        this.$confirm('此操作将永久删除该报表内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.deleteCfReptCont, qs.stringify({cfReptContIds: id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.pageNum = 1
              this.loading = true
              this.getList()
            }
          })
          
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      // 提交表单
      submitForm(formName) {
        // console.log(formName);
        // console.log(this.$refs);
        // console.log(this.$refs[formName]);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // console.log(this.ruleForm);
            if(!this.ruleForm.cfReptContId) { // 新增报表
              this.$http.post(url.addReptCont, qs.stringify(this.ruleForm)).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.loading = true
                  this.getList()
                }
              })
            }else { // 修改报表
              this.$http.post(url.editReptCont, qs.stringify(this.ruleForm)).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible = false;
                  this.loading = true
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.cfReptContacctId)
        this.edit = rows.length === 1
        this.del = rows.length >= 1
      },
      handleAdd1() {
        this.dialogFormVisible1 = true
        this.title1 = '新增报表科目'
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm1.clearValidate();
        })
        // console.log(itemList.length == 0 || cbSubjectList.length == 0);
        for(let key in this.ruleForm1) {
          if(key == 'cboactId') {
            this.ruleForm1[key] = null
          }else {
            this.ruleForm1[key] = ''
          }
          
        }
      },
      handleEdit1(row) {
        if(this.$refs.ruleForm1) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm1.clearValidate();
          })
        }
        // if(this.itemList.length == 0 || this.cbSubjectList.length == 0){
        //   // console.log(11111111);
        //   this.getData()
        // }
        const id = row.cfReptContacctId || this.ids[0]
        this.dialogFormVisible1 = true
        this.title1 = '修改报表科目'
        this.$http.get(url.getReptContacct, {params: {cfReptContacctId: id}}).then(res => {
          if(res.data.code === 200) {
            this.ruleForm1 = res.data.data
          }
        })
      },
      handleDel1(row) {
        const id = row.cfReptContacctId || this.ids.toString()
        this.$confirm('此操作将永久删除该报表内容科目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.deleteReptContacct, qs.stringify({cfReptContacctIds: id})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.pageNum = 1
              this.loading1 = true
              this.getCffreptContacctList()
            }
          })
          
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      
      // 提交表单
      submitForm1(formName) {
        // console.log(formName);
        // console.log(this.$refs);
        // console.log(this.$refs[formName]);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            // console.log(this.ruleForm);
            if(!this.ruleForm1.cfReptContacctId) { // 新增报表
              this.$http.post(url.addReptContacct, qs.stringify({
                ...this.ruleForm1,
                cfreptcontId: this.cfReptContId
              })).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible1 = false;
                  this.pageNum = 1
                  this.loading1 = true
                  this.getCffreptContacctList()
                }
              })
            }else { // 修改报表
              this.$http.post(url.editReptContacct, qs.stringify(this.ruleForm1)).then(res => {
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg);
                  this.dialogFormVisible1 = false;
                  this.pageNum = 1
                  this.loading1 = true
                  this.getCffreptContacctList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      
      getdata() {
        // 获取报表详情
        this.$http.get(url.getRept, {params: {cfReptId: this.cfReptId}}).then(res => {
          if(res.data.code == 200) {
            this.allData = res.data.data
          }
          
        })
        // 获取现金流下拉列表
        this.$http.get(url.getCashList).then(res => {
          if(res.data.code == 200) {
            this.cashList = res.data.data
          }
        })
        // 获取科目列表
        this.$http.get(url.getCbSubjectList, {params: this.formInline}).then(res => {
          if(res.data.code == 200) {
            this.cbSubjectList = handleTree(res.data.data, 'cbSubjectId', 'pid')
            this.cbSubjectList = this.getTree(this.cbSubjectList);
            // const data = {id: 0, label: '顶级节点', children: [] };
            // data.children = this.getTree(this.cbSubjectList);
            // this.options = 
            // this.options.push(data)
            // console.log(this.options);
          }
        })
        // 获取报表类型下拉
        if(!this.dropDown.reptType){
          this.$http.get(url.getDataList, {params: {type: 'buss_rept_type'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setReptType', res.data.data)
            }
          })
        }
        // 获取报表类型下拉
        if(!this.dropDown.zfType){
          this.$http.get(url.getDataList, {params: {type: 'buss_zf'}}).then(res => {
            if(res.data.code == 200) {
              this.$store.commit('setZfType', res.data.data)
            }
          })
        }
      },
      /*转化tree标准格式*/
      getTree(tree) {
        let arr = [];
        // console.log(tree);
        // if (tree) {
          if(tree.length !== 0) {
            tree.forEach(item => {
              let obj = {};
              obj.label = item.subjectName + ': ' + item.subjectNumber;
              obj.id = item.cbSubjectId;
              // if(item.children)
              if (item.children && item.children.length > 0) {
                obj.children = this.getTree(item.children);
              }
              arr.push(obj);
            });
          }
        // }
        return arr
      },
      normalizer(node) {
        this.$nextTick(() => {
          if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.cbSubjectId,
          label: node.subjectName + ': ' + node.subjectNumber,
          children: node.children
        };
        })
      },
      
      /*转化tree标准格式*/
      getTree1(tree) {
        let arr = [];
        // console.log(tree);
        // if (tree) {
          if(tree.length !== 0) {
            tree.forEach(item => {
              let obj = {};
              obj.label = item.name;
              obj.id = item.cfReptContId;
              // if(item.children)
              if (item.children && item.children.length > 0) {
                obj.children = this.getTree1(item.children);
              }
              arr.push(obj);
            });
          }
        // }
        return arr
      },
      normalizer1(node) {
        this.$nextTick(() => {
          if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.cfReptContId,
          label: node.name,
          children: node.children
        };
        })
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.loading1 = true
        this.getCffreptContacctList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.loading1 = true
        this.getCffreptContacctList()
      },
    },
  }
</script>

<style lang="scss" scoped>
.entry-detail {
  /* padding: 16px; */
  .main {
    background: #FFFFFF;
    box-shadow: 2px 3px 8px 0px rgba(208,208,208,0.5);
    border-radius: 11px;
    padding: 16px;
    position: relative;
    .border-right {
      border-right: 1px solid #f6b8b4;
    }
    .border-left {
      border-left: 1px solid #f6b8b4;
    }
  }
}
</style>