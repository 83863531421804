<template>
  <div class="process">
    <!-- 流程页 -->
    <!-- 描述列表 -->
    <el-row :gutter="30">
      <el-col :span="8">
        <span class="text1">名称：</span>
        <span class="text2">{{allData.name}}</span>
      </el-col>
      <el-col :span="8">
        <span class="text1">代码：</span>
        <span class="text2">{{allData.code}}</span>
      </el-col>
      <el-col :span="8">
        <span class="text1">人员：</span>
        <span class="text2">{{allData.userId}}</span>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="8">
        <span class="text1">类型：</span>
        <span class="text2">{{allData.cfflowitemName}}</span>
      </el-col>
      <el-col :span="8">
        <span class="text1">日期：</span>
        <span class="text2">{{allData.itemDate}}</span>
      </el-col>
      <el-col :span="8">
        <span class="text1">发布：</span>
        <span class="text2"><DictTag :options="dropDown.documentstatus" :value="allData.documentstatus"/></span>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="8">
        <span class="text1">菜单：</span>
        <span class="text2"><DictTag :options="dropDown.itemType" :value="allData.itemType"/></span>
      </el-col>
      <el-col :span="8">
        <span class="text1">描述：</span>
        <el-tooltip class="item" effect="dark" :content="allData.remarks" placement="top">
          <span class="text2">{{allData.remarks}}</span>
        </el-tooltip>
      </el-col>
      <el-col :span="8">
        <el-button class="extend" type="text" @click="isShowExtend = !isShowExtend">扩展信息
          <i class="el-icon-arrow-down el-icon--right" v-if="!isShowExtend"></i>
          <i class="el-icon-arrow-up el-icon--right" v-if="isShowExtend"></i>
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="30" v-if="isShowExtend">
      <el-col :span="8" v-for="(item, index) in allData.dimeList" :key="index" >
        <span class="text1">{{item.dimeName}}：</span>
        <span class="text2">
          <DimeTag :dimeObj="item"/>
        </span>
      </el-col>
    </el-row>
    <!-- <template v-if="change"> -->
    <!-- 交易 -->
    <div class="deal">
      <div 
        v-for="(item, index) in allData.ofoFlowDealList" 
        :key="index" 
        :class="[currentDeal == index ? 'current-deal' : '', 'deal-name']" 
        @click="changeCurrentDeal(index)"
      >{{item.name}}</div>
    </div>
    <!-- 活动 -->
    <div class="activity">
      <div class="activity-nav">
        <div
          v-for="(item, index) in activityNameList"
          :key="index"
          :class="[currentActivity == index ? 'current-activity' : '', 'activity-name']"
          @click="changeCurrentActivity(index)"
        >{{item.name}}</div>
      </div>
      <div class="activity-list">
        <Activity :restype1="allData.ofoFlowDealList[currentDeal].restype1" :ofoFlowWork="activityNameList[currentActivity]"/>
      </div>
    </div>
  </div>
</template>

<script>
import url from '@/config/url'
import DictTag from '@/components/DictTag.vue'
import DimeTag from '@/components/DimeTag.vue'
import Activity from '@/components/project/activity.vue'
import { mapState } from 'vuex'
  export default {
    components: {
      Activity,
      DictTag,
      DimeTag
    },
    computed: {
      ...mapState({
        dropDown: (state) => state.dropDown.dropDown
      }),
    },
    data() {
      return {
        ofoItemId: this.$route.params.ofoItemId, // 项目id
        allData: {},
        isShowExtend: false, // 是否展示扩展信息
        currentDeal: 0, // 当前交易项
        activityNameList: [], // 活动列表
        currentActivity: 0, // 当前活动项
      }
    },
    created () {
      this.loadData()
    },
    methods: {
      // 改变当前交易
      changeCurrentDeal(index) {
        this.currentDeal = index
        this.activityNameList = this.allData.ofoFlowDealList[index].ofoFlowWorkList
        this.currentActivity = 0
      },
      // 改变当前活动
      changeCurrentActivity(index) {
        this.currentActivity = index
      },
      // handleClick(tab, event) {
      //   // console.log(tab, event);
      //   this.activeName = this.allData.ofoFlowDealList[tab.index].ofoDealId + '';
      //   this.tabIndex = tab.index
      //   this.activeName1 = this.allData.ofoFlowDealList[tab.index].ofoFlowWorkList.length ? this.allData.ofoFlowDealList[tab.index].ofoFlowWorkList[0].ofoFlowWorkId + '' : ''
      // },
      // handleClick1(tab, event) {
      //   this.activeName1 = this.allData.ofoFlowDealList[this.tabIndex].ofoFlowWorkList[tab.index].ofoFlowWorkId + ''
      // },
      // 发送请求
      loadData() {
        let ofoItemId = this.$route.params.ofoItemId
        this.$http.get(url.getItemDetail, {params: {
          ofoItemId
        }}).then(res => {
          // console.log(res);
          this.allData = res.data.data
          this.activityNameList = res.data.data.ofoFlowDealList[0].ofoFlowWorkList
          // this.activeName = res.data.data.ofoFlowDealList[0].ofoDealId + ''
          // this.activeName1 = res.data.data.ofoFlowDealList[0].ofoFlowWorkList[0].ofoFlowWorkId + ''
          // 格式化时间戳
          this.allData.itemDate = this.parseTime(res.data.data.itemDate, '{y}-{m}-{d}')
        })
        // 获取交易类型下拉
        if(!this.dropDown.kindDealType){
          this.$http.get(url.getDataList, {params: {type: 'buss_kind_dealType'}}).then(res => {
            this.$store.commit('setKindDealType', res.data.data)
          })
        }
        
      }
    },
  }
</script>

<style lang="scss" scoped>
.process {
  .el-col {
    display: flex;
    /* table-layout: fixed; */
    position: relative;
    span {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .text2 {
      width: calc(100% - 80px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .extend {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 99;
      padding: 0;
      border: 0;
    }
  }
  .deal {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 2px solid #F2F2F2;
    margin-bottom: 16px;
    .deal-name {
      padding: 6px 16px 6px 32px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #282828;
      line-height: 20px;
      box-sizing: content-box;
      background-color: #F2F2F2;
      position: relative;
      margin-right: 16px;
      cursor: pointer;
    }
    .deal-name::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 16px solid rgba($color: #000000, $alpha: 0);
      border-left: 16px solid #fff;
    }
    .deal-name::after {
      content: '';
      position: absolute;
      top: 0;
      right: -32px;
      border: 16px solid rgba($color: #000000, $alpha: 0);
      border-left: 16px solid #F2F2F2;
    }
    .current-deal {
      color: #fff;
      background-color: #0C75E5;
    }
    .current-deal::after {
      border-left: 16px solid #0C75E5;
    }
  }
  .activity {
    display: flex;
    .activity-nav {
      margin-right: 50px;
      .activity-name {
        background: #FFFFFF;
        border: 1px solid #0C75E5;
        margin-bottom: 16px;
        padding: 6px 10px;
        text-align: center;
        margin-right: 0;
        cursor: pointer;
      }
      .current-activity {
        color: #fff;
        background-color: #0C75E5;
      }
    }
    .activity-list {
      flex: 1;
    }
  }
}
.tabs1 {
      ::v-deep >.el-tabs__header {
        border: 0;
        padding-bottom: 30px;
        margin: 0;
        border-bottom: 1px solid #E8E8E8;
        margin-bottom: 16px;
      }
      ::v-deep .el-tabs__item {
        border: 0;
        padding: 6px 16px 6px 32px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        line-height: 20px;
        box-sizing: content-box;
        background-color: #F2F2F2;
        position: relative;
        margin-right: 16px;
      }
      ::v-deep .el-tabs__item::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 16px solid rgba($color: #000000, $alpha: 0);
        border-left: 16px solid #fff;
      }
      ::v-deep .el-tabs__item::after {
        content: '';
        position: absolute;
        top: 0;
        right: -32px;
        border: 16px solid rgba($color: #000000, $alpha: 0);
        border-left: 16px solid #F2F2F2;
      }
      ::v-deep .el-tabs__nav {
        border: 0;
      }
      ::v-deep .el-tabs__item.is-active {
        color: #fff;
        background-color: #0C75E5;
      }
      ::v-deep .el-tabs__item.is-active::after {
        border-left: 16px solid #0C75E5;
      }
      .tabs2 {
        ::v-deep .el-tabs__header {
          border: 0;
          margin-right: 52px;
        }
        ::v-deep .el-tabs__item {
          background: #FFFFFF !important;
          border: 1px solid #0C75E5 !important;
          margin-bottom: 16px;
          padding: 6px 10px !important;
          text-align: center !important;
          margin-right: 0 !important;
        }
        ::v-deep .el-tabs__item::before {
          border: 0 !important;
        }
        ::v-deep .el-tabs__item::after {
          border: 0 !important;
        }
        ::v-deep .el-tabs__item.is-active {
          color: #fff !important;
          background-color: #0C75E5 !important;

        }
      }
    }
</style>