import Accounting from '@/views/accounting/Index.vue'

// 分录
import Entry from '@/views/accounting/entry/Index.vue'
import Detail from '@/views/accounting/entry/detail.vue'
// 凭证
import Voucher from '@/views/accounting/voucher/Index.vue'
// 期末
import Final from '@/views/accounting/final/Index.vue'

// 账簿
import Ledger from '@/views/accounting/ledger/Index.vue'
// 明细账
import Ledgers from '@/views/accounting/detail/Index.vue'
// 科目余额
import subSurplus from '@/views/accounting/subject/Surplus.vue'
// 总分类账
import Classify from '@/views/accounting/ledger/classify.vue'
// 明细分类账
import Classifys from '@/views/accounting/detail/classify.vue'

// 现金
import Cash from '@/views/accounting/cash/Index.vue'
// 往来
import Dealings from '@/views/accounting/dealings/Index.vue'
// 资产
import Asset from '@/views/accounting/asset/Index.vue'
// 存货
import Inventory from '@/views/accounting/inventory/Index.vue'
// 成本
import Cost from '@/views/accounting/cost/Index.vue'

// 报表
import Report from '@/views/accounting/report/Index.vue'
// 报表统计
import Count from '@/views/accounting/report/Count.vue'
// 报表详情
import ReportDetail from '@/views/accounting/report/Detail.vue'

// 基础
import Basis from '@/views/accounting/basis/Index.vue'
// 科目管理
import Subject from '@/views/accounting/subject/Index.vue'
// 报表管理
import ReportManage from '@/views/accounting/mReport/Index.vue'
// 报表管理详情
import ReportManageDetail from '@/views/accounting/mReport/detail.vue'

import Develop from '@/views/develop/Index.vue'


// console.log(JSON.parse(window.sessionStorage.getItem('secondMenu')));
const routes = [
  {
    path: '/accounting',
    name: 'accounting',
    redirect: '/accounting/entry',
    // redirect: JSON.parse(window.sessionStorage.getItem('secondMenu'))[0].path,
    component: Accounting,
    children: [
      {
        path: 'entry',
        name: 'entry',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Entry
      },
      {
        path: 'vDetail/:offOjdtId',
        name: 'voucherDetail',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Detail
      },
      {
        path: 'subject',
        name: 'subject',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Subject
      },
      {
        path: 'ledger',
        name: 'ledger',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Ledger
      },
      {
        path: 'voucher',
        name: 'voucher',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Voucher
      },
      {
        path: 'detail',
        name: 'detail',
        component: Ledgers
      },
      {
        path: 'reports',
        name: 'accountReport',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Report
      },
      {
        path: 'reports/count',
        name: 'count',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Count
      },
      
      {
        path: 'reports/:cfReptResultId',
        name: 'rDetail',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: ReportDetail
      },
      {
        path: 'final',
        name: 'final',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Final
      },
      {
        path: 'mReport',
        name: 'mReport',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: ReportManage
      },
      {
        path: 'mReport/:cfReptId',
        name: 'mReportDetail',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: ReportManageDetail
      },
      
      {
        path: 'basis',
        name: 'basis',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Basis
      },
      {
        path: 'cash',
        name: 'cash',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component:Cash
      },
      {
        path: 'subSurplus',
        name: 'subSurplus',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: subSurplus
      },
      {
        path: 'dealings',
        name: 'dealings',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Dealings
      },
      {
        path: 'inventory',
        name: 'inventory',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Inventory
      },
      {
        path: 'cost',
        name: 'cost',
        // meta: {
        //   keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        // },
        component: Cost
      },
      {
        path: 'asset',
        name: 'asset',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Asset
      },
      {
        path: 'classify',
        name: 'classify',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Classify
      },
      {
        path: 'classifys',
        name: 'classifys',
        meta: {
          keepAlive: true, //添加这个作为标志符，表明该页面需要保留状态
        },
        component: Classifys
      },
      {
        path: '*',
        name: 'develop',
        component: Develop
      },
    ]
  }
]

export default routes