<template>
  <div class="resource">
    <div class="sift">
      <div class="title">内容查询</div>
      <div class="inquire">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
          <el-form-item>
            <strong slot="label">类型</strong>
            <el-select v-model="formInline.cbRestypeId" placeholder="请选择类型" filterable @change="inquire" >
              <el-option-group
                v-for="group in resource"
                :key="group.id"
                :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item>
            <strong slot="label">名称</strong>
            <el-input
              v-model="formInline.name"
              placeholder="请输入名称"
              clearable
              @keyup.enter.native="inquire"
              @clear="inquire"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
            <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd" size="small">新建</el-button>
        <el-button icon="el-icon-edit-outline" plain type="success" :disabled="!edit" @click="handleEdit" size="small">编辑</el-button> -->
      </div>
    </div>
    <div class="detail">
      <div class="title">内容列表</div>
      <el-table
        :data="tableData" ref="table"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        :height="tableHeight"
        :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      >
        <el-table-column prop="name" label="名称" fixed="left">
          <template slot-scope="scope">
            <a @click="handleDetail(scope.row)" class="link">{{scope.row.name}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="ofrResourceName" label="资源" width="180" fixed="left">
        </el-table-column>
        <el-table-column v-for="(item, index) in cbussDimeList" :key="index" :prop="item.dimeName" :label="item.dimeName" width="150">
          <template slot-scope="scope">
            <div v-for="(val, id) in scope.row.obussDimeList" :key="id">
              <div v-if="item.cbdId == val.cbdId">{{val.dimeText}}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="pageSize"
        layout="->, total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :title="viewTitle"
      :visible.sync="dialogViewVisible"
      :append-to-body="true"
    >
      <el-descriptions :column="2">
        <el-descriptions-item label="名称">{{ruleForm.name}}</el-descriptions-item>
        <el-descriptions-item label="代码">{{ruleForm.code}}</el-descriptions-item>
        <el-descriptions-item label="状态">
          <DictTag :options="dropDown.documentstatus" :value="ruleForm.documentstatus"/>
        </el-descriptions-item>
        <el-descriptions-item label="描述">{{ruleForm.remarks}}</el-descriptions-item>
        <el-descriptions-item v-for="(item, index) in formList" :key="index" :label="item.dimeName + '*'">
          {{item.dimeText}}
        </el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogViewVisible = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="handleEdit1(ruleForm.ofrThingId)">编 辑</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/config/url";
import { mapState } from 'vuex'
import DictTag from '@/components/DictTag.vue'
export default {
  components: {
    DictTag
  },
  computed: {
    ...mapState ({
      dropDown: state => state.dropDown.dropDown
    })
  },
  data() {
    return {
      formInline: {
        cbRestypeId: "",
        name: "",
        code: ""
      },
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      resource: [],
      dialogViewVisible: false,
      viewTitle: '',
      ruleForm: {},
      formList: [],
      cbussDimeList: [],
      tableHeight: 0
    };
  },
  created() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 245;
    })
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.tableHeight = window.innerHeight - 245;
      })()
    }
    // 获取资源类型列表
    this.$http.get(url.getResTypeTree).then(res => {
      if(res.data.code == 200) {
        this.resource = res.data.data
        this.formInline.cbRestypeId = res.data.data[0].children[0].id
        this.getList();
        this.getCbussDimeList()
      }
    })
  },
  methods: {
    // 查询按钮
    inquire() {
      this.loading = true
      this.pageNum = 1
      this.getList()
      this.getCbussDimeList()
    },
    // 重置按钮
    reset() {
      for(let key in this.formInline) {
        this.formInline[key] = ""
      }
      this.formInline.cbRestypeId = res.data.data[0].children[0].id
      this.loading = true
      this.pageNum = 1
      this.getList()
      this.getCbussDimeList()
    },
    // 获取维度列表
    getCbussDimeList() {
      this.$http.get(url.getCbussDimeList, { params: {
        cbussId: this.formInline.cbRestypeId,
        dimetype: '2'
      } }).then((res) => {
        if(res.data.code == 200) {
          this.cbussDimeList = res.data.data
          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        }
      });
    },
    // 获取字典数据
    getList() {
      // 获取列表数据
      this.$http.get(url.getAllOfrThingList, {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline
        }
      }).then(res => {
        // console.log(res);
        if(res.data.code == 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.loading = true
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.loading = true
      this.getList()
    },
    // 查看详情按钮
    handleDetail(row) {
      this.viewTitle = row.name
      this.dialogViewVisible = true
      this.$http.get(url.getOfrThing, {params: {ofrThingId: row.ofrThingId}}).then(res => {
        if(res.data.code === 200) {
          // console.log(res.data);
          this.ruleForm = res.data.data.ofrThing
          this.formList = res.data.data.dimensionList
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.resource {
  // padding: 16px;
  .sift,.detail {
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 3px 8px 0px rgba(208, 208, 208, 0.5);
    border-radius: 11px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
  }
  .title {
    height: 14px;
    font-size: 14px;
    font-family: STHeitiSC-Medium, STHeitiSC;
    font-weight: 500;
    color: #282828;
    line-height: 15px;
    padding-left: 8px;
    border-left: #0c75e5 2px solid;
    margin-bottom: 16px;
    font-weight: bolder;
  }
  .detail {
    margin-bottom: 0;
    .el-table {
      margin-bottom: 39px;
    }
  }
}
::v-deep .el-dialog {
  margin: 0 auto;
  max-height: calc(100% - 126px);
  /* height: calc(100% - 72px); */
}
::v-deep .el-dialog__body {
  padding: 10px 0 10px 20px;
  max-height: calc(100vh - 300px);
  /* height: calc(100vh - 206px); */
  overflow: auto;
}
</style>
