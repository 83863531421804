<template>
  <div class="tab1">
    <el-form :inline="true" :model="thingFormInline" class="demo-form-inline" size="small" @submit.native.prevent>
      <el-form-item>
        <strong slot="label">{{name}}</strong>
        <el-input v-model="thingFormInline.name" :placeholder="`请输入${name}`" clearable @keyup.enter.native="inquire" @clear="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="inquire" style="background:#0C75E5">查询</el-button>
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" plain size="small" icon="el-icon-plus" @click="handleAdd" v-if="judgeButton('add')">新增</el-button>
    <el-button type="success" plain size="small" icon="el-icon-edit" :disabled="!edit" @click="handleEdit" v-if="judgeButton('edit')">编辑</el-button>
    <el-button type="danger" plain size="small" icon="el-icon-delete" :disabled="!del" @click="handleDel" v-if="judgeButton('delete')">删除</el-button>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%;margin-top:16px"
      @selection-change="handleSelectionChange"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      v-loading="loading">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="name"
        :label="name"
        width="200">
        <template slot-scope="scope">
          <a @click="handleView(scope.row)" class="link">{{scope.row.name}}</a>
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        prop="remarks">
      </el-table-column>
      <el-table-column 
        label="操作"
        fixed="right"
        width="120">
        <template slot-scope="scope">
          <el-button
            size="mini" icon="el-icon-edit" type="text"
            @click="handleEdit(scope.row)" v-if="judgeButton('edit')">编辑</el-button>
          <el-button
            size="mini" icon="el-icon-delete" type="text"
            @click="handleDel(scope.row)" v-if="judgeButton('delete')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30, 40]"
      :page-size="pageSize"
      layout="->, total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        style="max-height: 50vh; overflow-y: auto;overflow-x:hidden"
        label-position="right"
      >
        <el-row :gutter="20" v-if="resData.kind == '1' && ruleForm.ofrThingId">
          <el-col :span="12">
            <el-form-item :label="`${resourceName}${name}`" prop="name">
              <el-input
                v-model="ruleForm.name"
                :placeholder="`请输入${resourceName}${name}`"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗位" prop="ofrResourceId">
              <el-select
                v-model="ruleForm.ofrResourceId"
                placeholder="请选择岗位"
                style="width: 97%"
              >
                <el-option
                  v-for="(item, index) in postList"
                  :key="index"
                  :label="item.resourceName"
                  :value="item.ofrResourceId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="resData.kind != '1' || !ruleForm.ofrThingId">
          <!-- <el-col :span="resData.isPrivate == '0' ? 12 : 24"> -->
          <el-col :span="24">
            <el-form-item :label="`${resourceName}${name}`" prop="name" >
              <el-input
                v-model="ruleForm.name"
                :placeholder="`请输入${resourceName}${name}`"
                style="width:98%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <Form :formList="formList" :gutter="20" :ruleForm="ruleForm"/>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="isLoading">确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="viewTitle"
      :visible.sync="dialogViewVisible"
      :append-to-body="true"
    >
      <el-descriptions :column="2">
        <el-descriptions-item :label="name">{{ruleForm.name}}</el-descriptions-item>
        <el-descriptions-item label="代码">{{ruleForm.code}}</el-descriptions-item>
        <el-descriptions-item label="状态">
          <DictTag :options="dropDown.documentstatus" :value="ruleForm.documentstatus"/>
        </el-descriptions-item>
        <el-descriptions-item label="描述">{{ruleForm.remarks}}</el-descriptions-item>
        <el-descriptions-item v-for="(item, index) in formList" :key="index" :label="item.dimeName + '*'">{{item.dimeText}}</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogViewVisible = false">关 闭</el-button>
        <el-button type="primary" @click="handleEdit1(ruleForm)">编 辑</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
import {mapState} from 'vuex'
import DictTag from '@/components/DictTag.vue'
import Form from '@/components/Form.vue'
import DimeTag from '@/components/DimeTag.vue'
  export default {
    props: {
      resourceName: {
        type: String,
        default: ''
      },
      ofrResourceId: [String, Number],
      resData: Object
    },
    components: {
      DictTag,
      Form,
      DimeTag
    },
    computed: {
      ...mapState({
        dropDown: state => state.dropDown.dropDown,
        currentMenu: (state) => state.tags.currentMenu,
      })
    },
    data() {
      var validateName = async (rule, value, callback) => {
        if (!value) {
          callback(new Error("请输入名称"));
        } else if (this.resData.kind == '1') {
          await this.$http.get(url.getOfrThingByName, {params: {
            cbRestypeId: this.resData.cbRestypeId,
            name: value,
            ofrThingId: this.ruleForm.ofrThingId
          }}).then(res => {
            this.checkCode = res.data.data
          })
          if(this.checkCode == '1') {
            callback(new Error("名称已存在，请重新输入！"));
          }else {
            callback()
          }
        }else {
          
        }
      };
      return {
        dialogFormVisible: false,
        ruleForm: {
          name: "",
          ofrResourceId: '',
          remarks: "",
          dataSourceConfig: []
        },
        rules: {
          name: [{ required: true, validator: validateName, trigger: "blur" }]
        },
        // 弹出层标题
        title: '',
        edit: false,
        del: false,
        ids: [],
        loading: true,
        tableData: [],
        formList: [],
        isLoading: false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        checkCode: '',
        dialogViewVisible: false,
        viewTitle: '',
        postList: [],
        thingFormInline: {
          name: ''
        },
        userList: [],
        name: ""
      }
    },
    mounted() {
      this.$http.get(url.infoThingalias + this.resData.cbRestypeId + '/' + 0).then(res => {
        if(res.data.code == 200 && res.data.data) {
          this.name = res.data.data['0']
        }else {
          this.name = '名称'
        }
      })
      this.getList()
    },
    methods: {
      judgeButton(type) {
        return this.currentMenu.perms.some(item => item.menuType == 'F' && item.perms == `${this.currentMenu.kind}:${type}`)
      },
      // 拿资源维度列表
      getList() {
        this.loading = true
        this.$http.get(url.getOfrThingList, {params: {
          cbRestypeId: this.resData.cbRestypeId,
          ofrResourceId: this.ofrResourceId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.thingFormInline.name
        }}).then(res => {
          // console.log(res);
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        })
      },
      getFormList() {
        this.$http.get(url.getCbussDimeList, {params: {cbussId: this.resData.cbRestypeId, dimetype: '2'}}).then(res => {
          this.formList = this.handleFormList(res.data.data)
          this.ruleForm.dataSourceConfig = this.formList
        })
      },
      inquire() {
        this.pageNum = 1
        this.getList()
      },
      reset() {
        this.thingFormInline.name = ''
        this.pageNum = 1
        this.getList()
      },
      // 表格选中框事件
      handleSelectionChange(rows) {
        this.ids = rows.map(item => item.ofrThingId)
        this.edit = rows.length === 1
        this.del = rows.length
      },
      handleView(row) {
        // console.log(row);
        this.viewTitle = row.name
        this.dialogViewVisible = true
        this.$http.get(url.getOfrThing, {params: {ofrThingId: row.ofrThingId}}).then(res => {
          if(res.data.code === 200) {
            // console.log(res.data);
            this.ruleForm = res.data.data.ofrThing
            this.formList = res.data.data.dimensionList
            this.ruleForm.dataSourceConfig = this.formList
          }
        })
      },
      // 表格的修改按钮
      handleEdit(row) {
        if(this.$refs.ruleForm) {
          this.$nextTick(() => {
            // 清除表单校验效果
            this.$refs.ruleForm.clearValidate();
          })
        }
        const id = row.ofrThingId || this.ids[0]
        this.title = `修改${this.resourceName}`
        this.dialogFormVisible = true
        this.$http.get(url.getOfrThing, {params: {ofrThingId: id}}).then(res => {
          if(res.data.code === 200) {
            this.ruleForm = res.data.data.ofrThing
            this.formList = res.data.data.dimensionList
            this.ruleForm.dataSourceConfig = this.formList
          }
        })
        this.$http.get(url.getOfrResourceList, {params: {
          cbRestypeId: this.resData.cbRestypeId,
          pageNum: 1,
          pageSize: 1000
        }}).then(res => {
          if(res.data.code == 200) {
            this.postList = res.data.data.list
          }
        })
      },
      // 表格的修改按钮
      handleEdit1(row) {
        this.dialogViewVisible = false
        this.handleEdit(row)
      },
      // 表格的删除按钮
      handleDel(row) {
        // this.selected = row
        const ids = row.ofrThingId || this.ids.toString()
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(url.removeOfrThing, qs.stringify({ofrThingIds: ids})).then(res => {
            if(res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList()
            }
          })
          
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isLoading = true
            let dimes = []
            if(this.formList.length != 0) {
              dimes = this.handleDimes(this.formList)
            }
            
            let query = {
              ofrResourceId: this.ofrResourceId,
              cbRestypeId: this.resData.cbRestypeId,
              name: this.ruleForm.name,
              documentstatus: '1',
              dimes: JSON.stringify(dimes),
              remarks: this.ruleForm.remarks,
            }
            // console.log(this.ruleForm.ofrThingId);
            if(!this.ruleForm.ofrThingId) {
              // console.log(1111);
              this.$http.post(url.addOfrThing, qs.stringify(query)).then(res => {
                // console.log(res);
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }else {
              // console.log(22222222222);
              this.$http.post(url.editOfrThing, qs.stringify({...this.ruleForm, dimes: JSON.stringify(dimes)})).then(res => { 
                this.isLoading = false
                if(res.data.code === 200) {
                  this.$message.success(res.data.msg)
                  this.dialogFormVisible = false
                  this.getList()
                }
              })
            }
          } else {
            return false;
          }
        });
      },
      handleAdd() {
        this.title = `新增${this.resourceName}`
        this.$nextTick(() => {
          // 清除表单校验效果\
          this.$refs.ruleForm.clearValidate();
        })
        this.dialogFormVisible = true
        // 初始化表单内容
        for(let key in this.ruleForm) {
          if(key == 'documentstatus') {
            this.ruleForm[key] = "1"
          }else if(key == 'dataSourceConfig') {
            this.ruleForm[key] = []
          }else {
            this.ruleForm[key] = ""
          }
        }
        this.getFormList()
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getList()
      },
    },
    watch: {
      ofrResourceId(newValue, oldValue) {
        // console.log(newValue);
      }
    },
  }
</script>

<style lang="scss" scoped>
.tab1 {
  .el-table {
    margin-bottom: 39px;
  }
}
::v-deep .el-dialog__body {
  padding: 30px 0 30px 20px;
}
</style>