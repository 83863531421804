<template>
  <div>
    <el-tabs type="border-card" v-model="activeId" @tab-click="handleClick">
      <!-- <div style="height: 300px"> -->
        <!-- <expectancyEcharts /> -->
      <!-- </div> -->
      <el-tab-pane v-for="(item, index) in cflowReptList" :key="index" :label="item.showName" :name="item.cfFlowReptId + ''">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          size="mini"
          style="margin-bottom:16px"
          @change="handleChange"
          :picker-options="pickerOptions"
          >
        </el-date-picker>
        <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
          v-if="(item.cfFlowReptId + '') == activeId"
          :cell-style="{'vertical-align':'top'}"
          :height="tableHeight"
          v-loading="loading"
          default-expand-all
          row-key="id"
          :tree-props="{children: 'secondList', hasChildren: 'hasSecondList'}"
        >
          <el-table-column prop="name" label="名称" min-width="100" v-if="item.flowRepttype != '6' && item.flowRepttype != '7'"></el-table-column>
          <el-table-column v-for="(val, id) in item.cfFlowReptcontList" :key="id" :prop="val.prop" :label="val.label" :align="(item.flowRepttype == '6' || item.flowRepttype == '7' )? 'center' : 'right'" header-align="center" :min-width="item.flowRepttype == '4' ? '275' : '160'">
            <template slot-scope="scope">
              <div v-if="item.flowRepttype == '6' || item.flowRepttype == '7'">
                <template v-if="val.worktype == 'b'">{{scope.row.b[val.prop]}}</template>
                <template v-else-if="val.worktype == 'n'">{{scope.row.n[val.prop]}}</template>
                <template v-else-if="val.worktype == 'p'">{{scope.row.p[val.prop]}}</template>
                <template v-else>{{scope.row.b[val.prop]}}</template>
              </div>
              <div v-for="(el, cfFlowReptcontId) in scope.row.list" :key="cfFlowReptcontId" v-if="item.flowRepttype != '6' && item.flowRepttype != '7' && el.cfFlowReptcontId == val.cfFlowReptcontId">
                <el-table
                  v-if="item.flowRepttype == '4'"
                  :data="el.workList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
                >
                  <el-table-column prop="name" label="名称" header-align="center" min-width="100">
                    <template slot-scope="scope1">
                      <a class="link" @click="handleDetail(scope1.row)" style="user-select: none;">{{scope1.row.name}}</a>
                    </template>
                  </el-table-column>
                  <el-table-column prop="ptotal" label="金额" align="right" min-width="150" header-align="center">
                    <template slot-scope="scope1">
                      {{scope1.row.ptotal | comdify}}
                    </template>
                  </el-table-column>
                </el-table>
                <template v-else>
                  <div v-if="val.labelType == '4'">{{el.reptValue | comdify}}</div>
                  <div v-else-if="val.labelType == '5'">{{el.reptValue | filter_handleTimeRange}}</div>
                  <div v-else-if="el.calcList">
                    {{handleCalc(el.calcList, el.showType)}}
                  </div>
                  <div v-else>{{el.reptValue}}</div>
                </template>
              </div>
              
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      top="59px"
      width="90%">
      <!-- 导航栏 -->
      <div class="nav-tabs">
        <div v-for="(item, index) in navList" :key="index" @click="checked(index)" :class="['nav-title', isActive == index ? 'isActive' : '']" v-show="item.isShow">
          <div class="nav-icon">
            <span :class="item.icon"></span>
          </div>
          <div class="nav-text">{{item.text}}</div>
        </div>
      </div>
      <Subsidiary 
        v-show="isActive == 0"
        :disabled="true" 
        :ofoFlowWork="ofoFlowWork" 
        :thingsList="thingsList"
        @getWorkThingList="getWorkThingList"
        :ofoWorkId="ofoWorkId"
        :total="total2"
        :pageNum="pageNum2"
        :pageSize="pageSize2"
        :ctotal2="subTotal.ctotal"
        :ctotalNovat2="subTotal.ctotalNovat"
        :ctotalVat2="subTotal.ctotalVat"
        :ptotal2="subTotal.ptotal"
        :ptotalNovat2="subTotal.ptotalNovat"
        :ptotalVat2="subTotal.ptotalVat"
        ref="subsidiary"
        :workConfig="workConfig"
      />
      <Product 
        v-show="isActive == 1"
        :disabled="true"
        :ofoFlowWork="ofoFlowWork" 
        :productsList="productsList"
        :ofoWorkId="ofoWorkId"
        @getWorkResList="getWorkResList"
        :total="total1"
        :pageNum="pageNum1"
        :pageSize="pageSize1"
        :ctotal1="proTotal.ctotal"
        :ctotalNovat1="proTotal.ctotalNovat"
        :ctotalVat1="proTotal.ctotalVat"
        :ptotal1="proTotal.ptotal"
        :ptotalNovat1="proTotal.ptotalNovat"
        :ptotalVat1="proTotal.ptotalVat"
        :workConfig="workConfig"
        ref="product"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import expectancyEcharts from '@/components/echarts/expectancy.vue'
import url from '@/config/url'// 产品及服务
import Product from '@/components/project/activity/product.vue'
// 明细
import Subsidiary from '@/components/project/activity/subsidiary.vue'
import handleWorkConfigList from '@/utils/handleWorkConfigList'
  export default {
    components: {
      expectancyEcharts,
      Product,
      Subsidiary
    },
    props: {
      cfFlowItemId: [String, Number],
      ofoItemId: {
        type: [String, Number]
      }
    },
    data() {
      return {
        tableData: [],
        cflowReptList: [],
        activeId: '',
        // ofoItemId: this.$route.params.ofoItemId,
        date: [],
        dialogVisible: false,
        productsList: [],
        pageNum1: 1,
        pageSize1: 10,
        total1: 0,
        loading: true,
        ofoWorkId: '',
        tableHeight: window.innerHeight - 357,
        ofoFlowWork: {},
        proTotal: {
          ctotal: 0,
          ctotalNovat: 0,
          ctotalVat: 0,
          ptotal: 0,
          ptotalNovat: 0,
          ptotalVat: 0,
        },
        subTotal: {
          ctotal: 0,
          ctotalNovat: 0,
          ctotalVat: 0,
          ptotal: 0,
          ptotalNovat: 0,
          ptotalVat: 0,
        },
        navList: [],
        isActive: 0,
        thingsList: [],
        pageNum2: 1,
        pageSize2: 10,
        total2: 0,
        title: '',
        workConfig: {}
      }
    },
    created () {
      this.ofoItemId = this.ofoItemId ? this.ofoItemId : this.$route.params.ofoItemId
      if(this.cfFlowItemId) {
        // console.log(111);
        this.getCflowReptList();
      }
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - 357;
      })
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.tableHeight = window.innerHeight - 357;
        })()
      }
      
    },
    methods: {
      // 处理计算符
      handleCalcu(calcu) {
        if(calcu == '1') {
          return '+'
        }else if(calcu == '2') {
          return '-'
        }else if(calcu == '3') {
          return '*'
        }else if(calcu == '4') {
          return '/'
        }else {
          return ''
        }
      },
      // 添加千分位
      comdify(n) {
        if(!n) return '';
        n = Number(n).toFixed(2)
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      handleCalc(list, showType) {
        // console.log(JSON.parse(JSON.stringify(list)));
        if(list.length && list.every(item => item.showValue)) {
          let arr = []
          let length = list.length
          for(let i = 0; i < (length - 1); i++) {
            arr.push(list[i].brackets && list[i].brackets.includes('(') ? list[i].brackets : '', list[i].value, list[i].brackets && list[i].brackets.includes(')') ? list[i].brackets : '', this.handleCalcu(list[i].calcu))
          }
          arr.push(list[length - 1].brackets && list[length - 1].brackets.includes('(') ? list[length - 1].brackets : '', list[length - 1].value, list[length - 1].brackets && list[length - 1].brackets.includes(')') ? list[length - 1].brackets : '')
          
          if(showType == '1') {
            return eval(arr.join('')).toFixed(4)
          }else if(showType == '2') {
            return this.comdify(eval(arr.join('')))
          }else if(showType == '3') {
            return (eval(arr.join('')) * 100).toFixed(0) + '%'
          }else {
            return eval(arr.join('')).toFixed(2)
          }
        }else {
          return ''
        }
      },
      checked(index) {
        this.isActive = index
      },
      async handleDetail(item) {
        this.productsList = this.thingsList = []
        this.title = item.name
        this.dialogVisible = true
        this.ofoWorkId = item.ofoWorkId
        this.$http.get(url.getFlowDealAndWork, {params: {ofoWorkId: this.ofoWorkId}}).then(res => {
          if(res.data.code == 200) {
            // this.ofoFlowDeal = res.data.data.ofoFlowDeal
            this.ofoFlowWork = res.data.data.ofoFlowWork
            // 获取资源行配置
            if(this.ofoFlowWork.checkConfig) {
              this.$http.get(url.getItemWorkConfig, {params: {
                ofoflowworkId: this.ofoFlowWork.ofoFlowWorkId
              }}).then(res => {
                if(res.data.code == 200) {
                  this.workConfig = res.data.data
                }
              })
            }else {
              this.workConfig = {}
            }
            this.getWorkResList({pageNum: 1, pageSize: 10})
            this.getWorkThingList({pageNum: 1, pageSize: 10})
            this.navList = [
              {
                icon: 'iconfont icon-lvzhou_mingxi',
                text: this.ofoFlowWork.thingtypeName + '（明细）',
                isShow: this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0',
              },
              {
                icon: 'iconfont icon-chanpin',
                text: this.ofoFlowWork.kindtypeName,
                isShow: this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0'
              },
            ]
            let navArr = []
            this.navList.forEach((item, index) => {
              if(item.isShow) {
                navArr.push(index)
              }
            })
            this.isActive = navArr[0]
            for(let key in this.proTotal) {
              this.proTotal[key] = res.data.data.work[key]
            }
          }
        })
        
      },
      getWorkResList(item) {
        // this.loading = true
        if(this.ofoFlowWork.kindtype && this.ofoFlowWork.workkindmanagetype != '0') {
            this.$http.get(url.getWorkResAndThingList, {params: {
            ofoWorkId: this.ofoWorkId,
            pageNum: item.pageNum,
            pageSize: item.pageSize,
            type: '1'
          }}).then(res => {
            this.loading = false
            if(res.data.code == 200) {
              this.productsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
              this.total1 = res.data.data.total
              this.pageNum1 = res.data.data.pageNum
              this.pageSize1 = res.data.data.pageSize
            }
          })
        }
        
      },
      // 获取明细列表
      getWorkThingList(item) {
        if(this.ofoFlowWork.thingtype && this.ofoFlowWork.workkindmanagetype != '0') {
          this.$http.get(url.getWorkResAndThingList, {params: {
            ofoWorkId: this.ofoWorkId,
            pageNum: item.pageNum,
            pageSize: item.pageSize,
            type: '2'
          }}).then(res => {
            if(res.data.code == 200) {
              this.thingsList = handleWorkConfigList(this.ofoFlowWork.checkConfig, res.data.data.list, this.workConfig)
              this.total2 = res.data.data.total
              this.pageNum2 = res.data.data.pageNum
              this.pageSize2 = res.data.data.pageSize
              for(let key in this.subTotal) {
                this.subTotal[key] = res.data.data[key]
              }
            }
          })
        }
      },

      getCflowReptList() {
        this.$http.get(url.getCflowReptList, {params: {
          cfFlowItemId: this.cfFlowItemId
        }}).then(res => {
          if(res.data.code == 200) {
            this.cflowReptList = res.data.data
            if(this.cflowReptList.length) {
              this.activeId = this.cflowReptList[0].cfFlowReptId + ''
              this.getItemRept()
            }
          }
        })
      },
      getItemRept() {
        this.tableData = []
        this.loading = true
        this.$http.get(url.getItemRept, {params: {
          cfFlowReptId: this.activeId,
          ofoItemId: this.ofoItemId,
          beginTime: this.date ? this.date[0] : '',
          endTime: this.date ? this.date[1] : ''
        }}).then(res => {
          this.loading = false
          if(res.data.code == 200) {
            this.tableData = res.data.data
            // if(this.cflowReptList.length) {
            //   this.activeId = this.cflowReptList[0].cfFlowReptId + ''
            // }
          }
        })
      },
      handleChange() {
        this.getItemRept()
      },
      handleClick(tab) {
        // console.log(this.activeId);
        this.getItemRept()
      },
    },
    watch: {
      cfFlowItemId: {
        handler(newValue, oldValue) {
          // console.log(222);
          // console.log(newValue);
          this.getCflowReptList();
        },
        deep: true
      }
      
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .el-table tbody tr:hover>td { 
    background: #fff!important
}
::v-deep .el-dialog {
  margin: 0 auto;
  max-height: calc(100% - 72px);
  height: auto;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
  max-height: calc(100vh - 206px);
  height: auto;
  overflow: auto;
}
.nav-tabs {
  display: flex;
  /* overflow: hidden; */
  /* height: 50px; */
  /* background-color: yellow; */
  margin-bottom: 16px;
  margin-right: 20px;
  border-bottom: 1px solid #eeeeee;
  .nav-title {
    display: flex;
    padding-bottom: 16px;
    /* border-bottom: #409eff 2px solid; */
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    .nav-icon {
      width: 40px;
      height: 40px;
      background-color: #FFFFFF;
      border-radius: 50%;
      margin-right: 8px;
      text-align: center;
      line-height: 40px;
      border: #409eff 1px solid;
      color: #409eff;
      .iconfont {
        font-size: 18px;
      }
    }
  }
  .isActive {
    border-bottom: #409eff 3px solid;
    color: #409eff;
    .nav-icon {
      background-color: #409eff;
      color: #FFFFFF;
    }
  }
}
</style>