<template>
  <div>
    <el-table
      :data="ojdtacctList"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9FB', color: '#606266' }"
    >
      <el-table-column prop="remarks" label="摘要" show-overflow-tooltip></el-table-column>
      <el-table-column prop="acctName" label="会计科目"></el-table-column>
      <el-table-column prop="debit" label="借方金额" align="right" width="150">
        <template slot-scope="scope">
          {{scope.row.debit | comdify('no')}}
        </template>
      </el-table-column>
      <el-table-column prop="credit" label="贷方金额" align="right" width="150">
        <template slot-scope="scope">
          {{scope.row.credit | comdify('no')}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      ojdtacctList: Array,
    },
  }
</script>

<style lang="scss" scoped>

</style>